import React, { useEffect } from 'react';

// CSS Import
import '../../assets/css/InputComponents/textareaInput.css';

export default function TextareaInput({ title, field, value, setValue, placeholder, explanation }) {
  
  useEffect(() => {
    if (value[field] === '')
      delete value[field];
  }, [value[field]]);

  return (
    <div className="TextareaInput">    
      <div className="div_align_textareaInput">
        <div className='div_label_input_textarea'>
          <label htmlFor={field + 'Form'} className="label_textarea_input">
            <strong>{title}</strong>
          </label>
        </div>
        <div className='div_align_explanation_input div_align_explanation_textarea'>
          <textarea className='textarea_input_comp' id={field + 'Form'} onChange={(e) => setValue({ 
            ...value, 
            [field]: e.target.value,
            original: {
              ...value.original,
              [field]: e.target.value,
            },
          })} placeholder={placeholder || ''} value={value[field]} />
          <div className='div_explanation_input div_explanation_textarea'>
            <p className='subtext_explanation_input'>{explanation}</p>
          </div>
        </div>
      </div>
    </div>
  );
}