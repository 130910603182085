import React, { useState, useEffect, useContext } from 'react';

// eslint-disable-next-line import/no-cycle
import { MyContext } from '../../component/SidebarAdmin';

// Assets imports
import SearchIcon from '../../assets/imgs/search_icon.svg';

// Components imports
import { RodapeAdmin } from '../../component/RodapeAdmin';
import UsersList from '../../component/ListComponents/UsersList';
import EditUsers from '../../component/EditComponents/EditUsers';
import Loading from '../../component/Loading';
import NoResultsComponent from '../../component/NoResultsComponent';

// Firebase import
import firebase from '../../config/Firebase';

export default function UsersAdmin() {
  const [filter, setFilter] = useState('name');
  const [editPopup, setEditPopup] = useState(false);
  const [selectedItem, setSelectedItem] = useState(undefined);
  const [search, setSearch] = useState('');
  const [places, setPlaces] = useState();
  const [action, setAction] = useState('Editar');
  const [filteredPlaces, setFilteredPlaces] = useState();
  const { city, role } = useContext(MyContext);
  const rolesControl = ['admin', 'masterAdmin'];

  const buildQuery = () => {
    const currFilter = filter === 'searchBar' ? 'name' : filter;

    const docs = firebase.firestore()
      .collection('users')
      .where('city', '==', city)
      .orderBy(currFilter);
    
    return docs;
  };

  const getFirstPage = async () => {
    const newDocs = await buildQuery().get();

    setPlaces(newDocs);
    setFilteredPlaces(newDocs);
  };

  useEffect(() => {
    if (city)
      getFirstPage();
  }, [city, filter]);

  useEffect(() => {
    if (!editPopup) {
      setSelectedItem(undefined);
      if (city)
        getFirstPage();
    }
  }, [editPopup]);

  const handleEdit = (doc) => {
    setSelectedItem(doc);
    setAction('Editar');
    setEditPopup(true);
  };

  const searchInventoryText = (doc) => {
    if (search === '')
      return true;
    
    const isInTitle = doc.data().name.toLowerCase().includes(search.toLowerCase());
    const isInEmail = doc.data().email.toLowerCase().includes(search.toLowerCase());
    return isInTitle || isInEmail;
  };

  const handleSearch = () => {
    if (places.docs) {
      let filteredPlaces = places.docs.filter((doc) => searchInventoryText(doc));
      filteredPlaces = { docs: filteredPlaces };
      setFilteredPlaces(filteredPlaces);
    }
  };

  if (!places || !city) {
    return <Loading />;
  }

  return (
    <div className='InventarioAdmin'>
      { editPopup ? <EditUsers setEditPopup={setEditPopup} doc={selectedItem} action={action} /> : 
        (<div className='body_inventory_adm'>
        <div className='div_title_inventory_adm'>
          <h1>Usuários</h1>
        </div>
        <div className='div_browse_inventory_adm'>
          <input type='text' className='input_browse_inventory' onChange={(e) => { setSearch(e.target.value); handleSearch(); }} placeholder='Buscar' />
          <button type="button" className='btn_browse_inventory_adm' disabled>
            <img src={SearchIcon} alt='Icone de pesquisa' className='search_icon_admin'/>
          </button>
        </div>
        { filteredPlaces && filteredPlaces.docs && filteredPlaces.docs.length > 0 ? (
        <div className='div_table_inventory_adm' id='div_table_inventory_adm'>
          <table className='table_inventory_adm'>
            <thead>
              <tr className="row_table_inventory th_table_admin">
                <td className='column_title_table_inventory th_table_admin' onClick={() => setFilter('name')}>Nome</td>
                <td className='column_title_table_inventory th_table_admin' onClick={() => setFilter('email')}>Email</td>
                <td className='column_title_table_inventory th_table_admin' onClick={() => setFilter('role')}>Cargo</td>
                <td className='column_title_table_inventory th_table_admin' onClick={() => setFilter('role')}>Cidades Administradas</td>
                <td className='column_title_table_inventory th_table_admin' onClick={() => setFilter('phoneNumber')}>Telefone</td>
                <td className='column_table_inventory th_table_admin'></td>
              </tr>
            </thead>
            <tbody>
              {
                filteredPlaces.docs.map((doc) => {
                  if (role !== 'contentManager' || !rolesControl.includes(doc.data().role))
                    return <UsersList doc={doc} key={doc.id} handleEdit={handleEdit} />;
                })
              }
            </tbody>
          </table>
        </div>) : <NoResultsComponent title={'Usuários'} search={search} /> }
      </div>)}
    </div>
  );
}