import React, { useEffect, useState } from 'react';

import InputCheckboxCustom from './InputComponents/InputCheckBoxCustom';

export default function PendenciesList({ doc, handleEdit, handleAddDocList }) {
  const [date, setDate] = useState();
  const [titleDoc] = useState(doc.data().lastDoc.Title || doc.data().currDoc.Title || doc.data().lastDoc.name || doc.data().currDoc.name || doc.data().lastDoc.titulo || doc.data().currDoc.titulo);
  const [typeDoc] = useState({
    Attractions: 'Inventário',
    Events: 'Eventos',
    Cities: 'Cidade/Região',
    RoteirosTrechos: 'Roteiro',
    Roteiros: 'Rota',
  });

  const getDate = (currentDate) => {
    const date = new Date(currentDate);
    return `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`;
  };

  const getCreateDate = async () => {
    setDate(getDate(await doc.data().createDate.seconds * 1000));
  };
  
  useEffect(() => {
    getCreateDate();
  }, []);

  return (
    <tr className="row_table_inventory body_table">
      <td className="check_table_inventory"><InputCheckboxCustom handleAction={handleAddDocList} doc={doc} listPend={false} nameCheck={'checkbox_pendencies'} /></td>
      <td className="title_table_inventory">{typeDoc[doc.data().type]}</td>
      <td className="title_table_inventory">{titleDoc}</td>
      <td className="title_table_inventory">{doc.data().city}</td>
      <td className="title_table_inventory">{doc.data().action}</td>
      <td className="title_table_inventory">{doc.data().requestor}</td>
      <td className="column_min_table_inventory">{date}</td>
      <td className="column_edit_table_inventory">
        <div className='div_mod_inventory'>
          <button className='btn_mod_adm' onClick={() => handleEdit(doc)}>Avaliar</button>
        </div>
      </td>
    </tr>
  );
}