import React from 'react';
import { Marker } from 'react-google-maps';
import { store } from '../redux/store';
import { UPDATE_PREVIEW_DATA } from '../redux/actionTypes';
import { EventWithParameters } from '../config/TrackingGA';

class MarkerWithOverlayView extends React.Component {
  shouldComponentUpdate() {
    return false;
  }

  handleMarkerClick = async () => {
    // Atualiza os dados do último marker clicado na Store

    const { attractive, reduxAttractive } = this.props; 

    if (document && document.activeElement && window.innerWidth <= 500) {
      document.activeElement.blur();
    }

    if (reduxAttractive === attractive) { 
      this.props.closePreview();
    } else {
      // Centraliza o map no pin
      
      this.props.handleCenterClick();
      
      store.dispatch({
        type: UPDATE_PREVIEW_DATA,
        attractive,
      });
    }

    // GA
    const { document: attractiveDocument, Type, Subtype } = this.props.attractive;
    EventWithParameters('User', 'OpenPreview', 'PreviewAberto', attractiveDocument, Type, Subtype);
  }

  render() {
    return (
      <Marker
        ref = {(c) => this.markerRef = c }
        key={this.props.key}
        position={this.props.position}
        icon={this.props.icon}
        onClick={ this.handleMarkerClick }
      />
    );
  }
}
export default MarkerWithOverlayView;
