/* eslint-disable no-restricted-globals */
import React from 'react';
import { css, StyleSheet } from 'aphrodite';
import { connect } from 'react-redux';
import Grid from '@material-ui/core/Grid';
import { toggleSearch, updatePreviewData, openPreview, setMapZoom, openDrawer, centerMap, hideSearch, closeDrawer } from '../redux/actions';
import CloseDrawerButton from './CloseDrawerButton';
import Swipe from 'react-easy-swipe';

const backgroundColor = (index) => {
  if (index % 6 === 0 || index % 6 === 1) return '#C700DF';
  if (index % 6 === 2 || index % 6 === 3) return '#666666';
  if (index % 6 === 4 || index % 6 === 5) return '#4B69C3';
};
const backgroundColorMobile = (index) => {
  if (index % 4 === 0) return '#4689F2';
  if (index % 4 === 1) return '#2F33BD';
  if (index % 4 === 2) return '#666666';
  if (index % 4 === 3) return '#DA3D77';
};

class SearchResults extends React.Component {
  handleCardClick = (attractive) => {
    const { isMobile } = this.props;
    if (isMobile)
      this.props.hideSearch();
    this.props.openDrawer({
      ...attractive,
    });
    // Edimar - Centralizar o Pin de acordo com o clique
    this.props.centerMap({
      lat: attractive.lat,
      long: attractive.long,
    });
    // Edimar - Dar o Zoom no mapa
    this.props.setMapZoom(18);
  }

  renderCard = (result, index) => {
    const attractive = result.item ? result.item : result;

    return (
        <Grid key={index} item xs={6} style={{ cursor: 'pointer' }}>
          <div
            className={css(styles.card)}
            style={{ backgroundColor: backgroundColor(index) }}
            onClick={() => { this.handleCardClick(result.item ? result.item : result); }}
          >
            {
              result.score !== undefined ?
                <span className={css(styles.cardTitle)}>{`${attractive.Title} [${result.score}]`}</span>
                :
                <span className={css(styles.cardTitle)}>{attractive.Title}</span>
            }
            <div className={css(styles.cardPinkLine)} />
            <div className={css(styles.cardInfo)}>
              <p className={css(styles.cardShortDescription)}>{attractive['Short Description']}</p>
            </div>
          </div>
        </Grid>
    );
  }

  renderCardMobile = (result, index) => {
    const attractive = result.item ? result.item : result;

    return (
        <Grid key={index} item xs={12} style={{ cursor: 'pointer' }}>
          <div
            className={css(styles.cardMobile)}
            style={{ backgroundColor: backgroundColorMobile(index) }}
            onClick={() => this.handleCardClick(result.item ? result.item : result)}
          >
            {
              result.score !== undefined ?
                <span className={css(styles.cardTitle)}>{`${attractive.Title} [${result.score}]`}</span>
                :
                <span className={css(styles.cardTitle)}>{attractive.Title}</span>
            }
            <div className={css(styles.cardPinkLine)} />
            <div className={css(styles.cardInfo)}>
              <p className={css(styles.cardShortDescription)}>{attractive['Short Description']}</p>
            </div>
          </div>
        </Grid>
    );
  }

  render() {
    const { open, results, value, isDrawerOpen, isMobile } = this.props;
    const height = isMobile ? screen.height - 63 : window.innerHeight - 63; // 63px é a altura do header.
    const width = (isDrawerOpen && !isMobile) ? window.innerWidth - 432 : '100%';
    if (!open) return null;

    if (!isMobile) {
      return (
        <div style={{ height: height, width: width }} className={css(styles.container)}>
          <p className={css(styles.pageTitle)}>Resultado de: <b>{value}</b></p>
            <div className={css(styles.resultsContainer)}>
              {
                results.length > 0 ?
                  <Grid container spacing={8}>
                    {
                      results.map((result, i) =>
                        this.renderCard(result, i))
                    }
                  </Grid>
                  :
                  <p className={css(styles.pageTitle)}>Não foi encontrado nenhum resultado</p>
              }
            </div>
            {
              isDrawerOpen &&
                <div style={{ left: width - 55 }} onClick={() => { this.props.closeDrawer(); }} className={css(styles.closeDrawerButton)}>
                  <CloseDrawerButton />
                </div>
            }
        </div>
      );
    }

    return (
      <Swipe
          onSwipeDown={(a, b) => {
            const distanceFromTop = document.querySelector('.scrollDivHeight').scrollTop;
            if (distanceFromTop === 0) {
              this.props.toggleSearch(false, [], '');
            }
          }}
        >
        <div style={{ height: height, width: width }} className={css(styles.containerMobile) + ' scrollDivHeight'}>
          <p className={css(styles.pageTitle)}>Arraste para baixo para voltar ao mapa</p>
          <div className={css(styles.greyLine)}/>
            <div className={css(styles.resultsContainer)}>
              {
                results.length > 0 ?
                  <Grid container spacing={8}>
                    {
                      results.map((result, i) =>
                        this.renderCardMobile(result, i))
                    }
                  </Grid>
                  :
                  <p className={css(styles.pageTitle)}>Não foi encontrado nenhum resultado</p>
              }
            </div>
        </div>
      </Swipe>
    );
  }
}

const styles = StyleSheet.create({
  card: {
    cursor: 'pointer',
    color: 'white',
    borderRadius: '35px',
    paddingTop: '10px',
    paddingBottom: '10px',
    paddingLeft: '2vw',
    paddingRight: '2vw',
  },
  cardMobile: {
    cursor: 'pointer',
    color: 'white',
    borderRadius: '17px',
    paddingTop: '6vw',
    paddingBottom: '5vw',
    paddingLeft: '2vw',
    paddingRight: '2vw',
  },
  cardShortDescription: {
    color: 'white',
    fontSize: '0.9rem',
  },
  cardInfo: {
    paddingLeft: '1.7vw',
    paddingRight: '1.7vw',
  },
  cardPinkLine: {
    marginLeft: '0.7rem',
    borderBottomColor: '#db3d78',
    borderBottomStyle: 'solid',
    borderBottomWidth: '5px',
    width: '2rem',
  },
  cardTitle: {
    paddingLeft: '1.7vw',
    paddingRight: '1.7vw',
    fontSize: '1.2rem',
  },
  closeDrawerButton: {
    position: 'fixed',
    top: '50%',
  },
  container: {
    position: 'fixed',
    backgroundColor: 'rgba(255, 255, 255, 0.5)',
    top: '0px',
    left: '0px',
    zIndex: '10000',
    overflowY: 'scroll',
    marginTop: '63px',
  },
  containerMobile: {
    position: 'fixed',
    backgroundColor: 'white',
    top: '0px',
    left: '0px',
    zIndex: '10000',
    overflowY: 'scroll',
    marginTop: '63px',
  },
  greyLine: {
    borderBottomColor: 'rgb(102, 102, 102)',
    borderBottomStyle: 'solid',
    borderBottomWidth: '7px',
    marginLeft: 'auto',
    marginRight: 'auto',
    marginTop: '-0.5rem',
    marginBottom: '0.7rem',
    borderRadius: '25px',
    width: '5rem',
  },
  resultsContainer: {
    paddingLeft: '8vw',
    paddingRight: '8vw',
  },
  pageTitle: {
    textAlign: 'center',
    marginTop: '1rem',
  },
});

const mapStateToProps = (state) => ({
  isDrawerOpen: state.isDrawerOpen,
  open: state.search.searching,
  results: state.search.results,
  value: state.search.value,
});
const mapDispatchToProps = (dispatch) => ({
  toggleSearch: (open, results, value) => { dispatch(toggleSearch(open, results, value)); },
  updatePreviewData: (data) => { dispatch(updatePreviewData(data)); },
  setMapZoom: (zoom) => { dispatch(setMapZoom(zoom)); },
  openPreview: () => { dispatch(openPreview()); },
  openDrawer: (data) => { dispatch(openDrawer(data)); },

  // Edimar - adicionada Função centerMap
  centerMap: (location) => { dispatch(centerMap(location)); },
  hideSearch: () => { dispatch(hideSearch()); },
  closeDrawer: () => { dispatch(closeDrawer()); },
});
export default connect(mapStateToProps, mapDispatchToProps)(SearchResults);
