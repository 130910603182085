import React, { useState } from 'react';
import '../css/LogInAdmin.css';
import firebase from '../config/Firebase';
import { useHistory } from 'react-router-dom';
import Logo from '../assets/imgs/default.png';
import IconEmail from '../assets/imgs/email_icon.svg';
import IconPass from '../assets/imgs/pass_icon.svg';
import IconShowPass from '../assets/imgs/show_pass_icon.svg';

function LogInAdmin() {
  const [email, setEmail] = useState('');
  const [password, setPass] = useState('');
  const history = useHistory();

  async function handleLogLogin(data) {
    const date = new Date();
    const dataString = `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`;
    
    const log = {
      cidade: data.data().city,
      data: date,
      dataString: dataString,
      email: data.data().email,
      nome: data.data().name,
      role: data.data().role,
      userId: data.id,
    };

    await firebase.firestore().collection('LogLogin').add(log);
  }

  const loginAdm = () => {
    if (email === '') {
      alert('digite seu email');
    } else if (password === '') {
      alert('digite sua senha');
    } else {
      firebase.auth().signInWithEmailAndPassword(email, password).then(async (user) => {
        const stateQueryRes = await firebase.firestore().collection('users').doc(user.user.uid).get();
        const role = stateQueryRes.data().role;

        const rolesPermission = ['admin', 'masterAdmin', 'cityAdmin', 'contentManager', 'tradeAdmin', 'dashboardViewer', 'contentEditor', 'regionAdmin', 'rotaAdmin'];
        if (rolesPermission.includes(role)) {
          handleLogLogin(stateQueryRes);
          history.push('/admin/dashboards');
        } else {
          firebase.auth().signOut().then(() => {
            alert('Usuario sem permissão!');
          });
        }
      }).catch((error) => {
        // Email ou senha errados
        if (error.code === 'auth/invalid-email') {
          alert('Email incorreto!');
          document.getElementById('email_adm').value = '';
          document.getElementById('email_adm').focus();
        } else if (error.code === 'auth/wrong-password') {
          alert('Senha incorreta!');
          document.getElementById('password_login_adm').value = '';
          document.getElementById('password_login_adm').focus();
        } else if (error.code === 'auth/user-not-found') { // Email não cadastrado
          alert('Usuario não encontrado!');
          document.getElementById('email_adm').value = '';
          document.getElementById('email_adm').focus();
        }
      });
    }
  };

  function showPassword() {
    const pass = document.getElementById('password_login_adm');
    if (pass.type === 'text') 
      pass.type = 'password';
    else
      pass.type = 'text';
  }

  const forgotPass = () => {
    if (email !== '') {
      firebase.auth().sendPasswordResetEmail(email).then(() => {
        alert('link de redefinição enviado para o email!');
      }).catch((error) => {
        alert('Ocorreu um erro!');
      });
    } else {
      alert('digite algum email no campo para redefinir a senha');
    }
  };

  const handleKey = (e) => {
    if (e.key === 'Enter')
      document.getElementById('btn_login_adm').click();
  };

  return (
    <div className="loginAdm">
      <section className="top_navbar">
        <div className="div_elements_nav">
          <img src={Logo} className="icon_destinos" alt="Icone de um flamingo"/>
        </div>
        <div className="div_elements_nav">
          <h3 className="title_nav">Destinos Inteligentes</h3>
        </div>
      </section>
      <section className="content_login">
        <img src={Logo} className="icon_destinos" alt="Icone de um flamingo"/>
        <h3 className="title_form">PAINEL DE GESTÃO</h3>
        <form className="form_login">
          <div className="body_form">
            <div className="div_input_form">
              <img src={IconEmail} class="icon_email_form" alt="Icone de email" />
              <input type="text" name="email" placeholder="Email" className="field_login" onChange={(event) => setEmail(event.target.value)} id="email_adm" onKeyPress={(key) => handleKey(key)} />
            </div>
            <div className="div_input_form">
              <img src={IconPass} class="icon_pass_form" alt="Icone de senha" />
              <input type="password" placeholder="Password" className="field_login" onChange={(event) => setPass(event.target.value)} id="password_login_adm" onKeyPress={(key) => handleKey(key)} />
              <img src={IconShowPass} class="icon_show_pass" alt="Icone de senha" onClick={() => showPassword()}/>
            </div>
            <div className="div_forgot_password_admin">
              <p class="forgot_password_admin" onClick={() => forgotPass()}>Esqueceu a senha?</p>
            </div>
            <button type="button" id="btn_login_adm" className="btn_login_adm" onClick={() => loginAdm()}>Login com Email</button>
          </div>
        </form>
      </section>
    </div>
  );
}

export default LogInAdmin;