import React from 'react';

import '../assets/css/componentCounter.css';

export default function ComponentCounter({ title, numCounter }) {
  const colors = {
    'PENDÊNCIAS': '#f8ac58',
    'CORREÇÕES': '#23c6c8',
  };

  return (
    <div className='RowPendencyCounter'>
      <div className='div_text_pendency_counter'>
        <p>{title}</p>
      </div>
      <div className='div_pendency_counter' style={{ backgroundColor: colors[title] }}>
        <p>+ {numCounter}</p> 
      </div>
    </div>
  );
}