import React, { useState, useEffect } from "react";

import '../assets/css/mapInput.css';

import { Loader } from '@googlemaps/js-api-loader';

export default function MapInput({ center, id, setValue }) {
  function handleRenderMap() {
    const loader = new Loader({ apiKey: 'AIzaSyDd3qwHtsqLlv_0GuVzOOrtZ6-XvZRifEc', libraries: ['places', 'visualization'] });

    loader.load().then((google) => {
      const coordCenter = { lat: center.Ic, lng: center.wc };
      const mapComp = new google.maps.Map(document.getElementById(id + 'Div'), {
        zoom: 12,
        center: coordCenter,
      });

      let infoWindow = new google.maps.InfoWindow({
        content: 'Clique onde está localizado o Inventário',
        position: coordCenter,
      });

      infoWindow.open(mapComp);

      mapComp.addListener('click', (mapsMouseEvent) => {
        // Close the current InfoWindow.
        infoWindow.close();

        // Create a new InfoWindow.
        infoWindow = new google.maps.InfoWindow({
          position: mapsMouseEvent.latLng,
        });

        const coords = mapsMouseEvent.latLng.toJSON();
        infoWindow.setContent(
          `Latitude: ${coords.lat}
          \nLongitude: ${coords.lng}`,
        );
        
        const strCordinates = `${coords.lat},${coords.lng}`; 
        setValue(strCordinates);

        infoWindow.open(mapComp);

      });

    });
  }

  useEffect(() => {
    handleRenderMap();
  }, []);

  return (
    <div id={id + 'Map'} className='div_map_input'>
      <div id={id + 'Div'} className='div_align_map_input'>
      </div>
    </div>
  );
}