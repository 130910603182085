import React, { useState, useEffect } from "react";

import '../assets/css/mapInputAttraction.css';

import { Loader } from '@googlemaps/js-api-loader';

// eslint-disable-next-line import/no-extraneous-dependencies
// import firebase from '../config/Firebase';

export default function MapInputAttraction({ center, id, setValue, attractionList, mapComp, setMarkers, value, setWaypointTitleList }) {
  // const [attractionLocation, setAttractionLocation] = useState([]);

  /* async function handleGetLocation(attractionList) {
    if (attractionList && attractionList.length > 0) {
      await Promise.all(attractionList.map(async (attraction) => {
        const docs = await firebase.firestore().collection('Attractions')
          .where('Title', '==', attraction)
          .get();
        const coordformat = { lat: docs.docs[0].data().location.Ic, lng: docs.docs[0].data().location.wc };
        const isDuplicate = attractionLocation.some(
          (location) => JSON.stringify(location) === JSON.stringify(coordformat),
        );
        if (!isDuplicate) {
          setAttractionLocation((current) => [...current, coordformat]);
        }
      }));
    }
  } */
  
  function handleRenderMap() {
    const loader = new Loader({ apiKey: 'AIzaSyDd3qwHtsqLlv_0GuVzOOrtZ6-XvZRifEc', libraries: ['places', 'visualization'] });

    loader.load().then((google) => {
      const coordCenter = { lat: center.Ic, lng: center.wc };
      mapComp = new google.maps.Map(document.getElementById(id + 'Div'), {
        zoom: 12,
        center: coordCenter,
      });

      const infoWindow = new google.maps.InfoWindow({
        content: 'Clique para adicionar um waypoint',
        position: coordCenter,
      });
      
      infoWindow.open(mapComp);

      /* attractionLocation.map((attraction, index) => {
        if (attraction.length > 0) {
          const marker = new google.maps.Marker({
            title: attractionList[index],
            position: attraction,
            map: mapComp,
          });

          infoWindow.setContent(marker.title);
          infoWindow.open(mapComp, marker);

          marker.addListener('click', () => {
            infoWindow.close();
            infoWindow.setContent(marker.title);
            infoWindow.open(marker.map, marker);
          }); 
        }
      }); */

      mapComp.addListener('click', (mapsMouseEvent) => {
        infoWindow.close();
    
        // Adiciona marcador onde clickar
        const marker = new google.maps.Marker({
          position: mapsMouseEvent.latLng,
          map: mapComp,
        });

        setMarkers((current) => [...current, marker]);
        setWaypointTitleList((current) => [...current, '']);

        const coords = mapsMouseEvent.latLng.toJSON();

        infoWindow.setContent(
          `Latitude: ${coords.lat}
          \nLongitude: ${coords.lng}`,
        );

        infoWindow.open(marker.map, marker);

        const stringCordinates = `${coords.lat},${coords.lng}`;
        setValue(stringCordinates);

        // Mostra latitude do marcador quando clicka
        marker.addListener('click', () => {
          const position = marker.position;

          infoWindow.close();
          infoWindow.setContent(
            `Latitude: ${position.lat()}
            \nLongitude: ${position.lng()}`,
          );
          infoWindow.open(marker.map, marker);
        });
      });

      if (value.waypoint && value.waypoint.length > 0) {
        value.waypoint.map((waypoint) => {
          const marker = new google.maps.Marker({
            title: waypoint['Ic'] + ', ' + waypoint['wc'],
            position: { lat: waypoint['Ic'], lng: waypoint['wc'] },
            map: mapComp,
          });

          infoWindow.setContent(marker.title);
          infoWindow.open(mapComp, marker);

          marker.addListener('click', () => {
            infoWindow.close();
            infoWindow.setContent(marker.title);
            infoWindow.open(marker.map, marker);
          });

          setMarkers((current) => {
            if (!current.some((existingMarker) => JSON.stringify(existingMarker.position) === JSON.stringify(marker.position))) {
              return [...current, marker];
            }
            return current;
          });
        });
      }
    });
  }

  useEffect(() => {
    handleRenderMap();
    // handleGetLocation(attractionList);
  }, []);

  return (
    <div id="floating-panel">
      <div id={id + 'Map'} className='div_map_input_floating_panel'>
          <div id={id + 'Div'} className='div_align_map_input_floating_panel'>
          </div>
        </div>
    </div>
  );
}
