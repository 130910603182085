import React from 'react';

import '../assets/css/spinButton.css';

export default function SpinButton() {
  return (
    <div className="spin-outside">
      <div className="spin-inside"/>
    </div>
  );
}