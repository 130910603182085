import React from 'react';

// CSS import
import '../../assets/css/InputComponents/gPXFileInput.css';

import TrashIcon from '../../assets/imgs/trash_icon.svg';

export default function GPXFileInput({ field, title, value, setValue, explanation, setFile }) {
  
  function removeFileGPX() {
    setFile();
    delete value[field];
  }

  return (
    <div className='GPXFileInput'>
      <div className='div_align_file_input'>
        <div className='div_label_input_file'>
          <label htmlFor={field + 'Form'} className="label_text_input">
          <strong>{title}</strong>
          </label>
        </div>
        <div className='div_align_explanation_input div_align_explanation_input_gpx_file'>
          <div className='div_space_input_gpx_file'>
            <div className='div_field_input_file_type'>
              <div className='div_align_input_file div_align_input_file_gpx'>
                <input type='text' className='input_namefile_fileinput' disabled={true} value={value[field] || ''} />
                {
                  value[field] ? 
                  <button className='btn_remove_file_gpx' onClick={() => removeFileGPX()}>
                    <img src={TrashIcon} alt='Ícone de remover arquivo GPX' />
                  </button> : null
                }
                <button type='button' className='btn_image_inventory' onClick={() => document.getElementById('input_file_kml').click()}>Carregar Arquivo</button>
                <input className='input_file_inventory' type='file' id='input_file_kml' accept='.gpx' onChange={(event) => { setValue({ ...value, [field]: event.target.files[0].name }); setFile(event.target.files[0]); }} />
              </div>
            </div>
          </div>
          <div className='div_explanation_input'>
            <p className='subtext_explanation_input'>{explanation}</p>
          </div>
        </div>
      </div>
    </div>
  );
}