import React from 'react';
import firebase from '../config/Firebase';
import queryString from 'query-string';
import { store } from '../redux/store';
import { TOGGLE_LOG_OUT_FLAG } from '../redux/actionTypes';

const withAuthentication = (WrappedComponent) => {
  class WithAuthentication extends React.Component {
    _isMounted = false;

    state = {
      providerData: [],
      anonimous: undefined,
    };

    componentWillUnmount() {
      this._isMounted = false; // Indica que o componente vai ser desmontado
    }

    getUrlCity = () => {
      const { search } = this.props.location;
      const parsedUrl = queryString.parse(search);
      if (parsedUrl.city !== undefined) {
        return parsedUrl.city.charAt(0).toUpperCase() + parsedUrl.city.slice(1);
      }
      return undefined;
    }

    getOpenFilter = () => {
      const { search } = this.props.location;
      const parsedUrl = queryString.parse(search);
      return parsedUrl.openFilters !== undefined;
    }

    isUserCity = (userId) => {
      return firebase
        .firestore()
        .collection('users')
        .doc(userId)
        .get()
        .then((doc) => doc.data().city === this.getUrlCity());
    }

    checkUrlCity = (city) => {
      return firebase
        .firestore()
        .collection('Cities')
        .get()
        .then((result) => {
          const cities = result.docs.map((city) => city.data().name);
          return cities.indexOf(city) !== -1;
        });
    }

    componentDidMount() {
      this._isMounted = true; // Indica que o componente foi montado
      
      firebase.auth().onAuthStateChanged(async (user) => {
        // Muda os estados do componente somente se ele estiver montado
        if (this._isMounted) {
          if (user) {
            const isUserCity = await this.isUserCity(user.uid);
            if (!this.props.location.search || this.getOpenFilter() || isUserCity) {
              this.setState({ anonimous: false });
              const socialMediaUser = user.providerData[0].providerId === 'facebook.com' || user.providerData[0].providerId === 'google.com';
              // Usuários do face não precisam verificar o email
              if (socialMediaUser) {
                firebase.firestore().collection('users').doc(user.uid).get()
                  .then((doc) => {
                    if (doc.data().completedSignUp)
                      this.setState({ providerData: user.providerData });
                    else {
                      alert('Termine o cadastro para continuar!');
                      this.props.history.push('/signup-with-social-media');
                    }
                  });
              } else {
                this.setState({ providerData: user.providerData });
              }
            } else {
              firebase.auth().signOut();
            }
          } else if (this.getUrlCity()) {
            const cityExists = await this.checkUrlCity(this.getUrlCity());
            
            this.setState({ providerData: [], anonimous: true, cityExists });
          } else {
            // Se o usuário não tiver feito logOut, dispara o alerta
            if (!store.getState().user.logedOut) {
              alert('Must be authenticated');
            } else {
              // Indica que o usuário já terminou o logOut
              store.dispatch({ type: TOGGLE_LOG_OUT_FLAG });
            }
            this.props.history.push('/');
          }
        }
      });
    }

    render() {
      const { anonimous, cityExists } = this.state;
      return (this.state.providerData.length > 0 || anonimous) ? (
        <WrappedComponent
          {...this.props}
          providerData={this.state.providerData && this.state.providerData}
          anonimousData={{
            anonimous,
            city: this.getUrlCity(),
            cityExists,
          }}
        />
      ) : (
        <p>Loading...</p>
      );
    }
  }

  return WithAuthentication;
};

export default withAuthentication;
