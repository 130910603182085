import React from 'react';
import { StyleSheet, css } from 'aphrodite';
import ShareModal from '../component/ShareModal';
import DistanceToAttractive from '../component/DistanceToAttractive';
import CarouselStrap from '../component/CarouselStrap';
import DivHexagons from '../component/DivHexagons';
import { Button, Row, Col } from 'reactstrap';
import { PageView, Event } from '../config/TrackingGA';
import { connect } from 'react-redux';
import { convertDate, convertTime } from '../utils/parse';
import { openDrawer } from '../redux/actions';

class Events extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      title: '',
      longDescription: '',
      shortDescription: '',
      feedbackAlert: false,
      feedbackText: '',
      latitude: '',
      longitude: '',
      modal: false,
      shareModal: false,
    };
  }

  componentDidMount() {
    PageView();
  }

  async componentWillReceiveProps(nextProps) {
    if (this.props.attractive !== nextProps.attractive) {
      PageView();
    }
  }

  handleShare = () => {
    // Verifica se o browser suporta compartilhamento nativo
    const { event } = this.props;

    if (navigator.share) {
      Event('User', 'Share', String(event.document));
      navigator.share({
        url: '/map/' + event.collection + '/' + event.document,
        title: event.title,
        text: event.title + ' - Esse destino é a sua cara!',
      })
        .catch(console.error);
    } else { // Se não, ativa o modal de compartilhamento feito para o Destinos
      Event('User', 'Share', String(event.document));
      this.setState({
        shareModal: true,
      });
    }
  }

  handleLocalClick = () => {
    const { event } = this.props;
    const { attractive } = event;

    this.props.openDrawer(attractive);
  }

  closeShareModal = () => {
    this.setState({
      shareModal: false,
    });
  }

  handleDestination = () => {
    const { event } = this.props;
    const destination = 'https://www.google.com/maps/dir/?api=1&destination=' + event.attractive.lat + ',' + event.attractive.long;
    window.open(destination, '_blank');
    Event('User', 'Route', String(event.document));
  }

  render() {
    const { isMobile, event } = this.props;

    return (
      <div id='pageBackground' className={css(styles.pageBackground)}>
        {/* Tudo o que vai ser scrollado! */}
        <div id='scrollContentDiv' className={css(styles.scrollContent)} style={{ height: isMobile ? '85%' : '85%' }}>
          {/* Div que controla o tamanho de tudo até o long description */}
          <div id='beforeDiv' className={css(styles.beforeLongDescription)}>
            {/* Carrosel */}
            <div className={css(styles.carouselBackground)}>
              <div className={css(styles.carousel1)}>
                <div className={css(styles.carousel2)}>
                  <CarouselStrap
                    city={event.city}
                    isMobile={isMobile}
                    imageFile={event.imageFile}
                    document={event.document}
                    event
                  />
                </div>
              </div>
            </div>
            <div className={css(styles.paddingDiv)}>
              {/* Titulo do atrativo */}
              <div className={css(styles.attractiveTitle)}>
                <span> {event.title}</span>
              </div>

              {/* Pink Line */}
              <div className={css(styles.pinkLine)} />

              {/* Short Description */}
              <div className={css(styles.attractiveShortDescription)} lg='12'>
                {event.description}
              </div>

            <div className={css(styles.attractiveLongDescription)}>
              <div className={css(styles.longDescriptionDiv)}>
                <div className={css(styles.longDescription)}>
                  <span>{event.longDescription}</span>
                  <div style={{ marginTop: '15px' }}>
                    <div style={{ borderBottom: '1px solid rgba(255, 255, 255, 0.5)', marginBottom: '0.5rem', paddingBottom: '0.5rem' }}>
                      <strong className={css(styles.eventInfo)}>Data </strong>
                      <p style={{ marginLeft: '1em', marginBottom: '0px' }}>{`${convertDate(event.startDate)}`}</p>
                    </div>
                    <div style={{ borderBottom: '1px solid rgba(255, 255, 255, 0.5)', marginBottom: '0.5rem', paddingBottom: '0.5rem' }}>
                      <strong className={css(styles.eventInfo)}>Horário </strong>
                      <p style={{ marginLeft: '1em', marginBottom: '0px' }}>{`${convertTime(event.startDate)}`}</p>
                    </div>
                    {// Edimar - essa função remove todos os caracteres em branco
                     // para remover o branco do começo e do fim pode ser usado trime:
                     // event.attractive.Title.trim() !== event.attractive.Address.trim()
                    (event.attractive.Title.replaceAll(/\s/g, '') !== event.attractive.Endereço.replaceAll(/\s/g, '')) && (
                    <div style={{ borderBottom: '1px solid rgba(255, 255, 255, 0.5)', marginBottom: '0.5rem', paddingBottom: '0.5rem' }}>
                      <strong className={css(styles.eventInfo)}>Local </strong>
                      <p style={{ marginLeft: '1em', marginBottom: '0px' }}>{`${event.attractive.Title}`}</p>
                    </div>
                    )
                    }
                    <div style={{ borderBottom: '1px solid rgba(255, 255, 255, 0.5)', marginBottom: '0.5rem', paddingBottom: '0.5rem' }}>
                      <strong className={css(styles.eventInfo)}>Endereço </strong>
                      <p style={{ marginLeft: '1em', marginBottom: '0px' }}>{`${event.attractive.Endereço}`}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            </div>
          </div>
          {/* Hexagonos e distância do atrativo */}
          <Row style={{ margin: 0, padding: 35, paddingLeft: 0, paddingRight: 0 }} id='hexagonAndDistanceDiv'>
            <Col lg='12' xs='12' sm='12'>
              <DivHexagons
                activities={event.activitiesList}
              />
            </Col>
          </Row>
        </div>

        {/* Botões fixos no fim da página */}
        <div className={css(styles.buttonsDiv)} >
          <div style={{ width: 'max-content', paddingLeft: '0.7rem', paddingRight: '0.7rem', marginTop: 0 }}>
            <DistanceToAttractive
              attractiveLat={event.attractive.lat}
              attractiveLng={event.attractive.long}
            />
          </div>
          <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', paddingTop: '8px', paddingLeft: '0.7rem', paddingRight: '0.7rem' }}>
            <Button className={css(styles.shareButton)} onClick={this.handleShare}>Compartilhar</Button>
            <Button className={css(styles.routeButton)} onClick={this.handleDestination}>
              Rotas
            </Button>
            <Button className={css(styles.localButton)} onClick={this.handleLocalClick}>
              Local
            </Button>
          </div>
        </div>

        <ShareModal
          title={event.title}
          open={this.state.shareModal}
          closeModal={this.closeShareModal}
          document={event.document}
          collection={event.collection}
          event
        />
      </div>
    );
  }
}

const styles = StyleSheet.create({
  attractiveShortDescription: {
    color: 'white',
    marginTop: 0,
    textAlign: 'justify',
    fontFamily: 'ubuntu',
    fontSize: 18,
  },
  attractiveLongDescription: {
    color: 'white',
    display: 'flex',
    marginTop: 20,
    fontFamily: 'ubuntu',
    fontWeight: 'regular',
  },
  attractiveTitle: {
    marginTop: '-0.5rem',
    fontSize: 25,
    textAlign: 'left',
    marginBottom: 2,
    color: 'white',
    padding: '0',
    fontFamily: 'ubuntu',
    fontWeight: 'bold',
  },
  beforeLongDescription: {
    height: 'auto',
  },
  buttonsDiv: {
    position: 'absolute',
    bottom: '0',
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    width: '100%',
    backgroundColor: 'rgb(146, 85, 142)',
    justifyContent: 'space-evenly',
    textAlign: 'center',
    // height: '10%',
    padding: '1rem',
  },
  carousel1: {
    paddingTop: '1rem',
    height: '150px',
    backgroundColor: 'rgb(146, 85, 142)',
    borderRadius: '0px 0px 20px 20px',
  },
  carousel2: {
    height: '150px',
  },
  carouselBackground: {
    alignItems: 'center',
    backgroundColor: '#c844dd',
    justifyContent: 'center',
    marginBottom: 18,
    flex: 1,
  },
  eventInfo: {
    marginBottom: 0,
  },
  feedbackLabel: {
    cursor: 'pointer',
    color: '#F3A861',
    textDecoration: 'underline',
  },
  hideShowOption: {
    ': hover': {
      textDecoration: 'underline #FFB94F',
    },
    color: '#FFB94F',
    cursor: 'pointer',
    fontStyle: 'italic',
    zIndex: '2',
  },
  localButton: {
    borderRadius: 50,
    width: '6rem',
    fontSize: '1.4rem',
    letterSpacing: '.01em',
    height: 50,
    backgroundColor: '#000100',
    color: 'white',
    justifyContent: 'center',
    border: 'none',
  },
  longDescription: {
    marginBottom: '0.2rem',
    textAlign: 'justify',
    cursor: 'pointer',
    paddingBottom: '5px',
  },
  longDescriptionDiv: {
    fontSize: 15,
    paddingRight: 0,
    textAlign: 'justify',
    height: '100%',
    wordBreak: 'break-word',
  },
  otherFieldsInfo: {
    fontWeight: 'bold',
    wordBreak: 'break-word',
  },
  otheFieldsLink: {
    color: 'white',
    fontStyle: 'italic',
    fontWeight: 'bold',
    wordBreak: 'break-all',
  },
  otherFieldstext: {
    marginBottom: '0.2rem',
    wordBreak: 'break-word',
  },
  pageBackground: {
    flex: 1,
    height: '100vh',
    maxHeight: '100vh',
    flexDirection: 'column',
    backgroundColor: 'rgb(146, 85, 142)',
  },
  pinkLine: {
    borderBottomColor: '#f9407d',
    borderBottomStyle: 'solid',
    borderBottomWidth: '3px',
    width: '3rem',
    marginBottom: '1vh',
    marginLeft: '-0.4rem',
  },
  paddingDiv: {
    marginTop: '5rem',
    paddingLeft: '1.7rem',
    paddingRight: '1.7rem',
  },
  ratingAndFavorite: {
    display: 'flex',
    alignItems: 'center',
    marginLeft: 55,
    marginRight: 0,
    marginTop: '4rem',
    marginBottom: '.5rem',
    height: '1.8rem',
    width: 'auto',
  },
  routeButton: {
    borderRadius: 50,
    width: '6rem',
    fontSize: '1.4rem',
    letterSpacing: '.01em',
    height: 50,
    backgroundColor: '#f9407d',
    color: 'white',
    justifyContent: 'center',
    border: 'none',
  },
  scrollContent: {
    backgroundColor: '#c844dd',
    flex: 1,
    overflowY: 'auto',
  },
  shareButton: {
    backgroundColor: 'white',
    borderRadius: 50,
    color: '#f9407d',
    fontSize: '1.4rem',
    letterSpacing: '.01em',
    height: 50,
    width: '10rem',
    border: 'none',
  },
});

function mapStateToProps(state) {
  return {
    event: state.eventDrawerInfo,
  };
}
const mapDispatchToProps = (dispatch) => ({
  openDrawer: (attractive) => { dispatch(openDrawer(attractive)); },
});
export default connect(mapStateToProps, mapDispatchToProps)(Events);
