import React, { useEffect } from 'react';

import '../../assets/css/titleEditor.css';

export default function TitleEditor({ type, action, subTitle }) {
  const filteredTitles = {
    'Roteiro' : 'Rota',
    'RoteirosTrechos' : 'Trecho da Rota',
  };
  return (
    <div className="TitleEditor">
      <div className="div_top_card" />
      <div className="div_align_title_editor">
        <h2>{(filteredTitles[type] || type)}</h2>
        <h6>
          { subTitle || (`${action} ${type === 'Inventário' ? 'Registro' : (filteredTitles[type] || type)}`) }
        </h6>
      </div>
    </div>
  );
}