/* eslint-disable no-restricted-syntax */
/* eslint-disable guard-for-in */
import React from 'react';
import Paper from '@material-ui/core/Paper';
import InputBase from '@material-ui/core/InputBase';
import IconButton from '@material-ui/core/IconButton';
import ClearIcon from '@material-ui/icons/Clear';
import SearchIcon from '@material-ui/icons/Search';
import { StyleSheet, css } from 'aphrodite';
import { connect } from 'react-redux';
import Fuse from 'fuse.js';
import { toggleSearch } from '../redux/actions';
import { Event } from '../config/TrackingGA';
import cloneDeep from 'lodash/cloneDeep';

const mapaAcentosHex 	= {
  a: /[\xE0-\xE6]/g,
  A: /[\xC0-\xC6]/g,
  e: /[\xE8-\xEB]/g,
  E: /[\xC8-\xCB]/g,
  i: /[\xEC-\xEF]/g,
  I: /[\xCC-\xCF]/g,
  o: /[\xF2-\xF6]/g,
  O: /[\xD2-\xD6]/g,
  u: /[\xF9-\xFC]/g,
  U: /[\xD9-\xDC]/g,
  c: /\xE7/g,
  C: /\xC7/g,
  n: /\xF1/g,
  N: /\xD1/g,
};
class SearchBar extends React.Component {
  constructor(props) {
    super(props);

    this.state = { value: '' };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.onEnter = this.onEnter.bind(this);
    this.handleClear = this.handleClear.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    const { searchBarValue } = nextProps;
    const { searchBarValue: old } = this.props;

    if (searchBarValue === '' && old !== '') {
      this.setState({ value: '' });
    }
  }

  handleChange(event) {
    this.setState({ value: event.target.value });
    if (event.target.value === '')
      this.props.toggleSearch(false, [], '');
  }

  handleSubmit() {
    const { value } = this.state;
    const { threshold } = this.props;
    const filtered = cloneDeep(this.props.filtered);
    const options = {
      keys: ['Title', 'Short Description', 'Long Description', 'Activities List', 'Tags'],
      shouldSort: true,
      threshold: threshold || 0.2,
      includeScore: this.props.showScore,
    };
    if (document && document.activeElement && window.innerWidth <= 500) {
      document.activeElement.blur();
    }

    let valueSemAcento = value;

    for (const letra in mapaAcentosHex) {
      const regex = mapaAcentosHex[letra];
      valueSemAcento = valueSemAcento.replace(regex, letra);
    }

    const semAcentos = filtered.map((item) => {
      for (const letra in mapaAcentosHex) {
        const regex = mapaAcentosHex[letra];
        item.Title = item.Title && item.Title.replace(regex, letra);
        item['Short Description'] = item['Short Description'] && item['Short Description'].replace(regex, letra);
        item['Long Description'] = item['Long Description'] && item['Long Description'].replace(regex, letra);
        for (const i in item['Activities List']) {
          item['Activities List'][i] = item['Activities List'][i].replace(regex, letra);
        }
        for (const i in item.Tags) {
          item.Tags[i] = item.Tags[i].replace(regex, letra);
        }
      }
      return item;
    });
    const fuse = new Fuse(semAcentos, options);
    let results = fuse.search(valueSemAcento);
    console.log('Total de dados:');
    console.log(results.length);
    results = results.map((result) => {
      if (this.props.showScore) {
        result.item = this.props.filtered.filter((attraction) => attraction.document === result.item.document)[0];
      } else {
        result = this.props.filtered.filter((attraction) => attraction.document === result.item.document)[0];
      }
      return result;
    });

    this.props.toggleSearch(true, results, value);
    Event('User', 'Search', 'AttractiveSearchEvent');
  }

  onEnter(event) {
    if (event.key === 'Enter') {
      this.handleSubmit();
    }
  }

  handleClear() {
    this.setState({ value: '' });
    this.props.toggleSearch(false, [], '');
  }

  render() {
    const isMobile = window.innerWidth <= 500;
    return (
      isMobile ?
        <Paper className={css(styles.mobileRoot)}>
          <IconButton onClick={this.handleSubmit} className={css(styles.iconButton)} aria-label="search">
            <SearchIcon/>
          </IconButton>
          <InputBase
            className={css(styles.input)}
            placeholder='Busque um atrativo'
            value={this.state.value}
            onClick={(e) => { e.target.focus(); }}
            onChange={this.handleChange}
            onKeyPress={this.onEnter}
          />
          <IconButton onClick={this.handleClear} className={css(styles.iconButton)} aria-label="clear">
            <ClearIcon />
          </IconButton>
        </Paper>
        :
        <Paper className={css(styles.root)}>
          <IconButton onClick={this.handleSubmit} className={css(styles.iconButton)} aria-label="search">
            <SearchIcon/>
          </IconButton>
          <InputBase
            className={css(styles.input)}
            placeholder='Busque um atrativo'
            value={this.state.value}
            onClick={(e) => { e.target.focus(); }}
            onChange={this.handleChange}
            onKeyPress={this.onEnter}
          />
          <IconButton onClick={this.handleClear} className={css(styles.iconButton)} aria-label="clear">
            <ClearIcon />
          </IconButton>
        </Paper>

    );
  }
}

const styles = StyleSheet.create({
  mobileRoot: {
    padding: '2px 4px',
    display: 'flex',
    width: 300,
    marginTop: 8,
  },
  root: {
    padding: '2px 4px',
    display: 'flex',
    alignItems: 'center',
    marginTop: 10,
    marginBottom: 10,
    width: '60%',
    transform: 'scale(1)', /* you need a scale here to allow it to transition in both directions */
    transition: '0.2s all ease',
  },
  input: {
    marginLeft: 8,
    flex: 1,
  },
  iconButton: {
    padding: 10,
  },
  divider: {
    width: 1,
    height: 28,
    margin: 4,
  },
});

const mapStateToProps = (state) => ({
  filtered: state.filterInfo.filteredPoints,
  showScore: state.flags.showSearchScore,
  threshold: state.flags.threshold,
  searchBarValue: state.search.value,
});
const mapDispatchToProps = (dispatch) => ({
  toggleSearch: (open, results, value) => { dispatch(toggleSearch(open, results, value)); },
});
export default connect(mapStateToProps, mapDispatchToProps)(SearchBar);
