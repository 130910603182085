import React from 'react';

import firebase from '../config/Firebase';
import { StyleSheet, css } from 'aphrodite';
import {
  Alert, Button,
} from 'reactstrap';

import { Modal } from '@material-ui/core';

class FeedbackModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      feedbackAlert: false,
      feedbackText: '',
    };
    this.sendFeedback = this.sendFeedback.bind(this);
  }

  async sendFeedback() {
    if (this.state.feedbackText === '') {
      this.setState({
        feedbackText: '',
        feedbackAlert: true,
        feedbackAlertType: 'danger',
        feedbackAlertMessage: 'O campo está vazio',
      });
    } else {
      const values = {
        attractiveId: this.props.document,
        feedback: this.state.feedbackText,
      };
      if (firebase.auth().currentUser)
        values.userId = firebase.auth().currentUser.uid;
      await firebase.firestore().collection('Feedbacks').add(values);
      this.props.closeModal();
    }
  }

  render() {
    return (
      <Modal className={css(styles.modal)} open={this.props.open}>
        <div className={css(styles.container)}>
          <div className={css(styles.modalHeader)}>
            <span style={{ textAlign: 'center' }}>
              Comunicar Correção
            </span>
          </div>
          <div className={css(styles.modalBody)}>
            <textarea 
              autoFocus 
              value={this.state.feedbackText} 
              onChange={(input) => this.setState({ feedbackText: input.target.value })} 
              className={css(styles.feedbackInput)} 
              type="textarea"
              onClick={(e) => e.currentTarget.focus()}
            />
            <Alert 
              isOpen={this.state.feedbackAlert} 
              toggle={() => {
                this.setState({ feedbackAlert: false });
                if (this.state.feedbackAlertMessage === 'Feedback enviado!') 
                  this.props.closeModal();
              }} 
              color={this.state.feedbackAlertType}
            >
              {this.state.feedbackAlertMessage}
            </Alert>
          </div>
          <div className={css(styles.modalFooter)}>
            <Button onClick={this.sendFeedback}>Enviar</Button>
            <Button onClick={this.props.closeModal}>Cancelar</Button>
          </div>
        </div>
      </Modal>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    backgroundColor: 'white',
    display: 'block',
    margin: 'auto',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '10px',
  },  
  feedbackInput: {
    height: 100,
    marginBottom: 6,
    width: 270,
  },
  modal: {
    display: 'flex',
    justifyContent: 'center',
  },
  modalBody: {

  },
  modalFooter: {
    display: 'flex',
    justifyContent: 'space-around',
  },
  modalHeader: {
    marginBottom: 2,
    textAlign: 'center',
    fontSize: '17px',
  },
  shareButtonsModal: {
    marginLeft: 4,
    marginRight: 4,
  },
});

export default FeedbackModal;