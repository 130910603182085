import React from 'react';
import firebase from '../config/Firebase';

const adminAlreadyAuthenticated = (WrappedComponent) => {
  class adminAlreadyAuthenticated extends React.Component {
    _isMounted = false;

    state = {
      loading: true,
    };

    componentWillUnmount() {
      this._isMounted = false; // Indica que o componente vai ser desmontado
    }

    componentDidMount() {
      this._isMounted = true; // Indica que o componente foi montado
      
      firebase.auth().onAuthStateChanged(async (user) => {
        // Muda os estados do componente somente se ele estiver montado
        if (this._isMounted) {
          if (user) {
            const data = await firebase.firestore().collection('users').doc(user.uid).get();
            const role = data.data().role;
            const validRoles = ['admin', 'cityAdmin', 'contentManager', 'masterAdmin', 'tradeAdmin', 'contentEditor', 'rotaAdmin', 'regionAdmin', 'dashboardViewer', 'cae', 'caeAdmin'];

            if (!validRoles.includes(role)) {
              alert('Insuficient permissions!');
              this.props.history.push('/map');
            }
            this.setState({ loading: false });
          } else {
            alert('Must be authenticated');
            this.props.history.push('/adm');
          }
        }
      });
    }

    render() {
      return !this.state.loading ? (
        <WrappedComponent />
      ) : (
        <p>Loading...</p>
      );
    }
  }

  return adminAlreadyAuthenticated;
};

export default adminAlreadyAuthenticated;
