import React, { useEffect, useState, useContext } from 'react';

// Firebase Import
import firebase from '../../config/Firebase';

// CSS Import
import '../../css/InventoryAdmin.css';

// Components imports
import CitiesList from '../../component/ListComponents/CitiesList';
import Loading from '../../component/Loading';
import EditCities from '../../component/EditComponents/EditCities';
import VerifyPopup from '../../component/VerifyPopup';
import { RodapeAdmin } from '../../component/RodapeAdmin';
import NoResultsComponent from '../../component/NoResultsComponent';

// Assets imports
import SearchIcon from '../../assets/imgs/search_icon.svg';

// Context import
import { MyContext } from '../../component/SidebarAdmin';

export default function CitiesAdmin() {
  const [places, setPlaces] = useState();
  const [verifyPopup, setVerifyPopup] = useState(false);
  const [editPopup, setEditPopup] = useState(false);
  const [selectedItem, setSelectedItem] = useState();
  const [filter, setFilter] = useState('name');
  const [search, setSearch] = useState('');
  const [action, setAction] = useState('Editar');
  const [isLoadingCities, setIsLoadingCities] = useState(true);
  const [filteredPlaces, setFilteredPlaces] = useState();
  const { city, role, setNumPendency } = useContext(MyContext);

  const buildQuery = () => {
    const currFilter = filter === 'searchBar' ? 'name' : filter;
    setIsLoadingCities(true);
    let docs;

    if (role !== 'cityAdmin') {
      docs = firebase.firestore()
        .collection('Cities')
        .orderBy(currFilter);
    } else {
      docs = firebase.firestore()
        .collection('Cities')
        .where('name', '==', city);
    }
    
    setIsLoadingCities(false);

    return docs;
  };

  const getFirstPage = async () => {
    if (city) {
      const newDocs = await buildQuery().get();

      setFilteredPlaces(newDocs);
      setPlaces(newDocs);
    }
  };

  const handleSearch = () => {
    if (places.docs) {
      let filteredPlaces = places.docs.filter((doc) => searchCityText(doc));
      filteredPlaces = { docs: filteredPlaces };
      setFilteredPlaces(filteredPlaces);
    }
  };

  const searchCityText = (doc) => {
    if (search === '')
      return true;
    
    const isInName = doc.data().name.toLowerCase().includes(search.toLowerCase());
    const isInDescription = doc.data().longDescr?.toLowerCase().includes(search.toLowerCase());

    return isInName || isInDescription;
  };

  useEffect(() => {
    getFirstPage();
  }, [city, filter, editPopup]);

  useEffect(() => {
    if (!editPopup) {
      setSelectedItem(undefined);
      if (city)
        getFirstPage();
    }

    window.scrollTo(0, 0);
  }, [editPopup]);

  const handleDeleteCity = async (doc) => {
    try {
      const pendency = {
        action: 'Excluir',
        city: city,
        createDate: new Date(),
        currDoc: {},
        lastDoc: doc.data(),
        requestor: (await getUserInfo()).name,
        type: 'Cities',
        emailRequester: (await getUserInfo()).email,
        docId: doc.id,
      };
      await firebase.firestore().collection('Pendencies').add(pendency);
      setNumPendency((num) => num + 1);
      setVerifyPopup(false);
      setSelectedItem(undefined);
      getFirstPage();
    } catch (error) {
      setVerifyPopup(false);
    }
  };

  async function getUserInfo() {
    const userUid = firebase.auth().currentUser.uid;
    const userData = await firebase.firestore().collection('users').doc(userUid).get();

    return userData.data();
  }

  const handleEdit = (doc) => {
    setSelectedItem(doc);
    setEditPopup(true);
  };

  const handleDelete = (doc) => {
    setSelectedItem(doc);
    setVerifyPopup(true);
  };

  if (!places || isLoadingCities || !filteredPlaces) {
    return <Loading />;
  }

  return (
    <div className='InventarioAdmin'>
      { editPopup ? <EditCities setEditPopup={setEditPopup} doc={selectedItem} action={action} setNumPendency={setNumPendency} role={role} /> : (<div className='body_inventory_adm'>
        <div className='div_title_inventory_adm'>
          <h1>Cidades</h1>
        </div>
        { !['tradeAdmin', 'cityAdmin'].includes(role) ? <div className='div_create_inventory_adm'>
          <p className='create_inventory_adm' onClick={() => { setAction('Criar'); setEditPopup(true); }}>Criar Nova Cidade</p>
        </div> : null}
        <div className='div_browse_inventory_adm'>
          <input type='text' className='input_browse_inventory' onChange={(e) => { setSearch(e.target.value); handleSearch(); }} placeholder='Buscar' />
          <button type="button" className='btn_browse_inventory_adm' disabled>
            <img src={SearchIcon} alt='Icone de pesquisa' className='search_icon_admin'/>
          </button>
        </div>
        { filteredPlaces && filteredPlaces.docs && filteredPlaces.docs.length > 0 ? (
        <div className='div_table_inventory_adm'>
          <table className='table_inventory_adm'>
            <thead>
              <tr className="row_table_inventory th_table_admin">
                <td className='column_title_table_inventory th_table_admin' onClick={() => setFilter('name')}>Nome</td>
                <td className='column_table_inventory th_table_admin' onClick={() => setFilter('regionName')}>Região</td>
                <td className='column_desc_table_inventory th_table_admin' onClick={() => setFilter('shortDescr')}>Descrição Curta</td>
                <td className='column_desc_table_inventory th_table_admin' onClick={() => setFilter('longDescr')}>Descrição Longa</td>
                <td className='column_table_inventory th_table_admin' onClick={() => setFilter('Active')}>Status</td>
                <td className='column_table_inventory th_table_admin'></td>
              </tr>
            </thead>
            <tbody>
              {
                filteredPlaces.docs.map((doc) => {
                  if (!doc.data().regiao)
                    return <CitiesList doc={doc} key={doc.id} handleEdit={handleEdit} handleDelete={handleDelete} />;
                })
              }
            </tbody>
          </table>
        </div>) : <NoResultsComponent title={'Cidades'} search={search} /> }
        { verifyPopup ? <VerifyPopup setVerifyPopup={setVerifyPopup} action={'Excluir'} handleAction={handleDeleteCity} type={'Cidade'} doc={selectedItem} /> : null} 
      </div>)}
    </div>
  );
}
