import React from 'react';
import Firebase from 'firebase';
import firebase from '../config/Firebase';
import { Event } from '../config/TrackingGA';
import { createButton } from 'react-social-login-buttons';
import { FaFacebookSquare } from 'react-icons/fa';

export default class FacebookLogin extends React.Component {
  handleFacebookLogin = () => {
    const provider = new Firebase.auth.FacebookAuthProvider();
    provider.setCustomParameters({ display: 'popup' });

    firebase.auth().signInWithPopup(provider).then((result) => {
      const userId = firebase.auth().currentUser.uid;
      const imageUrl = result.additionalUserInfo.profile.picture.data.url;

      firebase.firestore().collection('users').doc(userId).get()
        .then((doc) => {
          if (doc.exists) {
            if (doc.data().completedSignUp)
              this.props.callback('complete');
            else
              this.props.callback('incomplete');
          } else {
            firebase.auth().currentUser.updateProfile({
              displayName: result.user.displayName,
            });
            firebase.firestore().collection('users').doc(userId).set({
              name: result.user.displayName,
              email: result.user.email,
              image: imageUrl,
              completedSignUp: false,
            })
              .then((docRef) => this.props.callback('incomplete'));
          }
        });
    }).catch((error) => {
      console.error(`Error code: ${error.code}\n Error message: ${error.message}`);
      if (error.code === 'auth/account-exists-with-different-credential')
        this.props.callback('Conta já existe mas com outro provedor!');
      else
        this.props.callback(false);
    });
    Event('User', 'Login', 'FacebookLogin');
  }

  render() {
    const { message } = this.props;
    const config = {
      text: message,
      icon: FaFacebookSquare,
      style: { background: '#3399ff' },
      activeStyle: { background: '#3399f0' },
    };
    const FacebookLoginButton = createButton(config);
    return (
      <FacebookLoginButton 
        style={{ 
          display: 'flex', 
          justifyContent: 'center', 
          alignItems: 'center', 
          fontSize: '17px', 
          width: '320px', 
          boxShaddow: 'none', 
        }} 
        onClick={this.handleFacebookLogin} 
      />
    );
  }
}
