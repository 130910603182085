import React from 'react';

// Assets imports
import CurrScoreIcon from '../assets/imgs/curr_score_icon.JPG';
import MaxScoreIcon from '../assets/imgs/max_score_icon.JPG';

// CSS Import
import '../assets/css/RankingComponents/cardInfoCalculator.css';

export default function CardInfoCalculator({ title, bg, type, score }) {
  return (
    <div className="CardInfoCalculator">
      <div className="div_card_info_calculator" style={{ backgroundColor: bg }}>
        <div className="div_align_card_info_calculator">
          <div className="div_img_card_max">
            <img src={type === 'current' ? CurrScoreIcon : MaxScoreIcon} className='img_type_score_calculator' />
          </div>
          <div className="col-xs-8 text-right">
            <span>{title}</span>
            <h2 className='title_score_calculator'>{score % 1 > 0 ? score.toFixed(1) : score}</h2>
          </div>
        </div>
      </div>
    </div>
  );
}