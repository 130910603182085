export const fieldsQuestionsDemand = [
  {
    title: 'Tema*',
    field: 'tema',
    type: 'text',
    boolType: false,
    component: 'textQuestion',
    placeholder: '',
  },
  {
    title: 'Pergunta*',
    field: 'pergunta',
    type: 'text',
    boolType: false,
    component: 'textQuestion',
    placeholder: '',
  },
  {
    title: 'Tipo da Pergunta*',
    field: 'tipoPergunta',
    type: 'select',
    boolType: true,
    component: 'select',
  },
  {
    title: 'Tela de Exemplo',
    field: 'tipoPergunta',
    component: 'example',
    explanation: 'Uma imagem de ilustração, que mostra o tipo da pergunta escolhida no aplicativo.'
  }, {
    title: 'Opções de resposta*',
    field: 'opcoes',
    component: 'optionEditor',
    explanation: 'Essas serão as opções de resposta que aparecerão no App.',
  }
];