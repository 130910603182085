import React, { useContext, useEffect, useState } from 'react';

// Components Imports
import Loading from '../../component/Loading';
import ReportAdmin from './ReportAdmin';

// eslint-disable-next-line import/no-cycle
import { MyContext } from '../../component/SidebarAdmin';

export default function HandleReportAdmin() {
  const { city } = useContext(MyContext);
  const [title, setTitle] = useState();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    const url = window.location.href; 
    const res = url.split('/');

    setTitle(res[5]);
    setLoading(false);
  }, [window.location.href]);

  useEffect(() => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 500);
  }, [city]);

  if (!city || !title || loading) {
    return <Loading />;
  }

  return (
    <div>
      <ReportAdmin title={title} city={city} />
    </div>
  );
}