import React, { useState, useEffect } from "react";
import firebase from "../../config/Firebase";

// Components imports
import VerifyPopup from '../VerifyPopup';
import ButtonForm from '../FormComponents/ButtonForm';
import HeaderForm from '../FormComponents/HeaderForm';
import TitleEditor from '../FormComponents/TitleEditor';
import { fieldsEditGuideline } from '../FieldsForm/guideline.fields';
import { inputsComponents } from '../../utils/formManager';

export default function EditGuideline({ doc, action, userCity, setEditPopup }) {
  const [popup, setPopup] = useState(false);
  const [item, setItem] = useState(doc ? doc.data() : {
    titulo: '',
    cidade: userCity,
    active: true,
    original: {
      titulo: '',
    },
  });

  const handleEditGuideline = async () => {
    await firebase.firestore().collection('PlanoDiretor_Diretrizes').doc(doc.id).set(item);
    setEditPopup(false);
  };

  const handleAddGuideline = async () => {
    await firebase.firestore().collection('PlanoDiretor_Diretrizes').add(item);
    setEditPopup(false);
  };

  function handleCheckFields() {
    const keys = ['titulo'];

    for (let i = 0; i < keys.length; i++) {
      if (item[keys[i]] === '') {
        document.getElementById(keys[i]).focus();
        return;
      }
    }

    setPopup(true);
  }

  useEffect(() => {
    if (userCity) 
      setItem({ ...item, cidade: userCity });
  }, [userCity]);

  return (
    <div className='EditInventory'>
      <div className='bodyEditInventory'>
        <TitleEditor action={action} type={'Diretriz'} />
        <div>
          <form className='form_edit_admin'>
            <HeaderForm />
            {
              fieldsEditGuideline.map((field) => {
                return inputsComponents[field.component]({ term: field, item: item, setItem: setItem, doc: doc, userCity: userCity });
              })
            }
            { popup ? <VerifyPopup setVerifyPopup={setPopup} handleAction={doc ? handleEditGuideline : handleAddGuideline} title={`Deseja ${action} esta Diretriz?`} message={'Ao realizar essas ações, elas serão gravadas na base de dados, e as novas informações poderão ser vistas posteriormente. Deseja continuar?'} /> : null }
            <ButtonForm setEditPopup={setEditPopup} handleCheckFields={handleCheckFields} />
          </form>
        </div>
      </div>
    </div>
  );
}