import React from 'react';

import NotFoundIcon from '../assets/imgs/not_found_icon.svg';

import '../assets/css/noResultsComponent.css';

export default function NoResultsComponent({ title, search }) {
  return (
    <div className='NoResultsComponent'>
      <div>
        <img src={NotFoundIcon} alt='Icone de busca de encontrada' className='img_not_found'/>
        { search !== '' ? <h1 className='title_not_found'>Desculpe! Não há "{search}" entre {title}.</h1> : <h1 className='title_not_found'>Não há {title} disponíveis.</h1>}
        <p className='text_not_found'>Não encontramos nenhum registro do tipo em nossa base de dados.</p>
      </div>
    </div>  
  );
}