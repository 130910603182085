/* eslint-disable quote-props */
import React from 'react';

// CSS import
import '../../assets/css/Dashboard/cardInfo.css';

export default function AgencyInfo({ infoNumAgency, infoNumArt }) {
  return (
    <div className='AgencyInfo card_container_dash'>
      <div className='div_top_header_dash'>
        <h1 className='title_cardInfo'>Guias e Artesãos</h1>
        <button className='btn_agency_info' disabled>Atual</button>
      </div>
      <div className='div_footer_dash'>
        <div>
          <h1 className='h1_cardInfo'>{ infoNumAgency }</h1>
          <p className='txt_cardInfo'>Guias</p>
        </div>
        <div>
        <h1 className='h1_cardInfo'>{ infoNumArt }</h1>
          <p className='txt_cardInfo'>Artesãos</p>
        </div>
      </div>
    </div>
  );
}