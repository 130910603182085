export const fieldsEditItinerary = [
  {    
    title: 'Titulo*',
    field: 'titulo',
    type: 'text',
    boolType: false,
    component: 'text',
    placeholder: '',
    explanation: 'Exemplo de Título: Trecho Leste/Oeste, Ramal Águas da Prata , Rota da Aventura , Rota dos Sabores',
  },
  {
    title: 'Pontos da Rota*',
    field: 'pontosRota',
    explanation: 'Selecione as atrações dentro do roteiro (no mínimo 2)',
    component: 'selectFiltered',
  },
  {    
    title: 'História da Rota',
    field: 'descrHistorico',
    type: 'textarea',
    boolType: false,
    component: 'textarea',
    placeholder: '',
    explanation: 'Descreva a história da Rota, o que já acnoteceu de relevante ou histórico, o motivo dela ter sido criada',
  },
  {    
    title: 'Descrição do Percurso',
    field: 'descrPercurso',
    type: 'textarea',
    boolType: false,
    component: 'textarea',
    placeholder: '',
    explanation: 'Coloque aqui o que o turista vai poder ver/vivenciar durante o trajeto',
  },
  {    
    title: 'Descrição do conteúdo da Rota',
    field: 'descrConteudo',
    type: 'textarea',
    boolType: false,
    component: 'textarea',
    placeholder: '',
    explanation: 'Descreva quais os elementos que compõem a rota, por exemplo: O turista vai conhecer fazendas produtoras de café do século XVII...',
  },
  {    
    title: 'Destaques da Rota',
    field: 'descrDestaques',
    type: 'textarea',
    boolType: false,
    component: 'textarea',
    placeholder: '',
    explanation: 'Liste quais itens de destaque merecem atenção do turista, algum animal peculiar, monumento, etc.',
  },
  {
    title: 'Pontos de Apoio',
    field: 'pontosApoio',
    explanation: 'Selecione os pontos de apoio dentro do roteiro',
    component: 'selectMultipleFilter',
  },
  {
    title: 'Guias e Agências',
    field: 'guiasAgencias',
    explanation: 'Selecione as guias e agências dentro do roteiro',
    component: 'selectMultiplePrefilter',
    filterProp: 'Subtype',
    filters: ['Agência de turismo', 'Agência de turismo emissivo', 'Agência de turismo receptivo', 'Assosiação de turismo', 'Guia de turismo', 'Operadora turística', 'Turismo'],
  },
  {    
    title: 'Carregar Arquivo GPX',
    field: 'GPXFile',
    type: 'file',
    boolType: false,
    component: 'gpxFile',
    explanation: 'Arquivo GPX de waypoints',
  },
  {
    title: 'Waypoints',
    field: 'waypoint',
    boolType: false,
    component: 'waypointInput',
    explanation: 'Localizar no Google Maps, clicar com botão direito e colar os dados. Ex: -23.22222, -47.44444',
  },
  {    
    title: 'Descrição Curta*',
    field: 'Short Descr',
    type: 'textarea',
    boolType: false,
    component: 'textarea',
    placeholder: '',
    explanation: 'Uma breve descrição sobre o trecho.',
  },
  {    
    title: 'Ativo*',
    field: 'Active',
    type: 'select',
    boolType: true,
    component: 'select',
  },
];