import React from 'react';
import { StyleSheet, css } from 'aphrodite';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { openEvents } from '../redux/actions';

class EventButton extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      title: '',
    };
  }

  render() {
    return (
      <div
        onClick={() => {
          this.props.openEvents();
          if (document && document.activeElement && window.innerWidth <= 500) {
            document.activeElement.blur();
          }
        }}
        className={css(styles.mobileContainer)}
      >
        eventos
      </div>
    );
  }
}

const styles = StyleSheet.create({
  mobileContainer: {
    width: (window.innerWidth / 3),
    textAlign: 'center',
    backgroundColor: '#000',
    cursor: 'pointer',
    justifyContent: 'center',
    backgroundImage: '#000',
    borderWidth: '.30px',
    color: 'white',
    fontSize: '23px',
    bottom: '20px',
    position: 'relative',
    borderRadius: '25px',
    paddingTop: '10px',
    paddingBottom: '10px',
    paddingLeft: '20px',
    paddingRight: '20px',
  },
  container: {

  },
});

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ openEvents }, dispatch);
}

export default connect(null, mapDispatchToProps)(EventButton);
