import React from 'react';

// Assets imports
import AttractionsPrint from '../../assets/imgs/screenshots/atrativos.jpg';
import CepPrint from '../../assets/imgs/screenshots/cep.jpg';
import MultipleChoisePrint from '../../assets/imgs/screenshots/escolhaMultipla.jpg';
import MultipleChoiseOtherPrint from '../../assets/imgs/screenshots/escolhaMultiplaOutro.jpg';
import OneChoisePrint from '../../assets/imgs/screenshots/escolhaUnica.jpg';
import OneChoiseOtherPrint from '../../assets/imgs/screenshots/escolhaUnicaOutro.jpg';
import GenderPrint from '../../assets/imgs/screenshots/genero.jpg';
import ItemReviewPrint from '../../assets/imgs/screenshots/notaItem.jpg';
import TextPrint from '../../assets/imgs/screenshots/texto.jpg';
import DinamicTextPrint from '../../assets/imgs/screenshots/textoDinamico.jpg';
import ValuePrint from '../../assets/imgs/screenshots/valor.jpg';
import CityPrint from '../../assets/imgs/screenshots/cidades_visitadas.jpg';

// CSS import
import '../../assets/css/InputComponents/exampleInput.css';

export default function ExampleInput({ title, explanation, field, value }) {
  const prints = {
    atrativos: AttractionsPrint,
    cep: CepPrint,
    escolhaMultipla: MultipleChoisePrint,
    escolhaMultiplaOutro: MultipleChoiseOtherPrint,
    escolhaUnica: OneChoisePrint,
    escolhaUnicaOutro: OneChoiseOtherPrint,
    genero: GenderPrint,
    notaItem: ItemReviewPrint,
    texto: TextPrint,
    textoDinamico: DinamicTextPrint,
    valor: ValuePrint,
    cidadesVisitadas: CityPrint,
  };
  const names = {
    atrativos: 'Atrativos',
    cep: 'CEP',
    escolhaMultipla: 'Escolha Múltipla',
    escolhaMultiplaOutro: 'Escolha Múltipla com Outro',
    escolhaUnica: 'Escolha Única',
    escolhaUnicaOutro: 'Escolha Única com Outro',
    genero: 'Genêro',
    notaItem: 'Nota de Item',
    texto: 'Texto',
    textoDinamico: 'Texto Dinâmico',
    valor: 'Valor',
    cidadesVisitadas: 'Cidades Visitadas'
  };

  return (
    <div className="ExampleInput">
      <div className='div_label_input_text div_label_input_example'>
        <label className="label_text_input">
          <strong>{title}</strong>
        </label>
      </div>
      <div className='div_align_explanation_input'>
        <div className="div_out_input_example">
          {
            prints[value[field]] ? <img src={prints[value[field]]} alt='Imagem de uma captura de tela do app Destinos Inteligentes' className='img_example_input'/> : null
          }
        </div>
        <div className='div_explanation_input'>
          <p className='subtext_explanation_input'>{explanation} (Tipo de Pergunta: {names[value[field]]})</p>
        </div>
      </div>
    </div>
  );
}