import axios from 'axios';

import API from '../config/API';

const routeTemplate = API.url + '/statistics/templates';

export const getAllTemplate = async (city) => {
  const res = await axios.get(routeTemplate + '/getAllTemplates', {
    params: {
      city,
    },
  });

  return res;
};