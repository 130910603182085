import React, { useEffect, useState } from "react";

import "../../assets/css/Dashboard/cardChartDashboard.css";

import Sunburst from 'sunburst-chart';

export default function CardChartDashboard({ title, data }) {
  const colors = {
    Attraction: "#A853F6",
    SubAttraction: "#C085F9",
    Commerce: "#F59D5D",
    SubCommerce: "#F9B98D",
    Food: "#6566F6",
    SubFood: "#9392F7",
    Infrastructure: "#23E3EE",
    SubInfrastructure: "#67ECF3",
    Lodging: "#0BE597",
    SubLodging: "#55ECB5",
    Services: "#E2483E",
    SubServices: "#EA7D76",
  };
  const translatedTypes = {
    Attraction: "Atrativos",
    Commerce: "Compras",
    Food: "Alimentação",
    Infrastructure: "Infraestrutura",
    Lodging: "Hospedagem",
    Services: "Serviços",
  }


  const handleFilterData = () => {
    const filteredData = {
      name: 'Tipos',
      color: 'white',
      children: Object.keys(data).map((key) => {
        return {
          name: translatedTypes[key],
          color: colors[key],
          children: Object.keys(data[key]).map((subKey) => {
            if (subKey !== 'amount') {
              return {
                name: subKey,
                color: colors[key],
                children: Object.keys(data[key][subKey]).map((finalKey) => {
                  if (finalKey !== 'amount') {
                    return {
                      name: finalKey,
                      size: data[key][subKey][finalKey],
                      color: colors['Sub' + key]
                    }
                  }
                }).filter((obj) => obj !== undefined)
              }
            }
          }).filter((obj) => obj !== undefined)
        }
      })
    }

    return filteredData;
  }

  const handleInitChart = (elementDiv) => {
    const dataChart = handleFilterData();

    elementDiv.innerHTML = '';

    Sunburst()
      .data(dataChart)
      .color('color')
      .size('size')
      .centerRadius(0)
      .radiusScaleExponent(1.5)
      .minSliceAngle(.4)
      .excludeRoot(true)
      .tooltipContent((d, node) => `Quantidade: <i>${node.value} </i>`)
      (elementDiv);
  }

  useEffect(() => {
    const elementDiv = document.getElementById("div_chart_dashboard" + title);

    elementDiv.innerHTML = '';
    if (elementDiv)
      handleInitChart(elementDiv)
  }, [document.getElementById("div_chart_dashboard"), data]);

  return (
    <div className='CardChartDashboard'>
      <div className='div_headear_carddashboard div_header_chart_dashboard'>
        <h1>{title}</h1>
      </div>
      <div className='div_align_chart_dashboard'>
        <div className="div_chart_dashboard" id={"div_chart_dashboard" + title} />
      </div>
    </div>
  );
}