import React from 'react';
import { StyleSheet, css } from 'aphrodite';
import arrow from '../assets/imgs/right-arrow-white.png';
import { connect } from 'react-redux';
import { closeDrawer, closeEventDrawer } from '../redux/actions';

class MapCenterButton extends React.Component {
  handleClick = () => {
    const { lastDrawerOpened } = this.props;

    if (lastDrawerOpened === 'attractive') {
      this.props.closeDrawer();
    } else {
      this.props.closeEventDrawer();
    }
  }

  render() {
    return (
      <div onClick={this.handleClick} className={css(styles.background)}>
        <img alt='' src={arrow} className={css(styles.arrow)} />
      </div>
    );
  }
}

const styles = StyleSheet.create({
  background: {
    alignItems: 'center',
    backgroundColor: '#c844dd',
    cursor: 'pointer',
    display: 'flex',
    height: 80,
    justifyContent: 'center',
    borderBottomLeftRadius: 50,
    borderTopLeftRadius: 50,
    marginRight: -1,
    width: 40,
  },
  arrow: {
    height: 30,
    width: 30,
    marginLeft: '16px',
  },
});

function mapStateToProps(state) {
  return {
    lastDrawerOpened: state.lastDrawerOpened,
  };
}

const mapDispatchToProps = (dispatch) => ({ 
  closeDrawer: () => { dispatch(closeDrawer()); },
  closeEventDrawer: () => { dispatch(closeEventDrawer()); },
});
export default connect(mapStateToProps, mapDispatchToProps)(MapCenterButton);
