/* eslint-disable import/no-extraneous-dependencies */
import React, { useState } from 'react';

import '../assets/css/calendarPopup.css';

// Import Calendar Component
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';

export default function CalendarPopup({ type, date, setDate, setPopup }) {
  const [num, setNum] = useState();

  function handleSetDate() {
    const value = new Date(num);

    value.setTime(value.getTime() + 86400000);
    return value.toISOString().slice(0, 10);
  }

  return (
    <div className='div_back_datepopup'>
        <div className='div_inner_datepopup'>
            <div className='div_calendar_component'>
                <Calendar className='calendar_component' onChange={(e) => setNum(e.toISOString().slice(0, 10))} maxDate={(type === 'initialDate') ? (new Date(date.finalDate)) : null} minDate={(type === 'finalDate') ? (new Date(date.initialDate)) : null} />
            </div>
            <div className='div_btns_datepopup'>
                <button className="btn_cancel_popup" type='button' onClick={() => setPopup(false)}>Cancelar</button>
                <button className="btn_confirm_popup" type='button' onClick={() => {
                  setDate({ ...date, [type]: handleSetDate() });
                  setPopup(false);
                }}>Confirmar</button>
            </div>
        </div>
    </div>
  );
}