import React from 'react';
import firebase from '../config/Firebase';
import { StyleSheet, css } from 'aphrodite';
import Logo from '../assets/imgs/Destinos.png';
import {
  Button, Form, FormGroup, Input,
} from 'reactstrap';
import AlertMessage from '../component/Alert';
import { PageView } from '../config/TrackingGA';
import bg from '../assets/imgs/desk_bg.png';

export default class ForgotPassword extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      email: '',
      errorFlag: false,
      errorMsg: '',
      warningFlag: false,
      warningMsg: '',
      sucessFlag: false,
      sucessMsg: '',
    };
    this.handleButtonClick = this.handleButtonClick.bind(this);
    this.handleKeyPress = this.handleKeyPress.bind(this);
  }

  componentDidMount() {
    PageView();
  }

  handleChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  }

  handleKeyPress(target) {
    if (target.charCode === 13) {
      target.preventDefault();
      this.handleButtonClick();    
    } 
  }

  handleButtonClick() {
    const { email } = this.state;
    if (email === '') {
      this.setState({ errorFlag: true, errorMsg: 'Email não digitado' });
    } else {
      firebase.auth().sendPasswordResetEmail(email).then(() => {
        this.setState({
          email: '',
          errorMsg: '',
          errorFlag: false,
          warningFlag: false,
          warningMsg: '',
          sucessFlag: true,
          sucessMsg: 'Enviamos um email para ' + email + ' para você poder redefinir a sua senha.',
        });
      }).catch((err) => {
        const { code, message } = err;
        if (code === 'auth/invalid-email') {
          this.setState({
            errorFlag: true,
            errorMsg: 'Email inválido',
          });
        } else if (code === 'auth/user-not-found') {
          this.setState({
            errorFlag: true,
            errorMsg: 'Email não encontrado',
          });
        } else {
          this.setState({
            errorFlag: true,
            errorMsg: message,
          });
        }
      }); 
    }
  }

  render() {
    return (
      <div className={css(styles.pageBackground)}>
        <div className={css(styles.container, styles.collumn)}>
        <img alt='' width='250px' src={Logo} />
        <span className={css(styles.subtitleText)}>
          Digite o seu email abaixo.
        </span> 
        {this.state.errorFlag && <AlertMessage color='danger' isOpen={this.state.errorFlag} onToggle={() => { this.setState({ errorFlag: false }); }} msg={this.state.errorMsg} />}
        {this.state.warningFlag && <AlertMessage color='warning' isOpen={this.state.warningFlag} onToggle={() => { this.setState({ warningFlag: false }); }} msg={this.state.warningMsg} />}
        {this.state.sucessFlag && <AlertMessage color='success' isOpen={this.state.sucessFlag} onToggle={() => { this.setState({ sucessFlag: false }); this.props.history.push('/'); }} msg={this.state.sucessMsg} />}
          <Form style={{ textAlign: 'center' }}>
            <FormGroup className={css(styles.formGroup)}>
              <Input
                onClick={(e) => { e.target.focus(); }}
                type='email'
                name='email'
                placeholder='Email'
                value={this.state.email}
                onChange={(e) => this.handleChange(e)}
                onKeyPress={this.handleKeyPress}
              />
            </FormGroup>
            <Button className={css(styles.formButton)} onClick={this.handleButtonClick}>Enviar</Button>
          </Form>                                                                                                                                                                                                                            
        </div>
      </div>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    maxWidth: '400px',
    alignItems: 'inherit',
    justifyContent: 'inherit',
  },
  errorMsg: {
    fontStyle: 'italic bold',
  },
  collumn: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
  },
  formButton: {
    width: '100%',
    backgroundColor: '#3C71D7',
    ':hover': {
      backgroundColor: '#3c8ed7',
    },
  },
  formGroup: {
    marginBottom: '0.2rem',
  },
  pageBackground: {
    backgroundImage: `url(${bg})`,
    backgroundRepeat: 'no-repeat',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: 'white',
    minHeight: '100%',
    height: 'fit-content',
  },
  subtitleText: {
    textAlign: 'center',
    maxWidth: '250px',
    marginTop: '10px',
    marginBottom: '5px',
  },
});
