import { 
  OPEN_PREVIEW, 
  CLOSE_PREVIEW, 
  TOGGLE_PREVIEW, 
  UPDATE_PREVIEW_DATA, 
  OPEN_DRAWER, 
  CLOSE_DRAWER, 
  UPDATE_USER_LOCATION, 
  TOGGLE_FILTER, 
  FILTER_PINS,
  FILTER_CHECKBOX, 
  TOGGLE_LOG_OUT_FLAG,
  TOGGLE_SEARCH,
  CHANGE_FLAGS,
  CENTER_MAP,
  SHOW_SEARCH,
  HIDE_SEARCH,
  OPEN_EVENTS,
  CLOSE_EVENTS,
  SET_MAP_ZOOM,
  SET_LOADING,
  SAVE_EVENTS,
  SAVE_CITY_DEFAULT_IMAGE,
  OPEN_EVENT_DRAWER,
  CLOSE_EVENT_DRAWER,
  SAVE_EVENTS_DEFAULT_IMAGE,
  SAVE_FILTER_MASK,
  SAVE_ACTIVITIES,
} from '../actionTypes';

const defaultState = {
  lastDrawerOpened: undefined,
  lastPreviewOpened: undefined,
  citiesDefaultImages: {},
  eventsDefaultImage: {},
  loading: false,
  isFilterOpen: false,
  isPreviewOpen: false,
  isEventsPreviewOpen: false,
  isDrawerOpen: false,
  isEventDrawerOpen: false,
  centerMap: {},
  mapZoom: undefined,
  drawerInfo: {},
  eventDrawerInfo: {},
  flags: {},
  activitiesIcons: {},
  events: [],
  filterInfo: {
    filteredPoints: [],
    filters: {
      alimentacao: true,
      hospedagem: true,
      atrativos: true,
      compras: true,
      infraEstrutura: true,
      servicos: true,
    },
    filterMask: {},
  },
  previewInfo: {
    title: '',
    shortDescription: '',
    position: { },
    document: '',
    collection: '',
  },
  search: {
    searching: false,
    value: '',
    results: [],
  },
  user: {
    logedOut: false,
    location: {
      lat: undefined,
      long: undefined,
    },
  },
};

export default function previewReducer(state = defaultState, action) {
  switch (action.type) {
    case OPEN_PREVIEW: {
      return {
        ...state,
        isPreviewOpen: true,
        lastPreviewOpened: 'attractive',
      };
    }

    case CLOSE_PREVIEW: {
      const lastPreviewOpened = state.isEventsPreviewOpen ? 'event' : undefined;
      return {
        ...state,
        isPreviewOpen: false,
        lastPreviewOpened,
        previewInfo: {
        },
      };
    }

    case TOGGLE_PREVIEW: {
      let lastPreviewOpened; 
      if (state.isPreviewOpen) {
        if (state.isEventsPreviewOpen) {
          lastPreviewOpened = 'event';
        } else {
          lastPreviewOpened = undefined;
        }
      } else {
        lastPreviewOpened = 'attractive';
      }
      return {
        ...state,
        isPreviewOpen: !state.isPreviewOpen,
        lastPreviewOpened,
      };
    }

    case UPDATE_PREVIEW_DATA: {
      return {
        ...state,
        isPreviewOpen: true,
        previewInfo: action.attractive,
        lastPreviewOpened: 'attractive',
      };
    }

    case OPEN_DRAWER: {
      return {
        ...state,
        lastDrawerOpened: 'attractive',
        isDrawerOpen: true,  
        drawerInfo: action.attractive,
      };
    }

    case CLOSE_DRAWER: {
      const lastDrawerOpened = state.isEventDrawerOpen ? 'event' : undefined;
      return {
        ...state,
        lastDrawerOpened,
        isDrawerOpen: false,
        drawerInfo: {
          collection: null,
          document: null,
        },
      };
    }
    
    case UPDATE_USER_LOCATION: {
      return {
        ...state,
        user: {
          location: {
            lat: action.lat,
            long: action.long,
          },
        },
      };
    }

    case TOGGLE_FILTER: {
      return {
        ...state,
        isFilterOpen: !state.isFilterOpen,
      };
    }

    case FILTER_PINS: {
      return {
        ...state,
        filterInfo: {
          ...state.filterInfo,
          filteredPoints: action.pins,
        },
      };
    }

    case FILTER_CHECKBOX: {
      return {
        ...state,
        filterInfo: {
          ...state.filterInfo,
          filters: action.options,
        },
      };
    }

    case OPEN_EVENTS: {
      return {
        ...state,
        isEventsPreviewOpen: true,
        lastPreviewOpened: 'event',
      };
    }

    case CLOSE_EVENTS: {
      const lastPreviewOpened = state.isPreviewOpen ? 'attractive' : undefined;
      return {
        ...state,
        isEventsPreviewOpen: false,
        lastPreviewOpened,
      };
    }

    case TOGGLE_LOG_OUT_FLAG: {
      return {
        ...state,
        user: {
          ...state.user,
          logedOut: !state.user.logedOut,
        },
      };
    }

    case TOGGLE_SEARCH: {
      return {
        ...state,
        search: {
          searching: action.open,
          results: action.results,
          value: action.value,
        },
      };
    }

    case CHANGE_FLAGS: {
      return {
        ...state,
        flags: action.flags,
      };
    }
    
    case CENTER_MAP: {
      return {
        ...state,
        centerMap: action.location,
      };
    }
    
    case SHOW_SEARCH: {
      return {
        ...state,
        search: {
          searching: true,
          value: state.search.value,
          results: state.search.results,
        },
      };
    }

    case HIDE_SEARCH: {
      return {
        ...state,
        search: {
          searching: false,
          value: state.search.value,
          results: state.search.results,
        },
      };
    }

    case SET_MAP_ZOOM: {
      return {
        ...state,
        mapZoom: action.zoom,
      };
    }
    
    case SET_LOADING: {
      return {
        ...state,
        loading: action.loading,
      };
    }
    
    case SAVE_EVENTS: {
      return {
        ...state,
        events: action.events,
      };
    }

    case SAVE_CITY_DEFAULT_IMAGE: {
      return {
        ...state,
        citiesDefaultImages: {
          ...state.citiesDefaultImages,
          [action.city]: action.urls,
        },
      };
    }

    case SAVE_EVENTS_DEFAULT_IMAGE: {
      return {
        ...state,
        eventsDefaultImage: {
          ...state.eventsDefaultImage,
          [action.city]: action.urls,
        },
      };
    }

    case OPEN_EVENT_DRAWER: {
      return {
        ...state,
        isEventDrawerOpen: true,  
        eventDrawerInfo: action.attractive,
        lastDrawerOpened: 'event',
      };
    }

    case CLOSE_EVENT_DRAWER: {
      const lastDrawerOpened = state.isDrawerOpen ? 'attractive' : undefined;
      return {
        ...state,
        lastDrawerOpened,
        isEventDrawerOpen: false,
        eventDrawerInfo: {},
      };
    }

    case SAVE_FILTER_MASK: {
      return {
        ...state,
        filterInfo: {
          ...state.filterInfo,
          filterMask: action.mask,
        },
      };
    }

    case SAVE_ACTIVITIES: {
      return {
        ...state,
        activitiesIcons: action.activities,
      };
    }

    default: {
      return {
        ...state,
        isPreviewOpen: false,
        isDrawerOpen: false,
        drawerInfo: {
          collection: null,
          document: null,
        },
      };
    }
  }
}