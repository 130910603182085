/* eslint-disable indent */
import React, { useEffect, useState } from 'react';

// Firebase Import
import firebase from '../../config/Firebase';

// Css import
import '../../assets/css/EditComponents/EditInventory.css';

// Components imports
import VerifyPopup from '../VerifyPopup';
import ButtonForm from '../FormComponents/ButtonForm';
import HeaderForm from '../FormComponents/HeaderForm';
import TitleEditor from '../FormComponents/TitleEditor';
import { inputsComponents } from '../../utils/formManager';
import { fieldsEditUsers } from '../FieldsForm/users.fields';

export default function EditUsers({ setEditPopup, doc, action }) {
  const [popup, setPopup] = useState(false);
  const [item, setItem] = useState(doc.data());
  const [load, setLoad] = useState(false);
  const rolesCond = ['cityAdmin', 'regionAdmin', 'tradeAdmin'];

  const handleEditUser = async () => {
    try {
      await firebase.firestore().collection('users').doc(doc.id).set(item);
      setEditPopup(false);
    } catch (error) {
      setEditPopup(false);
    }
  };

  function handleCheckFields() {
    const keys = ['name', 'email', 'city'];

    for (let i = 0; i < keys.length; i++) {
      if ((item[keys[i]] === '' || item[keys[i]] === 0)) {
        document.getElementById(keys[i] + 'Form').focus();
        return;
      }
    }
    setPopup(true);
  }

  function handleSetItem() {
    if (load) {
      setItem({
        name: item.name,
        email: item.email,
        role: item.role,
        city: item.city,
        image: item.image,
        [item.role]: [],
      });
    }
    setLoad(true);
  }

  useEffect(() => {
    handleSetItem();

    if (item.role && !rolesCond.includes(item.role)) {
      setItem({
        name: item.name,
        email: item.email,
        role: item.role,
        city: item.city,
        image: item.image,
      });
    }
  }, [item.role]);

  return (
    <div className='EditInventory'>
			<div className='bodyEditInventory'>
        <TitleEditor action={action} type={'Usuário'} />
        <div>
          <form className='form_edit_admin'>
            <HeaderForm />
            {
              fieldsEditUsers.map((field) => {
                if (!field.role || field.role === item.role)
                  return inputsComponents[field.component]({ term: field, item: item, setItem: setItem, doc: doc, role: item.role, userCity: item.city, action: action });
              })
            }
            { popup ? <VerifyPopup setVerifyPopup={setPopup} handleAction={handleEditUser} title={`Deseja ${action} este Usuário?`} message={'Ao realizar essas ações, elas serão gravadas na base de dados, e as novas informações poderão ser vistas posteriormente. Deseja continuar?'} /> : null }
            <ButtonForm setEditPopup={setEditPopup} handleCheckFields={handleCheckFields} />
          </form>
        </div>
			</div>
    </div>
  );
}