export const fieldsEditDemand = [
  {
    title: 'Titulo*',
    field: 'titulo',
    type: 'text',
    boolType: false,
    component: 'text',
    placeholder: '',
  },
  {    
    title: 'Cidade*',
    field: 'cidade',
    boolType: false,
    component: 'select',
    disable: true,
  },
  {
    title: 'Data de início*',
    field: 'dataIni',
    type: 'datetime-local',
    boolType: false,
    component: 'date',
  },
  {
    title: 'Data de fim*',
    field: 'dataFim',
    type: 'datetime-local',
    boolType: false,
    component: 'date',
  },
  {    
    title: 'Ativo*',
    field: 'active',
    type: 'select',
    boolType: true,
    component: 'select',
  },
];