import React from 'react';
import { StyleSheet, css } from 'aphrodite';
import firebase from '../config/Firebase';
import { withRouter, Link } from 'react-router-dom';
import { store } from '../redux/store';
import { TOGGLE_LOG_OUT_FLAG } from '../redux/actionTypes';

class UserMenu extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      admin: false,
      cityAdmin: '',
    };
  }

  componentDidMount() {
    const id = firebase.auth().currentUser.uid;
    firebase.firestore().collection('users').doc(id).get()
      .then((data) => {
        const admin = data.data().role === 'admin' || data.data().role === 'masterAdmin' || data.data().role === 'cityAdmin';
        this.setState({ admin: admin });
        if (data.data().role === 'cityAdmin') {
          const cityAdmin = '' + data.data().cityAdmin;
          this.setState({ cityAdmin: cityAdmin });
        } else {
          const cityAdmin = '';
          this.setState({ cityAdmin: cityAdmin });
        }
      });
  }

  handleLogOut = () => {
    // Seta a flag que indica o logOut do usuário
    store.dispatch({ type: TOGGLE_LOG_OUT_FLAG });
    // Efetua o logOut do usuário
    firebase.auth().signOut().then(() => {
      // Espera 0,5s para não gerar estranheza no usuário
      setTimeout(() => {
        this.props.history.push('/login');
      }, 500);
    }).catch((error) => { // Erro no LogOut
      console.error(error);
    });
    document.cookie = 'hasLogin=false;';
  }

  render() {
    const { admin } = this.state;
    return (
      <div className={css(styles.container)}>
        <div className={css(styles.list)}>
          <span style={{ marginTop: '0px !important' }} onClick={() => (this.props.history.push('/profile'))} className={css(styles.menuItem, styles.bottomLine)}>Perfil</span>
          {
            admin &&
              <Link
                className={css(styles.menuItem, styles.bottomLine)}
                to={{
                  pathname: '/report',
                  search: JSON.stringify(store.getState().filterInfo.filters),
                }}
                target='_blanck'
              >
                Relatório de Registros
              </Link>
          }
          <span className={css(styles.menuItem)} style={{ fontWeight: 'bold' }} onClick={this.handleLogOut} >Sair</span>
        </div>
      </div>
    );
  }
}

const styles = StyleSheet.create({
  bottomLine: {
    borderBottomStyle: 'solid',
    borderBottomColor: 'white',
    borderBottomWidth: '1px',
    ':hover': {
      // borderBottomColor: 'transparent',
      borderBottomColor: 'black',
    },
  },
  container: {
    position: 'absolute',
    justifyContent: 'flex-end',
    zIndex: 4,
    display: 'flex',
    height: '100%',
    width: 'fill-available',
    backgroundColor: ' #c844dd',
    opacity: 0.8,
    '@media (max-width: 500px)': {
      justifyContent: 'center',
      height: '100vh',
      alignItems: 'center',
    },
  },
  list: {
    display: 'flex',
    flexDirection: 'column',
    textAlign: 'right',
    marginRight: '10%',
    marginTop: '3%',
    marginBottom: '5px',
    fontSize: '23px',
    '@media (max-width: 500px)': {
      textAlign: 'center',
      marginRight: '0px',
      marginTop: '0px',
    },
  },
  menuItem: {
    textDecoration: 'none',
    marginTop: '5px',
    paddingBottom: '5px',
    color: 'white',
    cursor: 'pointer',
    ':hover': {
      color: 'black',
    },
  },
});

export default withRouter(UserMenu);
