import React, { useState } from 'react';

// CSS import
import '../../assets/css/InputComponents/adderInput.css';

// Components Imports
import { ActivityList } from '../ActivityList';

export default function AdderInput({ field, title, value, setValue, placeholder, explanation }) {
  const [arrayTerms, setArrayTerms] = useState('');

  const addItemList = () => {
    if (arrayTerms.length > 0) {
      if (value[field]) {
        if (!value[field].includes(arrayTerms))
          setValue({ ...value, [field]: [...value[field], arrayTerms] });
      } else
        setValue({ ...value, [field]: [arrayTerms] });
      setArrayTerms('');
    }
  };

  const removeItemList = (name) => {
    const newList = value[field].filter((value) => value !== name);
    setValue({ ...value, [field]: newList });
  };

  function handleKey(key) {
    if (key !== 'Enter') return;

    document.getElementById('btn_add_act').click();
  }

  return (
    <div className='AdderInput'>
      <div className='div_align_adder_input'>
        <div className='div_label_input_adder'>
          <label htmlFor={field + 'Form'} className="label_text_input">
          <strong>{title}</strong>
          </label>
        </div>
        <div className='div_align_explanation_input'>
          <div className='div_field_input_adder'>
            <input className='input_geolocation' id={field + 'Form'} type="text" value={arrayTerms} onChange={(e) => setArrayTerms(e.target.value) } placeholder={placeholder} onKeyPress={(e) => handleKey(e.key)}/>
            <button type='button' className='btn_open_map' onClick={addItemList} id='btn_add_act'>
              Adicionar
            </button>
          </div>
          <div className='div_explanation_input'>
            <p className='subtext_explanation_input'>{explanation}</p>
          </div>
        </div>
      </div>
      {
        value[field] && value[field].length > 0 ? (
          <div className='div_align_activities'>
            <div className='div_activities_edit_adder_input'>
              { value[field] ? value[field].map((name, index) => {
                return <ActivityList name={name} removeItemList={removeItemList} key={'Adder' + field + index} />;
              }) : null }
            </div>
          </div>
        ) : null
      }
    </div>
  );
}