import React from 'react';
import { css, StyleSheet } from 'aphrodite';
import SearchResult from '../component/SearchResults';
import firebase from '../config/Firebase';
import Header from '../component/Header';
import EventsCarousel from '../component/EventsCarousel';
import UserMenu from '../component/UserMenu';
import { MapComponent } from '../component/MapComponent';
import Attractives from './Attractives';
import Drawer from '@material-ui/core/Drawer';
import Events from './Events';
import Slide from '@material-ui/core/Slide';
import { withStyles } from '@material-ui/core/styles';
import { store } from '../redux/store';
import { connect } from 'react-redux';
import Filters from '../component/Filters';
import { changeFlags, updateUserLocation, saveEvents, saveCityDefautImages, saveEventsDefaultImage, saveFilterMask, saveActivies } from '../redux/actions';
import ErrorPage from './ErrorPage';
import { FILTER_PINS, OPEN_DRAWER, UPDATE_PREVIEW_DATA, OPEN_EVENT_DRAWER, OPEN_EVENTS, FILTER_CHECKBOX, TOGGLE_FILTER } from '../redux/actionTypes';
import { PageView } from '../config/TrackingGA';
import queryString from 'query-string';

class Map extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      isDrawerOpen: false,
      points: [],
      filtered: [],
      filterDefaultState: {
        alimentacao: true,
        hospedagem: true,
        atrativos: true,
        compras: true,
        infraEstrutura: true,
        servicos: true,
      },
      initialLocation: {},
      centerLocation: {},
      allPoints: true,
      collection: '',
      document: '',
      userCity: '',
      usercityAdmin: '',
      width: window.innerWidth,
      userMenuStatus: false,
      userLocation: {},
    };
    this.retrieveData = this.retrieveData.bind(this);
    this.updateDimensions = this.updateDimensions.bind(this);

    document.cookie = 'hasLogin=true;';
  }

  async componentWillMount() {
    const { anonimousData } = this.props;
    if (anonimousData && anonimousData.anonimous) {
      const { city } = anonimousData;
      this.setState({ userCity: city }, () => {
        this.retrieveData();
      });
    } else {
      const user = firebase.auth().currentUser;
      if (user) {
        try {
          firebase.firestore().collection('users').doc(user.uid).get()
            .then((doc) => {
              try {
                this.setState({ userCity: doc.data().city }, () => {
                  this.retrieveData();
                }); 
              } catch (e) {
                console.log(e.toString());
              }
              try {
                if (doc.data().role === 'cityAdmin') {
                  this.setState({ usercityAdmin: doc.data().cityAdmin }, () => {
                    this.retrieveData();
                  });
                } else {
                  this.setState({ usercityAdmin: '' });
                }
              } catch (e) {
                console.log(e.toString());
              }
            });
        } catch (e) {
          console.log(e.toString());
        }
      } else {
        await this.retrieveData();
      }
    }
    const { location } = this.props; // Props that holds the url parameters and the current filter state
    // Parses the url parameters and store it into an object
    const parsedUrl = queryString.parse(location.search);
    const filterUrlState = {
      alimentacao: (parsedUrl.alimentacao === 'true'),
      atrativos: (parsedUrl.atrativos === 'true'),
      compras: (parsedUrl.compras === 'true'),
      hospedagem: (parsedUrl.hospedagem === 'true'),
      infraEstrutura: (parsedUrl.infraestrutura === 'true'),
      servicos: (parsedUrl.servicos === 'true'),
      events: (parsedUrl.events === 'true'),
    };
    // Checks if there are filter parameters being passed throug the url
    if (parsedUrl.openFilters === 'true') {
      if (this.state.width >= 500) {
        store.dispatch({ type: TOGGLE_FILTER }); // Opens the filter drawer if not in mobile
      }
      store.dispatch({ type: FILTER_CHECKBOX, options: filterUrlState }); // Sends the filter state to Redux store
    }
  }

  componentDidMount() {
    PageView();
    this.updateDimensions();
  }

  updateDimensions() {
    this.setState({
      width: window.innerWidth,
    });
  }

  centerMapOnMarker(lat, long) {
    if (this.state.width <= 500) {
      if (this.state.centerLocation.latitude !== lat || this.state.centerLocation.longitude !== long) {
        this.setState({
          centerLocation: {
            latitude: lat,
            longitude: long,
          },
        });
      }
    } else if (this.state.centerLocation.latitude !== lat - 0.001 || this.state.centerLocation.longitude !== long + 0.003) {
      // Deslocamento para a esquerda e para o lado no desktop para ter espaço para o drawer
      this.setState({
        centerLocation: {
          latitude: lat - 0.001,
          longitude: long + 0.003,
        },
      });
    }
  }

  getUserLocation() {
    // Checks if HTML5 geolocation is supported
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position) => { // Success callback
        this.props.updateUserLocation(position.coords.latitude, position.coords.longitude);
      }, () => { // Error callback
        console.log('erro ao pegar localização do usuário');
      });
    } else { // HTML5 geolocation is not suported
      alert('Navegador não suporta sistema de geolocalização');
    }
  }

  getDocumentPath(doc) {
    /* Retorna a coleção e o documento de cada atrativo */
    try {
      // debugger;
      const path = doc.ref.path;
      const collection = path.split('/')[0];
      const document = path.split('/')[1];
      return [collection, document];
      // debugger;
      // alert(doc._document.key.path.segments[5]);
      // alert(doc._document.key.path.segments[6]);
      // return [doc._document.key.path.segments[5], doc._document.key.path.segments[6]]; ==> FIREBASE 5.7.2
      // return [doc._E.key.path.segments[5], doc._E.key.path.segments[6]]; ===> FIREBASE 7.14.4
    } catch (erro) {
      console.log(doc.data());
      return ['', ''];
    }
    // return [doc._document.key.path.segments[5], doc._document.key.path.segments[6]];
  }

  getEvents = (points, city, attractionId = undefined) => {
    if (attractionId) {
      firebase.firestore().collection('Events').where('City', '==', city).where('AttractionId', '==', attractionId)
        .get()
        .then((querySnapshot) => {
          const events = [];
          querySnapshot.docs.forEach((event) => {
            const attractive = points.filter((point) => point.document === attractionId)[0];

            const currentDate = new Date(); // Gets current date
            const eventEndDate = event.data().EndDate.toDate(); // Gets event's finishing date

            if (attractive) {
            // Only show events that haven't finished
              if (currentDate <= eventEndDate) {
                const path = this.getDocumentPath(event);
                events.push({
                  attractive,
                  city: event.data().City,
                  date: event.data().Date,
                  startDate: event.data().StartDate,
                  endDate: event.data().EndDate,
                  title: event.data().Title,
                  description: event.data().Description,
                  collection: path[0],
                  document: path[1],
                  imageFile: event.data().imagefile || undefined,
                  longDescription: event.data()['Long Description'],
                  activitiesList: event.data()['Activities List'],
                });
              }
            } else {
              console.error('Evento sem attractionId ou com attractionId inválido. Event ID:', event.id);
            }
          });
          this.props.saveEvents(events.sort((a, b) => (a.startDate < b.startDate ? -1 : 1)));
        })
        .catch((error) => {
          console.error(error);
        });
    } else {
      firebase.firestore().collection('Events').where('City', '==', city).get()
        .then((querySnapshot) => {
          const events = [];
          querySnapshot.docs.forEach((event) => {
            const attractionId = event.data().AttractionId;
            const attractive = points.filter((point) => point.document === attractionId)[0];

            const currentDate = new Date(); // Gets current date
            const eventEndDate = event.data().EndDate.toDate(); // Gets event's finishing date

            if (attractive) {
            // Only show events that haven't finished
              if (currentDate <= eventEndDate) {
                const path = this.getDocumentPath(event);
                events.push({
                  attractive,
                  city: event.data().City,
                  date: event.data().Date,
                  startDate: event.data().StartDate,
                  endDate: event.data().EndDate,
                  title: event.data().Title,
                  description: event.data().Description,
                  collection: path[0],
                  document: path[1],
                  imageFile: event.data().imagefile || undefined,
                  longDescription: event.data()['Long Description'],
                  activitiesList: event.data()['Activities List'],
                });
              }
            } else {
              console.error('Evento sem attractionId ou com attractionId inválido. Event ID:', event.id);
            }
          });
          this.props.saveEvents(events.sort((a, b) => (a.startDate < b.startDate ? -1 : 1)));
        })
        .catch((error) => {
          console.error(error);
        });
    }
  }

  getFlags = () => {
    firebase.firestore().collection('features').doc('flags').get()
      .then((doc) => {
        const flags = doc.data();

        firebase.firestore().collection('config').doc('fieldsToHide').get()
          .then((doc) => {
            if (doc.exists) {
              flags.fieldsToHide = doc.data().fieldsToHide;
            }

            this.props.changeFlags(flags);
          })
          .catch((error) => {
            console.error('Error getting fieldsToHide: ', error);
            this.props.changeFlags(flags);
          });
      });
  }

  getHexagonsIcons = () => {
    firebase
      .firestore()
      .collection('config')
      .doc('activities')
      .get()
      .then((doc) => {
        if (doc.exists) {
          this.props.saveActivies(doc.data());
        }
      });
  }

  getCitieDefaultImages = (city) => {
    firebase.firestore().collection('Cities').where('name', '==', city)
      .get()
      .then((querySnapshot) => {
        if (!querySnapshot.empty) {
          const defaultCitiesImageUrls = [];
          const defaultEventsImageUrls = [];
          querySnapshot.forEach((doc) => {
            defaultCitiesImageUrls.push(doc.data().defaultImage);
            defaultEventsImageUrls.push(doc.data().defaultEventImage);
          });
          this.props.saveCityDefautImages(city, defaultCitiesImageUrls);
          this.props.saveEventsDefaultImage(city, defaultEventsImageUrls);
        } else {
          console.error(`Cidade não cadastrada no banco de dados: "${city}"`);
        }
      })
      .catch((error) => {
        console.error('Error getting document:', error);
      });
  }

  getFilters = () => {
    firebase.firestore().collection('config').doc('filtros')
      .get()
      .then((doc) => {
        if (doc.exists) {
          this.props.saveFilterMask(doc.data());
        }
      });
  }

  retrieveData = async () => {
    const points = [];
    const { collection, document } = this.props.match.params;
    const { userCity } = this.state;
    const allPoints = collection === undefined && document === undefined;
    let neighborhood;
    let KMLFile;
    let desktopDefaultZoom;
    let mobileDefaultZoom;
    let initialLocation;
    let centerLocation;

    this.getFlags();
    this.getUserLocation();
    this.getFilters();
    this.getHexagonsIcons();

    if (allPoints) {
      this.getCitieDefaultImages(userCity);
      await firebase.firestore().collection('Cities').where('name', '==', userCity).get()
        .then(async (querySnapshot) => {
          querySnapshot.forEach((doc) => {
            neighborhood = doc.data().neighborhood;
            KMLFile = doc.data().KMLFile;
            desktopDefaultZoom = doc.data().desktopDefaultZoom;
            mobileDefaultZoom = doc.data().mobileDefaultZoom;
            initialLocation = {
              latitude: doc.data().location.latitude,
              longitude: doc.data().location.longitude,
            };
            centerLocation = {
              latitude: doc.data().location.latitude,
              longitude: doc.data().location.longitude,
            };

            // edimar - comentado

            if (neighborhood) {
              neighborhood.forEach((nearbyCity) => {
                this.getCitieDefaultImages(nearbyCity);
                firebase.firestore().collection('Attractions').where('City', '==', nearbyCity).where('Active', '==', true)
                  .where('Visible', '==', true)
                  .get()
                  .then((querySnapshot) => querySnapshot.forEach((doc) => {
                    const path = this.getDocumentPath(doc); // não está retornando nada devido ao Try Catch
                    points.push({
                      ...doc.data(),
                      lat: doc.data().location.latitude,
                      long: doc.data().location.longitude,
                      collection: path[0],
                      document: path[1],
                      userCity: false,
                    });
                  }));
              });
            }
          });
        })
        .catch((err) => console.log(err));

      // Edimar - Comentado
      // Getting city markers
      await firebase.firestore().collection('Attractions').where('City', '==', userCity).where('Active', '==', true)
        .where('Visible', '==', true)
        .get()
        .then((querySnapshot) => querySnapshot.forEach((doc) => {
          // alert(doc.data().Tags);
          const path = this.getDocumentPath(doc);
          // const path = '';
          points.push({
            ...doc.data(),
            Tags: doc.data().Tags,
            lat: doc.data().location.latitude,
            long: doc.data().location.longitude,
            collection: path[0],
            document: path[1],
            userCity: true,
          });
        }));

      this.setState({
        points,
        allPoints: allPoints,
        collection,
        document,
        neighborhood,
        KMLFile,
        desktopDefaultZoom,
        mobileDefaultZoom,
        initialLocation,
        centerLocation,
        loading: false,
      });
      store.dispatch({ type: FILTER_PINS, pins: points });
      this.getEvents(points, userCity);
    } else {
      // ponto específico compartilhado.

      // Se for no mobile, ele fica centralizado, se não é deslocado
      const latAux = this.state.width > 500 ? -0.001 : 0;
      const longAux = this.state.width > 500 ? 0.02 : 0;
      if (collection === 'Events') {
        await firebase
          .firestore()
          .collection(collection)
          .doc(document)
          .get()
          .then(async (eventDoc) => {
            if (eventDoc.exists) {
              const event = {};
              event.city = eventDoc.data().City;
              event.startDate = eventDoc.data().StartDate;
              event.endDate = eventDoc.data().EndDate;
              event.title = eventDoc.data().Title;
              event.description = eventDoc.data().Description;
              event.collection = collection;
              event.document = document;
              event.imageFile = eventDoc.data().imagefile || undefined;
              event.longDescription = eventDoc.data()['Long Description'];
              event.activitiesList = eventDoc.data()['Activities List'];

              await firebase
                .firestore()
                .collection('Attractions')
                .doc(eventDoc.data().AttractionId)
                .get()
                .then(async (doc) => {
                  const path = this.getDocumentPath(doc);
                  const attractive = {
                    ...doc.data(),
                    lat: doc.data().location.latitude,
                    long: doc.data().location.longitude,
                    collection: path[0],
                    document: path[1],
                  };

                  initialLocation = {
                    latitude: doc.data().location.latitude + latAux,
                    longitude: doc.data().location.longitude + longAux,
                  };
                  centerLocation = {
                    latitude: doc.data().location.latitude + latAux,
                    longitude: doc.data().location.longitude + longAux,
                  };
                  this.getCitieDefaultImages(doc.data().City);
                  // Getting city info
                  await firebase.firestore().collection('Cities').where('name', '==', doc.data().City).get()
                    .then(async (querySnapshot) => {
                      querySnapshot.forEach((doc2) => {
                        KMLFile = doc2.data().KMLFile;
                      });
                    });

                  points.push(attractive);
                  event.attractive = attractive;
                  await this.props.saveEvents([event]);
                  // Abre o carrosel de eventos, se for mobile
                  if (this.state.width <= 500) {
                    store.dispatch({
                      type: OPEN_EVENTS,
                    });
                  }
                  store.dispatch({
                    type: OPEN_EVENT_DRAWER,
                    attractive: event,
                  });
                  this.getEvents(points, eventDoc.data().City, eventDoc.data().AttractionId);
                });
            }
          })
          .catch((error) => {
            console.log(error);
          });
      } else {
        // Getting Marker
        await firebase.firestore().collection(collection).doc(document).get()
          .then(async (doc) => {
            const path = this.getDocumentPath(doc);
            points.push({
              ...doc.data(),
              lat: doc.data().location.latitude,
              long: doc.data().location.longitude,
              collection: path[0],
              document: path[1],
              userCity: true,
            });
            initialLocation = {
              latitude: doc.data().location.latitude + latAux,
              longitude: doc.data().location.longitude + longAux,
            };
            centerLocation = {
              latitude: doc.data().location.latitude + latAux,
              longitude: doc.data().location.longitude + longAux,
            };
            this.getCitieDefaultImages(doc.data().City);

            this.getEvents(points, doc.data().City, document);
            // Getting city info
            await firebase.firestore().collection('Cities').where('name', '==', doc.data().City).get()
              .then(async (querySnapshot) => {
                querySnapshot.forEach((doc2) => {
                  KMLFile = doc2.data().KMLFile;
                });
              });

            store.dispatch({
              type: UPDATE_PREVIEW_DATA,
              attractive: points[0],
            });
            store.dispatch({
              type: OPEN_DRAWER,
              attractive: points[0],
            });
          });
      }

      this.setState({
        KMLFile,
        allPoints: allPoints,
        loading: false,
        desktopDefaultZoom: 13.5,
        mobileDefaultZoom: 13.5,
        points,
        initialLocation,
        centerLocation,
      });
      store.dispatch({ type: FILTER_PINS, pins: points });
    }
  }

  // Função que altera o estado do menu do usuário
  toggleUserMenu = () => {
    this.setState({
      userMenuStatus: !this.state.userMenuStatus,
    });
  }

  render() {
    const { loading } = this.state;
    const { isDrawerOpen, isFilterOpen, filtered, isEventsPreviewOpen, isEventDrawerOpen, lastDrawerOpened, anonimousData } = this.props;
    const paddingRightComponent = (isDrawerOpen || isEventDrawerOpen) && this.state.width > 500 ? '27rem' : '0px';
    const paddingLeftComponent = isFilterOpen && this.state.width > 500 ? '22rem' : '0px';
    const paddingBottomComponent = (isDrawerOpen || isEventDrawerOpen) && this.state.width <= 500 ? window.innerHeight : '0px';
    if (loading || !store)
      return <p>Loading...</p>;
    if (anonimousData && anonimousData.anonimous && !anonimousData.cityExists) {
      return <ErrorPage message={`Cidade "${anonimousData.city}" não cadastrada`}/>;
    }
    let AttractiveDrawer;
    let EventDrawer;
    // Drawer
    if (this.state.width <= 500) {
      // Mobile
      AttractiveDrawer = withStyles({
        paper: {
          width: this.state.width,
          right: 0,
          left: 0,
          height: window.innerHeight,
          border: 'none',
          zIndex: lastDrawerOpened === 'attractive' ? 99 : 98,
        },
      })(Drawer);
      EventDrawer = withStyles({
        paper: {
          width: this.state.width,
          right: 0,
          left: 0,
          height: window.innerHeight,
          border: 'none',
          zIndex: lastDrawerOpened !== 'attractive' ? 99 : 98,
        },
      })(Drawer);
    } else {
      // PC
      AttractiveDrawer = withStyles({
        paper: {
          width: '27rem',
          border: 'none',
          zIndex: lastDrawerOpened === 'attractive' ? 99 : 98,
        },
      })(Drawer);
      EventDrawer = withStyles({
        paper: {
          width: '27rem',
          border: 'none',
          zIndex: lastDrawerOpened !== 'attractive' ? 99 : 98,
        },
      })(Drawer);
    }

    return (
      <div style={{ paddingRight: paddingRightComponent, paddingLeft: paddingLeftComponent, paddingBottom: paddingBottomComponent }} className={css(styles.container)}>
        <div className={css(styles.header)}>
          <Header isMobile={this.state.width <= 500} toggleUserMenu={this.toggleUserMenu} />
        </div>
        <div id='map-contanier' className={css(styles.map)}>
          {
            // Uncaught TypeError: Cannot read property 'getBoundingClientRect' of null
            // at getTranslateValue (Slide.js:17)
            // at setTranslateValue (Slide.js:53)

            // REMOVI da tag Slide o parametro=>  in={this.state.userMenuStatus}
            this.state.userMenuStatus &&
            <Slide element={document.getElementById('user-menu')} direction='down' timeout={200}>
              <UserMenu id='user-menu' />
            </Slide>
          }
          { isEventsPreviewOpen && <EventsCarousel points={this.state.points}/> }
          <MapComponent
            defaultZoom = { this.state.width <= 500 ? this.state.mobileDefaultZoom : this.state.desktopDefaultZoom }
            initialLocation = {this.state.initialLocation}
            centerLocation = {this.state.centerLocation}
            KMLFile = {this.state.KMLFile}
            filtered = {filtered}
            singleAttractive = { this.state.allPoints }
            isMobile = { this.state.width <= 500 }
          />
        </div>
        {
          isDrawerOpen &&
            <div style={{ position: 'relative', display: 'flex', alignItems: 'flex-end' }}>
              <AttractiveDrawer
                open={isDrawerOpen}
                anchor={ this.state.width <= 500 ? 'bottom' : 'right'}
                variant='persistent'
                BackdropProps={{ style: { position: 'relative' } }}
                ModalProps={{
                  container: document.getElementById('drawer-container'),
                  style: { position: 'relative' },
                }}
              >
                <Attractives isMobile={this.state.width <= 500} />
              </AttractiveDrawer>
            </div>
        }

        {
          isEventDrawerOpen &&
          <div style={{ position: 'relative', display: 'flex', alignItems: 'flex-end' }}>
              <EventDrawer
                open={isEventDrawerOpen}
                anchor={ this.state.width <= 500 ? 'bottom' : 'right'}
                variant='persistent'
                BackdropProps={{ style: { position: 'relative' } }}
                ModalProps={{
                  container: document.getElementById('drawer-container'),
                  style: { position: 'relative' },
                }}
              >
                <Events isMobile={this.state.width <= 500} />
              </EventDrawer>
            </div>
        }

        <div id='' style={{ position: 'relative', display: isFilterOpen ? 'flex' : 'none', alignItems: 'flex-end' }}>
          <Drawer
            open={isFilterOpen}
            anchor={ this.state.width <= 500 ? 'bottom' : 'left'}
            variant='persistent'
            BackdropProps={{ style: { position: 'relative', justifyContent: 'center' } }}
            ModalProps={{
              container: document.getElementById('drawer-container'),
              style: { position: 'relative', justifyContent: 'center' },
            }}
            transitionDuration={200}
          >
            <Filters width={this.state.width} isMobile={this.state.width <= 500} points={this.state.points} location={this.props.location} />
          </Drawer>
        </div>

        <SearchResult isMobile={this.state.width <= 500}/>
      </div>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    width: '100%',
    transform: 'scale(1)', /* you need a scale here to allow it to transition in both directions */
    transition: '0.2s all ease',
    backgroundColor: '#b03cdb',
  },
  map: {
    flex: 1,
    position: 'relative',
  },
});

function mapStateToProps(state) {
  return {
    isEventDrawerOpen: state.isEventDrawerOpen,
    isDrawerOpen: state.isDrawerOpen,
    drawerInfo: state.drawerInfo,
    isFilterOpen: state.isFilterOpen,
    filters: state.filterInfo.filters,
    filtered: state.filterInfo.filteredPoints,
    filterMask: state.filterInfo.filterMask,
    isEventsPreviewOpen: state.isEventsPreviewOpen,
    lastDrawerOpened: state.lastDrawerOpened,
    eventsDefaultImage: state.eventsDefaultImage,
  };
}

const mapDispatchToProps = (dispatch) => ({
  saveFilterMask: (mask) => { dispatch(saveFilterMask(mask)); },
  changeFlags: (flags) => { dispatch(changeFlags(flags)); },
  updateUserLocation: (lat, long) => { dispatch(updateUserLocation(lat, long)); },
  saveEvents: (events) => { dispatch(saveEvents(events)); },
  saveEventsDefaultImage: (city, urls) => { dispatch(saveEventsDefaultImage(city, urls)); },
  saveCityDefautImages: (city, urls) => { dispatch(saveCityDefautImages(city, urls)); },
  saveActivies: (activities) => { dispatch(saveActivies(activities)); },
});
export default connect(mapStateToProps, mapDispatchToProps)(Map);
