import React from 'react';

import '../assets/css/fieldReview.css';

export default function FieldReview({ field, value, styleTxt }) {
  const filteredTitles = {
    'Atendimento Idioma Estrangeiro': 'Idiomas',
    mobileDefaultZoom: 'Zoom Mobile',
    desktopDefaultZoom: 'Zoom Desktop',
    defaultEventImage: 'Imagem Eventos',
    defaultImage: 'Imagem Padrão',
    'Número de Funcionários Temporários': 'Funcionários Temp',
    'Número de Funcionários Fixos': 'Funcionários Fixos',
    classificadoAtrativo: 'Classificado',
    justifiqueAtrativo: 'Justificação',
    destaqueLocal: 'Destaques',
    tombadoCondephaat: 'Tombado Condephaat',
    centroHistoricoTombado: 'Centro Histórico',
    tombadoIPHAN: 'IPHAN',
    tombamentoMunicipal: 'Tombado Municipal',
    Tripadvisor_Amenities: 'TripAd Amenities',
    Tripadvisor_AvaliacoesPorNota: 'TripAd Avaliações Nota',
    Tripadvisor_Award: 'TripAd Award',
    Tripadvisor_FaixaPreco: 'TripAd Faixa Preço',
    Tripadvisor_Nota: 'TripAd Nota',
    Tripadvisor_NotaPorTipo: 'TripAd Nota por tipo',
    Tripadvisor_QuantidadeAvaliacoes: 'TripAd Avaliações',
    Tripadvisor_Ranking: 'TripAd Ranking',
    Tripadvisor_TipoPublico: 'TripAd Tipo de Público',
    Tripadvisor_location_id: 'TripAd Location ID',
    Tripadvisor_UrlFotos: 'TripAd Url Fotos',
    'Decoração diferenciada ou temática': 'Decoração diferenciada',
    guiasEagencias: 'Guias e Agências',
    Cities: 'Cidades',
    pontosApoio: 'Pontos de apoio',
    pontosRota: 'Pontos da rota',
    pontosRotaDuração: 'Duração dos pontos da rota',
    'Short Descr': 'Descrição Curta',
    'Long Descr': 'Descrição Longa',
    'descrHistorico': 'História da Rota',
    'descrPercurso': 'Descrição do Percurso',
    'descrConteudo': 'Descrição do Conteúdo da rota',
    'descrDestaques': 'Destaques da rota',


  };

  return (
    <div className={`FieldReview ${field === 'new' ? 'new_field_review' : ''}`} style={styleTxt}>
      <span className='title_field_review'>{field !== 'new' ? (filteredTitles[field] || field) + ':' : ''}</span>
      <textarea className='input_field_review' value={field !== 'new' ? value : ''} disabled/>
    </div>
  )
}