import React, { useState, useEffect } from "react";

// Assets imports
import ArrowFoward from '../../assets/imgs/left-arrow-white.png';
import ArrowBack from '../../assets/imgs/right-arrow-white.png';
import Loading from '../Loading';

// Firebase Import
import firebase from '../../config/Firebase';

// CSS import
import '../../assets/css/InputComponents/selectMultipleInput.css';

export default function SelectMultipleInput({ type, title, item, setItem, role, explanation }) {
  const [valueSelected, setValuesSelected] = useState();
  const [loading, setLoading] = useState(false);
  const [values, setValues] = useState();
  const [titleDocs, setTitleDocs] = useState();
  const [cities, setCities] = useState();
  const [citySelected, setCitySelected] = useState(item.city);

  async function handleCities(isRegion, setField) {
    let docs;

    if (isRegion)
      docs = await firebase.firestore().collection('Cities')
        .where('regiao', '==', true)
        .orderBy('name')
        .get();
    else
      docs = await firebase.firestore().collection('Cities').orderBy('name').get();

    setField(docs);
    setTitleDocs('name');
  }

  async function handleAttrations() {
    const docs = await firebase.firestore().collection('Attractions')
      .where('City', '==', citySelected)  
      .orderBy('Title')
      .get();
    setValues(docs);
    setTitleDocs('Title');
  }

  function handleAddNeighborhood(type) {
    if (!valueSelected) return;
    
    if (!item[role]) {
      setItem({ ...item, [role]: [valueSelected] });
      setValuesSelected();
    }

    if (type === 'add' && !item[role].includes(valueSelected)) {
      setItem({ ...item, [role]: [...item[role], valueSelected] });
      setValuesSelected();
    } else if (type === 'remove' && item[role].includes(valueSelected)) {
      const val = item[role].filter((value) => value !== valueSelected);
      setItem({ ...item, [role]: val });
      setValuesSelected();
    }
  }

  useEffect(() => {
    setLoading(true);
    if (type === 'cities')
      handleCities(false, setValues);
    else if (type === 'region')
      handleCities(true, setValues);
    else {
      handleCities(false, setCities);
      handleAttrations();
    }

    setLoading(false);
  }, [role]);

  useEffect(() => {
    if (type === 'attraction')
      handleAttrations();
  }, [citySelected]);

  if (!values || !item[role] || loading || (type === 'attraction' && !cities))
    return <Loading />;

  return (
    <div className='div_select_neighborhood_region div_multiple_select'>
      {
        type === 'attraction' ? (
          <div className='div_align_multiple_select_input'>
            <div className='div_label_input_textarea'>
              <label htmlFor={'CityInventoryForm'} className="label_text_input">
                <strong>Cidade do Inventário</strong>
              </label>
            </div>
						<select className='select_inventory' id='CityInventoryForm' onChange={(e) => setCitySelected(e.target.value)}>
              {
                cities.docs.map((city, index) => {
                  return <option key={'SelectMultipleCityAdmin' + index} selected={city.data().name === item.city}>{city.data().name}</option>;
                })
              }
						</select>
					</div>
        ) : null
      }
      <div className='div_algin_multiple_select_form'>
        <div className='div_label_input_textarea'>
          <label htmlFor={role + 'Form'} className="label_text_input">
            <strong>{title}</strong>
          </label>
        </div>
        <div className='div_align_explanation_input'>
          <div className='div_align_multiple_select'>
            <div className="div_selects_neigh_align">
              <select className='select_inventory' id={role + 'Form'} onChange={(e) => setValuesSelected(e.target.value) } multiple>
                {
                  values.docs.length > 0 ? values.docs.map((docInfo, index) => {
                    if (!item[role].includes(docInfo.data()[titleDocs])) {
                      return <option key={'ItemsAdmin' + index} value={type === 'attraction' ? docInfo.id : docInfo.data()[titleDocs]}>{docInfo.data()[titleDocs]}</option>;
                    }
                  }) : <option disabled>Sem Inventários nessa cidade</option>
                }
              </select>
              <div className='div_btns_set_neighborhood'>
                <button type='button' className='btn_remove_city btn_handle_neighborhood'>
                  <img src={ArrowFoward} alt='logo de uma seta para a direita' className='img_arrow_region' onClick={() => handleAddNeighborhood('add')} />
                </button>
                <button type='button' className='btn_add_city btn_handle_neighborhood'>
                  <img src={ArrowBack} alt='logo de uma seta para a esquerda' className='img_arrow_region' onClick={() => handleAddNeighborhood('remove')} />
                </button>
              </div>
              <select className='select_inventory' onChange={(e) => setValuesSelected(e.target.value)} multiple>
              {
                item[role].sort().map((val, index) => {
                  return <option key={'ItemsSelectedAdmin' + index}>{val}</option>;
                })
              }
              </select>
            </div>
          </div>
          <div className='div_explanation_input'>
            <p className='subtext_explanation_input'>{explanation}</p>
          </div>
        </div>
      </div>
    </div>
  );
}