import React from 'react';

export default class ErrorPage extends React.Component {
  render() {
    const { message } = this.props;

    return (
      <div>
        {message}
      </div>
    );
  }
}