import React, { useState, useEffect } from 'react';
import firebase from '../../config/Firebase';

// CSS import
import '../../assets/css/atractionList.css';

export default function ProgramList({ doc, handleEdit, handleDelete }) {
  const [titleGuide, setTitleGuide] = useState('Não Há');

  async function handleTitleGuideline() {
    if (doc.data().idDiretrizes) {
      const docGuide = await firebase.firestore().collection('PlanoDiretor_Diretrizes').doc(doc.data().idDiretrizes).get();

      setTitleGuide(docGuide.data().titulo);
    }
  }

  useEffect(() => {
    handleTitleGuideline();
  }, []);
  
  return (
    <tr className="row_table_inventory body_table">
      <td className="title_table_inventory">{doc.data().titulo}</td>
      <td className="title_table_inventory">{doc.data().cidade}</td>
      <td className="title_table_inventory">{titleGuide}</td>
      <td className="title_table_inventory">{doc.data().active ? 'Ativo' : 'Desativado'}</td>
      <td className="column_edit_table_inventory">
        <div className='div_mod_inventory'>
          <button className='btn_mod_adm' onClick={() => handleEdit(doc)}>Editar</button>
          <button className='btn_mod_adm' onClick={() => handleDelete(doc)}>Excluir</button>
        </div>
      </td>
    </tr>
  );
}