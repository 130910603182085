import React from 'react';
import { StyleSheet, css } from 'aphrodite';
import Rating from '../component/Rating';
import Favorite from '../component/Favorite';
import ShareModal from '../component/ShareModal';
import FeedbackModal from '../component/FeedbackModal';
import DistanceToAttractive from '../component/DistanceToAttractive';
import CarouselStrap from '../component/CarouselStrap';
import DivHexagons from '../component/DivHexagons';
import { Button, Row, Col } from 'reactstrap';
import { PageView, Event } from '../config/TrackingGA';
import { connect } from 'react-redux';
import { convertDate } from '../utils/parse';
import { openEventDrawer } from '../redux/actions';

class Attractives extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isTruncated: false,
      title: '',
      titleLoaded: false,
      longDescription: '',
      shortDescription: '',
      shortDescriptionLoaded: false,
      descriptionShowAll: false,
      feedbackAlert: false,
      feedbackText: '',
      latitude: '',
      longitude: '',
      modal: false,
      readMoreTitle: false,
      readMoreLabel: 'Ler Mais',
      showFeedbackModal: false,
      shareModal: false,
      distance: '',
      distanceText: '',
      attractiveLocation: null,
      geolocationFlag: 0,
      fieldsToShow: [],
      fieldsToShowLoaded: false,
      valuesToShow: [],
      valuesToShowLoaded: false,
      fieldsToHide: [],
      id: null,
    };
    this.toggleFeedbackModal = this.toggleFeedbackModal.bind(this);
  }

  componentDidMount() {
    PageView();
  }

  async componentWillReceiveProps(nextProps) {
    if (this.props.attractive !== nextProps.attractive) {
      PageView();
    }
  }

  shouldComponentUpdate(nextProps) {
    const { attractive } = this.props;
    const { attractive: reduxAttractive } = nextProps;
    if (attractive === reduxAttractive) return true;

    return false;
  }

  handleShare = () => {
    // Verifica se o browser suporta compartilhamento nativo
    const { attractive } = this.props;
    if (navigator.share) {
      Event('User', 'Share', String(attractive.document));
      navigator.share({
        url: '/map/' + attractive.collection + '/' + attractive.document,
        title: attractive.Title,
        text: attractive.Title + ' - Esse destino é a sua cara!',
      })
        .catch(console.error);
    } else { // Se não, ativa o modal de compartilhamento feito para o Destinos
      Event('User', 'Share', String(attractive.document));
      this.setState({
        shareModal: true,
      });
    }
  }

  closeShareModal = () => {
    this.setState({
      shareModal: false,
    });
  }

  toggleFeedbackModal() {
    this.setState({
      showFeedbackModal: !this.state.showFeedbackModal,
    });
  }

  handleModifyValue(value, index) {
    if (!value) return <p style={{ marginBottom: 0 }} className={css(styles.otherFieldsInfo)}></p>;

    if (Array.isArray(value)) {
      if (typeof value[0] === 'object') return this.handleTextArrayObject(value, index);
      return <p style={{ marginBottom: 0 }} className={css(styles.otherFieldsInfo)}>{value}</p>;
    }

    if (typeof value === 'object') return this.handleTextObject(value);

    return <p style={{ marginBottom: 0 }} className={css(styles.otherFieldsInfo)}>{value}</p>;
  }

  handleTextObject(obj) {
    const keys = Object.keys(obj);

    let text = '';

    keys.map((key) => {
      text = text + obj[key] + ', ';
    });

    return <p style={{ marginBottom: 0 }} className={css(styles.otherFieldsInfo)}>{text}</p>;
  }

  handleTextArrayObject(arrayObj, index) {
    let text = '';

    arrayObj.forEach((obj) => {
      const keys = Object.keys(obj);
      const title = {
        author_name: 'Nome',
        text: 'Avaliação',
      };

      keys.map((key) => {
        if (key === 'author_name' || key === 'text')
          text = text + title[key] + ': ' + obj[key] + '              ';
      });

      text += '                  ';
    });

    return <p id={'text_array_obj' + index} style={{ marginBottom: 0 }} className={css(styles.otherFieldsInfo)}>{text}</p>;
  }

  handleGetOtherElement(value, index) {
    return this.handleModifyValue(value, index);
  }

  renderLongDescription = () => {
    const { fieldsToHide, attractive, localEvents } = this.props;
    const fieldsToShow = Object.keys(attractive).filter((field) => fieldsToHide.indexOf(field) === -1 && field !== 'userCity' && field !== 'document' && field !== 'collection' && field !== 'lat' && field !== 'long' && field !== 'imagefile' && field !== 'nomeRegiao' && field !== 'PossuiTripadvisor' && field !== 'types' && field !== 'PlaceId' && field !== 'Avaliacoes' && field !== 'Tags');

    const otherInfos = fieldsToShow.map((field, i) => {
      return (

        <div id={'otherFields' + i} style={{ borderBottom: '1px solid rgba(255, 255, 255, 0.5)', marginBottom: 5, paddingBottom: 5 }} key={i} >
          <strong className={css(styles.otherFieldstext)}>
          {(() => {
            switch (field) {
              case 'City': return 'Cidade';
              case 'Type': return 'Tipo';
              case 'Subtype': return 'Subtipo';
              default: return field;
            }
          })()}

          </strong>
          {
            field !== 'Website' ?
              (() => {
                switch (attractive[field]) {
                  case 'Attraction': return (<p style={{ marginBottom: 0 }} className={css(styles.otherFieldsInfo)}>Atrativo</p>);
                  case 'Food': return (<p style={{ marginBottom: 0 }} className={css(styles.otherFieldsInfo)}>Comida</p>);
                  case 'Commerce': return (<p style={{ marginBottom: 0 }} className={css(styles.otherFieldsInfo)}>Comércio</p>);
                  case 'Lodgin': return (<p style={{ marginBottom: 0 }} className={css(styles.otherFieldsInfo)}>Hospedagem</p>);
                  case 'Services': return (<p style={{ marginBottom: 0 }} className={css(styles.otherFieldsInfo)}>Serviços</p>);
                  case 'Green Space': return (<p style={{ marginBottom: 0 }} className={css(styles.otherFieldsInfo)}>Espaço Verde</p>);
                  case 'Craftwork': return (<p style={{ marginBottom: 0 }} className={css(styles.otherFieldsInfo)}>Artesanato</p>);
                  case 'Supermarket': return (<p style={{ marginBottom: 0 }} className={css(styles.otherFieldsInfo)}>Supermercado</p>);
                  case 'Drugstore': return (<p style={{ marginBottom: 0 }} className={css(styles.otherFieldsInfo)}>Drogaria</p>);
                  case 'Flower Shop': return (<p style={{ marginBottom: 0 }} className={css(styles.otherFieldsInfo)}>Floricultura</p>);
                  case 'E-commerce': return (<p style={{ marginBottom: 0 }} className={css(styles.otherFieldsInfo)}>Comércio Eletrônico</p>);
                  case 'Infrastructure': return (<p style={{ marginBottom: 0 }} className={css(styles.otherFieldsInfo)}>Infraestrutura</p>);
                  case 'Bank': return (<p style={{ marginBottom: 0 }} className={css(styles.otherFieldsInfo)}>Banco</p>);
                  case 'Fuel Station': return (<p style={{ marginBottom: 0 }} className={css(styles.otherFieldsInfo)}>Posto de Combustível</p>);
                  case 'Education': return (<p style={{ marginBottom: 0 }} className={css(styles.otherFieldsInfo)}>Educação</p>);
                  case 'Health': return (<p style={{ marginBottom: 0 }} className={css(styles.otherFieldsInfo)}>Saúde</p>);
                  case 'Music': return (<p style={{ marginBottom: 0 }} className={css(styles.otherFieldsInfo)}>Música</p>);
                  case 'Hair Stylist': return (<p style={{ marginBottom: 0 }} className={css(styles.otherFieldsInfo)}>Cabelereiro</p>);
                  case 'Pet care': return (<p style={{ marginBottom: 0 }} className={css(styles.otherFieldsInfo)}>Petshop</p>);
                  default: return this.handleGetOtherElement(attractive[field], i);
                }
              })()
              :
              <p style={{ marginBottom: 0 }}>
                <a
                  className={css(styles.otheFieldsLink)}
                  target="_blank"
                  rel="noopener noreferrer"
                  // Edimar - Tratar link URL dos Atrativos
//                  href={attractive[field] && 'http://www.' + attractive[field].substr(attractive[field].indexOf('www.') + 4)}
                  // href={attractive[field] &&   attractive[field]}
                  href={attractive[field]}
                >
                  {attractive[field]}
                </a>
              </p>
          }
        </div>
      );
    });

    return (
      <div className={css(styles.longDescriptionDiv)}>
        <div /* onClick={ this.handleCollapsableClick } */ id='collapsableDiv' className={css(styles.longDescription)}>
          <span>{attractive['Long Description']}</span>
          <p></p>
          {otherInfos}
        </div>
        <div className={css(styles.feedbackLabel)}>
            <p onClick={() => this.setState({ showFeedbackModal: true })} className={css(styles.feedbackForm)}> <i>Comunicar correção</i></p>
          </div>
        {/* Eventos do local */}
        <div style={{ marginTop: '20px' }}>
          {
            localEvents.map((event, i) =>
              <div
                style={{ cursor: 'pointer', marginBottom: i === localEvents.length - 1 ? '0px' : '10px' }}
                key={i}
                onClick={() => this.props.openEventDrawer(event)}
              >
                <p className={css(styles.eventTitle)}>{event.title}</p>
                <p className={css(styles.eventInfo)}>{event.description}</p>
                <p className={css(styles.eventInfo)}>{`Data: ${convertDate(event.startDate)}`}</p>
              </div>)
          }

        </div>

      </div>
    );
  }

  handleDestination = () => {
    const { attractive } = this.props;
    const destination = 'https://www.google.com/maps/dir/?api=1&destination=' + attractive.lat + ',' + attractive.long;
    window.open(destination, '_blank');
    Event('User', 'Route', String(attractive.document));
  }

  render() {
    const { showFeedbackModal } = this.state;
    const { isMobile, attractive, showFavoreites, showRating } = this.props;

    return (
      <div id='pageBackground' className={css(styles.pageBackground)}>
        {/* Tudo o que vai ser scrollado! */}
        <div id='scrollContentDiv' className={css(styles.scrollContent)} style={{ height: isMobile ? '85%' : '88%' }}>
          {/* Div que controla o tamanho de tudo até o long description */}
          <div id='beforeDiv' className={css(styles.beforeLongDescription)}>
            {/* Carrosel */}
            <div className={css(styles.carouselBackground)}>
              <div className={css(styles.carousel1)}>
                <div className={css(styles.carousel2)}>
                  <CarouselStrap
                    city={attractive.City}
                    isMobile={isMobile}
                    imageFile={attractive.imagefile}
                    document={attractive.document}
                  />
                </div>
              </div>
            </div>
              <div className={css(styles.paddingDiv)}>
                {/* Rating e Favorite */}
                <div className={css(styles.ratingAndFavorite)}>
                  {showRating && <Rating />}
                  {showFavoreites && <Favorite />}
                </div>

                {/* Titulo do atrativo */}
                <div className={css(styles.attractiveTitle)}>
                  <span> {attractive.Title} </span>
                </div>

                {/* Pink Line */}
                <div className={css(styles.pinkLine)} />

                {/* Short Description */}
                <div className={css(styles.attractiveShortDescription)} lg='12'>
                  {attractive['Short Description']}
                </div>
              </div>
            </div>
            <div className={css(styles.paddingDiv)}>
            {/* Descricoes do atrativo. Pode colapsar se o texto for muito grande */}
              <div className={css(styles.attractiveLongDescription)}>
                {this.renderLongDescription()}
              </div>
            </div>

          {/* Hexagonos e distância do atrativo */}
          <Row style={{ margin: 0, padding: 35, paddingLeft: 0, paddingRight: 0 }} id='hexagonAndDistanceDiv'>
            <Col lg='12' xs='12' sm='12'>
              <DivHexagons
                activities={attractive['Activities List']}
                isMobile={this.props.isMobile}
              />
            </Col>
          </Row>
        </div>

        {/* Botões fixos no fim da página */}
        <div className={css(styles.buttonsDiv)} >

          <div style={{ width: 'max-content', paddingLeft: '0.7rem', paddingRight: '0.7rem', marginTop: 0 }}>
            <DistanceToAttractive
              attractiveLat={attractive.lat}
              attractiveLng={attractive.long}
            />
          </div>

          <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', paddingTop: '8px', paddingLeft: '0.7rem', paddingRight: '0.7rem' }}>
            <Button className={css(styles.shareButton)} onClick={this.handleShare}>Compartilhar</Button>
            <Button className={css(styles.routeButton)} onClick={this.handleDestination}>Rotas</Button>
          </div>

        </div>

        <ShareModal
          title={attractive.Title}
          open={this.state.shareModal}
          closeModal={this.closeShareModal}
          document={attractive.document}
          collection={attractive.collection}
        />

        <FeedbackModal
          document={attractive.document}
          open={showFeedbackModal}
          closeModal={() => this.setState({ showFeedbackModal: false })}
        />
      </div>
    );
  }
}

const styles = StyleSheet.create({
  attractiveShortDescription: {
    color: 'white',
    marginTop: 0,
    textAlign: 'justify',
    fontFamily: 'ubuntu',
    fontSize: 18,
  },
  attractiveLongDescription: {
    color: 'white',
    display: 'flex',
    marginTop: 20,
    fontFamily: 'ubuntu',
    fontWeight: 'regular',
  },
  attractiveTitle: {
    marginTop: '-0.5rem',
    fontSize: 25,
    textAlign: 'left',
    marginBottom: 2,
    color: 'white',
    padding: '0',
    fontFamily: 'ubuntu',
    fontWeight: 'bold',
  },
  beforeLongDescription: {
    height: 'auto',
  },
  buttonsDiv: {
    position: 'absolute',
    bottom: '0',
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    width: '100%',
    backgroundColor: 'rgb(146, 85, 142)',
    justifyContent: 'space-evenly',
    textAlign: 'center',
    padding: '1rem',
    marginTop: 0,
    // height: '10%',
  },
  carousel1: {
    paddingTop: '1rem',
    height: '150px',
    backgroundColor: 'rgb(146, 85, 142)',
    borderRadius: '0px 0px 20px 20px',
  },
  carousel2: {
    height: '150px',
  },
  carouselBackground: {
    alignItems: 'center',
    backgroundColor: '#c844dd',
    justifyContent: 'center',
    marginBottom: 18,
    flex: 1,
  },
  eventInfo: {
    marginBottom: '0px',
  },
  eventTitle: {
    fontSize: '1.1rem',
    marginBottom: '0px',
    fontWeight: 'bold',
  },
  feedbackLabel: {

    cursor: 'pointer',
    color: '#F3A861',
    textDecoration: 'underline',
  },
  hideShowOption: {
    ': hover': {
      textDecoration: 'underline #FFB94F',
    },
    color: '#FFB94F',
    cursor: 'pointer',
    fontStyle: 'italic',
    zIndex: '2',
  },
  longDescription: {
    marginBottom: '0.2rem',
    textAlign: 'justify',
    cursor: 'pointer',
    paddingBottom: '5px',
  },
  longDescriptionDiv: {
    fontSize: 15,
    paddingRight: 0,
    textAlign: 'justify',
    height: '100%',
    wordBreak: 'break-word',
  },
  otherFieldsInfo: {
    marginLeft: '1em',
    wordBreak: 'break-word',
  },
  otheFieldsLink: {
    color: 'white',
    marginLeft: '1em',
    fontStyle: 'italic',
    fontWeight: 'bold',
    wordBreak: 'break-all',
  },
  otherFieldstext: {

    wordBreak: 'break-word',
  },
  pageBackground: {
    flex: 1,
    height: '100vh',
    maxHeight: '100vh',
    flexDirection: 'column',
    backgroundColor: 'rgb(146, 85, 142)',
  },
  pinkLine: {
    borderBottomColor: '#f9407d',
    borderBottomStyle: 'solid',
    borderBottomWidth: '3px',
    width: '3rem',
    marginBottom: '1vh',
    marginLeft: '-0.4rem',
  },
  paddingDiv: {
    paddingLeft: '1.7rem',
    paddingRight: '1.7rem',
  },
  ratingAndFavorite: {
    display: 'flex',
    alignItems: 'center',
    marginLeft: 55,
    marginRight: 0,
    marginTop: '4rem',
    marginBottom: '.5rem',
    height: '1.8rem',
    width: 'auto',
  },
  routeButton: {
    borderRadius: 50,
    width: '8rem',
    fontSize: '1.4rem',
    letterSpacing: '.01em',
    height: 50,
    backgroundColor: '#f9407d',
    color: 'white',
    justifyContent: 'center',
    border: 'none',
  },
  scrollContent: {
    backgroundColor: '#c844dd',
    flex: 1,
    overflowY: 'auto',
  },
  shareButton: {
    backgroundColor: 'white',
    borderRadius: 50,
    color: '#f9407d',
    fontSize: '1.4rem',
    letterSpacing: '.01em',

    height: 50,
    width: '12rem',
    border: 'none',
  },
});

function mapStateToProps(state) {
  return {
    attractive: state.drawerInfo,
    fieldsToHide: state.flags.fieldsToHide,
    showFavoreites: state.flags.showFavoreites,
    showRating: state.flags.showRating,
    localEvents: state.events.filter((event) => event.attractive === state.drawerInfo),
  };
}
const mapDispatchToProps = (dispatch) => ({
  openEventDrawer: (event) => { dispatch(openEventDrawer(event)); },
});
export default connect(mapStateToProps, mapDispatchToProps)(Attractives);
