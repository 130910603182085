export const fieldsEditUsers = [
  {    
    title: 'Nome*',
    field: 'name',
    type: 'text',
    boolType: false,
    component: 'text',
    placeholder: '',
  },
  {    
    title: 'Email',
    field: 'email',
    type: 'text',
    boolType: false,
    component: 'text',
    placeholder: '',
  },
  {    
    title: 'Telefone',
    field: 'phoneNumber',
    type: 'text',
    boolType: false,
    component: 'text',
    placeholder: '(xx) xxxx-xxxx',
  },
  {    
    title: 'Cidade*',
    field: 'City',
    boolType: false,
    component: 'select',
    disable: true,
  },
  {    
    title: 'Cargo*',
    field: 'role',
    boolType: false,
    component: 'select',
  },
  {    
    title: 'Selecione os Inventários Administrados',
    component: 'selectMultipleInput',
    explanation: 'Selecione as Cidade que fazem parte dessa região, que estão dentro do território da região',
    role: 'tradeAdmin',
    typeComp: 'attraction',
  },
  {    
    title: 'Selecione as Cidades Administradas',
    component: 'selectMultipleInput',
    explanation: 'Selecione as Cidade que fazem parte dessa região, que estão dentro do território da região',
    role: 'cityAdmin',
    typeComp: 'cities',
  },
  {    
    title: 'Selecione as Regiões Administradas',
    component: 'selectMultipleInput',
    explanation: 'Selecione as Cidade que fazem parte dessa região, que estão dentro do território da região',
    role: 'regionAdmin',
    typeComp: 'region',
  },
];