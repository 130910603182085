import React from 'react';
import { StyleSheet, css } from 'aphrodite';
import { store } from '../redux/store';

class DistanceToAttractive extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      distance: '',
      distanceText: '',
      distanceError: false,
    };
    this.distanceCalculator = this.distanceCalculator.bind(this);
  }

  _isMounted = false;

  distanceCalculator(lat, long) { // Haversine formula
    if (!lat && !long) {
      this.setState({
        distance: 'Indefinida',
        distanceError: true,
      });
    } else if (this._isMounted) {
      const R = 6378137; // Earth’s mean radius in meter
      const dLat = (this.props.attractiveLat - lat) * Math.PI / 180;
      const dLong = (this.props.attractiveLng - long) * Math.PI / 180;
      const a = Math.sin(dLat / 2) * Math.sin(dLat / 2) +
        Math.cos((lat) * Math.PI / 180) * Math.cos((this.props.attractiveLng) * Math.PI / 180) *
        Math.sin(dLong / 2) * Math.sin(dLong / 2);
      const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
      const d = Math.round(R * c);
      if (d <= 1000) {
        this.setState({
          distance: d + ' m',
          distanceError: false,
        });
      } else {
        this.setState({
          distance: Math.round(d / 1000) + ' km',
          distanceError: false,
        });
      }
    } else {
      this.setState({
        distanceText: '',
      });
    } 
  }

  componentDidMount() {
    this._isMounted = true;
    // Calcula distância do usuário
    const userLocation = store.getState().user.location;
    if (userLocation.lat !== null && userLocation.long !== null) {
      this.distanceCalculator(userLocation.lat, userLocation.long);
    } else {
      console.log('Erro ao calcular distância: usuário não tem dados de geolocalização');
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  render() {
    const { isMobile } = this.props;
    return (
      <div>
        { isMobile || this.props.isPreviewOpen ?
          <div>
            <p className={css(styles.distance2)}>Distância</p>
            <p className={css(styles.distanceText)}>do destino</p>
            <div className={css(styles.distanceTextLine)}></div>
            <b className={css(styles.distance3)}>{this.state.distance}</b>
          </div>
          :
          <div className={css(styles.distanceText)}>
            <b>Distância</b><span> do destino </span>
            <b>{this.state.distance}</b>
            <div className={css(styles.distanceTextLine)}></div>
          </div>
        }
      </div>
    );
  }
}

const styles = StyleSheet.create({
  distanceText: {
    fontFamily: 'ubuntu',
    fontSize: 17,
    margin: '0',
    padding: '0',
    color: 'white',
  },
  distance2: {
    color: 'white',
    fontSize: 17,
    fontFamily: 'ubuntu',
    fontWeight: 'bold',
    margin: '0',
    padding: '0',
  },
  distance3: {
    color: 'white',
    fontFamily: 'ubuntu',
    fontSize: '1.4rem',
    fontWeight: 'bold',
    margin: '0',
    padding: '0',
  },
  distanceTextLine: {
    borderBottomColor: '#f9407d',
    borderBottomStyle: 'solid',
    marginTop: '3px',
    marginBottom: '.30rem',

    width: '100%',
  },
});

export default DistanceToAttractive;