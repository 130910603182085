/* eslint-disable global-require */
import React from 'react';
import PropTypes from 'prop-types';
import { MAP, MARKER } from 'react-google-maps/lib/constants';

export class Spiderfy extends React.Component {
  static contextTypes = {
    [MAP]: PropTypes.object,
  };

  constructor(props, context) {
    super(props, context);
    // eslint-disable-next-line import/no-unresolved
    const oms = require('npm-overlapping-marker-spiderfier/lib/oms.min');
    this.oms = new oms.OverlappingMarkerSpiderfier(this.context[MAP], { keepSpiderfied: true, spiralFootSeparation: 30, spiralLengthFactor: 8, ignoreMapClick: !this.props.isMobile, nearbyDistance: 21 });
    this.markerNodeMounted = this.markerNodeMounted.bind(this);
  }

  markerNodeMounted(ref) {
    if (ref) {
      const marker = ref.markerRef.state[MARKER];
      this.oms.addMarker(marker);
      
      window.google.maps.event.addListener(marker, 'click', (e) => {
        const nearMarkers = this.oms.markersNearMarker(marker, false);
        const willSpiderfy = nearMarkers.length > 0;
        if (willSpiderfy) {
          nearMarkers.push(marker);
          nearMarkers.forEach((m) => {
            window.google.maps.event.addListener(m, 'mouseover', () => m.setAnimation(window.google.maps.Animation.BOUNCE));
            window.google.maps.event.addListener(m, 'mouseout', () => m.setAnimation(null));
          });
        }
      });
    }
  }

  render() {
    return React.Children.map(this.props.children, (child) =>
      React.cloneElement(child, { ref: this.markerNodeMounted }));
  }
}