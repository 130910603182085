import React from 'react';

import '../../assets/css/titleReviewPendency.css';

export default function TitleReviewPendency({ title, type, action, requestor }) {
  const filteredTitles = {
    'Roteiro' : 'Rota',
    'RoteirosTrechos' : 'Trecho da Rota',
  };
  return (
    <div className="TitleEditor TitleReviewPendency">
      <div className="div_top_card" />
      <div className="div_align_title_editor">
        <h2>{title}</h2>
        <div className='div_info_pendency'>
            <p><strong>Ação: </strong>{action}&emsp; </p>
            <p><strong>Tipo: </strong>{(filteredTitles[type] || type)}&emsp; </p>
            <p><strong>Solicitante: </strong>{requestor} </p>
        </div>
      </div>
    </div>
  );
}