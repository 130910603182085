import React from 'react';

// CSS Import
import '../../assets/css/InputComponents/rangeInput.css';

export default function RangeInput({ title, field, type, value, setValue }) {
  return (
    <div className="RangeInput">    
      <div className='div_label_input_range'>
        <label htmlFor={field + 'Form'} className="label_text_input">
          <strong>{title}</strong>
        </label>
      </div>
      <div className="div_out_input_range">
        <p>
          {value[field]}
        </p>
        <input className='input_text_inventory' type={type} value={value[field]} onChange={(event) => setValue({ ...value, [field]: Number(event.target.value) }) } id={field + 'Form'} />
      </div>
    </div>
  );
}