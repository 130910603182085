import React from 'react';
import { StyleSheet, css } from 'aphrodite';
import DefaultCityImage from '../assets/imgs/defaultCityImage.png';
import {
  Carousel,
  CarouselItem,
  CarouselControl,
} from 'reactstrap';
import CloseButton from '../assets/imgs/closeButton.png';
import { connect } from 'react-redux';
import { closeDrawer, showSearch, closeEventDrawer } from '../redux/actions';
import { bindActionCreators } from 'redux';

class CarouselStrap extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      activeIndex: 0,
      urls: [],
      cityname: '',
    };
    this.next = this.next.bind(this);
    this.previous = this.previous.bind(this);
    this.goToIndex = this.goToIndex.bind(this);
    this.onExiting = this.onExiting.bind(this);
    this.onExited = this.onExited.bind(this);
    this.retrieveData = this.retrieveData.bind(this);
  }

  onExiting() {
    this.animating = true;
  }

  onExited() {
    this.animating = false;
  }

  next() {
    if (this.animating) return;

    const nextIndex = this.state.activeIndex === this.state.urls.length - 1 ? 0 : this.state.activeIndex + 1;
    this.setState({
      activeIndex: nextIndex,
    });
  }

  previous() {
    if (this.animating) return;

    const nextIndex = this.state.activeIndex === 0 ? this.state.urls.length - 1 : this.state.activeIndex - 1;
    this.setState({
      activeIndex: nextIndex,
    });
  }

  goToIndex(newIndex) {
    if (this.animating) return;

    this.setState({
      activeIndex: newIndex,
    });
  }

  componentDidMount() {
    this.retrieveData(this.props);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.document !== this.props.document) {
      this.retrieveData(nextProps);
    }
  }

  getCityDefaultImage(city) {
    this.setState({ urls: this.props.citiesDefaultImages[city] });
  }

  getDefaultEventImage(city) {
    this.setState({ urls: this.props.eventsDefaultImages[city] });
  }

  getAttrativeImages = (imageFile, city) => {
    const baseUrl = this.props.citiesDefaultImages[city][0];
    const slash = '%2F';
    let url = baseUrl.substring(0, baseUrl.indexOf('/o/'));
    const sulfix = '?alt=media';
    url += '/o/cities' + slash + city + slash;
    // EDIMAR - Procurar onde está definindo a URL de imagens para o portaldev-effa3
    // Após fazer isso excluir o comando de replace para buscar sempre em produção.
    url = url.replace('portaldev-effa3.appspot.com', 'portal.appspot.com');

    const urls = [];
    imageFile.forEach(async (name) => {
      urls.push(url + name + sulfix);
    });
    this.setState({ urls: urls });
  }

  getEventImages = (imageFile, city) => {
    const baseUrl = this.props.eventsDefaultImages[city][0];
    const slash = '%2F';
    let url = baseUrl.substring(0, baseUrl.indexOf('/o/'));
    const sulfix = '?alt=media';
    url += '/o/cities' + slash + city + slash + 'events' + slash;
    // EDIMAR - Procurar onde está definindo a URL de imagens para o portaldev-effa3
    // Após fazer isso excluir o comando de replace para buscar sempre em produção.
    url = url.replace('portaldev-effa3.appspot.com', 'portal.appspot.com');

    const urls = [];
    imageFile.forEach(async (name) => {
      urls.push(url + name + sulfix);
    });
    this.setState({ urls: urls });
  }

  retrieveData(props) {
    const { imageFile, city, event } = props;

    if (imageFile !== undefined) {
      if (event) {
        this.getEventImages(imageFile, city);
      } else {
        this.getAttrativeImages(imageFile, city);
      }
    } else if (event) {
      this.getDefaultEventImage(city);
    } else {
      this.getCityDefaultImage(city);
    }
  }

  handleCloseImageClick = () => {
    const { lastDrawerOpened } = this.props;
    if (lastDrawerOpened === 'event')
      this.props.closeEventDrawer();
    else
      this.props.closeDrawer();
    if (this.props.searchValue !== '')
      this.props.showSearch();
  }

  render() {
    const { activeIndex } = this.state;
    const { isMobile } = this.props;
    const slides = this.state.urls !== false ?
      this.state.urls.map((url, i) =>
        (url.search('youtube') === -1 ?
          <CarouselItem
            onExiting={this.onExiting}
            onExited={this.onExited}
            key={i}
          >
            <div className={css(styles.carousel)}>
              <img alt='' className={css(styles.image)} src={url}></img>
              { isMobile &&
                <img alt='' onClick={this.handleCloseImageClick} src={CloseButton} className={css(styles.closeAttractivesImg)}/>
              }
            </div>
          </CarouselItem>
          :
          <CarouselItem
            onExiting={this.onExiting}
            onExited={this.onExited}
            key={i}
          >
          <div className={css(styles.carousel)}>
            <iframe
              className={css(styles.video)}
              src={url}
              title='Video'
              allowFullScreen='allowfullscreen'
            ></iframe>
            { isMobile &&
              <img alt='' onClick={this.handleCloseImageClick} src={CloseButton} className={css(styles.closeAttractivesImg)}/>
            }
          </div>
          </CarouselItem>
        )) :
      <CarouselItem
        onExiting={this.onExiting}
        onExited={this.onExited}
      >
        <div className={css(styles.carousel)}>
          <img alt='' className={css(styles.image)} src={DefaultCityImage} />
          { isMobile &&
            <img alt='' onClick={this.handleCloseImageClick} src={CloseButton} className={css(styles.closeAttractivesImg)}/>
          }
        </div>
      </CarouselItem>;

    return (
      <div>
        <Carousel
          activeIndex={activeIndex}
          next={this.next}
          previous={this.previous}
        >
          {slides}
          <CarouselControl direction="prev" directionText="Previous" onClickHandler={this.previous} />
          <CarouselControl direction="next" directionText="Next" onClickHandler={this.next} />
        </Carousel>
      </div>
    );
  }
}
const styles = StyleSheet.create({
  carousel: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'relative',
    maxWidth: 'fit-content',
    margin: 'auto',
    paddingTop: '7px',
    paddingRight: '7px',
    paddingLeft: '7px',
  },
  closeAttractivesImg: {
    height: '30px',
    position: 'absolute',
    top: '0',
    right: '0',
    zIndex: 8,
  },
  image: {
    borderRadius: 20,
    maxHeight: '12rem',
    maxWidth: '20rem',
  },
  video: {
    maxHeight: '12rem',
    maxWidth: '20rem',
  },
});

const mapStateToProps = (state) => ({
  searchValue: state.search.value,
  citiesDefaultImages: state.citiesDefaultImages,
  eventsDefaultImages: state.eventsDefaultImage,
  lastDrawerOpened: state.lastDrawerOpened,
});
function mapDispatchToProps(dispatch) {
  return bindActionCreators({ closeDrawer, showSearch, closeEventDrawer }, dispatch);
}
export default connect(mapStateToProps, mapDispatchToProps)(CarouselStrap);
