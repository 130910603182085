export const generateRandomId = () => {
  // Define the characters that can be used in the ID
  const characters = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';

  // Set the desired length of the ID
  const idLength = 42;

  // Initialize the ID string
  let randomId = '';

  // Generate the ID by randomly selecting characters from the set
  for (let i = 0; i < idLength; i++) {
    if ([8, 13, 18, 23].includes(i)) {
      randomId += '-';
    } else {
      const randomIndex = Math.floor(Math.random() * characters.length);
      randomId += characters.charAt(randomIndex);
    }
  }

  return randomId;
};