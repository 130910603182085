/* eslint-disable no-nested-ternary */
import React from 'react';
import { limitStr } from '../../utils/limitStr';

// CSS import
import '../../assets/css/atractionList.css';

export default function CitiesList({ doc, handleEdit, handleDelete }) {
  return (
    <tr className="row_table_inventory body_table">
      <td className="title_table_inventory">{doc.data().name}</td>
      <td className="column_min_table_inventory">{doc.data().regionName || 'Não Há'}</td>
      <td className="desc_table_inventory">{limitStr(doc.data().shortDescr)}</td>
      <td className="desc_table_inventory">{limitStr(doc.data().longDescr)}</td>
      <td className="column_min_table_inventory">{doc.data().Active ? 'Ativo' : 'Desativado'}</td>
      <td className="column_edit_table_inventory">
        <div className='div_mod_inventory'>
          <button className='btn_mod_adm' onClick={() => handleEdit(doc)}>Editar</button>
          <button className='btn_mod_adm' onClick={() => handleDelete(doc)}>Exluir</button>
        </div>
      </td>
    </tr>
  );
}