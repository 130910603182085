import React from 'react';

// CSS Import
import '../assets/css/RankingComponents/cardReportCalculator.css';

// Assets imports
import DeleteIcon from '../assets/imgs/delete_card_icon.JPG';
import ReAddIcon from '../assets/imgs/readd_icon.svg';

// Firebase Import
import firebase from '../config/Firebase';

export default function CardReportCalculator({ type, styleType, topic, body, score, deleteCards, setDeleteCards, city, isDeleted, keyProps }) {
  async function handleDeleteCard() {
    const docs = await firebase.firestore().collection('DeleteCards').where('city', '==', city).get();
    const docId = docs.docs[0].id;

    await firebase.firestore().collection('DeleteCards').doc(docId).update({
      cardsDelete: [...deleteCards, topic],
    });

    setDeleteCards([...deleteCards, topic]);
  }

  async function handleAddCard() {
    const docs = await firebase.firestore().collection('DeleteCards').where('city', '==', city).get();
    const docId = docs.docs[0].id;
    const newCards = deleteCards.filter((card) => card !== topic);

    await firebase.firestore().collection('DeleteCards').doc(docId).update({
      cardsDelete: newCards,
    });

    setDeleteCards(newCards);
  }

  return (
    <li
      className={`CardReportCalculator ${styleType} ${isDeleted ? 'style_card_deleted' : ''}`}
      id={topic + '_id'}
      key={keyProps}
      >
      <div className='div_align_card_report'>
        <small>{type}</small>
        <h4>{topic}</h4>
        {
          body.map((line, index) => {
            return <p key={`${line}_${index}`}>{line}</p>;
          })
        }
        <span className="span_info_card_report">Atualmente possui {score === 'Não' ? 0 : score}</span>
        <img src={isDeleted ? ReAddIcon : DeleteIcon} alt='Ícone de uma lixeira' onClick={isDeleted ? () => handleAddCard() : () => handleDeleteCard()}/>
      </div>
    </li>
  );
}