import ReactGA from 'react-ga';

ReactGA.initialize('UA-133940860-01');

export const PageView = () => {  
  ReactGA.pageview(window.location.pathname + window.location.search); 
}; 

/**
 * Event - Add custom tracking event.
 * @param {string} category 
 * @param {string} action 
 * @param {string} label 
 */ 
export const Event = (category, action, label) => {
  ReactGA.event({
    category: category,
    action: action,
    label: label,
  });
};

export const EventWithParameters = (category, action, label, id, type, subtype) => {
  ReactGA.ga('send', {
    hitType: 'event',
    eventCategory: category,
    eventAction: action,
    eventLabel: label,
    eventId: id,
    eventType: type,
    eventSubtype: subtype,
  });
};