import React, { useState, useEffect } from 'react';
import Papa from 'papaparse';

// Firebase import
// eslint-disable-next-line import/no-extraneous-dependencies
import Firebase from 'firebase';

// CSS import
import '../../assets/css/InputComponents/geolocationItineraryInput.css';

export function GeolocationItineraryInput({ value, setValue, explanation }) {
  const [csvFile, setCsvFile] = useState(null);
  const [coordinates, setCoordinates] = useState(value.waypoint || []);
  const [names, setNames] = useState(value.waypointTitulo || []);

  useEffect(() => {
    if (value.waypoint && value.waypoint.length > 0) {
      setCoordinates(value.waypoint);
      setNames(value.waypointTitulo);
    }
  }, []);

  function handleUpload() {
    // Upload GeoPoints to Firebase
    const geoPointsArray = coordinates.map((item) => new Firebase.firestore.GeoPoint(Number(item.latitude), Number(item.longitude)));

    setValue({ ...value,
      waypoint: geoPointsArray,
      waypointTitulo: names,
    });
  }

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    setCsvFile(file);
    setCoordinates([]);
    setNames([]);

    // Parse the CSV file when the user selects one
    Papa.parse(file, {
      header: false,
      skipEmptyLines: true,
      dynamicTyping: true,
      quoteChar: false,
      complete: (result) => {
        if (result.data.length > 0) {
          const parsedCoordinates = result.data.map((row) => ({
            latitude: row[0],
            longitude: row[1],
          }));
          const parsedNames = result.data.map((row) => {
            let name = row[2] || ''; // Initialize with the third column, or an empty string if it's not present
            // Append all columns after the third one
            for (let i = 3; i < row.length; i++) {
              name += ',' + row[i];
            }
            return name;
          });

          setCoordinates(parsedCoordinates);
          setNames(parsedNames);
        }
      },
    });
  };

  useEffect(() => {
    if (csvFile && coordinates.length > 0 && names.length > 0) {
      handleUpload();
    }
  }, [coordinates, names]);

  return (
    <>
      <div className='div_field_input_geolocation_itinerary'>
        <input className='btn_input_arquive' type="file" accept=".csv, .txt" onChange={handleFileChange} />
      </div>
      <div className="coordinates-box">
        <h6>Coordenadas carregadas:</h6>
        <div className="coordinates-list">
          {value.waypointTitulo && value.waypointTitulo.length > 0 ? (
            <ul>
              {value.waypointTitulo.map((nome, index) => (
                <li key={index}>
                  <strong>Latitude:</strong> {value.waypoint[index].Ic}, <strong>Longitude:</strong> {value.waypoint[index].wc}, <strong>Nome:</strong> {nome}
                </li>
              ))}
            </ul>
          ) : (
            <h6>Nenhuma coordenada foi carregada.</h6>
          )}
        </div>
      </div>
      <div className='div_explanation_waypoint_input'>
        <p className='subtext_explanation_input'>{explanation}</p>
      </div>
    </>
  );
}