import React from 'react';

import '../../assets/css/headerForm.css';

export default function HeaderForm({ title, setMapPopup, getPlaceId }) {
  return (
    <div className={`HeaderForm ${ getPlaceId ? 'HeaderPlaceId' : '' }`}>
      <p><strong>{ title || 'Preencha os campos abaixo'}</strong> campos com * são obrigatórios.</p>
      {
        getPlaceId ? <button type="button" className="btn_get_place_id" onClick={() => setMapPopup(true)}>Procurar Local</button> : null
      }
    </div>
  );
}