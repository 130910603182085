import React from 'react';

import firebase from '../config/Firebase';

const withAuthenticationCompleteSignUp = (WrappedComponent) => {
  class withAuthenticationCompleteSignUp extends React.Component {
    state = {
      providerData: [],
    };

    componentDidMount() {
      firebase.auth().onAuthStateChanged((user) => {
        if (user) {
          const socialMediaUser = user.providerData[0].providerId === 'facebook.com' || user.providerData[0].providerId === 'google.com';
          // Usuários do face não precisam verificar o email
          if (socialMediaUser) {
            firebase.firestore().collection('users').doc(user.uid).get()
              .then((doc) => {
                if (doc.data().completedSignUp === false)
                  this.setState({ providerData: user.providerData });
                else {
                  this.props.history.push('/map');
                }
              });
          }
        } else {
          alert('Must be authenticated');
          this.props.history.push('/');
        }
      });
    }

    render() {
      return this.state.providerData.length > 0 ? (
        <WrappedComponent
          {...this.props}
          providerData={this.state.providerData}
        />
      ) : (
        <p>Loading...</p>
      );
    }
  }

  return withAuthenticationCompleteSignUp;
};

export default withAuthenticationCompleteSignUp;
