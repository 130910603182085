import React, { useState } from 'react';

import '../../assets/css/checkboxRender.css';
import CalculatorIcon from '../../assets/imgs/pins/ranking_icon.png';

// Components Imports
import InputCheckbox from '../InputComponents/InputCheckbox';

export default function CheckboxRender({ title, value, setValue, field, values, checkboxValues, objectPend, doc, styleType, explanation, role, actionType, rankingPoints }) {
  const [checkElement, setCheckElement] = useState(handleArrayItem(field));
  const rolesControl = ['admin', 'masterAdmin', 'contentManager', 'contentEditor'];

  function handleArrayItem(field) {
    if (!values) return undefined;

    if (objectPend) {
      if ((field !== 'Atendimento Idioma Estrangeiro' && field !== 'Meios de Pagamento') || isArray(objectPend.currDoc[field]))
        return objectPend.currDoc[field] ? (objectPend.currDoc[field] || []) : [];
      
      return objectPend.currDoc[field] ? (objectPend.currDoc[field].split(',') || []) : [];
    }

    if (doc) {
      if ((field !== 'Atendimento Idioma Estrangeiro' && field !== 'Meios de Pagamento') || isArray(doc.data()[field])) {
        return doc.data()[field] || [];
      }

      return doc.data()[field] ? (doc.data()[field].split(',') || []) : [];
    }
    
    return [];
  }

  function isArray(obj) {
    return (typeof obj) === 'object';
  }

  return (
    field !== 'Impulsionar' || rolesControl.includes(role) ? (
      <div className="CheckboxRender">
        <div className='div_label_input_checkbox'>
          {
            rankingPoints ? (
              <div className="div_align_img_ranking_points">
                <span className="checkbox_ranking_type">Este campo contribui para a Calculadora de Ranqueamento.</span>
                <img src={CalculatorIcon} alt="Ícone com um C, que representa que o campo contabiliza para a contagem na calculadora" />
              </div>
            ) : null
          }
          <label className="label_radio_input">
            <strong>{title}</strong>
          </label>  
        </div>
        <div className='div_align_explanation_input'>
          <div className='div_align_checkbox'>
            <div className={styleType ? 'div_checkbox_row_grid_2' : 'div_checkbox_row_grid'}>
              {
                checkboxValues.map((seg, index) => {
                  return <InputCheckbox key={'Checkbox' + field + index} title={seg.title} idValue={seg.id} values={checkElement} item={value} setItem={setValue} field={field} setValue={setCheckElement} role={role} action={actionType} />;
                })
              }
            </div>
          </div>
          <div className='div_explanation_input'>
            <p className='subtext_explanation_input'>{explanation}</p>
          </div>
        </div>
      </div>
    ) : null
  );
}