import React from 'react';

// CSS import
import '../../assets/css/Dashboard/cardInfo.css';

export default function EmployeeInfo({ totalFix, totalTemp }) {
  return (
    <div className='EmployeeInfo card_container_dash'>
      <div className='div_top_header_dash'>
        <h1 className='title_cardInfo'>Funcionários</h1>
        <button className='btn_card_info' disabled>Atual</button>
      </div>
      <div className='div_footer_dash'>
        <div>
          <h1 className='h1_cardInfo'>{ totalFix }</h1>
          <p className='txt_cardInfo'>{ ((totalFix + totalTemp) !== 0) ?
            ((totalFix / (Number(totalFix) + totalTemp)) * 100).toFixed() : 0 }% Fixos</p>
        </div>
        <div>
          <h1 className='h1_cardInfo'>{ totalTemp }</h1>
          <p className='txt_cardInfo'>{ (totalFix + totalTemp) !== 0 ? ((totalTemp / (totalFix + totalTemp)) * 100).toFixed() : 0 }% Temporários</p>
        </div>
      </div>
    </div>
  );
}