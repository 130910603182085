import React from 'react';
import RemoveIcon from '../assets/imgs/remove_icon.svg';
import '../css/ActivityList.css';

export function ActivityList({ name, removeItemList }) {
  return (
    <div className='div_act_name_list'>
      <div className='div_align_div_activity'>
        <div className='div_align_component_activity'>
          <img alt='Icone de remover' className='remove_icon_edit' src={RemoveIcon} onClick={() => removeItemList(name)} />
        </div>
        <div className='div_align_component_activity'>
          <span className='text_act_edit'>
            {name}
          </span>
        </div>
      </div>
    </div>
  );
}