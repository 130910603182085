import React from 'react';

// CSS import
import '../../assets/css/InputComponents/fileInput.css';

export default function FileInput({ field, title, value, setValue, explanation, setFile }) {
  return (
    <div className='FileInput'>
      <div className='div_align_file_input'>
        <div className='div_label_input_file'>
          <label htmlFor={field + 'Form'} className="label_text_input">
          <strong>{title}</strong>
          </label>
        </div>
        <div className='div_align_explanation_input'>
          <div className='div_field_input_file_type'>
            <div className='div_align_input_file'>
              <input type='text' className='input_namefile_fileinput' disabled={true} value={value[field]} />
              <button type='button' className='btn_image_inventory' onClick={() => document.getElementById('input_file_kml').click()}>Carregar Arquivo</button>
              <input className='input_file_inventory' type='file' id='input_file_kml' accept='.kml' onChange={(event) => { setValue({ ...value, [field]: event.target.files[0].name }); setFile(event.target.files[0]); }} />
            </div>
          </div>
          <div className='div_explanation_input'>
            <p className='subtext_explanation_input'>{explanation}</p>
          </div>
        </div>
      </div>
    </div>
  );
}