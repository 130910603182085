import React, { useState, useEffect } from 'react';

// Firebase imports
import Firebase from 'firebase';
import firebase from '../../config/Firebase';

// Assets imports
import SetLocalIcon from '../../assets/imgs/set_local_icon.svg';

// Components imports
import MapInput from '../MapInput';
import Loading from '../Loading';

// CSS import
import '../../assets/css/InputComponents/geolocationInput.css';

export function GeolocationInput({ field, title, value, setValue, doc, objectPend, placeholder, userCity, explanation }) {
  const [geolocation, setGeolocation] = useState(handleGeolocationItem(field));
  const [center, setCenter] = useState();
  const [loadingMap, setLoadingMap] = useState(true);

  async function handleLocationCity() {
    setLoadingMap(true);

    if (!userCity) {
      const centerSP = new Firebase.firestore.GeoPoint(-23.543559, -46.604112);

      setCenter(centerSP);
    } else {
      const docs = await firebase.firestore().collection('Cities')
        .where('name', '==', userCity)
        .get();

      setCenter(docs.docs[0].data().location);
    }

    setLoadingMap(false);
  }

  function handleGeolocationItem(fieldGeo) {
    if (objectPend)
      return objectPend.currDoc[fieldGeo] ? `${objectPend.currDoc[fieldGeo].latitude}, ${objectPend.currDoc[fieldGeo].longitude}` : '';

    return doc && doc.data()[fieldGeo] ? `${doc.data()[fieldGeo].latitude}, ${doc.data()[fieldGeo].longitude}` : '';
  }

  function validateCord(cord) {
    if (cord >= -180 && cord <= 180) return true;

    return false;
  }

  function handleOpenMap(idElement) {
    if (window.google) {
      const element = document.getElementById(idElement);

      if (element.clientHeight === 0)
        element.style = 'height: 70vh; margin-top: 1rem; max-height: 400px;';
      else
        handleCloseMap(idElement);
    } else {
      const element = document.getElementById(idElement);

      if (element.clientHeight === 0)
        element.style = 'height: 10vh; margin-top: 1rem';
      else
        handleCloseMap(idElement);
    }
  }

  function handleCloseMap(idElement) {
    document.getElementById(idElement).style = 'height: 0; margin-top: 0; max-height: 400px;';
  }

  const handleGeopoint = (e, field) => {
    const list = e.split(',');

    if (list.length > 0) {
      if (list.length === 2 && validateCord(Number(list[0])) && validateCord(Number(list[1]))) {
        setValue({ ...value, [field]: new Firebase.firestore.GeoPoint(Number(list[0]), Number(list[1])) });
      }
    }
  };

  useEffect(() => {
    handleLocationCity();
  }, [userCity]);

  useEffect(() => {
    handleGeopoint(geolocation, field);
    if (geolocation === '')
      delete value[field];
  }, [geolocation]);

  useEffect(() => {
    if (value[field])
      setGeolocation(`${value[field].latitude}, ${value[field].longitude}`);
  }, [value[field]]);

  if (!center || loadingMap)
    return <Loading />;

  return (
    <div className='GeolocationInput'>
      <div className='div_align_input_geolocation'>
        <div className='div_label_input_geolocation'>
          <label htmlFor={field + 'Form'} className="label_text_input">
            <strong>{title}</strong>
          </label>
        </div>
        <div className='div_align_explanation_input'>
          <div className='div_field_input_geolocation'>
            <input className='input_geolocation' id={field + 'Form'} type="text" value={geolocation} onChange={(e) => setGeolocation(e.target.value) } placeholder={placeholder}/>
            <button type='button' className='btn_open_map' onClick={() => handleOpenMap(field + 'Map')}>
              <img src={SetLocalIcon} alt='Abrir Mapa' className='img_map_btn'/>
            </button>
          </div>
          <div className='div_explanation_input'>
            <p className='subtext_explanation_input'>{explanation}</p>
          </div>
        </div>
      </div>
      <MapInput center={center} id={field} setValue={setGeolocation} />
    </div>
  );
}