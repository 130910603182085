import React from "react";

import '../../assets/css/CaeLogin/textField.css';

export default function TextField({ icon, alt, ...props }) {
  return (
    <div className="TextField">
      <img alt={alt} src={icon} />
      <input {...props} />
    </div>
  );
}