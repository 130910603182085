import React, { useState, useEffect } from 'react';

// CSS Import
import '../../assets/css/InputComponents/placeID.css';

import Firebase from 'firebase';

import { Loader } from '@googlemaps/js-api-loader';

export default function PlaceID({ setMapPopup, setItem, item }) {
  const handleClickInner = (event) => { event.stopPropagation(); };
  const [name, setName] = useState('');
  const [placeInfos, setPlaceInfos] = useState();

  function handleInitPlaceID() {
    const loader = new Loader({ apiKey: 'AIzaSyDd3qwHtsqLlv_0GuVzOOrtZ6-XvZRifEc', libraries: ['places', 'visualization'] });

    loader.load().then((google) => {
      const map = new google.maps.Map(document.getElementById('map_place_id'), {
        center: { lat: -33.8688, lng: 151.2195 },
        zoom: 13,
      });

      const input = document.getElementById('input_place_id');
      // Specify just the place data fields that you need.
      const autocomplete = new google.maps.places.Autocomplete(input, {
        fields: ['place_id', 'geometry', 'formatted_address', 'name'],
      });
    
      autocomplete.bindTo('bounds', map);
    
      const marker = new google.maps.Marker({ map: map });
      const service = new google.maps.places.PlacesService(map);
    
      autocomplete.addListener('place_changed', async () => {
        const place = autocomplete.getPlace();

        const fields = ['website', 'rating', 'user_ratings_total', 'place_id', 'price_level', 'reviews', 'vicinity', 'formatted_phone_number', 'name', 'types', 'geometry', 'current_opening_hours'];

        service.getDetails({ placeId: place.place_id, language: 'pt', fields: fields }, (place, status) => {
          if (status === 'OK')
            setPlaceInfos(place);
        });

        if (!place.geometry || !place.geometry.location) return;
    
        if (place.geometry.viewport) {
          map.fitBounds(place.geometry.viewport);
        } else {
          map.setCenter(place.geometry.location);
          map.setZoom(17);
        }

        marker.setPlace({
          placeId: place.place_id,
          location: place.geometry.location,
        });
        marker.setVisible(true);
      });
    });
  }

  async function handleGetInfo() {
    try {
      const fieldsGetInfoAPI = {
        // Acessibilidade: 'wheelchair_accessible_entrance',
        Website: 'website',
        Nota: 'rating',
        QuantidadeAvaliacoes: 'user_ratings_total',
        PlaceId: 'place_id',
        FaixaPreco: 'price_level',
        Avaliacoes: 'reviews',
        Endereço: 'vicinity',
        Telefone: 'formatted_phone_number',
        Title: 'name',
        // serves_beer: 'serves_beer',
        // serves_breakfast: 'serves_breakfast',
        // serves_brunch: 'serves_brunch',
        // serves_dinner: 'serves_dinner',
        // serves_lunch: 'serves_lunch',
        // serves_wine: 'serves_wine',
        // takeout: 'takeout',
        types: 'types',
      };

      const newItem = {
        Title: '',
        'Short Description': '',
        'Long Description': '',
        City: item.City,
        Type: '',
        Subtype: '',
        Especialidade: '',
        Active: true,
        Visible: true,
        location: null,
        cidadeAtiva: item.cidadeAtiva,
        nomeRegiao: item.nomeRegiao,
        original: {
          'Short Description': '',
          'Long Description': '',
        },
      };

      Object.keys(fieldsGetInfoAPI).map((key) => {
        if (typeof placeInfos[fieldsGetInfoAPI[key]] === 'object')
          newItem[key] = placeInfos[fieldsGetInfoAPI[key]].toString();

        if (placeInfos[fieldsGetInfoAPI[key]])
          newItem[key] = placeInfos[fieldsGetInfoAPI[key]];
      });

      if (placeInfos['geometry']) {
        const { lat, lng } = placeInfos['geometry']['location'];
        newItem['location'] = new Firebase.firestore.GeoPoint(lat(), lng());
      }

      if (placeInfos['current_opening_hours']) {
        let textFunc = '';
        placeInfos['current_opening_hours']['weekday_text'].map((day) => {
          textFunc += day + '\n';
        });

        newItem['Horário de Funcionamento'] = textFunc;
      }
      
      setItem(newItem);
      setMapPopup(false);
    } catch (error) {
      setMapPopup(false);
    }
  }

  useEffect(() => {
    handleInitPlaceID();
  }, []);

  return (
    <div className="PlaceID" onClick={() => setMapPopup(false)}>
      <div className="inner_popup_placeid" onClick={handleClickInner}>
        <div className="header_placeid">
          <span className="title_header_placeid">Procurar local</span>
          <span className="subtitle_header_placeid">Você pode usar essa pesquisa para adiantar o processo de cadastro, se encontrar o lugar que deseja podemos completar algumas informações.</span>
        </div>
        <div className="div_field_placeid">
          <label htmlFor="input_place_id">Nome do Local</label>
          <input type="text" className="input_place_id" id="input_place_id" onChange={(e) => setName(e.target.value)} value={name} placeholder="" />
        </div>
        <div className="map_place_id" id="map_place_id"></div>
        <div className="div_buttons_placeid">
          <button type="button" className="btn_cancel_placeid" onClick={() => setMapPopup(false)}>Cancelar</button>
          <button type="button" className="btn_confirm_placeid" onClick={() => handleGetInfo()} disabled={placeInfos === undefined}>Confirmar</button>
        </div>
      </div>
    </div>
  );
}