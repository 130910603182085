import React, { useEffect, useState } from 'react';

// CSS Import
import '../../assets/css/InputComponents/cityPickerInput.css';

import { Country, State, City } from 'country-state-city';

// Components Import
import Loading from '../Loading';

export default function CityPickerInput({ value, setValue }) {
  const [countryCodes, setCountryCodes] = useState({ Brasil: "BR" });
  const [stateCodes, setStateCodes] = useState();
  const [cities, setCities] = useState([]);
  const [states, setStates] = useState([]);
  const [countries, setCountries] = useState();

  function getCountries(places) {
    if (!places) return [];

    const names = [];
    let codes = {};

    places.map((plac) => {
      names.push(plac.name);
      codes = { ...codes, [plac.name === 'Brazil' ? 'Brasil' : plac.name]: plac.isoCode};
    });

    setCountryCodes(codes);
    return names;
  }

  function getStates(places) {
    if (!places) return [];

    const names = [];
    let codes = {};

    places.map((plac) => {
      names.push(plac.name);
      codes = { ...codes, [plac.name]: plac.isoCode};
    });

    setStateCodes(codes);
    return names;
  }

  function getCities(cities) {
    const filtered = [];

    cities.map((city) => {
      filtered.push(city.name);
    });

    return filtered;
  }

  function handleCountry() {
    const countries = getCountries(Country.getAllCountries()).map((plac) => {
      return { title: plac === 'Brazil' ? 'Brasil' : plac, value: plac === 'Brazil' ? 'Brasil' : plac }
    });
    
    setCountries(countries);
  }

  function handleState() {
    const country = value.Pais || '';

    const states = getStates(State.getStatesOfCountry(countryCodes ? countryCodes[country] : '')).map((state) => {
      return { title: state, value: state }
    });

    setStates(states);
  }

  function handleCityName() {
    const country = value.Pais || ''; 
    const state = value.Estado || ''; 

    const cities = getCities(City.getCitiesOfState(countryCodes ? countryCodes[country] : '', stateCodes ? stateCodes[state] : '')).map((city) => {
      return { title: city, value: city }
    });

    setCities(cities);
  }
 
  useEffect(() => {
    handleCountry();
  }, []);

  useEffect(() => {
    if (value.Pais)
      handleState();
  }, [value.Pais, countries]);

  useEffect(() => {
    if (value.Estado)
      handleCityName();
  }, [value.Estado, states]);

  useEffect(() => {
    if (value.Estado && stateCodes)
      setValue({ ...value, Sigla: stateCodes[value.Estado] });
  }, [value.Estado]);

  if (!cities || !states || !countries) {
    return <Loading />;
  }

  return (
    <div className='CityPickerInput'>
      <div className='SelectInput'>
        <div className='div_label_input_text'>
          <label className="label_text_input">
            <strong>País*</strong>
          </label>
        </div>
        <div className='div_select_input_select'>
          <select onChange={(event) => setValue({ ...value, Pais: event.target.value })} defaultValue={value.Pais || ''} >
            <option value=''>Selecione uma opção</option>
            { 
              countries.map((e, index) => {
                return <option key={'CityPicker' + 'country' + index} value={e.value}>{e.title}</option>
              })
            }
          </select>
        </div>
      </div>
      <hr className='divisor_component'/>
      <div className='SelectInput'>
        <div className='div_label_input_text'>
          <label className="label_text_input">
            <strong>Estado*</strong>
          </label>
        </div>
        <div className='div_select_input_select'>
          <select onChange={(event) => setValue({ ...value, Estado: event.target.value })} defaultValue={value.Estado || ''} >
            <option value=''>Selecione uma opção</option>
            { 
              states.length > 0 ? states.map((e, index) => {
                return <option key={'CityPicker' + 'state' + index} value={e.value}>{e.title}</option>
              }) : <option disabled>Sem opções</option>
            }
          </select>
        </div>
      </div>
      <hr className='divisor_component'/>
      <div className='SelectInput'>
        <div className='div_label_input_text'>
          <label className="label_text_input">
            <strong>Cidade*</strong>
          </label>
        </div>
        <div className='div_select_input_select'>
        <select onChange={(event) => setValue({ ...value, name: event.target.value })} defaultValue={value.name || ''} value={value.name || ''}>
          <option value=''>Selecione uma opção</option>
          {
            cities.length > 0 ? cities.map((e, index) => {
              return <option key={'CityPicker' + 'city' + index} value={e.value}>{e.title}</option>
            }) : <option disabled>Sem opções</option>
          }
        </select>
      </div>
      </div>
    </div>
  );
}