import React, { useState, useEffect } from "react";
import firebase from '../../config/Firebase';

// Components imports
import VerifyPopup from '../VerifyPopup';
import ButtonForm from '../FormComponents/ButtonForm';
import HeaderQuestion from '../FormComponents/HeaderQuestion';
import HeaderForm from '../FormComponents/HeaderForm';
import TitleEditor from '../FormComponents/TitleEditor';
import { fieldsEditDemand } from '../FieldsForm/demand.fields';
import { fieldsQuestionsDemand } from '../FieldsForm/questions.fields';
import { inputsComponents } from '../../utils/formManager';
import Loading from '../Loading';
import AddIcon from '../../assets/imgs/add_icon.svg';
import WarningPopup from '../WarningPopup';

// CSS Import
import '../../assets/css/EditComponents/editDemand.css';

export default function EditDemand({ doc, action, userCity, setEditPopup, template, role }) {
  const [popup, setPopup] = useState(false);
  const [item, setItem] = useState(doc ? doc.data() : {
    titulo: '',
    cidade: userCity,
    active: true,
    respondentes: [],
    publicado: false,
  });
  const [questions, setQuestions] = useState();
  const [idQuestions, setIdQuestions] = useState();
  const [deleteQuestions, setDeleteQuestions] = useState([]);
  const typesControl = ['escolhaMultipla', 'escolhaMultiplaOutro', 'escolhaUnica', 'escolhaUnicaOutro', 'notaItem'];

  function handleTemplate() {
    setItem({ ...template.demand, cidade: userCity, publicado: false, respondentes: [] });
    setQuestions(template.questions);

    const ids = [];
    for (let i = 0; i < template.questions.length; i++)
      ids.push('');

    setIdQuestions(ids);
  }

  async function handleQuestionsDemand() {
    if (doc) {
      const dataQuestions = await firebase.firestore().collection('EstudoDemandaPerguntas')
        .where('idEstudoDemanda', '==', doc.id)
        .orderBy('ordem')
        .get();

      const questionsVector = [];
      const idsVector = [];

      dataQuestions.docs.map((ques) => {
        questionsVector.push(ques.data());
        idsVector.push(ques.id);
      });

      setQuestions(questionsVector);
      setIdQuestions(idsVector);
    } else {
      setQuestions([{
        tipoPergunta: '',
        pergunta: '',
        tema: '',
        idEstudoDemanda: '',
      }]);
      setIdQuestions(['']);
    }
  }

  const handleEditDemand = async () => {
    await firebase.firestore().collection('EstudoDemanda').doc(doc.id).set(item);

    questions.map(async (ques, index) => {
      if (idQuestions[index] === '')
        await firebase.firestore().collection('EstudoDemandaPerguntas').add({ ...ques, ordem: (index + 1) });
      else
        await firebase.firestore().collection('EstudoDemandaPerguntas').doc(idQuestions[index]).set({ ...ques, ordem: (index + 1) });
    });

    deleteQuestions.map(async (id) => {
      if (id !== '')
        await firebase.firestore().collection('EstudoDemandaPerguntas').doc(id).delete();
    });

    setEditPopup(false);
  };

  async function addTemplate(share) {
    await firebase.firestore().collection('Templates').add({
      template: {
        demand: item,
        questions: questions,
      },
      createDate: new Date(),
      sharing: share,
      type: 'EstudoDemanda',
      title: item.titulo,
      city: item.cidade,
    });
  }

  const handleAddDemand = async () => { 
    firebase.firestore().collection('EstudoDemanda').add(item).then((document) => {
      questions.map(async (ques, index) => {
        const newQues = { ...ques, idEstudoDemanda: document.id, ordem: (index + 1) };
        await firebase.firestore().collection('EstudoDemandaPerguntas').add(newQues);
      });
    });

    if (['masterAdmin', 'admin', 'contentManager'].includes(role))
      await addTemplate('global');
    
    if (role === 'cityAdmin')
      await addTemplate('city');

    setEditPopup(false);
  };

  function handleCheckFields() {
    const keysDemand = ['titulo', 'cidade', 'active'];
    const keysQuestion = ['tema', 'pergunta', 'tipoPergunta'];

    for (let i = 0; i < keysDemand.length; i++) {
      if (item[keysDemand[i]] === '' || item[keysDemand[i]] === false) {
        document.getElementById(keysDemand[i] + 'Form').focus();
        return;
      }
    }

    for (let pos = 0; pos < questions.length; pos++) {
      for (let j = 0; j < keysQuestion.length; j++) {
        if (questions[pos][keysQuestion[j]] === '' || questions[pos][keysQuestion[j]] === false || !questions[pos][keysQuestion[j]]) {
          (document.getElementById(keysQuestion[j] + 'Form' + pos) || document.getElementById(keysQuestion[j] + 'FormQuestion' + pos)).focus();
          return;
        }
  
        if (questions[pos].opcoes) {
          for (let k = 0; k < questions[pos].opcoes.length; k++) {
            if (questions[pos].opcoes[k] === '' || !questions[pos].opcoes[k]) {
              document.getElementById('Opções de resposta*opcoesForm' + k).focus(); 
              return;
            }
          }
        }
      }
    }

    setPopup(true);
  }

  function handleAddQuestion() {
    setQuestions([...questions, {
      tipoPergunta: '',
      pergunta: '',
      tema: '',
      idEstudoDemanda: doc ? doc.id : '',
    }]);
    setIdQuestions([...idQuestions, '']);
  }

  useEffect(() => {
    if (userCity) 
      setItem({ ...item, cidade: userCity });
  }, [userCity]);

  useEffect(() => {
    if (!template)
      handleQuestionsDemand();
    else
      handleTemplate();
  }, []);

  if (!questions)
    return <Loading />;
  
  return (
    <div className='EditInventory'>
      <div className='bodyEditInventory'>
        <TitleEditor action={action} type={'Estudo de Demanda'} />
        <div className='div_align_form_question'>
          <form className='form_edit_admin'>
            <HeaderForm />
            {
              fieldsEditDemand.map((field, index) => {
                return inputsComponents[field.component]({ term: field, item: item, setItem: setItem, doc: doc, userCity: userCity, startDate: 'dataIni', endDate: 'dataFim', keyName: 'DemandField' + index });
              })
            }
            { popup ? <VerifyPopup setVerifyPopup={setPopup} handleAction={doc ? handleEditDemand : handleAddDemand} title={`Deseja ${action} este Estudo de Demanda?`} message={'Ao realizar essas ações, elas serão gravadas na base de dados, e as novas informações poderão ser vistas posteriormente. Deseja continuar?'} /> : null }
          </form>
          { 
            item.publicado ? <WarningPopup message={'Como o Estudo de Demanda já está publicado, a edição de suas perguntas estão suspensas até o termino do estudo, para evitar respostas inconsistentes.'} type={'warning'} /> : null
          }
          {
            questions.map((ques, index) => {
              return (
                <form className='form_edit_admin form_questions_demand'>
                  <div className='div_align_form_question' id={'div_align_form_question' + index}>
                    <HeaderQuestion index={index} questions={questions} setQuestions={setQuestions} publish={item.publicado} deleteQuestions={deleteQuestions} setDeleteQuestions={setDeleteQuestions} idQuestions={idQuestions} setIdQuestions={setIdQuestions} />
                    {
                      fieldsQuestionsDemand.map((field, indexQues) => {
                        if (field.field !== 'opcoes' || (typesControl.includes(ques.tipoPergunta)))
                          return inputsComponents[field.component]({ term: field, item: questions, value: ques, setItem: setQuestions, userCity: userCity, index: index, options: ques.opcoes, publish: item.publicado, keyName: 'DemandQuestionsField' + index + indexQues, startDate: 'dataIni', endDate: 'dataFim' });
                      })
                    }
                  </div>
                </form>
              );
            })
          }
          {
            !item.publicado ? (
              <div className='div_add_new_question'>
                <div className='div_btn_add_question' onClick={() => handleAddQuestion()}>
                  <img src={AddIcon} alt='Ícone de adicionar' className='img_add_question' />
                  <span>Nova pergunta</span>
                </div>
              </div>
            ) : null
          }
          <div className='div_align_button_form_question'>
            <ButtonForm setEditPopup={setEditPopup} handleCheckFields={handleCheckFields} />
          </div>
        </div>
      </div>
    </div>
  );
}