import React, { useState, useEffect } from "react";

import '../../assets/css/InputComponents/inputCheckbox.css';

export default function InputCheckbox({ title, values, setValue, idValue, item, setItem, field, role, action }) {
  const [otherIsOpen, setOtherIsOpen] = useState(false);
  const [otherOption, setOtherOption] = useState(handleOtherOption());
  const [cache, setCache] = useState(handleOtherOption());
  const rolesControl = ['admin', 'masterAdmin', 'contentEditor', 'contentManager'];

  function handleOtherOption() {
    const checkTypes = ['pix', 'dinheiro', 'crédito', 'débito', 'vale refeição', 'boleto', 'inglês', 'coreano', 'mandarim', 'espanhol', 'italiano', 'alemão', 'francês', 'japonês'];

    let text = '';
    if (idValue === 'outro') {
      for (let i = 0; i < values.length; i++) {
        if (!checkTypes.includes(values[i]))
          text = text + (text !== '' ? ',' : '') + values[i];
      }
    }

    return text;
  }

  const handleAddValues = (seg) => {
    if (seg === 'outro') {
      handleOtherSeg(false);
    } else if (values) {
      const index = values.indexOf(seg);

      if (index !== -1) {
        const newVet = values.filter((val) => val !== seg);
        setValue(newVet);
        setItem({ ...item, [field]: newVet });
      } else {
        setValue([...values, seg]);
        setItem({ ...item, [field]: [...values, seg] });
      }
    } else {
      setItem({ ...item, [idValue]: !item[idValue] });
    }
  };

  function handleOtherSeg(isBegin) {
    if (!isBegin)
      handleAddValues(otherOption);

    if (!otherIsOpen) {
      document.getElementById('input_other_option' + idValue + field).style = 'width: 40%; border-bottom: 1px solid var(--sixthcolor);';
      document.getElementById('title_checkbox_input' + idValue + field).style = 'display: none';
      document.getElementById(idValue + field).checked = true;
    } else {
      document.getElementById('input_other_option' + idValue + field).style = 'width: -1px; border-bottom: none';
      document.getElementById('title_checkbox_input' + idValue + field).style = 'display: block';
      document.getElementById(idValue + field).checked = false;
      setOtherOption('');
      setCache('');
    }

    setOtherIsOpen(!otherIsOpen);
  }

  function handleChecked() {
    if (!values) return item[idValue];

    const index = values.indexOf(idValue);
    
    if (index !== -1 || otherIsOpen || (idValue === 'outro' && otherOption !== ''))
      return true;
    
    return false;
  }

  useEffect(() => {
    if (values && otherIsOpen) {
      const filteredValues = values.filter((val) => val !== cache);
      const newValues = [...filteredValues, otherOption];
      setValue(newValues);
      setItem({ ...item, [field]: newValues });
      setCache(otherOption);
    }
  }, [otherOption]);

  useEffect(() => {
    if (otherOption !== '')
      handleOtherSeg(true);
  }, []);

  return (
    <div>
      <div className='div_checkbox_input'>
        <label className='label_checkbox_input'>
          <input type='checkbox' id={idValue + field} checked={handleChecked()} onChange={(event) => handleAddValues(event.target.value) } value={idValue} />
          <span className='checkmark_input'></span>
        </label>
        <label htmlFor={idValue + field} className='title_checkbox_input' id={'title_checkbox_input' + idValue + field}>{title}</label>
        <input type='text' className='input_other_option' id={'input_other_option' + idValue + field} onChange={(e) => setOtherOption(e.target.value)} value={otherOption} />
      </div>
    </div>
  );
}