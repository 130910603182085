import React from 'react';
import { StyleSheet, css } from 'aphrodite';
import { Button, Modal } from '@material-ui/core';
import { Input } from 'reactstrap';
import AlertMessage from './Alert';

export default class ProfileModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      password: '',
      newPassword: '',
      email: '',
      result: '',
    };
  }

  handleChange = (name) => (event) => {
    this.setState({ [name]: event.target.value });
  }

  onConfirm = () => {
    const { password: hasNewPassword } = this.props;
    const { email, password, newPassword } = this.state;
    if (hasNewPassword) {
      this.props.onConfirm({ email: email, password: password, newPassword: newPassword }, (result) => {
        this.setState({ result: result });
      });
    } else {
      this.props.onConfirm({ email: email, password: password, newPassword: false }, (result) => {
        this.setState({ result: result });
      });
    }
  }

  render() {
    const { open, onCancel, password } = this.props;
    const { result } = this.state;
    return (
      <Modal style={{ height: '90px' }} open={open}>
        <div className={css(styles.container)}>
          <div className={css(styles.modalBody)}>
            <div>
              { !!result && <AlertMessage onToggle={() => { this.setState({ result: false }); }} color='danger' msg={result}/> }
              <div>
                <p style={{ marginTop: '10px', marginBottom: '0px' }}>Confirme seu email:</p>
                <Input
                  style={{ marginBottom: '10px' }}
                  onClick={(e) => { e.target.focus(); }}
                  type='email'
                  name='email'
                  onChange={this.handleChange('email')}
                />
              </div>
              {
                password ?
                  <p style={{ marginBottom: '0px' }}>Senha atual:</p>
                  :
                  <p style={{ marginBottom: '0px' }}>Confirme sua senha:</p>
              } 
              <Input
                style={{ marginBottom: '10px' }}
                onClick={(e) => { e.target.focus(); }}
                type='password'
                name='password'
                onChange={this.handleChange('password')}
              />
              { password &&
                <div>
                  <p style={{ marginTop: '10px', marginBottom: '0px' }}>Nova senha:</p>
                  <Input
                    style={{ marginBottom: '10px' }}
                    onClick={(e) => { e.target.focus(); }}
                    type='password'
                    name='newPassword'
                    onChange={this.handleChange('newPassword')}
                  />
                </div>
              }
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <Button className={css(styles.modalButton)} onClick={() => this.onConfirm() } >Atualizar</Button>
                <Button className={css(styles.modalButton, styles.cancelModalButton)} onClick={onCancel}>Cancelar</Button>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    );
  }
}

const styles = StyleSheet.create({
  cancelModalButton: {
    backgroundColor: '#6c757d',
  },  
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100vh',
    width: '100vw',
  },
  modalBody: {
    borderRadius: 30,
    padding: '10px',
    backgroundColor: 'white',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    width: '300px',
  },
  modalButton: {
    padding: '10px 8px',
    color: 'white',
    backgroundColor: '#db3d78',
    borderRadius: '25px',
  },
});
