import React, { useState, useEffect } from 'react';
import firebase from '../../config/Firebase';

// CSS import
import '../../assets/css/atractionList.css';

export default function ProjectList({ doc, handleEdit, handleDelete }) {
  const [diretriz, setDiretriz] = useState();
  const [programa, setPrograma] = useState();

  async function handleData() {
    if (doc.data().diretriz) {
      const docDir = await firebase.firestore().collection('PlanoDiretor_Diretrizes').doc(doc.data().diretriz).get();
      setDiretriz(docDir.data().titulo);
    } else
      setDiretriz('Não há');

    if (doc.data().programa) {
      const docProg = await firebase.firestore().collection('PlanoDiretor_Programas').doc(doc.data().programa).get();
      setPrograma(docProg.data().titulo);
    } else
      setDiretriz('Não há');
  }

  useEffect(() => {
    handleData();
  }, []);

  if (!diretriz || !programa) {
    return <></>;
  }

  return (
    <tr className="row_table_inventory body_table">
      <td className="title_table_inventory">{doc.data().titulo}</td>
      <td className="title_table_inventory">{programa}</td>
      <td className="title_table_inventory">{doc.data().resultadoEsperado}</td>
      <td className="title_table_inventory">{doc.data().responsaveis}</td>
      <td className="title_table_inventory">{doc.data().custoAproximado}</td>
      <td className="title_table_inventory">{doc.data().status}</td>
      <td className="column_edit_table_inventory">
        <div className='div_mod_inventory'>
          <button className='btn_mod_adm' onClick={() => handleEdit(doc)}>Editar</button>
          <button className='btn_mod_adm' onClick={() => handleDelete(doc)}>Excluir</button>
        </div>
      </td>
    </tr>
  );
}