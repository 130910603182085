import React, { useState } from "react";

import "../assets/css/RankingComponents/EditInfoTableScore.css";

import EditIcon from '../assets/imgs/edit_icon_list.svg';
import CorrectIcon from '../assets/imgs/correct_icon.svg';
import WrongIcon from '../assets/imgs/wrong_icon.svg';

export default function EditInfoTableScore({ info, index, setScore, functionUpdateScore, keyScore, updateValueCity }) {
  const [editMode, setEditMode] = useState(false);
  const [scoreEdit, setScoreEdit] = useState(info.score);

  function filterAmount(number) { 
    setScoreEdit(number.replace("-", "").replace("e", "").replace("+", ""));
  }

  function editRankingSelect() {
    let fillScore;
    let field;
    let type;

    setScore((lastScore) => {
      const newScore = [...lastScore];
      const val = newScore[index].options.findIndex((element) => {
        return element === scoreEdit;
      });

      newScore[index] = {
        ...newScore[index],
        score: scoreEdit,
        currScore: newScore[index].pointType[val],
      };

      field = newScore[index].field;
      type = newScore[index].typeSave;
      fillScore = [...newScore];
      return newScore;
    });

    updateValueCity(scoreEdit, field, type);
    setEditMode(false);
    functionUpdateScore(keyScore, fillScore);
  }

  function editRankingAmount() {
    let fillScore;
    let field;
    let type;

    setScore((lastScore) => {
      const newScore = [...lastScore];

      newScore[index] = {
        ...newScore[index],
        score: Number(scoreEdit),
        currScore: calculateCurrScore(newScore[index].scoreType, newScore[index].pointType, Number(scoreEdit), newScore[index].maxScore),
      }

      field = newScore[index].field;
      type = newScore[index].typeSave;
      fillScore = [...newScore];
      return newScore;
    })

    updateValueCity(Number(scoreEdit), field, type);
    setEditMode(false);
    functionUpdateScore(keyScore, fillScore);
  }
  
  function calculateCurrScore(scoreType, pointType, score, maxScore) {
    for (let index = 0; index < scoreType.length; index++) {
      if (score < scoreType[index])
        return pointType[index - 1];
  
      if (score === scoreType[index])
        return pointType[index];
    }

    return maxScore;
  }

  if (info.formatEdit === 'select') {
    return (
      <div className='edit_info_table_score'>
        {
          editMode ?
            <>
              <select defaultValue={info.score} onChange={(e) => setScoreEdit(e.target.value)}>
                {
                  info.options.map((op, index) => {
                    return <option value={op} key={info.title + op + index}>{op}</option>
                  })
                }
              </select>
              <div className="aling_icons_edit_info_table_score">
                <img src={CorrectIcon} alt="Ícone de salvar, para gravar a informação" className="icon_edit_info_table_score" onClick={() => editRankingSelect()} />
                <img src={WrongIcon} alt="Ícone de cancelar, para parar de editar a informação" onClick={() => setEditMode(false)} className="icon_edit_info_table_score"/>
              </div>
            </>
          :
          <>
            <span>{info.score}</span>
            <img src={EditIcon} alt="Ícone de Edição de campo" onClick={() => setEditMode(true)} className="icon_edit_info_table_score" />
          </>
        }
      </div>
    )
  }

  if (info.formatEdit === 'amount') {
    return (
      <div className='edit_info_table_score'>
        {
          editMode ?
            <>
              <input type="number" value={scoreEdit} onChange={(e) => filterAmount(e.target.value)} min={0} />
              <div className="aling_icons_edit_info_table_score">
                <img src={CorrectIcon} alt="Ícone de salvar, para gravar a informação" className="icon_edit_info_table_score" onClick={() => editRankingAmount()} />
                <img src={WrongIcon} alt="Ícone de cancelar, para parar de editar a informação" onClick={() => setEditMode(false)} className="icon_edit_info_table_score"/>
              </div>
            </>
          :
          <>
            <span>{info.score}</span>
            <img src={EditIcon} alt="Ícone de Edição de campo" onClick={() => setEditMode(true)} className="icon_edit_info_table_score" />
          </>
        }
      </div>
    )
  }
}