import React, { useState } from 'react';

// Components imports
import { InfoInventoryRow } from './InfoInventoryRow';
import CalendarPopup from '../CalendarPopup';

// CSS import
import '../../assets/css/Dashboard/cardInventoryAdmin.css';

export function CardInventoryAdmin({ dateObj, setDateObj, placesInfo, total }) {
  const [datePopup, setDatePopup] = useState(false);
  const [dateType, setDateType] = useState();

  function handleDateText(txtDate) {
    const date = new Date(txtDate);

    return `${date.getMonth() + 1}/${date.getFullYear()}`;
  }

  function handleCalendar(type) {
    setDateType(type);
    setDatePopup(true);
  }

  return (
    <div className='CardInventoryAdmin card_container_dash'>
      <div className='div_headear_carddashboard'>
        <h1>Distribuição do Inventário</h1>
      </div>
      {
        datePopup ? <CalendarPopup type={dateType} date={dateObj} setDate={setDateObj} setPopup={setDatePopup}/> : null
      }
      <div className='div_body_cardinvertory'>
        <table className='table_cardinventory'>
          <tbody>
            <tr>
              <th className='td_cardinventory'>Item</th>
              <th className='td_cardinventory_desc'>Descrição</th>
              <th className='td_value_cardinventory td_date' onClick={() => handleCalendar('initialDate')}>
                { handleDateText(dateObj.initialDate) }
              </th>
              <th className='td_value_cardinventory td_date' onClick={() => handleCalendar('finalDate')}>
                { handleDateText(dateObj.finalDate) }
              </th>
            </tr>
            {
              Object.keys(placesInfo).map((field, index) => {
                return <InfoInventoryRow info={placesInfo[field]} key={index} />;
              })
            }
            <tr>
              <td className='td_cardinventory'></td>
              <td className='td_cardinventory_desc'>TOTAL</td>
              <td className='td_cardinventory'>{total.totalLastMonth}</td>
              <td className='td_cardinventory'>{total.totalCurrMonth}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
}