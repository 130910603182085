/* eslint-disable no-else-return */
import React from 'react';

// CSS Import
import '../../assets/css/Dashboard/cardInventoryAdmin.css';

// Assets imports
import Increase from '../../assets/imgs/pins/aumento_icon.JPG';
import Reduce from '../../assets/imgs/pins/reducao_icon.JPG';

export function InfoInventoryRow({ info }) {
  function handleValues() {
    const value = (info.currentMonth - info.lastMonth);

    if (value === 0)
      return '';
    else if (value > 0)
      return `(+${value})`;
    
    return `(${value})`;
  }

  return (
    <tr>
        <td className='td_cardinventory'><img className='img_cardinventory' src={info.icon} alt='Icone de um tipo de inventário'/></td>
        <td className='td_cardinventory_desc'>{info.desc}</td>
        <td className='td_cardinventory'>{info.lastMonth}</td>
        <td className='td_cardinventory'>{info.currentMonth + handleValues()}{info.lastMonth !== info.currentMonth ? <img className='img_situation_icon_dashboard' alt='icone de uma seta' src={info.lastMonth > info.currentMonth ? Reduce : Increase} /> : ''}</td>
    </tr>
  );
}