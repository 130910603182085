import React, { useEffect, useState } from 'react';

import '../assets/cardScoreCalculator.css';
import HandleSize from '../assets/imgs/handle_size_icon.JPG';

import CardInfoCalculator from './CardInfoCalculator';
import SpinButton from './SpinButton';

import EditInfoTableScore from './EditInfoTableScore';

export default function CardScoreCalculator({ title, data, dataRankSum, setScore, functionUpdateScore, keyScore, updateValueCity }) {
  const [maxPontuation, setMaxPontuation] = useState();
  const [totalPontuation, setTotalPontuation] = useState();

  function handlePontuation() {
    setTotalPontuation(dataRankSum.curr);
    setMaxPontuation(dataRankSum.max);
  }

  function handleSizeCardReport() {
    const isOpen = document.getElementById('div_table_score_calculator' + title).clientHeight !== 0;
    const heigthUl = document.getElementById('table_score_calculator' + title).clientHeight;

    if (isOpen)
      document.getElementById('div_table_score_calculator' + title).style = 'height: 0px; padding-top: 0;';
    else
      document.getElementById('div_table_score_calculator' + title).style = 'height: fit-content; padding-top: 1rem;';
  }

  useEffect(() => {
    setTotalPontuation();
    setMaxPontuation();
    if (data)
      handlePontuation();
  }, [data, dataRankSum]);

  return (
    <div className="CardScoreCalculator">                    
      <h2 className='title_card_calculator'>{title}</h2>
      <div className="ibox-tools">
        <a className="collapse-link">
          <i className="fa fa-chevron-up"></i>
        </a>
      </div>
      <div className="div_handle_size_cards_score">
        <button type='button' className='btn_handle_size_calculator' onClick={() => handleSizeCardReport()}><img src={HandleSize} alt='Icone de uma seta para baixo' className='img_handle_size_calculator img_handle_size_score'/></button>
      </div>
      <div className='div_align_scores_ranking'>
        <CardInfoCalculator bg={'rgb(236, 68, 141)'} title={'Pontuação Atual'} score={totalPontuation === undefined ? <SpinButton /> : totalPontuation} type={'current'} />
        <CardInfoCalculator bg={'#f8ac59'} title={'Pontuação Máxima'} score={maxPontuation === undefined ? <SpinButton /> : maxPontuation} type={'max'} />
      </div>
      <div className='div_table_score_calculator' id={'div_table_score_calculator' + title} >
        <table className='table_score_calculator' id={'table_score_calculator' + title}>
          <thead>
            <tr className='row_table_score_calculator title_rows_table_score'>
              <th className='title_table_score short_td_table_score'>Item</th>
              <th className='title_table_score large_td_table_score'>Descrição</th>
              <th className='title_table_score score_type_table'>Possui/Quantidade</th>
              <th className='title_table_score default_td_table_score'>Pontuação</th>
              <th className='title_table_score medium_td_table_score'>Pontuação Máxima</th>
              <th className='title_table_score default_td_table_score'>Indicação</th>
            </tr>
          </thead>
          {
            data ? data.map((obj, index) => {
              return (
                <tbody key={index}>
                  <tr className={`row_table_score_calculator sub_rows_table_score ${(index % 2) ? '' : 'bg_gray'}`}>
                    <td className='info_table_score short_td_table_score'>{index + 1}</td>
                    <td className='info_table_score large_td_table_score'>{obj.title}</td>
                    <td className='info_table_score score_type_table'>
                      <div className='amount_table_score'>
                        {
                          obj.editType ?
                          <EditInfoTableScore info={obj} index={index} setScore={setScore} functionUpdateScore={functionUpdateScore} keyScore={keyScore} updateValueCity={updateValueCity} />
                          : <span>{obj.score}</span>
                        }
                      </div>
                    </td>
                    <td className='info_table_score default_td_table_score'>{obj.currScore}</td>
                    <td className='info_table_score medium_td_table_score'>{obj.maxScore}</td>
                    <td className='info_table_score default_td_table_score'>
                      <div className='div_pie_chart_calculator' style={{ background: `conic-gradient(var(--ninethcolor) 0.00% ${((obj.currScore / obj.maxScore) * 100)}%, #d7d7d7 ${((obj.currScore / obj.maxScore) * 100)}%)` }} />
                    </td>
                  </tr>
                </tbody>
              )
            }) : null
          }
        </table>
      </div>
    </div>
  );
}