import React from 'react';

// CSS import
import '../../assets/css/InputComponents/textareaInput.css';

export default function TextareaReview({ title, field, setMessage, placeholder, explanation }) {
  return (
    <div className="TextareaInput">   
      <div className="div_align_textareaInput">
        <div className='div_label_input_textarea'>
          <label htmlFor={field} className="label_textarea_input">
            <strong>{title}</strong>
          </label>
        </div>
        <div className='div_align_explanation_input div_align_explanation_textarea'>
          <textarea className='textarea_input_comp' id={field} onChange={(e) => setMessage(e.target.value)} placeholder={placeholder || ''} />
          <div className='div_explanation_input div_explanation_textarea'>
            <p className='subtext_explanation_input'>{explanation}</p>
          </div>
        </div>
      </div> 
    </div>
  );
}