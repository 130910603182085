export const fieldsEditRegions = [
  {    
    title: 'Nome da Região*',
    field: 'name',
    type: 'text',
    boolType: false,
    component: 'text',
    placeholder: '',
  },
  {    
    title: 'Geolocalização',
    field: 'location',
    boolType: false,
    component: 'location',
    placeholder: '[ Latitude, Longitude ]',
    explanation: 'Localizar no Google Maps, clicar com botão direito e colar os dados. Ex: -23.22222, -47.44444',
  },
  {    
    title: 'Zoom Desktop',
    field: 'desktopDefaultZoom',
    type: 'number',
    boolType: false,
    component: 'text',
    placeholder: '',
  },
  {    
    title: 'Zoom Mobile',
    field: 'mobileDefaultZoom',
    type: 'number',
    boolType: false,
    component: 'text',
    placeholder: '',
  },
  {    
    title: 'Imagem Padrão da Região',
    type: 'file',
    field: 'defaultImage',
    boolType: false,
    component: 'singleImage',
    explanation: 'Imagem padrão com as cidade da região, as entradas ou locais',
    typeImage: 'regionImage',
  },
  {    
    title: 'Imagem PIN',
    type: 'file',
    field: 'ImagemPinCidade',
    boolType: false,
    component: 'singleImage',
    explanation: '',
    typeImage: 'pinImage',
  },
  {    
    title: 'Selecione as Cidades da Região',
    field: 'neighborhood',
    component: 'selectMultiple',
    explanation: 'Selecione as Cidade que fazem parte dessa região, que estão dentro do território da região',
  },
  {    
    title: 'Descrição Curta*',
    field: 'shortDescr',
    type: 'textarea',
    boolType: false,
    component: 'textarea',
    placeholder: '',
    explanation: 'Uma breve descrição sobre a região',
  },
  {    
    title: 'Descrição Longa*',
    field: 'longDescr',
    type: 'textarea',
    boolType: false,
    component: 'textarea',
    placeholder: '',
    explanation: 'Uma descrição mais detalhada sobre a região',
  },
  {    
    title: 'Ativo*',
    field: 'Active',
    type: 'select',
    boolType: true,
    component: 'select',
  },
];