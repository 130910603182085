import React from 'react';
import { GoogleLoginButton } from 'react-social-login-buttons';
import Firebase from 'firebase';
import firebase from '../config/Firebase';
import { Event } from '../config/TrackingGA';

export default class GoogleLogin extends React.Component {
  handleGoogleLogin = async () => {
    const provider = new Firebase.auth.GoogleAuthProvider();
    provider.setCustomParameters({ display: 'popup' });

    firebase.auth().signInWithPopup(provider).then((result) => {
      const userId = firebase.auth().currentUser.uid;
      const imageUrl = result.additionalUserInfo.profile.picture;
      firebase.firestore().collection('users').doc(userId).get()
        .then((doc) => {
          if (doc.exists) {
            this.props.callback('complete');
          } else {
            firebase.auth().currentUser.updateProfile({
              displayName: result.user.displayName,
            });
            firebase.firestore().collection('users').doc(userId).set({
              name: result.user.displayName,
              email: result.user.email,
              image: imageUrl,
              completedSignUp: false,
            })
              .then((docRef) => this.props.callback('incomplete'));
          }
        });
    }).catch((error) => { 
      if (error.code === 'auth/account-exists-with-different-credential')
        this.props.callback('Conta já existe mas com outro provedor!');
      else
        this.props.callback(error.message);
    });
    Event('User', 'Login', 'GoogleLogin');
  }

  render() {
    const { message } = this.props;
    return (
      <div>
        <GoogleLoginButton style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', fontSize: '17px', width: '320px', boxShaddow: 'none' }} onClick={ this.handleGoogleLogin } >
          <span>{message}</span>
        </GoogleLoginButton>
      </div>
    );
  }
}
