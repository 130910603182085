import React, { useState, useEffect } from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

// Firebase import
// eslint-disable-next-line import/no-extraneous-dependencies
import Firebase from 'firebase';
import firebase from '../../config/Firebase';

// Assets imports
import OrdenateIcon from '../../assets/imgs/ordenate_icon.png';
import TrashIcon from '../../assets/imgs/trash_icon.svg';

// Components imports
import MapInputAttraction from '../MapInputAttraction';
import LoadingComponent from '../LoadingComponent';

export default function WaypointMap({ field, value, setValue, doc, objectPend, userCity, explanation }) {
  const [geolocation, setGeolocation] = useState(handleGeolocationItem(field));
  const [center, setCenter] = useState();
  const [loadingMap, setLoadingMap] = useState(true);
  const [mapComp, setMapComp] = useState();
  const [markers, setMarkers] = useState([]);
  const [waypointTitleList, setWaypointTitleList] = useState(() => {
    return value.waypointTitulo || [];
  });

  async function handleLocationCity() {
    setLoadingMap(true);

    if (!userCity) {
      const centerSP = new Firebase.firestore.GeoPoint(-23.543559, -46.604112);

      setCenter(centerSP);
    } else {
      const docs = await firebase.firestore().collection('Cities')
        .where('name', '==', userCity)
        .get();

      setCenter(docs.docs[0].data().location);
    }

    setLoadingMap(false);
  }

  function handleGeolocationItem(fieldGeo) {
    if (objectPend)
      return objectPend.currDoc[fieldGeo] ? `${objectPend.currDoc[fieldGeo].latitude}, ${objectPend.currDoc[fieldGeo].longitude}` : '';
    return doc && doc[fieldGeo] ? `${doc[fieldGeo].latitude}, ${doc[fieldGeo].longitude}` : '';
  }

  function validateCord(cord) {
    if (cord >= -180 && cord <= 180) return true;

    return false;
  }

  const handleGeolocationList = (markers, waypointTitleList) => {
    setValue({ ...value, 
      [field]: markers.map((waypoint) => {
        const lat = waypoint.getPosition().lat();
        const lng = waypoint.getPosition().lng();
        const list = [lat, lng];
        if (list.length === 2 && validateCord(Number(list[0])) && validateCord(Number(list[1]))) {
          return new Firebase.firestore.GeoPoint(Number(list[0]), Number(list[1]));
        }
        return null;
      }).filter(Boolean),
      waypointTitulo: waypointTitleList });
  };

  const handleTitleChange = (e, index) => {
    const list = [...waypointTitleList];
    list[index] = e.target.value;
    setWaypointTitleList(list);
  };

  const handleDeleteMarker = (e, lat, lng) => {
    e.preventDefault();
    const indexToRemove = markers.findIndex((waypoint) => waypoint.getPosition().lat() === lat && waypoint.getPosition().lng() === lng);

    if (indexToRemove !== -1) {
      markers[indexToRemove].setMap(null);
      markers[indexToRemove].setVisible(false);
      const updatedTitleList = waypointTitleList.filter((_, index) => index !== indexToRemove);
      setWaypointTitleList(updatedTitleList);
      const updatedMarkers = markers.filter((_, index) => index !== indexToRemove);
      setMarkers(updatedMarkers);
    }
  };
  
  useEffect(() => {
    handleLocationCity();
  }, [userCity]);

  useEffect(() => {
    if (markers.length !== 0 || value.waypoint.length <= 0) {
      handleGeolocationList(markers, waypointTitleList);
    }
  }, [markers, waypointTitleList]);

  if (!center || loadingMap)
    return <LoadingComponent />;

  return (
    <DragDropContext onDragEnd={(waypoint) => {
      const srcI = waypoint.source.index;
      const desI = waypoint.destination?.index;
      if (desI != null) {
        const updatedMarkers = [...markers];
        updatedMarkers.splice(desI, 0, updatedMarkers.splice(srcI, 1)[0]);
        setMarkers(updatedMarkers);

        const updatedTitles = [...waypointTitleList];
        updatedTitles.splice(desI, 0, updatedTitles.splice(srcI, 1)[0]);
        setWaypointTitleList(updatedTitles);

        const updatedValue = { ...value };
        updatedValue[field].splice(desI, 0, updatedValue[field].splice(srcI, 1)[0]);
        setValue(updatedValue);

        const updatedWaypointTitulo = [...value.waypointTitulo];
        updatedWaypointTitulo.splice(desI, 0, updatedWaypointTitulo.splice(srcI, 1)[0]);
        setValue({ ...value, waypointTitulo: updatedWaypointTitulo });
      }
    }
    }>
      <MapInputAttraction center={center} id={field} setValue={setGeolocation} mapComp={mapComp} markers={markers} setMarkers={setMarkers} value={value} setWaypointTitleList={setWaypointTitleList}/>
      <p className='subtext_explanation_waypoint_input'>{explanation}</p>
        <Droppable droppableId="droppable-1">
        {(provided, _) => (
          <div ref={provided.innerRef} {...provided.droppableProps}>
          {markers.length > 0 && (
            <div className="div_align_cabecalho_waypoint">
              <div className='div_cabecalho_waypoint short_waypoint'>
                <span>Latitude</span>
              </div>
              <div className='div_cabecalho_waypoint short_waypoint'>
                <span>Longitude</span>
              </div>
              <div className='div_cabecalho_waypoint'>
                <span>Titulo</span>
              </div>
            </div>
          )}
          {markers.map((marker, index) => (
            <Draggable className="div_align_input_duration" key={index} draggableId={'draggable' + index} index={index}>
              {(provided, snapshot) => (
                <div className='div_align_input_waypoint' ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}
                  style={{ ...provided.draggableProps.style, ...provided.dragHandleProps.style, boxShadow: snapshot.isDragging ? '0 0 .4rem #666' : 'none' }}>
                  <div className="align_btn">
                    <img src={OrdenateIcon} alt='Ordenar' className="btn_ordenate"/>
                  </div>
                  <div className="align_lat_input">
                    <span>{marker.getPosition().lat().toFixed(2)}</span>
                  </div>
                  <div className="align_long_input">
                    <span>{marker.getPosition().lng().toFixed(3)}</span>
                  </div>
                  <div className="align_title_input_waypoint">
                    <input className="align_input_waypoint" type="text" id="waypointForm" value={waypointTitleList[index]} onChange={(e) => handleTitleChange(e, index)}/>
                  </div>
                  <div className="align_waypoint_btn">
                    <button type="button" onClick={(e) => handleDeleteMarker(e, marker.getPosition().lat(), marker.getPosition().lng())} className="remove_waypoint_btn">
                      <img src={TrashIcon} alt="Ícone de uma lixeira para deletar um waypoint" />
                    </button>
                  </div>
                </div>
              )}
            </Draggable>
          ))}
          {provided.placeholder}
        </div>
        )}    
      </Droppable>
    </DragDropContext>
  );
}