import React, { useContext, useEffect, useState } from 'react';
import firebase from '../../config/Firebase';
import '../../css/InventoryAdmin.css';

import PendenciesList from '../../component/PendenciesList';
import ReviewPendecy from '../../component/ReviewPendecy';
import { handleSetDoc } from '../../utils/pendencyManager';
import VerifyPopup from '../../component/VerifyPopup';
import NoResultsComponent from '../../component/NoResultsComponent';

import InputCheckboxCustom from '../../component/InputComponents/InputCheckBoxCustom';

// Assets imports
import SearchIcon from '../../assets/imgs/search_icon.svg';

import Loading from '../../component/Loading';

import { MyContext } from '../../component/SidebarAdmin';
import { RodapeAdmin } from '../../component/RodapeAdmin';

export default function PendentAdmin() {
  const [pendencies, setPendencies] = useState();
  const [filter, setFilter] = useState('action');
  const [search, setSearch] = useState('');
  const [action, setAction] = useState('Aprovar');
  const [filteredPlaces, setFilteredPlaces] = useState();
  const { city, setNumPendency, role } = useContext(MyContext);
  const [selectedItem, setSelectedItem] = useState();
  const [reviewPopup, setReviewPopup] = useState(false);
  const [verifyPopup, setVerifyPopup] = useState(false);
  const [listPend, setListPend] = useState({
    ids: [],
    values: [],
  });

  const buildQuery = () => {
    const currFilter = filter === 'searchBar' ? 'action' : filter;

    const docs = firebase.firestore()
      .collection('Pendencies')
      .orderBy(currFilter);

    return docs;
  };

  const getFirstPage = async () => {
    const newDocs = await buildQuery().get();

    setPendencies(newDocs);
    setFilteredPlaces(newDocs);
  };

  async function handlePendencies(avaliation) {
    const message = avaliation ? '' : 'Solicitação Recusada. Existe algum campo preenchido de maneira incorreta, por esse motivo sua solitação de documento foi negada. Entre em contato conosco para sanar dúvidas.';

    for (let i = 0; i < listPend.values.length; i++) {
      // eslint-disable-next-line no-await-in-loop
      await handleSetDoc(listPend.values[i], message, avaliation, setNumPendency, setReviewPopup, 'Lote', (i === (listPend.values.length - 1)));
    }
    
    setListPend({
      ids: [],
      values: [],
    });
    
    handleUnCheckedPendencies();
    setVerifyPopup(false);
    
    setPendencies();
    setFilteredPlaces();

    getFirstPage();
  }

  useEffect(() => {
    getFirstPage();
    
    if (!reviewPopup)
      setSelectedItem(undefined);

    window.scrollTo(0, 0);
  }, [city, reviewPopup, filter]);

  useEffect(() => {
    setListPend({
      ids: [],
      values: [],
    });
  }, [city, reviewPopup]);

  useEffect(() => {
    handleShowButtonsPend();
  }, [listPend]);

  const searchInventoryText = (doc) => {
    if (search === '') {
      return true;
    }
    
    const isInAction = doc.data().action.toLowerCase().includes(search.toLowerCase());
    const isInType = doc.data().type.toLowerCase().includes(search.toLowerCase());

    return isInAction || isInType;
  };

  const handleSearch = () => {
    if (pendencies.docs) {
      let filteredPlaces = pendencies.docs.filter((doc) => searchInventoryText(doc));
      filteredPlaces = { docs: filteredPlaces };
      setFilteredPlaces(filteredPlaces);
    }
  };

  const handleEdit = (doc) => {
    setSelectedItem(doc); 
    setReviewPopup(true);
  };

  function handleAddDocList(doc) {
    const arrayId = listPend.ids;
    const arrayVal = listPend.values;

    const index = arrayId.indexOf(doc.id);
    if (index !== -1) {
      arrayId.splice(index, 1);
      arrayVal.splice(index, 1);
    } else {
      arrayId.push(doc.id);
      arrayVal.push(doc);
    }

    setListPend({
      ids: arrayId,
      values: arrayVal,
    });
  }

  function handleSetPendencies(e) {
    const isChecked = document.getElementById('check_all_pendencies').checked;
    const fields = document.getElementsByName('checkbox_pendencies');

    for (let i = 0; i < fields.length; i++) {
      if (isChecked && !fields[i].checked)
        fields[i].click();
      else if (!isChecked && fields[i].checked)
        fields[i].click();
    }
  }

  function handleUnCheckedPendencies() {
    const fields = document.getElementsByName('checkbox_pendencies');

    for (let i = 0; i < fields.length; i++) 
      fields[i].checked = false;
  }

  function handleShowButtonsPend() {
    if (!document.getElementById('btn_approve_pendency')) return;

    const isChecked = listPend.values.length > 0;

    if (!isChecked)
      document.getElementById('check_all_pendencies').checked = isChecked;

    document.getElementById('div_review_manyPendencies').style = `opacity: ${isChecked ? 1 : 0};`;
    document.getElementById('div_select_all_pendencies').style = `opacity: ${isChecked ? 1 : 0};`;
  }

  if (!pendencies) {
    return <Loading />;
  }

  return (
    <div className='InventarioAdmin'>
      {reviewPopup ? <ReviewPendecy pendency={selectedItem} setEditPopup={setReviewPopup} setNumPendency={setNumPendency} city={city} role={role} /> : 
        (<div className='body_inventory_adm'>
        <div className='div_title_inventory_adm'>
          <h1>Pendências</h1>
        </div>
        <div className="hr-line-dashed"></div>
        <div className='div_align_pendencies'>
          <div className='div_browse_inventory_adm'>
            <input type='text' className='input_browse_inventory' onChange={(e) => { setSearch(e.target.value); handleSearch(); }} placeholder='Buscar' />
            <button type="button" className='btn_browse_inventory_adm' disabled>
              <img src={SearchIcon} alt='Icone de pesquisa' className='search_icon_admin'/>
            </button>
          </div>
          <div className='div_review_manyPendencies' id='div_review_manyPendencies'>
            <p>{listPend.ids.length} Pendências Selecionadas</p>
            <button type='button' id='btn_approve_pendency' disabled={listPend.values.length === 0} onClick={() => { setAction('Aprovar'); setVerifyPopup(true); }}>Aprovar</button>
            <button type='button' id='btn_refuse_pendency' disabled={listPend.values.length === 0} onClick={() => { setAction('Recusar'); setVerifyPopup(true); }}>Recusar</button>
          </div>
        </div>
        {
          filteredPlaces && filteredPlaces.docs && filteredPlaces.docs.length > 0 ? (
          <div className='div_table_inventory_adm visible-print-inline-block'>
            <table className='table_inventory_adm'>
              <thead>
                <tr className="row_table_inventory th_table_admin">
                  <td className='column_check_table_inventory th_table_admin'>
                    <div className='div_select_all_pendencies' id='div_select_all_pendencies'>
                      <label htmlFor='check_all_pendencies' className='label_check_all_pendencies'>Todas</label>
                      <InputCheckboxCustom handleAction={handleSetPendencies} listPend={listPend} nameCheck={''} />
                    </div>
                  </td>
                  <td className='column_title_table_inventory th_table_admin' onClick={() => setFilter('type')}>Tipo</td>
                  <td className='column_title_table_inventory th_table_admin'>Titulo</td>
                  <td className='column_title_table_inventory th_table_admin' onClick={() => setFilter('city')}>Cidade</td>
                  <td className='column_title_table_inventory th_table_admin' onClick={() => setFilter('action')}>Ação</td>
                  <td className='column_title_table_inventory th_table_admin' onClick={() => setFilter('requestor')}>Solicitante</td>
                  <td className='column_title_table_inventory th_table_admin' onClick={() => setFilter('createDate')} >Data de solicitação</td>
                  <td className='column_table_inventory th_table_admin'></td>
                </tr>
              </thead>
              <tbody>
                {
                  filteredPlaces.docs.map((doc) => {
                    return <PendenciesList doc={doc} key={doc.id} handleEdit={handleEdit} handleAddDocList={handleAddDocList} />;
                  })
                }
              </tbody>
            </table>
          </div>) : <NoResultsComponent title={'Pendências'} search={search} />
        }
        { verifyPopup ? <VerifyPopup setVerifyPopup={setVerifyPopup} doc={action === 'Aprovar'} action={action} handleAction={handlePendencies} type={'Pendências'} message={true} /> : null} 
      </div>)}
    </div>
  );
}