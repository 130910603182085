import React from 'react';
import firebase from '../config/Firebase';
import { store } from '../redux/store';
import { TOGGLE_LOG_OUT_FLAG } from '../redux/actionTypes';

const withAuthentication = (WrappedComponent) => {
  class WithAuthentication extends React.Component {
    _isMounted = false;

    state = {
      providerData: [],
    };

    componentWillUnmount() {
      this._isMounted = false; // Indica que o componente vai ser desmontado
    }

    componentDidMount() {
      this._isMounted = true; // Indica que o componente foi montado
      
      firebase.auth().onAuthStateChanged((user) => {
        // Muda os estados do componente somente se ele estiver montado
        if (this._isMounted) {
          if (user) {
            const socialMediaUser = user.providerData[0].providerId === 'facebook.com' || user.providerData[0].providerId === 'google.com';
            // Usuários do face não precisam verificar o email
            if (socialMediaUser) {
              firebase.firestore().collection('users').doc(user.uid).get()
                .then((doc) => {
                  if (doc.data().completedSignUp)
                    this.setState({ providerData: user.providerData });
                  else {
                    alert('Termine o cadastro para continuar!');
                    this.props.history.push('/signup-with-social-media');
                  }
                });
            } else {
              this.setState({ providerData: user.providerData });
            }
          } else {
            // Se o usuário não tiver feito logOut, dispara o alerta
            if (!store.getState().user.logedOut) {
              alert('Must be authenticated');
            } else {
              // Indica que o usuário já terminou o logOut
              store.dispatch({ type: TOGGLE_LOG_OUT_FLAG });
            }
            this.props.history.push('/');
          }
        }
      });
    }

    render() {
      return this.state.providerData.length > 0 ? (
        <WrappedComponent
          {...this.props}
          providerData={this.state.providerData}
        />
      ) : (
        <p>Loading...</p>
      );
    }
  }

  return WithAuthentication;
};

export default withAuthentication;
