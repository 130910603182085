import React from "react";
// eslint-disable-next-line import/no-extraneous-dependencies
import { Map, GoogleApiWrapper, HeatMap } from 'google-maps-react';

// CSS Import
import '../../assets/css/Dashboard/mapCardHeatmap.css';

class MapComponent extends React.Component {
  render() {
    return (
      <>
        <Map
          google={this.props.google}
          zoom={12}
          initialCenter={this.props.center}
          style={{ width: '90%', height: '100%', margin: '0 auto', alignSelf: 'center', justifySelf: 'center' }}
          className={'map_container'}
          disableDefaultUI={true}
        >
          <HeatMap
            positions={this.props.data}
            opacity={1}
            radius={20}
            style={{ width: '100vw', height: '40vh', border: '1px solid black' }}
          />
        </Map>
      </>
    );
  }
}

export default GoogleApiWrapper({
  apiKey: 'AIzaSyDd3qwHtsqLlv_0GuVzOOrtZ6-XvZRifEc',
  libraries: ['places', 'visualization'],
})(MapComponent);
