import React, { useEffect, useState } from 'react';

// CSS import
import '../../assets/css/Dashboard/cardDashboard.css'

// Assets imports
import ArrowDown from '../../assets/imgs/arrow_down.svg';

export function CardDashboard({ setor, stats }) {
  // Quantities's Percentages
  const [porcents, setPorcents] = useState({
    qtdAces: 0,
    acessPorcent: 0,
    qtdAnimal: 0,
    aniPorcent: 0,
    qtdCad: 0,
    cadPorcent: 0,
    qtdCartao: 0,
    cartPorcent: 0,
    qtdIdioma: 0,
    idiPorcent: 0,
    qtdUhs: 0,
    uhsPorcent: 0,
  });
  const [capacidade, setCapacidade] = useState(0);
  const [statusCard, setStatusCard] = useState(true);

  function handleStats() {
    const qtdStats = {
      qtdAces: 0,
      acessPorcent: 0,
      qtdAnimal: 0,
      aniPorcent: 0,
      qtdCad: 0,
      cadPorcent: 0,
      qtdCartao: 0,
      cartPorcent: 0,
      qtdIdioma: 0,
      idiPorcent: 0,
      qtdUhs: 0,
      uhsPorcent: 0,
    };

    let cap = 0;

    stats.map((doc) => {
      if (doc.Cadastur)
        qtdStats.qtdCad++;
      if (doc.Acessibilidade && doc.Acessibilidade !== 'não')
        qtdStats.qtdAces++;
      if (doc['Aceita Animais'] && doc['Aceita Animais'] !== 'não')
        qtdStats.qtdAnimal++;
      if (doc['Aceita Cartões'] && doc['Aceita Cartões'] !== 'não')
        qtdStats.qtdCartao++;
      if (doc['Atendimento Idioma Estrangeiro'] && doc['Atendimento Idioma Estrangeiro'] !== 'não')
        qtdStats.qtdIdioma++;
      if (doc.UHS || doc.UHs)
        qtdStats.qtdUhs += doc.UHS || doc.UHs;
      if (doc['Capacidade Individual'])
        cap += doc['Capacidade Individual'];
    });

    if (stats.length > 0) {
      qtdStats.acessPorcent = ((qtdStats.qtdAces / stats.length) * 100).toFixed();
      qtdStats.aniPorcent = ((qtdStats.qtdAnimal / stats.length) * 100).toFixed();
      qtdStats.cadPorcent = ((qtdStats.qtdCad / stats.length) * 100).toFixed();
      qtdStats.cartPorcent = ((qtdStats.qtdCartao / stats.length) * 100).toFixed();
      qtdStats.idiPorcent = ((qtdStats.qtdIdioma / stats.length) * 100).toFixed();
      qtdStats.uhsPorcent = ((qtdStats.qtdUhs / stats.length) * 100).toFixed();
    }

    setCapacidade(cap);
    setPorcents(qtdStats);
  }

  function handleSizeCard() {
    setStatusCard(!statusCard);
    if (!statusCard) {
      document.getElementById('div' + setor + 'Card').style = 'height: 80vh;';
      setTimeout(() => {
        document.getElementById(setor + 'CardHeader').style = 'height: 10%; border-bottom: 2px solid #f5f5f5;';
        document.getElementById(setor + 'Card').style = 'display: flex';
      }, 150);
    } else {
      document.getElementById('div' + setor + 'Card').style = 'height: 8.5vh;';
      setTimeout(() => {
        document.getElementById(setor + 'CardHeader').style = 'height: 100%; border-bottom: 0;';
        document.getElementById(setor + 'Card').style = 'display: none';
      }, 150);
    }

  }

  useEffect(() => {
    handleStats();
  }, [stats]);

  return (
    <div className='CardDashboard card_container_dash' id={'div' + setor + 'Card'}>
        <div className='div_headear_carddashboard' id={setor + 'CardHeader'}>
            <h1>{setor}</h1>
            <button type='button' className='btn_close_carddashboard' onClick={() => handleSizeCard()}><img src={ArrowDown} alt='Icone de uma seta para baixo' className='arrow_down_carddashboard'/></button>
        </div>
        <div className='div_body_carddashboard' id={setor + 'Card'}>
            <div className='div_bodyleft_carddashboard'>
                <div className='div_info_left_carddashboard'>
                    <h1 className='h1_info_carddashboard'>{stats.length}</h1>
                    <p>Total</p>
                </div>
                {
                  setor === 'Meios de Hospedagens' ?
                    (<div className='div_info_left_carddashboard'>
                      <h1 className='h1_info_carddashboard'>{porcents.qtdUhs}</h1>
                      <p>UHs</p>
                    </div>) : null
                }
                {
                    setor !== 'Serviços' && setor !== 'Comércios' ? (
                    <div className='div_info_left_carddashboard'>
                        <h1 className='h1_info_carddashboard'>{capacidade}</h1>
                        <p>Capacidade</p>
                    </div>) : null
                }
            </div>
            <div className='div_bodyright_carddashboard'>
                <div className='div_info_carddashboard'>
                    <div className='div_top_info_carddashboard'>
                        <div className='div_left_info_carddashboard'>
                            <h1 className='h1_info_carddashboard'>{porcents.qtdCad}</h1>
                            <p>Cadastur</p>
                        </div>
                        <div className='div_right_info_carddashboard'>
                            <h3 className='h3_info_carddashboard'>{porcents.cadPorcent}%</h3>
                        </div>
                    </div>
                    <div className='div_bottom_info_carddashboard'>
                        <div className='div_porcent_outbar'>
                            <div style={{ width: porcents.cadPorcent + '%' }} className='div_porcent_innerbar'/>
                        </div>
                    </div>
                </div>
                <div className='div_info_carddashboard'>
                    <div className='div_top_info_carddashboard'>
                        <div className='div_left_info_carddashboard'>
                            <h1 className='h1_info_carddashboard'>{porcents.qtdAnimal}</h1>
                            <p>Aceita Animais</p>
                        </div>
                        <div className='div_right_info_carddashboard'>
                            <h3 className='h3_info_carddashboard'>{porcents.aniPorcent}%</h3>
                        </div>
                    </div>
                    <div className='div_bottom_info_carddashboard'>
                        <div className='div_porcent_outbar'>
                            <div style={{ width: porcents.aniPorcent + '%' }} className='div_porcent_innerbar'/>
                        </div>
                    </div>
                </div>
                <div className='div_info_carddashboard'>
                    <div className='div_top_info_carddashboard'>
                        <div className='div_left_info_carddashboard'>
                            <h1 className='h1_info_carddashboard'>{porcents.qtdCartao}</h1>
                            <p>Aceita Cartão</p>
                        </div>
                        <div className='div_right_info_carddashboard'>
                            <h3 className='h3_info_carddashboard'>{porcents.cartPorcent}%</h3>
                        </div>
                    </div>
                    <div className='div_bottom_info_carddashboard'>
                        <div className='div_porcent_outbar'>
                            <div style={{ width: porcents.cartPorcent + '%' }} className='div_porcent_innerbar'/>
                        </div>
                    </div>
                </div>
                <div className='div_info_carddashboard'>
                    <div className='div_top_info_carddashboard'>
                        <div className='div_left_info_carddashboard'>
                            <h1 className='h1_info_carddashboard'>{porcents.qtdAces}</h1>
                            <p>Acessibilidade</p>
                        </div>
                        <div className='div_right_info_carddashboard'>
                            <h3 className='h3_info_carddashboard'>{porcents.acessPorcent}%</h3>
                        </div>
                    </div>
                    <div className='div_bottom_info_carddashboard'>
                        <div className='div_porcent_outbar'>
                            <div style={{ width: porcents.acessPorcent + '%' }} className='div_porcent_innerbar'/>
                        </div>
                    </div>
                </div>
                <div className='div_info_carddashboard'>
                    <div className='div_top_info_carddashboard'>
                        <div className='div_left_info_carddashboard'>
                            <h1 className='h1_info_carddashboard'>{porcents.qtdIdioma}</h1>
                            <p>Idioma Estrangeiro</p>
                        </div>
                        <div className='div_right_info_carddashboard'>
                            <h3 className='h3_info_carddashboard'>{porcents.idiPorcent}%</h3>
                        </div>
                    </div>
                    <div className='div_bottom_info_carddashboard'>
                        <div className='div_porcent_outbar'>
                            <div style={{ width: porcents.idiPorcent + '%' }} className='div_porcent_innerbar'/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
  );
}