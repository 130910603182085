import React from 'react';

// CSS Import
import '../../assets/css/Dashboard/cardInventoryAdmin.css';

export function InfoSegmentsRow({ name, num }) {
  return (
    <tr>
        <td className='td_cardinventory_desc'>{name}</td>
        <td className='td_cardinventory'>{num}</td>
    </tr>
  );
}