import React from 'react';
import { Alert } from 'reactstrap';

export default class AlertMessage extends React.Component {
  render() {
    const { msg, color } = this.props;
    return (
      <Alert color={color} isOpen={this.props.open} toggle={this.props.onToggle}>
        {msg}
      </Alert>
    );
  }
}