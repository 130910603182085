import React, { useEffect, useState } from 'react';
import firebase from '../../config/Firebase';
import Loading from '../Loading';
import '../../assets/css/InputComponents/selectFiltered.css';

export default function SelectFiltered({ title, field, value, setValue, explanation, route }) {
  const [pontosApoio, setPontosApoio] = useState([]);
  const [selectedPontosApoio, setSelectedPontosApoio] = useState();
  const [selectedRota, setSelectedRota] = useState();
  const [filtersCity, setFiltersCity] = useState('');
  const [filtersType, setFiltersType] = useState('');
  const [filtersSubtype, setFiltersSubtype] = useState('');
  const [filtersSpecialty, setFiltersSpecialty] = useState('');
  const [cities] = useState(route.Cities);
  const [subtypes, setSubtypes] = useState([]);
  const [specialties, setSpecialties] = useState([]);
  const [durationList, setDurationList] = useState([]);
  const [tipos] = useState([
    { value: 'Attraction', title: 'Atrativo' },
    { value: 'Commerce', title: 'Compras' },
    { value: 'Food', title: 'Alimentação' },
    { value: 'Infrastructure', title: 'Infraestrutura' },
    { value: 'Lodging', title: 'Hospedagem' },
    { value: 'Services', title: 'Serviços' },
  ]);

  async function handleSubtypes() {
    if (filtersType !== '') {
      const docs = await firebase.firestore().collection('Taxonomia')
        .where('Type', '==', filtersType)
        .orderBy('Subtype')
        .get();
      
      const subtypes = [];
 
      docs.docs.map((sub) => {
        subtypes.push({ title: sub.data().Subtype, value: sub.data().Subtype });
      });
      setSubtypes(subtypes);
    } else {
      setSubtypes([]);
    }

    setFiltersSubtype('');
  }

  async function handleEspecialidade() {
    if (filtersSubtype !== '') {
      const docs = await firebase.firestore().collection('Taxonomia')
        .where('Type', '==', filtersType)
        .where('Subtype', '==', filtersSubtype)
        .get();
      
      if (docs.docs[0]) {
        const docsFil = docs.docs[0].data().Especialidade.sort();
        const especialidades = [];

        docsFil.forEach((espec) => {
          especialidades.push({ title: espec, value: espec });
        });

        setSpecialties(especialidades);
      } else {
        setSpecialties([]);
      }
    } else {
      setSpecialties([]);
    }

    setFiltersSpecialty('');
  }

  async function handleRoutes() {
    const db = firebase.firestore();
    
    db.collection('Attractions')
      .where('City', 'in', cities)
      .orderBy('Title')
      .get()
      .then((snapshot) => {
        const data = snapshot.docs.map((doc) => {
          return ({ id: doc.id, ...doc.data() });
        });

        let selectedPontosApoio = [];
        value.pontosRota.forEach((p) => {
          selectedPontosApoio = selectedPontosApoio.concat(data.filter((d) => d.id === p));
        });
        
        setPontosApoio(data);
        setSelectedPontosApoio(selectedPontosApoio);
      });
  }

  useEffect(() => {
    handleRoutes();
    if (value['pontosRotaDuracao'])
      setDurationList(value['pontosRotaDuracao']);
  }, []);

  useEffect(() => {
    handleSubtypes();
  }, [filtersType]);

  useEffect(() => {
    handleEspecialidade();
  }, [filtersSubtype]);

  const filteredPontosApoio = pontosApoio.filter((ponto) => {
    if (selectedPontosApoio && selectedPontosApoio.map((item) => item.id).includes(ponto.id))
      return true;

    if (filtersCity && ponto.City !== filtersCity)
      return false;

    if (filtersType && ponto.Type !== filtersType)
      return false;

    if (filtersSubtype && ponto.Subtype !== filtersSubtype)
      return false;

    if (filtersSpecialty && ponto.Especialidade !== filtersSpecialty)
      return false;

    return true;
  });
  
  useEffect(() => {
    if (selectedPontosApoio) {
      setValue({
        ...value, 
        pontosRota: [...new Set(selectedPontosApoio.map((attraction) => attraction.id))], 
        pontosRotaDuracao: durationList }); 
    }
  }, [selectedPontosApoio, durationList]);

  const handleAttractionChange = (e) => {
    const selectedAttractionTitle = e.target.value;
    const selectedAttraction = filteredPontosApoio.find((attraction) => attraction.Title === selectedAttractionTitle);
    setSelectedRota(selectedAttraction);
  };

  function handleAttractionAdd() {
    if (!selectedRota) return;
    setSelectedPontosApoio((prevAttractions) => [...prevAttractions, selectedRota]);
    setSelectedRota();
    setDurationList((prev) => [...prev, '']);
  }

  function handleAttractionRemove(index) {
    const list = [...selectedPontosApoio];
    list.splice(index, 1);
    setSelectedPontosApoio(list);
    const dList = [...durationList];
    dList.splice(index, 1);
    setDurationList(dList);
  }

  const handleDurationChange = (e, index) => {
    const list = [...durationList];
    list[index] = e.target.value;
    setDurationList(list);
  };

  function isNotInArray(array, value) {
    for (let index = 0; index < array.length; index++) 
      if (array[index].id === value.id)
        return false;

    return true;
  }

  if (!pontosApoio || !selectedPontosApoio) {
    return <Loading />;
  }

  return (
    <div className="inputFilters">
      <div className="div_label_input_textarea">
        <label htmlFor={field + 'Form'} className="label_text_input">
          <strong>{title}</strong>
        </label>
      </div>
      <div className="div_align_explanation_input_do_nelli">
        <div className="filter-dropdown">
        <select value={filtersCity} onChange={(e) => setFiltersCity(e.target.value)}>
            <option value="">Todas cidades</option>
            {
              cities.map((city) => {
                return <option key={city} value={city}>{city}</option>;
              })
            }
          </select>
          <select value={filtersType} onChange={(e) => setFiltersType(e.target.value)} >
            <option value="">Todos tipos</option>
            {
              tipos.map((val, index) => {
                return <option key={val.value + index} value={val.value}>{val.title}</option>;
              })
            }
          </select>
          <select value={filtersSubtype} onChange={(e) => setFiltersSubtype(e.target.value)}>
            <option value="">Todos subtipos</option>
            {
              subtypes.map((val, index) => {
                return <option key={val.value + index} value={val.value}>{val.title}</option>;
              })
            }
          </select>
          <select value={filtersSpecialty} onChange={(e) => setFiltersSpecialty(e.target.value)}>
            <option value="">Todas especialidades</option>
            {
              specialties.map((val, index) => {
                return <option key={val.value + index} value={val.value}>{val.title}</option>;
              })
            }
          </select>
        </div>
        <div className="div_align_multiple_select_do_nelli">
          <select className="select_inventory_do_nelli" onClick={(e) => handleAttractionChange(e)} multiple>
            {filteredPontosApoio.map((attraction, index) => {
              if (isNotInArray(selectedPontosApoio, attraction))
                return <option key={'SelectMultiple' + field + index} value={attraction.Title}>{attraction.Title}</option>;
            })}
          </select>
        </div>
        <div className="div_explanation_input">
          <p className="subtext_explanation_input">{explanation}</p>
        </div>
        <button type='button' id={field + 'Form'} className="add_duration_btn" onClick={() => handleAttractionAdd()}>Adicionar</button>
        <div className="div_align_cabecalho">
          <strong className="alignPonto">Ponto</strong>
          <strong className="alignDuracao">Duração</strong>
        </div>
          {selectedPontosApoio.map((ponto, index) => (
          <div key={index} className="div_align_input_duration">
            <strong className="align_title_input">
              {ponto.Title}
            </strong>
            <input name="pontoRota" className="align_input" type="text" id="pontosRotaDuracaoForm" value={durationList[index]} onChange={(e) => handleDurationChange(e, index)}/>
            <button type="button" className="remove_duration_btn" onClick={() => handleAttractionRemove(index)}>
              Remover
            </button>
          </div>
          ))  
          }
      </div>
    </div>
  );
}
