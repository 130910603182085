import axios from 'axios';

import API from '../config/API';

const routeAttraction = API.url + '/statistics/attractions';

export const getAllAttractions = async (filter) => {
  const res = await axios.get(routeAttraction + '/getAllAtractions', {
    params: {
      filter,
    },
  });

  return res;
};

export const getStatsAttractions = async (city, startDate, endDate) => {
  const res = await axios.get(routeAttraction + '/inventoryDistribution', {
    params: {
      city,
      startDate,
      endDate,
    },
  });

  return res;
};