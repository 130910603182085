import React, { useState } from "react";
import { useHistory } from 'react-router-dom';

// CSS import
import '../assets/css/caeLogin.css';

import { loginCae } from '../apis/cae.endpoint';

// Assets imports
import UserIcon from '../assets/imgs/user_icon.svg';
import PasswordIcon from '../assets/imgs/password_icon.svg';
import CaeIcon from '../assets/imgs/cae_background_login.png';
import LogoDestinosInteligentes from '../assets/imgs/footer_logo_destinos.png';


// Components imports
import TextField from '../component/CaeLogin/TextField';
import FooterLoginCae from '../component/CaeLogin/FooterLoginCae';

export default function CaeLogin() {
  const [user, setUser] = useState({ email: '', password: '' });
  const [messageError, setMessageError] = useState('');
  const history = useHistory();

  const handleSetData = (userInfos, uid) => {
    localStorage.setItem('userInfos', JSON.stringify(userInfos));
    localStorage.setItem('uid', uid);
    history.push('/cae/experiencias');
  };

  async function handleLogin() {
    const error = await loginCae(user.email, user.password, handleSetData);

    setMessageError(error);
  }

  return (
    <div className="CaeLogin">
      <div className="main-container-cae-login">
        <div className="container-cae-login">
          <div className="header-container-cae-login">
            <img src={CaeIcon} />
          </div>
          <div className="title-container-cae-login">
            <h1>LOGIN</h1>
            <span>Plataforma para o cadastro de experiências.</span>
          </div>
          <div className="div-align-fields-cae-login">
            <TextField
              placeholder={'Email'}
              icon={UserIcon}
              type={'text'}
              onChange={(e) => setUser({ ...user, email: e.target.value })}
            />
            <TextField
              placeholder={'Senha'}
              icon={PasswordIcon}
              type={'password'}
              onChange={(e) => setUser({ ...user, password: e.target.value })}
            />
            { messageError !== '' ? <span className="error-message-form-cae error-message-login">{messageError}</span> : null }
          </div>
          <div className="div-button-cae-login">
            <button onClick={() => handleLogin()}>Entrar</button>
          </div>
          <div className="hr-div-cae-login" />
          <div className="div-create-account-cae-login">
            <span><a onClick={() => history.push('/cae-forgot-password')}>Esqueceu sua senha?</a></span><br />
            <span>Não possui uma conta? <a onClick={() => history.push('/cae-registro')}>Crie uma aqui.</a></span>
          </div>
          <div className="footer-logo-destinos">
            <span>Powered by</span>
            <img src={LogoDestinosInteligentes} />
          </div>
        </div>
        <div className="container-cae-login" />
      </div>
      <FooterLoginCae />
    </div>
  );
}