import React, { useState, useEffect } from "react";

// Firebase import
import firebase from '../../config/Firebase';

// Components imports
import VerifyPopup from '../VerifyPopup';
import Loading from '../Loading';
import TitleEditor from '../FormComponents/TitleEditor';
import ButtonForm from '../FormComponents/ButtonForm';
import HeaderForm from '../FormComponents/HeaderForm';
import { fieldsEditProject } from '../FieldsForm/project.fields';
import { inputsComponents } from '../../utils/formManager';

export default function EditGuideline({ doc, action, userCity, setEditPopup }) {
  const [popup, setPopup] = useState(false);
  const [item, setItem] = useState(doc ? doc.data() : {
    titulo: '',
    cidade: userCity,
    diretriz: '',
    programa: '',
    status: '',
    resultadoEsperado: '',
    responsaveis: '',
    custoAproximado: null,
    prazos: '',
    percentualConcluido: null,
    active: true,
    logAlteracao: [],
    original: {
      titulo: '',
    },
  });

  const handleEditProject = async () => {
    await firebase.firestore().collection('PlanoDiretor_Projetos').doc(doc.id).set(item);
    setEditPopup(false);
  };

  const handleAddProject = async () => {
    await firebase.firestore().collection('PlanoDiretor_Projetos').add(item);
    setEditPopup(false);
  };

  function handleCheckFields() {
    const keys = ['titulo', 'diretriz', 'programa'];

    for (let i = 0; i < keys.length; i++) {
      if (item[keys[i]] === '') {
        document.getElementById(keys[i]).focus();
        return;
      }
    }

    setPopup(true);
  }

  useEffect(() => {
    if (userCity)
      setItem({ ...item, cidade: userCity });
  }, [userCity]);

  useEffect(() => {
    setItem({ ...item, cidade: userCity });
  }, [item.cidade]);

  return (
    <div className='EditInventory'>
      <div className='bodyEditInventory'>
        <TitleEditor action={action} type={'Projeto'} />
        <div>
          <form className='form_edit_admin'>
            <HeaderForm />
            {
              fieldsEditProject.map((field) => {
                return inputsComponents[field.component]({ term: field, item: item, setItem: setItem, doc: doc, userCity: userCity });
              })
            }
            { popup ? <VerifyPopup setVerifyPopup={setPopup} handleAction={doc ? handleEditProject : handleAddProject} title={`Deseja ${action} este Projeto?`} message={'Ao realizar essas ações, elas serão gravadas na base de dados, e as novas informações poderão ser vistas posteriormente. Deseja continuar?'} /> : null }
            <ButtonForm setEditPopup={setEditPopup} handleCheckFields={handleCheckFields} />
          </form>
        </div>
      </div>
    </div>
  );
}