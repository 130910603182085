import React, { useEffect, useState } from 'react';

// CSS Import
import '../../assets/css/atractionList.css';

// Component imports
import { endStr } from '../../utils/limitStr';

export default function RegionsList({ doc, handleEdit, handleDelete }) {
  const [neighborhood, setNeighborhood] = useState();

  function handleText() {
    let value = '';
    doc.data().neighborhood.forEach((str) => {
      value = value + str + ', ';
    });

    setNeighborhood(value);
  }

  useEffect(() => {
    handleText();
  }, []);

  if (!neighborhood)
    return <></>;
    
  return (
    <tr className="row_table_inventory body_table">
      <td className="title_table_inventory">{doc.data().name}</td>
      <td className="column_min_table_inventory">{doc.data().location && doc.data().location.latitude && doc.data().location.longitude ? doc.data().location.latitude.toFixed(4) + ', ' + doc.data().location.longitude.toFixed(4) : 'Não Há'}</td>
      <td className="desc_table_inventory">{endStr(neighborhood)}</td>
      <td className="column_min_table_inventory">{doc.data().Active ? 'Ativo' : 'Desativado'}</td>
      <td className="column_edit_table_inventory">
        <div className='div_mod_inventory'>
          <button className='btn_mod_adm' onClick={() => handleEdit(doc)}>Editar</button>
          <button className='btn_mod_adm' onClick={() => handleDelete(doc)}>Desativar</button>
        </div>
      </td>
    </tr>
  );
}