import React, { useState, useEffect, useContext } from 'react';

// eslint-disable-next-line import/no-cycle
import { MyContext } from '../../component/SidebarAdmin';

// Assets imports
import SearchIcon from '../../assets/imgs/search_icon.svg';

// Components imports
import { RodapeAdmin } from '../../component/RodapeAdmin';
import VerifyPopup from '../../component/VerifyPopup';
import ProgramList from '../../component/ListComponents/ProgramList';
import Loading from '../../component/Loading';
import EditProgram from '../../component/EditComponents/EditProgram';
import NoResultsComponent from '../../component/NoResultsComponent';

// Firebase import
import firebase from '../../config/Firebase';

export default function ProgramAdmin() {
  const [filter, setFilter] = useState('titulo');
  const [editPopup, setEditPopup] = useState(false);
  const [verifyPopup, setVerifyPopup] = useState(false);
  const [selectedItem, setSelectedItem] = useState(undefined);
  const [search, setSearch] = useState('');
  const [places, setPlaces] = useState();
  const [action, setAction] = useState('Editar');
  const [filteredPlaces, setFilteredPlaces] = useState();
  const { city } = useContext(MyContext);

  const buildQuery = () => {
    const currFilter = filter === 'searchBar' ? 'titulo' : filter;

    const docs = firebase.firestore()
      .collection('PlanoDiretor_Programas')
      .where('cidade', '==', city)
      .orderBy(currFilter);
    
    return docs;
  };

  const getFirstPage = async () => {
    const newDocs = await buildQuery().get();

    setPlaces(newDocs);
    setFilteredPlaces(newDocs);
  };

  useEffect(() => {
    if (city)
      getFirstPage();
  }, [city, filter]);

  useEffect(() => {
    if (!editPopup) {
      setSelectedItem(undefined);
      if (city)
        getFirstPage();
    }
  }, [editPopup]);

  const handleEdit = (doc) => {
    setSelectedItem(doc);
    setAction('Editar');
    setEditPopup(true);
  };

  const handleDelete = (doc) => {
    setSelectedItem(doc);
    setVerifyPopup(true);
  };

  const handleDeleteProgram = async (doc) => {
    await firebase.firestore().collection('PlanoDiretor_Programas').doc(doc.id).update({
      active: false,
    });
    setVerifyPopup(false);
    getFirstPage();
  };

  const searchInventoryText = (doc) => {
    if (search === '')
      return true;
    
    const isInTitle = doc.data().titulo.toLowerCase().includes(search.toLowerCase());
    return isInTitle;
  };

  const handleSearch = () => {
    if (places.docs) {
      let filteredPlaces = places.docs.filter((doc) => searchInventoryText(doc));
      filteredPlaces = { docs: filteredPlaces };
      setFilteredPlaces(filteredPlaces);
    }
  };

  if (!places || !city) {
    return <Loading />;
  }

  return (
    <div className='InventarioAdmin'>
      { editPopup ? <EditProgram userCity={city} doc={selectedItem} action={action} setEditPopup={setEditPopup} /> : 
        (<div className='body_inventory_adm'>
        <div className='div_title_inventory_adm'>
          <h1>Programas</h1>
        </div>
        <div className='div_create_inventory_adm'>
          <p className='create_inventory_adm' onClick={() => { setAction('Criar'); setEditPopup(true); }}>Criar Novo Programa</p>
        </div>
        <div className='div_browse_inventory_adm'>
          <input type='text' className='input_browse_inventory' onChange={(e) => { setSearch(e.target.value); handleSearch(); }} placeholder='Buscar' />
          <button type="button" className='btn_browse_inventory_adm' disabled>
            <img src={SearchIcon} alt='Icone de pesquisa' className='search_icon_admin'/>
          </button>
        </div>
        { filteredPlaces && filteredPlaces.docs && filteredPlaces.docs.length > 0 ? (
        <div className='div_table_inventory_adm' id='div_table_inventory_adm'>
          <table className='table_inventory_adm'>
            <thead>
              <tr className="row_table_inventory th_table_admin">
                <td className='column_title_table_inventory th_table_admin' onClick={() => setFilter('titulo')}>Titulo</td>
                <td className='column_title_table_inventory th_table_admin' onClick={() => setFilter('cidade')} >Cidade</td>
                <td className='column_title_table_inventory th_table_admin' onClick={() => setFilter('idDiretriz')} >Diretriz</td>
                <td className='column_title_table_inventory th_table_admin' onClick={() => setFilter('active')}>Ativo</td>
                <td className='column_table_inventory th_table_admin'></td>
              </tr>
            </thead>
            <tbody>
              {
                filteredPlaces.docs.map((doc) => {
                  return <ProgramList doc={doc} key={doc.id} handleEdit={handleEdit} handleDelete={handleDelete} />;
                })
              }
            </tbody>
          </table>
        </div>) : <NoResultsComponent title={'Programas'} search={search} /> }
        { verifyPopup ? <VerifyPopup setVerifyPopup={setVerifyPopup} action={'Excluir'} handleAction={handleDeleteProgram} type={'Inventário'} doc={selectedItem} /> : null} 
      </div>)}
    </div>
  );
}