import React, { useEffect, useState } from 'react';
import firebase from '../../config/Firebase';
import Loading from '../Loading';
import '../../assets/css/InputComponents/inputFilters.css';

export default function SelectMultiplePrefilter({ title, field, value, setValue, explanation, route }) {
  const [attractions, setAttractions] = useState([]);
  const [selectedAttractions, setSelectedAttractions] = useState([]);
  const [filtersCity, setFiltersCity] = useState();
  const [specialties] = useState(['Agência de turismo', 'Agência de turismo emissivo', 'Agência de turismo receptivo', 'Assosiação de turismo', 'Guia de turismo', 'Operadora turística', 'Turismo']);
  const [filtersSpecialty, setFiltersSpecialty] = useState('');
  const [cities] = useState(route.Cities);
  const [attractionsLoaded, setAttractionsLoaded] = useState(false); 

  async function handleGetData() {
    const db = firebase.firestore();
    
    // Create an array to store promises for fetching attractions
    const attractions = [];
    const especialidades = [];

    // Fetch attractions for each city separately
    const attractionDocs = await db.collection('Attractions')
      .where('Subtype', 'in', ['Agência de turismo', 'Agência de turismo emissivo', 'Agência de turismo receptivo', 'Assosiação de turismo', 'Guia de turismo', 'Operadora turística', 'Turismo'])
      .orderBy('Title')
      .get();

    attractionDocs.docs.map((doc) => {
      const data = doc.data();

      if (cities.includes(data.City))
        attractions.push({ id: doc.id, ...data });
    });

    setAttractions(attractions);
    setAttractionsLoaded(true);
  }

  useEffect(() => {
    handleGetData();
  }, []);
  
  useEffect(() => {
    if (attractionsLoaded && attractions.length > 0 && value[field] && value[field].length > 0)
      setSelectedAttractions([...new Set(attractions.filter((item) => value[field].includes(item.id)))]);
  }, [attractions, attractionsLoaded]);

  const handleAttractionChange = (attraction) => {
    const isSelected = selectedAttractions.map((item) => item.id).includes(attraction.id);
    if (isSelected) {
      setSelectedAttractions(selectedAttractions.filter((id) => id.id !== attraction.id));
    } else {
      setSelectedAttractions([...selectedAttractions, attraction]);
    }
  };

  useEffect(() => {
    setValue({ ...value, [field]: [...new Set(selectedAttractions.map((attraction) => attraction.id))] });
  }, [selectedAttractions]);

  const filteredPontosApoio = attractions.filter((ponto) => {
    if (selectedAttractions.map((item) => item.id).includes(ponto.id))
      return true;
    if (filtersCity && ponto.City !== filtersCity)
      return false;
    if (filtersSpecialty && ponto.Especialidade !== filtersSpecialty)
      return false;

    return true;
  });

  if (!attractions) {
    return <Loading />;
  }

  return (
    <div className='inputFilters'>
      <div className='div_label_input_textarea'>
        <label htmlFor={field + 'Form'} className="label_text_input">
          <strong>{title}</strong>
        </label>
      </div>
      <div className='div_align_explanation_input_do_nelli'>
        <div className="filter-dropdown">
          <select value={filtersCity} onChange={(e) => setFiltersCity(e.target.value) }>
            <option value="">Todas cidades</option>
            {
              cities.map((city) => {
                return <option key={city} value={city}>{city}</option>;
              })
            }
          </select>
          <select value={filtersSpecialty} onChange={(e) => setFiltersSpecialty(e.target.value)}>
            <option value="">Todas as especialidades</option>
            {
              specialties.map((spec) => {
                return <option key={spec} value={spec}>{spec}</option>;
              })
            }
          </select>
        </div>
        <div className='div_align_multiple_select_do_nelli'>
          <div className='select_inventory_do_nelli'>
            { filteredPontosApoio.length > 0 ? 
              filteredPontosApoio.map((attraction) => (
                <div key={attraction.id}>
                  <label>
                  <input
                    type="checkbox"
                    checked={selectedAttractions.map((item) => item.id).includes(attraction.id)}
                    onChange={() => handleAttractionChange(attraction)}
                  />
                  {attraction.Title}
                  </label>
                </div>
              )) : <span>Sem opções</span>
            }
          </div>
        </div>
        <div className='div_explanation_input'>
          <p className='subtext_explanation_input'>{explanation}</p>
        </div>
      </div>
    </div>
  );}
