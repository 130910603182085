import React, { useState, useEffect } from 'react';
// Firebase imports
import firebase from '../../config/Firebase';

// CSS import
import '../../assets/css/InputComponents/imageInput.css';
import Loading from '../Loading';

// Components Imports
import LocalImage from '../LocalImage';
import { generateRandomId } from '../../utils/randomId';

export default function ImageInput({ field, title, value, setValue, explanation, doc, objectPend, setObjectPend, allImages, setAllImages, pathStorage }) {
  const [loadingAllImage, setLoadingAllImage] = useState(!!doc);
  const [messageError, setMessageError] = useState('');

  const handleImages = async (doc) => {
    if (doc[field] && doc[field].length > 0) {
      const vet = [];
      for (let index = 0; index < doc[field].length; index++) {
        try {
          const name = doc[field][index];
          const ref = firebase.storage().ref(`${pathStorage}/${name}`);
          const url = await ref.getDownloadURL();
          vet.push({
            name: name,
            url: url,
            image: '',
          })
        } catch (error) {}
      }

      setAllImages(vet);
      setLoadingAllImage(false);
    }
    setLoadingAllImage(false);
  };

  const handleListImages = async (src) => {
    let vectorImg = allImages;
    const names = [];
    const keys = Object.keys(src.target.files);

    for (let key = 0; key < keys.length; key++) {
      const file = src.target.files[keys[key]];

      if (file.size / 1000 <= 1000) {
        let position = -1;
  
        vectorImg.map((obj, index) => {
          if (obj.image !== '' && obj.image.name === file.name)
            position = index;
        });
        
        if (position === -1) {
          const name = file.name.split('.');
          const extension = '.' + name[name.length - 1];

          vectorImg = [...vectorImg, {
            name: generateRandomId() + extension,
            url: URL.createObjectURL(file),
            image: file,
          }];
        }
      } else {
        setMessageError('Tamanho de uma das fotos excede 1 MB (Megabytes)');
        return;
      }
    }

    // Add image names
    vectorImg.map((obj) => {
      names.push(obj.name);
    });

    // Reset selected files 
    document.getElementById('input_file_inventory').value = null;
    
    setMessageError('');
    setAllImages(vectorImg);
    setValue({ ...value, [field]: names });
  };

  const handleDeleteImage = (index) => {
    if (objectPend) {
      let newPend;

      if (!objectPend.deleteFiles)
        newPend = { ...objectPend, deleteFiles: [allImages[index].name] };
      else
        newPend = { ...objectPend, deleteFiles: [...objectPend.deleteFiles, allImages[index].name] };

      setObjectPend(newPend);
    }

    setValue({ ...value, [field]: value[field].filter((name) => name !== allImages[index].name) });
    setAllImages(allImages.filter((value, pos) => pos !== index));
  };

  useEffect(() => {
    if (allImages !== undefined && allImages.length > 0)
      setLoadingAllImage(false);
  }, [allImages]);

  useEffect(() => {
    if (doc || objectPend)
      handleImages(objectPend ? objectPend.currDoc : doc.data());
  }, []);

  if (loadingAllImage)
    return <Loading />;

  return ( 
    <div className='ImageInput'>
      <div className='div_align_image_input'>
        <div className='div_label_input_image'>
          <label htmlFor={field + 'Form'} className="label_text_input">
          <strong>{title}</strong>
          </label>
        </div>
        <div className='div_align_explanation_input'>
          <div className='div_field_input_image'>
            <div className='div_align_input_image'>
              <button type='button' className='btn_image_inventory' onClick={() => document.getElementById('input_file_inventory').click()}>Carregar Fotos</button>
              <input className='input_file_inventory' type='file' id='input_file_inventory' accept='image/png image/jpg' onChange={(event) => handleListImages(event)} multiple='multiple' />
            </div>
            <div className='div_edit_list_images' id='div_edit_list_images'>
              {
                allImages.length !== 0 ? allImages.map((img, index) => {  
                  return <LocalImage url={img.url} handleDeleteImage={handleDeleteImage} index={index} key={'ImageInputInventory' + img.name + index} />;
                }) : (
                  <div className='div_message_noimage_input'>
                    <h1><strong>Nenhuma imagem selecionada.</strong></h1>
                    <p>Clique no botão para carregar imagens.</p>
                  </div>
                )
              }
            </div>
          </div>
          <div className='div_explanation_input'>
            { messageError !== '' ? <span className="error-message-form-cae error-message-login">{messageError}</span> : null }
            <p className='subtext_explanation_input'>{explanation}</p>
          </div>
        </div>
      </div>
    </div>
  );
}