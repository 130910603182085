import React from 'react';

import { GeolocationInput } from '../component/InputComponents/GeolocationInput';
import { GeolocationItineraryInput } from '../component/InputComponents/GeolocationItineraryInput';
import { WaypointInput } from '../component/InputComponents/WaypointInput';
import TextareaInput from '../component/InputComponents/TextareaInput';
import RadioInput from '../component/InputComponents/RadioInput';
import CheckboxRender from '../component/FormComponents/CheckboxRender';
import AdderInput from '../component/InputComponents/AdderInput';
import ImageInput from '../component/InputComponents/ImageInput';
import TextInput from '../component/InputComponents/TextInput';
import SelectInput from '../component/InputComponents/SelectInput';
import DateInput from '../component/InputComponents/DateInput';
import FileInput from '../component/InputComponents/FileInput';
import SingleImageInput from '../component/InputComponents/SingleImageInput';
import SelectMultipleRender from '../component/InputComponents/SelectMultipleRender';
import RangeInput from '../component/InputComponents/RangeInput';
import SelectMultipleInput from '../component/InputComponents/SelectMultipleInput';
import SelectFiltered from '../component/InputComponents/SelectFiltered';
import SelectMultipleFilter from '../component/InputComponents/SelectMultipleFilter';
import SelectMultiplePrefilter from '../component/InputComponents/SelectMultiplePrefilter';
import TextInputQuestion from '../component/InputComponents/TextInputQuestion';
import ExampleInput from '../component/InputComponents/ExampleInput';
import OptionEditorInput from '../component/FormQuestion/OptionEditorInput';
import GPXFileInput from '../component/InputComponents/GPXFileInput';
import CityPickerInput from '../component/InputComponents/CityPickerInput';
import { SelectLocalEventInput } from '../component/InputComponents/SelectLocalEventInput';

function handleRenderTextInput({ term, item, setItem, editType, keyName, rankingPoints }) {
  return (
    <>
      <TextInput key={keyName} title={term.title} field={term.field} type={term.type} value={item} setValue={setItem} link={term.link} placeholderText={term.placeholder} editType={editType} explanation={term.explanation} rankingPoints={rankingPoints} />
      <hr className='divisor_component' />
    </>
  );
}

function handleRenderTextareaInput({ term, item, setItem, keyName, rankingPoints }) {
  return (
    <>
      <TextareaInput key={keyName} title={term.title} field={term.field} value={item} setValue={setItem} explanation={term.explanation} rankingPoints={rankingPoints} />
      <hr className='divisor_component'/>
    </>
  );
}

function handleRenderSelectInput({ term, item, setItem, doc, userCity, objectPend, role, action, index, publish, keyName, rankingPoints, typeDocument, tradeAdmin }) {
  return (
    <>
      <SelectInput key={keyName} title={term.title} field={term.field} value={item} setValue={setItem} boolType={term.boolType} disable={term.disable} userCity={userCity} doc={doc} objectPend={objectPend} role={role} action={action} indexVal={index} publish={publish} rankingPoints={rankingPoints} typeDocument={typeDocument} tradeAdmin={tradeAdmin} typeRoutes={term.typeRoutes} />
      <hr className='divisor_component'/>
    </>
  );
}

function handleRenderCheckboxInput({ term, item, setItem, doc, objectPend, styleType, userRole, actionType, keyName, rankingPoints }) {
  return (
    <>
      <CheckboxRender key={keyName} title={term.title} field={term.field} value={item} setValue={setItem} checkboxValues={term.checkboxValues} values={term.typeCheck} objectPend={objectPend} doc={doc} styleType={styleType} explanation={term.explanation} role={userRole} actionType={actionType} rankingPoints={rankingPoints} />
      <hr className='divisor_component'/>
    </>
  );
}

function handleRenderRadioInput({ term, item, setItem, keyName, rankingPoints }) {
  return (
    <>
      <RadioInput key={keyName} title={term.title} field={term.field} value={item} setValue={setItem} rankingPoints={rankingPoints} />
      <hr className='divisor_component'/>
    </>
  );
}

function handleRenderAdderInput({ term, item, setItem, keyName }) {
  return (
    <>
      <AdderInput key={keyName} title={term.title} field={term.field} value={item} setValue={setItem} placeholder={''} explanation={term.explanation} />
      <hr className='divisor_component'/>
    </>
  );
}

function handleRenderLocationInput({ term, item, setItem, doc, userCity, objectPend, keyName, rankingPoints }) {
  return (
    <>
      <GeolocationInput key={keyName} doc={doc} title={term.title} field={term.field} value={item} setValue={setItem} placeholder={term.placeholder} userCity={userCity} explanation={term.explanation} objectPend={objectPend} rankingPoints={rankingPoints} />
      <hr className='divisor_component'/>
    </>
  );
}

function handleRenderGeolocationInput({ term, item, setItem, doc, userCity, objectPend, role, action }) {
  return (
    <>
      <GeolocationItineraryInput title={term.title} field={term.field} value={item} setValue={setItem} boolType={term.boolType} disable={term.disable} userCity={userCity} doc={doc} objectPend={objectPend} explanation={term.explanation} role={role} action={action} />
      <hr className='divisor_component'/>
    </>
  );
}

function handleRenderWaypointInput({ term, item, setItem, doc, userCity, objectPend, role, action }) {
  return (
    <>
      <WaypointInput title={term.title} field={term.field} value={item} setValue={setItem} boolType={term.boolType} disable={term.disable} userCity={userCity} doc={doc} objectPend={objectPend} explanation={term.explanation} role={role} action={action} />
      <hr className='divisor_component'/>
    </>
  );
}

function handleRenderImageInput({ term, item, setItem, doc, allImages, setAllImages, objectPend, setObjectPend, pathStorage, keyName, rankingPoints }) {
  return (
    <>
      <ImageInput key={keyName} title={term.title} field={term.field} value={item} setValue={setItem} explanation={term.explanation} doc={doc} objectPend={objectPend} setObjectPend={setObjectPend} allImages={allImages} setAllImages={setAllImages} pathStorage={pathStorage} rankingPoints={rankingPoints} />
      <hr className='divisor_component'/>
    </>
  );
}

function handleRenderDateInput({ term, item, setItem, doc, objectPend, role, startDate, endDate, keyName, rankingPoints }) {
  return (
    <>
      <DateInput key={keyName} title={term.title} type={term.type} field={term.field} value={item} setValue={setItem} doc={doc} objectPend={objectPend} role={role} startDate={startDate} endDate={endDate} rankingPoints={rankingPoints} />
      <hr className='divisor_component'/>
    </>
  );
}

function handleRenderFileInput({ term, item, setItem, setFile, keyName, rankingPoints }) {
  return (
    <>
      <FileInput key={keyName} title={term.title} type={term.type} field={term.field} value={item} setValue={setItem} setFile={setFile} explanation={term.explanation} rankingPoints={rankingPoints} />
      <hr className='divisor_component'/>
    </>
  );
}

function handleRenderGPXFileInput({ term, item, setItem, setFile, keyName, rankingPoints }) {
  return (
    <>
      <GPXFileInput key={keyName} title={term.title} type={term.type} field={term.field} value={item} setValue={setItem} setFile={setFile} explanation={term.explanation} rankingPoints={rankingPoints} />
      <hr className='divisor_component'/>
    </>
  );
}

function handleRenderSingleImageInput({ term, item, doc, objectPend, image, setImage, keyName, rankingPoints }) {
  return (
    <>
      <SingleImageInput key={keyName} title={term.title} field={term.field} type={term.type} explanation={term.explanation} value={item} doc={doc} objectPend={objectPend} image={image} setImage={setImage} rankingPoints={rankingPoints} />
      <hr className='divisor_component'/>
    </>
  );
}

function handleRenderSelectMultipleRender({ term, item, setItem, keyName, typeRemove }) {
  return (
    <>
      <SelectMultipleRender key={keyName} title={term.title} field={term.field} value={item} setValue={setItem} explanation={term.explanation} typeRemove={typeRemove} />
      <hr className='divisor_component'/>
    </>
  );
}

function handleRenderSelectFiltered({ term, item, setItem, keyName, route }) {
  return (
    <>
      <SelectFiltered key={keyName} title={term.title} field={term.field} value={item} setValue={setItem} explanation={term.explanation} route={route} />
      <hr className='divisor_component'/>
    </>
  );
}

function handleRenderSelectMultipleFilter({ term, item, setItem, route }) {
  return (
    <>
      <SelectMultipleFilter title={term.title} field={term.field} value={item} setValue={setItem} explanation={term.explanation} filterProp={term.filterProp} filters={term.filters} route={route} />
      <hr className='divisor_component'/>
    </>
  );
}

function handleRenderSelectMultiplePrefilter({ term, item, setItem, route }) {
  return (
    <>
      <SelectMultiplePrefilter title={term.title} field={term.field} value={item} setValue={setItem} explanation={term.explanation} route={route} />
      <hr className='divisor_component'/>
    </>
  );
}


function handleRenderRangeInput({ term, item, setItem, keyName }) {
  return (
    <>
      <RangeInput key={keyName} title={term.title} field={term.field} type={term.type} value={item} setValue={setItem} />
      <hr className='divisor_component'/>
    </>
  );
}

function handleRenderSelectMultipleInput({ term, item, setItem, keyName }) {
  return (
    <>
      <SelectMultipleInput key={keyName} type={term.typeComp} title={term.title} item={item} setItem={setItem} role={term.role} />
      <hr className='divisor_component'/>
    </>
  );
}

function handleRenderTextInputQuestion({ term, item, setItem, editType, index, value, publish, keyName }) {
  return (
    <>
      <TextInputQuestion key={keyName} title={term.title} field={term.field} type={term.type} item={item} value={value} setItem={setItem} link={term.link} placeholderText={term.placeholder} editType={editType} explanation={term.explanation} index={index} publish={publish} />
      <hr className='divisor_component' />
    </>
  );
}

function handleRenderExampleInput({ term, value, keyName }) {
  return (
    <>
      <ExampleInput key={keyName} title={term.title} explanation={term.explanation} field={term.field} value={value} />
      <hr className='divisor_component' />
    </>
  );
}

function handleRenderOptionEditorInput({ term, value, item, setItem, index, options, publish, keyName }) {
  return (
    <>
      <OptionEditorInput key={keyName} title={term.title} explanation={term.explanation} field={term.field} value={value} indexQuestion={index} item={item} setItem={setItem} options={options} publish={publish} />
      <hr className='divisor_component' />
    </>
  );
}

function handleRenderCityPicker({ item, setItem }) {
  return (
    <>
      <CityPickerInput value={item} setValue={setItem} />
      <hr className='divisor_component'/>
    </>
  );
}

function handleRenderSelectLocalEventInput({ term, tradeAdmin, userCity, item, setItem, doc, role, objectPend, disable }) {
  return (
    <>
      <SelectLocalEventInput title={term.title} field={term.field} tradeAdmin={tradeAdmin} userCity={userCity} value={item} setValue={setItem} objectPend={objectPend} doc={doc} role={role} disable={disable} />
      <hr className='divisor_component'/>
    </>
  )
}

export const inputsComponents = {
  text: handleRenderTextInput,
  textarea: handleRenderTextareaInput,
  select: handleRenderSelectInput,
  checkbox: handleRenderCheckboxInput,
  radio: handleRenderRadioInput,
  adder: handleRenderAdderInput,
  location: handleRenderLocationInput,
  waypoint: handleRenderGeolocationInput,
  waypointInput: handleRenderWaypointInput,
  image: handleRenderImageInput,
  date: handleRenderDateInput,
  file: handleRenderFileInput,
  gpxFile: handleRenderGPXFileInput,
  singleImage: handleRenderSingleImageInput,
  selectMultiple: handleRenderSelectMultipleRender,
  selectFiltered: handleRenderSelectFiltered,
  selectMultipleInput: handleRenderSelectMultipleInput,
  selectMultipleFilter: handleRenderSelectMultipleFilter,
  selectMultiplePrefilter: handleRenderSelectMultiplePrefilter,
  range: handleRenderRangeInput,
  textQuestion: handleRenderTextInputQuestion,
  example: handleRenderExampleInput,
  optionEditor: handleRenderOptionEditorInput,
  cityPicker: handleRenderCityPicker,
  selectLocal: handleRenderSelectLocalEventInput,
};