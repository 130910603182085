import React, { Component } from 'react';

// Components imports
import { RodapeAdmin } from '../../component/RodapeAdmin';
import Report from '../Report';

// CSS import
import '../../assets/css/reportAdmin.css';

export default class ReportAdmin extends Component {
  handleTitle = () => {
    const titleParam = this.props.title;

    const titlesType = {
      inventario: 'Inventários',
      eventos: 'Eventos',
      planoDiretor: 'Plano Diretor',
      estudosDemanda: 'Estudos de Demanda',
    };

    return titlesType[titleParam];
  };

  render() {
    return (
      <div className='InventarioAdmin'>
        <div className='div_align_report_admin' id='div_align_report_admin'>
          <div className='div_title_inventory_adm' id='div_title_report_adm'>
            <h1>Relatório de {this.handleTitle()}</h1>
          </div>
          <div className='div_report_admin'>
            <Report location={{ 
              search: '?{%22alimentacao%22:true,%22hospedagem%22:true,%22atrativos%22:true,%22compras%22:true,%22infraEstrutura%22:true,%22servicos%22:true}',
              admin: true,
              city: this.props.city,
            }} />
          </div>
        </div>
      </div>
    );
  }
}