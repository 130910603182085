import React from 'react';
import { StyleSheet, css } from 'aphrodite';
import { store } from '../redux/store';
import { TOGGLE_FILTER } from '../redux/actionTypes';
import { connect } from 'react-redux';
import arrow from '../assets/imgs/right-arrow-white.png';

class MapCenterButton extends React.Component {
  render() {
    const { isMobile, isFilterOpen } = this.props;
    // fazer o filtros para mobile
    return (
      isMobile ?
        <div 
          onClick={() => { 
            if (document && document.activeElement) {
              document.activeElement.blur();
            }
            store.dispatch({ type: TOGGLE_FILTER });  
          }} 
          className={css(styles.mobileContainer)}
        >
          filtros
          {/* <p className={css(styles.title)}>filtro</p>
          <div className={css(styles.distanceTextLine)}/>
          { isFilterOpen ? 
              <p className={css(styles.info)}>Recolher lista de atrativos</p>
            :
              <p className={css(styles.info)}>Mostrar lista de atrativos</p>
          } */}
        </div>
        :
        <div 
          onClick={() => { 
            if (document && document.activeElement) {
              document.querySelector(':focus');
            }
            store.dispatch({ type: TOGGLE_FILTER });
          }} 
          className={css(styles.container)}
        >
          
          { isFilterOpen ? 
              <img alt='' src={arrow} className={css(styles.arrowClose)} />
            :
            <img alt=''src={arrow} className={css(styles.arrow)} />
          }
        </div>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    alignItems: 'center',
    backgroundColor: '#c844dd',
    cursor: 'pointer',
    display: 'flex',
    height: 90,
    justifyContent: 'center',
    borderBottomRightRadius: 50,
    borderTopRightRadius: 50,
    marginRight: 'auto',
    marginLeft: 'auto',
    width: 50,
  },
  distanceTextLine: {
    borderBottomColor: 'white',
    borderBottomStyle: 'solid',
    borderBottomWidth: '2px',
    marginTop: '0',
    marginBottom: '2px',
    padding: '0',
    width: 55,
  },
  info: {

  },
  title: {
    marginBottom: 0,
    fontSize: '18px',
  },

  mobileContainer: {
    width: (window.innerWidth / 3),
    textAlign: 'center',
    backgroundColor: '#c844dd',
    cursor: 'pointer',
    justifyContent: 'center',
    backgroundImage: 'linear-gradient(to right, #f9407d, #c844dd)',
    borderWidth: '.30px',
    color: 'white',
    fontSize: '23px',
    bottom: '20px',
    position: 'relative',
    borderRadius: '25px',
    paddingTop: '10px',
    paddingBottom: '10px',
    paddingLeft: '20px',
    paddingRight: '20px',
  },
  arrow: {
    height: 30,
    width: 30,
    marginRight: '2px',
  },
  arrowClose: {
    height: 30,
    width: 30,
    transform: 'rotateY(180deg)',
    marginRight: '2px',
  },
});

function mapStateToProps(state) {
  return {
    isFilterOpen: state.isFilterOpen,
  };
}

export default connect(mapStateToProps, null)(MapCenterButton);
