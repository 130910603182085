import React, { useState } from 'react';
import DashboardIcon from '@material-ui/icons/Dashboard';
import InsertPhotoIcon from '@material-ui/icons/InsertPhoto';
import EventIcon from '@material-ui/icons/Event';
import ApartmentIcon from '@material-ui/icons/Apartment';
import MapIcon from '@material-ui/icons/Map';
import NatureIcon from '@material-ui/icons/Nature';
import DesktopWindowsIcon from '@material-ui/icons/DesktopWindows';
import DirectionsWalkIcon from '@material-ui/icons/DirectionsWalk';
import MenuBookIcon from '@material-ui/icons/MenuBook';
import ConfirmationNumberIcon from '@material-ui/icons/ConfirmationNumber';
import AssessmentIcon from '@material-ui/icons/Assessment';
import GroupIcon from '@material-ui/icons/Group';
import BackspaceIcon from '@material-ui/icons/Backspace';
import GavelIcon from '@material-ui/icons/Gavel';
import AssignmentTurnedInIcon from '@material-ui/icons/AssignmentTurnedIn';
import KeyboardIcon from '@material-ui/icons/Keyboard';

export const SidebarData = [
  {
    title: 'DASHBOARDS',
    icon: <DashboardIcon />,
    link: '/admin/dashboards',
  },
  {
    title: 'INVENTÁRIO',
    icon: <InsertPhotoIcon />,
    link: '/admin/inventario',
  },
  {
    title: 'EVENTOS',
    icon: <EventIcon />,
    link: '/admin/eventos',
  },
  {
    title: 'ROTEIROS',
    icon: <MenuBookIcon />,
    link: '/admin/roteiros',
  },
  {
    title: 'ESTUDO DE DEMANDA',
    icon: <DesktopWindowsIcon />,
    link: '/admin/estudoDemanda',
  },
  {
    title: 'RANQUEAMENTO PAULISTA',
    icon: <KeyboardIcon />,
    link: '/admin/ranqueamentoPaulista',
  },
  {
    title: 'COMPETITIVIDADE TURÍSTICA',
    icon: <KeyboardIcon />,
    subLinks: [
      {
        title: 'Avaliação da Cidade',
        link: '/admin/calculadora/avaliacao',
      }, {
        title: 'Ranking Geral',
        link: '/admin/calculadora/ranking',
      }
    ],
  },
  {
    title: 'CIDADES',
    icon: <ApartmentIcon />,
    link: '/admin/cidades',
  },
  {
    title: 'REGIÕES',
    icon: <MapIcon />,
    link: '/admin/regioes',
  },
  {
    title: 'PLANO DE TURISMO',
    icon: <DirectionsWalkIcon />,
    subLinks: [
      {
        title: 'Diretrizes',
        link: '/admin/planoDiretor/diretrizes',
      }, {
        title: 'Programas',
        link: '/admin/planoDiretor/programas',
      }, {
        title: 'Projetos',
        link: '/admin/planoDiretor/projetos',
      },
    ],
  },
  {
    title: 'RELATÓRIOS',
    icon: <AssessmentIcon />,
    subLinks: [
      {
        title: 'Inventário',
        link: '/admin/relatorios/inventario',
        type: false,
      }, {
        title: 'Eventos',
        link: '/admin/relatorios/eventos',
        type: true,
      }, {
        title: 'Plano Diretor',
        link: '/admin/relatorios/planoDiretor',
        type: true,
      }, {
        title: 'Estudo de Demanda',
        link: '/admin/relatorios/estudosDemanda',
        type: true,
      },
    ],
  },
  {
    title: 'CORREÇÕES',
    icon: <BackspaceIcon />,
    link: '/admin/correcoes',
  },
  {
    title: 'PENDÊNCIAS',
    icon: <AssignmentTurnedInIcon />,
    link: '/admin/pendencias',
  },
  {
    title: 'TAXONOMIA',
    icon: <NatureIcon />,
    link: '/admin/taxonomia',
  },
  {
    title: 'USUÁRIOS',
    icon: <GroupIcon />,
    link: '/admin/usuarios',
  },
];   
