import React from 'react';

import '../assets/css/rodapeAdmin.css';

export function RodapeAdmin() {
  return (
    <div className='RodapeAdmin' id='RodapeAdmin'>
        <p className='txt_rodape_admin'>Destinos Inteligentes TI Ltda - Todos os direitos reservados.</p>
    </div>
  );
}