import React, { useState, useEffect } from 'react';

// CSS import
import '../../assets/css/InputComponents/dateInput.css';

export default function DateInput({ title, field, type, value, setValue, doc, objectPend, startDate, endDate }) {
  const [date, setDate] = useState(handleSetDateItem(field));
  const [minDate, setMinDate] = useState(handleSetDate(startDate));
  const [maxDate, setMaxDate] = useState(handleSetDate(endDate));

  function handleSetDate(fieldDate) {
    if (value[fieldDate] && value[fieldDate].seconds && (field === startDate || field === endDate)) {
      const typeDate = new Date(value[fieldDate].seconds * 1000);

      return typeDate.toISOString().slice(0, new Date().toISOString().lastIndexOf(':'));
    }
    
    return '';
  }
  
  function handleSetDateItem(field) {
    if (objectPend)
      return getStringDate(objectPend.currDoc[field]);

    return value[field] ? getStringDate(value[field]) : null;
  }

  function getStringDate(valueDate) {
    if (!valueDate) return null;

    let typeDate = valueDate;

    if (valueDate.seconds || valueDate._seconds)
      typeDate = new Date((valueDate.seconds || valueDate._seconds) * 1000);
    else 
      return null;
    
    return handleFormatDate(typeDate);
  }

  function handleMinDate(type) {
    if (!type && value[startDate] && !value[startDate].seconds && !value[startDate]._seconds) {
      setMinDate(handleFormatDate(value[startDate]));
    }

    if (type && value[endDate] && !value[endDate].seconds && !value[endDate]._seconds) {
      setMaxDate(handleFormatDate(value[endDate]));
    }
  }

  function pad(number) {
    if (number < 10) {
      return '0' + number;
    }
    return number;
  }

  function handleFormatDate(date) {
    let textDate = '';

    textDate = date.getUTCFullYear() +
        '-' + pad(date.getUTCMonth() + 1) +
        '-' + pad(date.getDate()) +
        'T' + pad(date.getHours()) +
        ':' + pad(date.getMinutes());

    return textDate;
  }

  useEffect(() => {
    if (date)
      setValue({ ...value, [field]: new Date(date) });
  }, [date]);

  useEffect(() => {
    if (field === endDate) {
      handleMinDate(0);
      setMaxDate('');
    }
  }, [value[startDate]]);

  useEffect(() => {
    if (field === startDate) {
      handleMinDate(1);
      setMinDate('');
    }
  }, [value[endDate]]);

  return (
    <div className="DateInput">
      <div className='div_label_input_date'>
        <label htmlFor={field + 'Form'} className="label_text_input">
          <strong>{title}</strong>
        </label>
      </div>
      <div className="div_out_input_date">
        <input type={type} id={field + 'Form'} className='input_text_inventory' name='appt' min={minDate} max={maxDate} onChange={(e) => setDate(e.target.value)} value={date} />
      </div>
    </div>
  );
}