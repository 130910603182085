import React, { useEffect, useState } from 'react';
import firebase from '../../config/Firebase';
import Loading from '../Loading';
import '../../assets/css/InputComponents/inputFilters.css';

export default function SelectMultipleFilter({ title, field, value, setValue, explanation, route }) {
  const [pontosApoio, setPontosApoio] = useState([]);
  const [selectedPontosApoio, setSelectedPontosApoio] = useState([]);
  const [filtersCity, setFiltersCity] = useState('');
  const [filtersType, setFiltersType] = useState('');
  const [filtersSubtype, setFiltersSubtype] = useState('');
  const [cities] = useState(route.Cities);
  const [subtypes, setSubtypes] = useState([]);
  const [specialties, setSpecialties] = useState([]);
  const [filtersSpecialty, setFiltersSpecialty] = useState('');
  const [tipos] = useState([
    { value: 'Attraction', title: 'Atrativo' },
    { value: 'Commerce', title: 'Compras' },
    { value: 'Food', title: 'Alimentação' },
    { value: 'Infrastructure', title: 'Infraestrutura' },
    { value: 'Lodging', title: 'Hospedagem' },
    { value: 'Services', title: 'Serviços' },
  ]);
  
  async function handleSubtypes() {
    if (filtersType !== '') {
      const docs = await firebase.firestore().collection('Taxonomia')
        .where('Type', '==', filtersType)
        .orderBy('Subtype')
        .get();
      
      const subtypes = [];
 
      docs.docs.map((sub) => {
        subtypes.push({ title: sub.data().Subtype, value: sub.data().Subtype });
      });
      setSubtypes(subtypes);
    } else {
      setSubtypes([]);
    }

    setFiltersSubtype('');
  }

  async function handleEspecialidade() {
    if (filtersSubtype !== '') {
      const docs = await firebase.firestore().collection('Taxonomia')
        .where('Type', '==', filtersType)
        .where('Subtype', '==', filtersSubtype)
        .get();
      
      if (docs.docs[0]) {
        const docsFil = docs.docs[0].data().Especialidade.sort();
        const especialidades = [];

        docsFil.forEach((espec) => {
          especialidades.push({ title: espec, value: espec });
        });

        setSpecialties(especialidades);
      } else {
        setSpecialties([]);
      }
    } else {
      setSpecialties([]);
    }

    setFiltersSpecialty('');
  }

  async function handleRoutes() {
    const db = firebase.firestore();
    
    db.collection('Attractions')
      .where('City', 'in', cities)
      .orderBy('Title')
      .get()
      .then((snapshot) => {
        const data = snapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
        setPontosApoio(data);
      });
  }

  useEffect(() => {
    handleRoutes();
  }, []);

  useEffect(() => {
    handleSubtypes();
  }, [filtersType]);

  useEffect(() => {
    handleEspecialidade();
  }, [filtersSubtype]);

  useEffect(() => {
    if (pontosApoio && pontosApoio.length > 0 && value[field] && value[field].length > 0)
      setSelectedPontosApoio([...new Set(pontosApoio.filter((item) => value[field].includes(item.id)))]);
  }, [pontosApoio]);

  const handleAttractionChange = (attraction) => {
    const isSelected = selectedPontosApoio.map((item) => item.id).includes(attraction.id);
    if (isSelected) {
      setSelectedPontosApoio(selectedPontosApoio.filter((id) => id.id !== attraction.id));
    } else {
      setSelectedPontosApoio([...selectedPontosApoio, attraction]);
    }
  };

  useEffect(() => {
    setValue({ ...value, [field]: [...new Set(selectedPontosApoio.map((attraction) => attraction.id))] });
  }, [selectedPontosApoio]);

  const filteredPontosApoio = pontosApoio.filter((ponto) => {
    if (selectedPontosApoio.map((item) => item.id).includes(ponto.id))
      return true;

    if (filtersCity && ponto.City !== filtersCity)
      return false;

    if (filtersType && ponto.Type !== filtersType)
      return false;

    if (filtersSubtype && ponto.Subtype !== filtersSubtype)
      return false;

    if (filtersSpecialty && ponto.Especialidade !== filtersSpecialty)
      return false;

    return true;
  });

  if (!pontosApoio) {
    return <Loading />;
  }

  return (
    <div className='inputFilters'>
      <div className='div_label_input_textarea'>
        <label htmlFor={field + 'Form'} className="label_text_input">
          <strong>{title}</strong>
        </label>
      </div>
      <div className='div_align_explanation_input_do_nelli'>
        <div className="filter-dropdown">
          <select value={filtersCity} onChange={(e) => setFiltersCity(e.target.value)}>
            <option value="">Todas cidades</option>
            {
              cities.map((city) => {
                return <option key={city} value={city}>{city}</option>;
              })
            }
          </select>
          <select value={filtersType} onChange={(e) => setFiltersType(e.target.value)} >
            <option value="">Todos tipos</option>
            {
              tipos.map((val, index) => {
                return <option key={val.value + index} value={val.value}>{val.title}</option>;
              })
            }
          </select>
          <select value={filtersSubtype} onChange={(e) => setFiltersSubtype(e.target.value)}>
            <option value="">Todos subtipos</option>
            {
              subtypes.map((val, index) => {
                return <option key={val.value + index} value={val.value}>{val.title}</option>;
              })
            }
          </select>
          <select value={filtersSpecialty} onChange={(e) => setFiltersSpecialty(e.target.value)}>
            <option value="">Todas especialidades</option>
            {
              specialties.map((val, index) => {
                return <option key={val.value + index} value={val.value}>{val.title}</option>;
              })
            }
          </select>
        </div>
        <div className='div_align_multiple_select_do_nelli'>
          <div className='select_inventory_do_nelli'>
            {filteredPontosApoio.map((attraction) => (
            <div key={attraction.id}>
              <label>
              <input type="checkbox" checked={selectedPontosApoio.map((item) => item.id).includes(attraction.id)} onChange={() => handleAttractionChange(attraction)} />
                {attraction.Title}
              </label>
            </div>
            ))}
          </div>
        </div>
        <div className='div_explanation_input'>
          <p className='subtext_explanation_input'>{explanation}</p>
        </div>
      </div>
    </div>
  );
}
