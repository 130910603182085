import React from 'react';

// CSS import
import '../../assets/css/Dashboard/cardInfo.css';

export default function EventsCityInfo({ eventsLastYear, eventsThisYear, nextEvents, totalEvents }) {
  return (
    <div className='EventsCityInfo card_container_dash'>
      <div className='div_top_header_dash'>
        <h1 className='title_cardInfo'>Eventos</h1>
        <button className='btn_card_info' disabled>Atual</button>
      </div>
      <div className='div_footer_dash'>
        <div>
          <h1 className='h1_cardInfo'>{ eventsLastYear }</h1>
          <p className='txt_cardInfo'>Ano Anterior</p>
        </div>
        <div>
          <h1 className='h1_cardInfo'>{ eventsThisYear }</h1>
          <p className='txt_cardInfo'>Realizados ano</p>
        </div>
        <div>
          <h1 className='h1_cardInfo'>{ nextEvents }</h1>
          <p className='txt_cardInfo'>A Realizar</p>
        </div>
        <div>
          <h1 className='h1_cardInfo'>{ totalEvents }</h1>
          <p className='txt_cardInfo'>Total no Ano</p>
        </div>
      </div>
    </div>
  );
}