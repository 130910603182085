import React, { useState } from 'react';

import HandleSize from '../../assets/imgs/handle_size_icon.JPG';
import TrashIcon from '../../assets/imgs/trash_icon_gray.svg';

import '../../assets/css/headerForm.css';
import Loading from '../Loading';

export default function HeaderQuestion({ index, questions, setQuestions, publish, deleteQuestions, setDeleteQuestions, idQuestions, setIdQuestions }) {
  const [loading, setLoading] = useState(false);

  function handleSizeFormQuestion() {
    const isOpen = document.getElementById('div_align_form_question' + index).clientHeight > 100;

    if (isOpen) {
      document.getElementById('div_align_form_question' + index).style = 'height: 8vh';
      document.getElementById('img_handle_size_question' + index).style = 'transform: rotate(-180deg);';
    } else {
      document.getElementById('div_align_form_question' + index).style = 'height: fit-content';
      document.getElementById('img_handle_size_question' + index).style = 'transform: rotate(0);';
    }
  }

  function handleDeleteQuestion() {
    setLoading(true);
    const newQues = questions.filter((ques, indexT) => indexT !== index);

    setDeleteQuestions([...deleteQuestions, idQuestions[index]]);
    setIdQuestions(() => idQuestions.filter((id, indexId) => indexId !== index));
    setQuestions(newQues);
    setLoading(false);
  }

  if (loading)
    return <Loading />;

  return (
    <div className='HeaderForm HeaderQuestion' id={'header_form_question' + index}>
      <p className='title_question'><strong>Pergunta {index + 1}</strong> preencha todos os campos abaixo sobre a pergunta.</p>
      <div className='div_align_handle_question_header'>
        <div className="div_handle_size_cards_score div_handle_question_header" onClick={!publish ? () => handleDeleteQuestion() : null} style={(publish ? { opacity: 0, cursor: 'default' } : {})}>
          <button type='button' className='btn_handle_size_calculator btn_handle_question' style={(publish ? { cursor: 'default' } : {})}>
            <img src={TrashIcon} alt='Icone de uma lixeira' className='img_handle_size_calculator img_handle_size_score img_handle_size_question' style={(publish ? { cursor: 'default' } : {})} />
          </button>
        </div>
        <div className="div_handle_size_cards_score div_handle_question_header" onClick={() => handleSizeFormQuestion()}>
          <button type='button' className='btn_handle_size_calculator btn_handle_question'>
            <img src={HandleSize} alt='Icone de uma seta para baixo' className='img_handle_size_calculator img_handle_size_score img_handle_size_question' id={'img_handle_size_question' + index} />
          </button>
        </div>
      </div>
    </div>
  );
}