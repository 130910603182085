import React from 'react';

// CSS Import
import '../../assets/css/InputComponents/textInput.css';

export default function TextInputQuestion({ title, field, type, value, item, setItem, placeholderText, explanation, index, publish }) {
  return (
    <div className="TextInput">
      <div className='div_label_input_text'>
        <label htmlFor={field + 'FormQuestion' + index} className="label_text_input">
          <strong>{title}</strong>
        </label>
      </div>
      <div className='div_align_explanation_input'>
        <div className="div_out_input_text">
          <input type={type} id={field + 'FormQuestion' + index} onChange={(e) => setItem(Object.values({ ...item, [index]: { ...value, [field]: e.target.value } })) } placeholder={placeholderText || ''} value={value[field]} disabled={publish} />
        </div>
        <div className='div_explanation_input'>
          <p className='subtext_explanation_input'>{explanation}</p>
        </div>
      </div>
    </div>
  );
}