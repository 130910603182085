import React, { useEffect, useState, useContext } from 'react';
import firebase from '../../config/Firebase';
import '../../css/InventoryAdmin.css';
import RegionsList from '../../component/ListComponents/RegionsList';
import Loading from '../../component/Loading';
import EditRegions from '../../component/EditComponents/EditRegions';
import VerifyPopup from '../../component/VerifyPopup';
import { RodapeAdmin } from '../../component/RodapeAdmin';
import NoResultsComponent from '../../component/NoResultsComponent';

// Assets imports
import SearchIcon from '../../assets/imgs/search_icon.svg';

// Context import
import { MyContext } from '../../component/SidebarAdmin';

export default function EventsAdmin() {
  const [places, setPlaces] = useState();
  const [action, setAction] = useState('Editar');
  const [verifyPopup, setVerifyPopup] = useState(false);
  const [editPopup, setEditPopup] = useState(false);
  const [selectedItem, setSelectedItem] = useState();
  const [filter, setFilter] = useState('name');
  const [search, setSearch] = useState('');
  const [isLoadingCities, setIsLoadingCities] = useState(true);
  const [filteredPlaces, setFilteredPlaces] = useState();
  const { city, setNumPendency } = useContext(MyContext);

  const buildQuery = () => {
    const currFilter = filter === 'searchBar' ? 'name' : filter;
    setIsLoadingCities(true);

    const docs = firebase.firestore()
      .collection('Cities')
      .orderBy(currFilter)
      .where('regiao', '==', true);
    
    setIsLoadingCities(false);

    return docs;
  };

  const getFirstPage = async () => {
    if (city) {
      const newDocs = await buildQuery().get();

      setFilteredPlaces(newDocs);
      setPlaces(newDocs);
    }
  };

  const handleSearch = () => {
    if (places.docs) {
      let filteredPlaces = places.docs.filter((doc) => searchRegionText(doc));
      filteredPlaces = { docs: filteredPlaces };
      setFilteredPlaces(filteredPlaces);
    }
  };

  const searchRegionText = (doc) => {
    if (search === '')
      return true;
    
    const isInName = doc.data().name.toLowerCase().includes(search.toLowerCase());
    const isInDescription = doc.data().longDescr?.toLowerCase().includes(search.toLowerCase());

    return isInName || isInDescription;
  };

  useEffect(() => {
    getFirstPage();
  }, [city, filter, editPopup]);

  useEffect(() => {
    if (!editPopup) {
      setSelectedItem(undefined);
      if (city)
        getFirstPage();
    }

    window.scrollTo(0, 0);
  }, [editPopup]);

  const handleDeleteRegion = async (doc) => {
    try {
      const pendency = {
        action: 'Excluir',
        city: city,
        createDate: new Date(),
        currDoc: {},
        lastDoc: doc.data(),
        requestor: (await getUserInfo()).name,
        type: 'Cities',
        emailRequester: (await getUserInfo()).email,
        docId: doc.id,
      };
      await firebase.firestore().collection('Pendencies').add(pendency);
      setNumPendency((num) => num + 1);
      setVerifyPopup(false);
      setSelectedItem(undefined);
      getFirstPage();
    } catch (error) {
      setVerifyPopup(false);
    }
  };

  async function getUserInfo() {
    const userUid = firebase.auth().currentUser.uid;
    const userData = await firebase.firestore().collection('users').doc(userUid).get();

    return userData.data();
  }

  const handleEdit = (doc) => {
    setSelectedItem(doc);
    setAction('Editar');
    setEditPopup(true);
  };

  const handleDelete = (doc) => {
    setSelectedItem(doc);
    setVerifyPopup(true);
  };

  if (!places) {
    return <Loading />;
  }

  return (
    <div className='InventarioAdmin'>
      { editPopup ? <EditRegions setEditPopup={setEditPopup} doc={selectedItem} action={action} setNumPendency={setNumPendency} /> : (<div className='body_inventory_adm'>
        <div className='div_title_inventory_adm'>
          <h1>Regiões</h1>
        </div>
        <div className='div_create_inventory_adm'>
          <p className='create_inventory_adm' onClick={() => { setAction('Criar'); setEditPopup(true); }}>Criar Nova Região</p>
        </div>
        <div className='div_browse_inventory_adm'>
          <input type='text' className='input_browse_inventory' onChange={(e) => { setSearch(e.target.value); handleSearch(); }} placeholder='Buscar' />
          <button type="button" className='btn_browse_inventory_adm' disabled>
            <img src={SearchIcon} alt='Icone de pesquisa' className='search_icon_admin'/>
          </button>
        </div>
        { filteredPlaces && filteredPlaces.docs && filteredPlaces.docs.length > 0 ? (
        <div className='div_table_inventory_adm'>
          <table className='table_inventory_adm'>
            <thead>
              <tr className="row_table_inventory th_table_admin">
                <td className='column_title_table_inventory th_table_admin'>Nome</td>
                <td className='column_table_inventory th_table_admin'>Coordenadas</td>
                <td className='column_desc_table_inventory th_table_admin'>Cidades</td>
                <td className='column_table_inventory th_table_admin'>Status</td>
                <td className='column_table_inventory th_table_admin'></td>
              </tr>
            </thead>
            <tbody>
              {
                filteredPlaces.docs.map((doc) => {
                  return <RegionsList key={doc.id} doc={doc} handleEdit={handleEdit} handleDelete={handleDelete} />;
                })
              }
            </tbody>
          </table>
        </div>) : <NoResultsComponent title={'Regiões'} search={search} /> }
        { verifyPopup ? <VerifyPopup setVerifyPopup={setVerifyPopup} action={'Excluir'} handleAction={handleDeleteRegion} type={'Região'} doc={selectedItem} /> : null}   
      </div>)}
    </div>
  );
}
