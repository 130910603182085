import React, { useState, useEffect } from 'react';

// CSS import
import '../../assets/css/InputComponents/singleImageInput.css';

// ComponentsImports
import Loading from '../Loading';

import { generatorRandowString } from '../../utils/limitStr';

export default function SingleImageInput({ field, title, value, explanation, doc, objectPend, image, setImage }) {
  const [loadingAllImage, setLoadingAllImage] = useState(!!doc);

  useEffect(() => {
    const handleImage = (doc) => {
      try {
        setLoadingAllImage(true);

        const imageName = doc[field];

        if (imageName) {
          setImage({
            url: imageName,
            image: '',
            name: '',
          });
        }
        setLoadingAllImage(false);
      } catch (error) {
        setLoadingAllImage(false);
      }
    };
    
    if (doc || objectPend) {
      handleImage(objectPend ? objectPend.currDoc : doc.data());
    }
  }, [doc]);

  const handleAddImage = async (src) => {
    const file = src.files[0];
    
    let nameImage;
    
    if (value.name !== '') {
      nameImage = value.name + '.jpg';
    } else {
      nameImage = generatorRandowString();
      nameImage += '.' + file.name.split('.').pop();
    }

    setImage({
      url: URL.createObjectURL(file),
      image: file,
      name: nameImage,
    });
  };

  if (loadingAllImage)
    return <Loading />;

  return (
    <div className='ImageInput'>
      <div className='div_align_image_input'>
        <div className='div_label_input_image'>
          <label htmlFor={field + 'Form'} className="label_text_input">
          <strong>{title}</strong>
          </label>
        </div>
        <div className='div_align_explanation_input'>
          <div className='div_field_input_image'>
            <div className='div_align_input_image'>
              <button type='button' className='btn_image_inventory' onClick={() => document.getElementById('input_file_inventory' + field).click()}>Carregar Fotos</button>
              <input className='input_file_inventory' type='file' id={'input_file_inventory' + field} accept='image/png, image/jpeg, image/jpg' onChange={(event) => handleAddImage(event.target)} />
            </div>
            <div className='div_edit_list_images ' id='div_edit_list_images'>
              {
                image.url !== '' ? (
                  <img src={image.url} alt='Imagem selecionada' className='img_edit_inventory_admin div_align_single_image'/>
                ) : (
                  <div className='div_message_noimage_input' onClick={() => document.getElementById('input_file_inventory' + field).click()}>
                    <h1><strong>Nenhuma imagem selecionada.</strong></h1>
                    <p>Clique no botão ou aqui para carregar uma imagem.</p>
                  </div>
                )
              }
            </div>
          </div>
          <div className='div_explanation_input'>
            <p className='subtext_explanation_input'>{explanation}</p>
          </div>
        </div>
      </div>
    </div>
  );
}