/* eslint-disable quotes */
import React from 'react';
import TrashIcon from '../assets/imgs/trash_icon.svg';
import '../css/LocalImage.css';

export default function LocalImage({ url, handleDeleteImage, index }) { 
  return (
    <div className='LocalImage'>
        <img src={url} alt='Imagem do local' className='img_edit_inventory_admin' />
        <img src={TrashIcon} alt='Icone de uma lixeira para excluir' className='icon_trash_img' onClick={() => handleDeleteImage(index)} />
    </div>
  );
}