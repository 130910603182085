import React from 'react';
import { StyleSheet, css } from 'aphrodite';
import { Link, Redirect } from 'react-router-dom';
import firebase from '../config/Firebase';
import {
  Input,
} from 'reactstrap';
import Logo from '../assets/imgs/Destinos.png';
import AlertMessage from '../component/Alert';
import FacebookLogin from '../component/FacebookLogin';
import GoogleLogin from '../component/GoogleLogin';
import { PageView, Event } from '../config/TrackingGA';
import bg from '../assets/imgs/desk_bg.png';

class LogIn extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      email: '',
      password: '',
      errorMsg: '',
      errorFlag: false,
      toMap: false,
      loading: true,
    };
  }

  componentWillMount() {
    this.checkAuthentication();
    PageView();
  }

  checkAuthentication() {
    firebase.auth().onAuthStateChanged((user) => {
      if (user) this.props.history.push('/map');
      else this.setState({ loading: false });
    });
  }

  onKeyDown = (event) => {
    // 'keypress' event misbehaves on mobile so we track 'Enter' key via 'keydown' event
    if (event.key === 'Enter') {
      event.preventDefault();
      event.stopPropagation();
      this.login();
    }
  }

  handleFacebookCallback = (result) => {
    if (result === 'incomplete') {
      this.setState({ toSignUpWithSocialMedia: true, provider: 'Facebook' });
    } else if (result === 'complete') {
      this.setState({ toMap: true });
    } else {
      this.setState({ errorFlag: true, errorMsg: result });
    }
  }

  handleGoogleCallback = (result) => {
    if (result === 'incomplete') {
      this.setState({ toSignUpWithSocialMedia: true, provider: 'Facebook' });
    } else if (result === 'complete') {
      this.setState({ toMap: true });
    } else {
      this.setState({ errorFlag: true, errorMsg: result });
    }
  }

  handleChange = (name) => (event) => {
    this.setState({ [name]: event.target.value });
  }

  login = () => {
    if (this.state.email === '') {
      this.setState({
        errorMsg: 'Digite o seu email',
        errorFlag: true,
      });
    } else if (this.state.password === '') {
      this.setState({
        errorMsg: 'Digite a sua senha',
        errorFlag: true,
      });
    } else {
      firebase.auth().signInWithEmailAndPassword(this.state.email, this.state.password).then(() => {
        this.setState({ toMap: true });
      }).catch((error) => {
        // Email ou senha errados
        if (error.code === 'auth/invalid-email' || error.code === 'auth/wrong-password') {
          this.setState({
            errorMsg: 'Email ou senha inválido! Tente novamente',
            errorFlag: true,
          });
        } else if (error.code === 'auth/user-not-found') { // Email não cadastrado
          this.setState({
            errorMsg: 'Email não cadastrado',
            errorFlag: true,
          });
        }
      });
      Event('User', 'Login', 'LetsGoButtonLogin');
    }
  }

  forms = () => {
    return (
      <div style={{ flex: 1, justifyContent: 'center', alignItems: 'center' }}>
        <Input
          style={{ width: '320px', height: '45px', marginBottom: '10px', marginTop: '15px', color: 'grey' }}
          onClick={(e) => { e.target.focus(); }}
          type='email'
          name='email'
          placeholder='Email'
          value={this.state.email}
          onKeyDown={(e) => e.key === 'Enter' && this.login()}
          onChange={this.handleChange('email')}
        />
        <Input
          style={{ width: '320px', height: '45px', marginBottom: '20px', color: 'grey' }}
          onClick={(e) => { e.target.focus(); }}
          type='password'
          name='password'
          placeholder='Digite sua senha'
          value={this.state.password}
          onKeyDown={(e) => e.key === 'Enter' && this.login()}
          onChange={this.handleChange('password')}
        />
      </div>
    );
  }

  render() {
    if (this.state.loading) return <p>Loading...</p>;

    if (this.state.toMap) {
      return <Redirect to='/map' />;
    }

    if (this.state.toSignUpWithSocialMedia) {
      return (<Redirect to={{ pathname: '/signup-with-social-media', state: { provider: this.state.provider } }} />);
    }

    return (
      <div className={css(styles.pageBackground)}>
        <div className={css(styles.container)} style={{ overflowY: window.innerWidth <= 500 ? 'scroll' : 'none' }}>
          <img alt='' width='325px' src={Logo} style={{ marginTop: '50px' }} />
          <p style={{ paddingBottom: '15px', marginTop: '-12px' }}>Encontre destinos para se aventurar</p>
          {/* <FacebookLogin callback={(result) => { this.handleFacebookCallback(result); }} message='Entrar com o Facebook' />
          <GoogleLogin callback={(result) => { this.handleGoogleCallback(result); }} message='Entrar com o Google' />
          
          <div className={css(styles.row)}>
            <hr className={css(styles.line)} />
            <span className={css(styles.ouText)}>ou</span>
            <hr className={css(styles.line)} />
          </div> */}
          {this.forms()}
          {this.state.errorFlag && <AlertMessage color='warning' isOpen={this.state.errorFlag} onToggle={() => { this.setState({ errorFlag: false }); }} msg={this.state.errorMsg} />}

          <div className={css(styles.login, styles.collumn)}>
            <button onClick={this.login} className={css(styles.letsGoButton)}>Let's Go!</button>
          </div>

          <div className={css(styles.collumn)}>
            <span>
              Ainda não tem uma &nbsp;
              <button
              type="button"
              className="link-button"
              onClick={() => this.props.history.push('/signup')}
              style={{ color: 'white', cursor: 'pointer', textDecoration: 'underline', backgroundColor: 'transparent', border: 'none', display: 'inline', margin: 0, padding: 0 }}>
                conta?
                </button>

            </span>
            {/* <Link to='/signup'><span className={css(styles.signUp)}>Cadastre-se com email</span></Link> */}
            <Link to='/forgotpassword'><span className={css(styles.signUp)}>Esqueci minha senha</span></Link>
          </div>

        </div>
      </div>
    );
  }
}

export default LogIn;

const styles = StyleSheet.create({
  collumn: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    textAlign: 'center',
  },
  container: {
    display: 'flex',
    flex: 1,
    maxWidth: '400px',
    alignItems: 'inherit',
    justifyContent: 'space-around',
    flexDirection: 'column',
    maxHeight: '100%',
    textSize: '17px',
  },
  letsGoButton: {
    backgroundColor: 'none',
    background: 'inherit',
    boxShadow: 'none',
    borderStyle: 'solid',
    borderColor: 'white',
    borderWidth: '1px',
    borderRadius: '40px',
    fontSize: '30px',
    color: 'white',
    paddingLeft: '35px',
    paddingRight: '35px',
    marginBottom: '20px',
    ':hover': {
      borderColor: '#c4c4c4',
      color: '#c4c4c4',
    },
  },
  line: {
    overflow: 'hidden',
    marginTop: '.8rem',
    width: '48%',
    color: 'white',
    backgroundColor: 'white',
  },
  ouText: {
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    paddingLeft: '5px',
    paddingRight: '5px',
  },
  pageBackground: {
    color: 'white',
    backgroundImage: `url(${bg})`,
    backgroundRepeat: 'no-repeat',
    backGroundPosition: 'center',
    backgroundSize: 'cover',
    backGroundRepeat: 'no-repeat',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    minHeight: '100%',
    maxHeight: '100%',
    width: '100%',
    overflowY: 'auto',
  },
  row: {
    display: 'flex',
    width: '100%',
    flexDirection: 'row',
    maxWidth: '320px',
    marginTop: '15px',
  },
  signUp: {
    cursor: 'pointer',
    color: 'white',
    textDecoration: 'underline',
  },
  subtitleText: {
    textAlign: 'center',
    maxWidth: '250px',
    marginTop: '10px',
    marginBottom: '10px',
  },
});
