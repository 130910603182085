import Firebase from 'firebase';
import 'firebase/auth';

// Required for side-effects
require('firebase/firestore');

// Initialize Firebase
const config = {
  apiKey: 'AIzaSyAhbOMOLuz12mI0_y7sgqja4M3G-zJwoLc',
  authDomain: 'portal-d6118.firebaseapp.com',
  databaseURL: 'https://portal-d6118.firebaseio.com',
  projectId: 'portal',
  storageBucket: 'portal.appspot.com',
  messagingSenderId: '397033828221',
};
const firebase = Firebase.initializeApp(config);
firebase.firestore().settings({ timestampsInSnapshots: true });
export default firebase;
