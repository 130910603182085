/* eslint-disable import/no-extraneous-dependencies */
import React, { useEffect, useState } from 'react';

// Css import
import '../../assets/css/EditComponents/EditInventory.css';

// Assets imports
import VerifyPopup from '../VerifyPopup';
import firebase from '../../config/Firebase';
import { handleStringFormat } from '../../utils/limitStr';
import { fieldsEditRegions } from '../FieldsForm/regions.fields';

import TitleEditor from '../FormComponents/TitleEditor';
import ButtonForm from '../FormComponents/ButtonForm';
import HeaderForm from '../FormComponents/HeaderForm';
import { inputsComponents } from '../../utils/formManager';

export default function EditRegions({ setEditPopup, doc, action, pendency, objectPend, setObjectPend, setNumPendency }) {
  const [popup, setPopup] = useState(false);
  const [file, setFile] = useState();
  const [regionImage, setRegionImage] = useState({ url: '', image: '', name: '' });
  const [pinImage, setPinImage] = useState({ url: '', image: '', name: '' });
  const [item, setItem] = useState(handleItem());
  const typesImages = {
    regionImage: regionImage,
    pinImage: pinImage,
  };
  const typesSetImages = {
    regionImage: setRegionImage,
    pinImage: setPinImage,
  };

  function handleItem() {
    if (objectPend)
      return objectPend.currDoc;
      
    return doc ? doc.data() : {
      name: '',
      shortDescr: '',
      longDescr: '',
      neighborhood: [],
      regiao: true,
      desktopDefaultZoom: 10,
      mobileDefaultZoom: 11.2,
      location: { 
        latitude: 0.0,
        longitude: 0.0,
      },
      Visible: true,
      Active: true,
      original: {
        shortDescr: '',
        longDescr: '',
      },
    };
  }

  const handleEditRegion = async () => {
    try {
      const docData = await handleUploadFiles();
      const pendency = {
        action: 'Editar',
        city: item.name,
        createDate: new Date(),
        currDoc: docData,
        lastDoc: doc.data(),
        requestor: (await getUserInfo()).name,
        type: 'Cities',
        emailRequester: (await getUserInfo()).email,
        docId: doc.id,
      };
      await firebase.firestore().collection('Pendencies').add(pendency);
      setNumPendency((num) => num + 1);
      setEditPopup(false);
    } catch (error) {
      setEditPopup(false);
    }
  };

  async function getUserInfo() {
    const userUid = firebase.auth().currentUser.uid;
    const userData = await firebase.firestore().collection('users').doc(userUid).get();

    return userData.data();
  }

  const handleAddRegion = async () => {
    try {
      const docData = await handleUploadFiles();
      const pendency = {
        action: 'Criar',
        city: item.name,
        createDate: new Date(),
        currDoc: docData,
        lastDoc: {},
        requestor: (await getUserInfo()).name,
        type: 'Cities',
        emailRequester: (await getUserInfo()).email,
        docId: '',
      };
      await firebase.firestore().collection('Pendencies').add(pendency);
      setNumPendency((num) => num + 1);
      setEditPopup(false);
    } catch (error) {
      setEditPopup(false);
    }
  };

  async function saveImage(value, field, aux) {
    const ref = firebase.storage().ref(`cities/${item.name}/${value.name}`);
    await ref.put(value.image);

    const url = await ref.getDownloadURL(); 

    const newItem = { ...aux, [field]: url };
    return newItem;
  }

  async function handleKMLFile(file) {
    const ref = firebase.storage().ref(`cities-kml/${file.name}`);
    await ref.put(file);

    const url = await ref.getDownloadURL();

    return url;
  }

  async function savePinImage(value, field, aux) {
    const ref = firebase.storage().ref(`cities-pins/${value.name}`);
    await ref.put(value.image);

    const url = await ref.getDownloadURL(); 

    const newItem = { ...aux, [field]: url };
    return newItem;
  }

  async function handleUploadFiles() {
    let aux = item;
    if (regionImage.image !== '')
      aux = await saveImage(regionImage, 'defaultImage', aux);
    if (pinImage.image !== '')
      aux = await savePinImage(pinImage, 'ImagemPin', aux);

    if (file)
      aux = { ...aux, KMLFile: await handleKMLFile(file) };

    return aux;
  }

  useEffect(() => {
    if (item.name)
      setItem({ ...item, nameOrdem: handleStringFormat(item.name) });
  }, [item.name]);

  function handleCheckFields() {
    const keys = ['name', 'shortDescr', 'longDescr', 'neighborhood'];

    for (let i = 0; i < keys.length; i++) {
      if ((item[keys[i]] === '' || item[keys[i]] === 0) || (keys[i] === 'neighborhood' && item[keys[i]].length === 0)) {
        document.getElementById(keys[i]).focus();
        return;
      }
    }

    if (objectPend)
      handleEditPendency();
    else
      setPopup(true);
  }

  async function handleEditPendency() {
    const newPendency = { ...objectPend, currDoc: item };
    setObjectPend(newPendency);
    await firebase.firestore().collection('Pendencies').doc(pendency.id).set(newPendency)
      .then(() => setEditPopup(false));
  }

  return (
    <div className='EditInventory'>
        <div className='bodyEditInventory'>
          <TitleEditor action={action} type={'Região'} />
          <div>
            <form className='form_edit_admin'>
              <HeaderForm />
              {
                fieldsEditRegions.map((field) => {
                  return inputsComponents[field.component]({ term: field, item: item, setItem: setItem, doc: doc, userCity: false, image: typesImages[field.typeImage], setImage: typesSetImages[field.typeImage], styleType: field.styleType, setFile: setFile, objectPend: objectPend, setObjectPend: setObjectPend });
                })
              }
              { popup ? <VerifyPopup setVerifyPopup={setPopup} handleAction={doc ? handleEditRegion : handleAddRegion} title={`Deseja ${action} esta Região?`} message={'Ao realizar essas ações, elas serão gravadas na base de dados, e as novas informações poderão ser vistas posteriormente. Deseja continuar?'} /> : null }
              <ButtonForm setEditPopup={setEditPopup} handleCheckFields={handleCheckFields} />
            </form>
          </div>
        </div>
    </div>
  );
}