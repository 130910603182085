import React, { Component } from 'react';
import { FaRegHeart, FaHeart } from 'react-icons/fa';
import { IconContext } from 'react-icons';
import firebase from '../config/Firebase';

export default class Favorite extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selected: false,
    };
    this.retrieveData = this.retrieveData.bind(this);
    this.onClick = this.onClick.bind(this);
    this.retrieveData();
  }

  retrieveData() {
    const docRef = firebase.firestore().collection('TestRating').doc('1HRMTLYopcpJe2PfXBW8');
    docRef.onSnapshot((doc) => {
      if (doc.exists) {
        const data = doc.data().favorite;
        this.setState({ selected: data });
      } else {
        // doc.data() will be undefined in this case
        console.error('No such document!');
      }
    });
  }

  onClick() {
    const docRef = firebase.firestore().collection('TestRating').doc('1HRMTLYopcpJe2PfXBW8');

    this.setState({ selected: !(this.state.selected) }, () => {
      docRef.update({
        favorite: this.state.selected,
      });
    });
  }

  render() {
    const settings = {
      style: {
        color: '#e3a3ed',
        cursor: 'pointer',
        marginLeft: '0.5rem',
      },
      size: this.props.size,
    };

    return (
      <IconContext.Provider value = {settings}>
        {
          this.state.selected === true ? <FaHeart onClick={this.onClick} /> : <FaRegHeart onClick={this.onClick}/>
        }
      </IconContext.Provider>
    );
  }
}

Favorite.defaultProps = {
  size: 14,
};
