export const fieldsEditProject = [
  {    
    title: 'Titulo do Projeto*',
    field: 'titulo',
    type: 'text',
    boolType: false,
    component: 'text',
    placeholder: '',
  },
  {    
    title: 'Cidade',
    field: 'cidade',
    boolType: false,
    component: 'select',
    disable: true,
  },
  {    
    title: 'Diretriz*',
    field: 'diretriz',
    type: 'select',
    boolType: false,
    component: 'select',
  },
  {    
    title: 'Programa*',
    field: 'programa',
    type: 'select',
    boolType: false,
    component: 'select',
  },
  {    
    title: 'Resultado Esperado',
    field: 'resultadoEsperado',
    type: 'textarea',
    boolType: false,
    component: 'textarea',
    placeholder: '',
    explanation: '',
  },
  {    
    title: 'Responsáveis',
    field: 'responsaveis',
    type: 'textarea',
    boolType: false,
    component: 'textarea',
    placeholder: '',
    explanation: '',
  },
  {    
    title: 'Custo Aproximado',
    field: 'custoAproximado',
    type: 'number',
    boolType: false,
    component: 'text',
    placeholder: '',
  },
  {
    title: 'Percentual Concluído',
    field: 'percentualConcluido',
    type: 'range',
    component: 'range',
  },
  {    
    title: 'Prazo',
    field: 'prazos',
    type: 'radio',
    boolType: false,
    component: 'radio',
  },
  {    
    title: 'Status do Projeto',
    field: 'status',
    type: 'select',
    boolType: false,
    component: 'select',
  },
  {    
    title: 'Atividade*',
    field: 'active',
    type: 'select',
    boolType: true,
    component: 'select',
  },
];