import React from 'react';
import Swipe from 'react-easy-swipe';
import '../assets/css/eventCard.css';
import { connect } from 'react-redux';
import { convertDate, convertTime } from '../utils/parse';

class EventCard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      urls: [],
      index: 0,
    };
  }

  componentWillMount() {
    this.getEventImages(this.props);
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.document !== nextProps.document) {
      this.getEventImages(nextProps);
    }
  }

  componentWillUnmount() {
    if (this.imageInterval)
      clearInterval(this.imageInterval);
  }

  setImagesTimer = () => {
    this.imageInterval = setInterval(() => {
      let { index } = this.state;
      const { urls } = this.state;
      if (index < urls.length - 1)
        this.setState({ index: ++index });
      else
        this.setState({ index: 0 });
    }, 4500);
  }

  getEventImages = (props) => {
    const { imageFile, city } = props;

    if (imageFile) {
      const baseUrl = this.props.eventsDefaultImages[city][0];
      const slash = '%2F';
      let url = baseUrl.substring(0, baseUrl.indexOf('/o/'));
      const sulfix = '?alt=media';
      url += '/o/cities' + slash + city + slash + 'events' + slash;
  
      const urls = [];
      imageFile.forEach(async (name) => {
        urls.push(url + name + sulfix);
      });
      this.setState({ urls, index: 0 }, () => this.setImagesTimer());
    } else {
      this.setState({ urls: this.props.eventsDefaultImages[city], index: 0 });
    }
  }

  render() {
    const { handleSwipeLeft, handleSwipeRight, onClick, title, description, startDate } = this.props;
    const { urls, index } = this.state;
    return (
      <Swipe
      onSwipeLeft={handleSwipeLeft}
      onSwipeRight={handleSwipeRight}
      allowMouseEvents={true}
    >
      <div onClick={onClick} className='container'>
        <div className='photo-container'>
          {/* Arrumar esse src! */}
          <img src={urls[index]} alt='' style={{ maxHeight: '90%', maxWidth: '90%', borderRadius: 20 }}/>
        </div>
        <div className='data-container'>
          <p className='big-size-font'>{title}</p>
          <div className='pink-line' />
          <p className='medium-size-font'>{description}</p>
          <p className='small-size-font'>Data: {convertDate(startDate)}</p>
          <p className='small-size-font'>Horário: {convertTime(startDate)}</p>
        </div>
      </div>
    </Swipe>
    );
  }
}

const mapStateToProps = (state) => ({
  eventsDefaultImages: state.eventsDefaultImage,
});
export default connect(mapStateToProps)(EventCard);