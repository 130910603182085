import React from 'react';
import firebase from '../config/Firebase';
import { StyleSheet, css } from 'aphrodite';
import Logo from '../assets/imgs/Destinos.png';
import {
  Button, Form, FormGroup,
} from 'reactstrap';
import { Redirect } from 'react-router-dom';
import AlertMessage from '../component/Alert';
import { PageView } from '../config/TrackingGA';
import SingleSelect from '../component/CitiesSelector';

class SignUpWithSocialMedia extends React.Component {
  componentDidMount() {
    PageView();
  }

  constructor(props) {
    super(props);
    this.state = {
      city: '',
      errorMsg: '',
      errorFlag: false,
      warningMsg: '',
      warningFlag: false,
      toMap: false,
    };
  }

  componentWillMount() {
    const { search } = this.props.history.location;

    if (search.search('city') !== -1) {
      const city = search.substring(search.indexOf('city=') + 5, search.length);
      this.setState({ city: city }, () => this.handleSignUp());
    }
  }

  forms = () => {
    return (
      <Form style={{ textAlign: 'center' }}>
        <FormGroup className={css(styles.formGroup)}>
          <SingleSelect
            className={css(styles.citySelect)}
            placeholder={'Cidade'}
            value={this.state.city}
            name='city'
            onChange={({ value }) => this.setState({ city: value })}
          />
        </FormGroup>
        <Button className={css(styles.formButton)} onClick={ this.handleSignUp }>Cadastrar-se</Button>
      </Form>
    );
  }

  handleSignUp = (event) => {
    const { city } = this.state;

    this.setState({ errorMsg: '', errorFlag: false });
    if (event) event.preventDefault();

    if (city === '')
      this.setState({ errorMsg: 'Digite a sua cidade! ', errorFlag: true });
    else {
      const userId = firebase.auth().currentUser.uid;

      firebase.firestore().collection('users').doc(userId).update({
        city: city,
        completedSignUp: true,
      })
        .then(() => this.setState({ toMap: true }));
    }
  }

  render() {
    if (this.state.toMap)
      return <Redirect to='/map'/>;

    return (
        <div className={css(styles.pageBackground)}>
          <div className={css(styles.container, styles.collumn)}>
            <img alt='' width='250px' src={Logo}/>

            <span className={css(styles.subtitleText)}>
              Termine o cadastro preenchendo os campos abaixo!
            </span>

            { this.state.errorFlag && <AlertMessage color='error' isOpen={this.state.errorFlag} onToggle={ () => { this.setState({ errorFlag: false }); } } msg={this.state.errorMsg}/> }
            { this.state.warningFlag && <AlertMessage color='warning' isOpen={this.state.warningFlag} onToggle={ () => { this.setState({ warningFlag: false }); } } msg={this.state.warningMsg}/> }
            { this.forms() }

            <div className={css(styles.termos)}>
              <span>
                Ao se cadastrar, você concorda com nossos
                <strong>
                  Termos, Política de dados e política de Cookies.
                </strong>
              </span>
            </div>

          </div>
        </div>
    );
  }
}

const styles = StyleSheet.create({
  citySelect: {
    color: 'grey',
    width: '250px',
    cursor: 'pointer',
  },
  container: {
    maxWidth: '400px',
    alignItems: 'inherit',
    justifyContent: 'inherit',
  },
  errorMsg: {
    fontStyle: 'italic bold',
  },
  collumn: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
  },
  formButton: {
    width: '100%',
    backgroundColor: '#3C71D7',
    ':hover': {
      backgroundColor: '#3c8ed7',
    },
  },
  formGroup: {
    marginBottom: '0.2rem',
  },
  letsGoButton: {
    backgroundColor: 'none',
    background: 'inherit',
    boxShadow: 'none',
    borderStyle: 'solid',
    borderColor: 'white',
    borderWidth: '1px',
    borderRadius: '40px',
    fontSize: '30px',
    color: 'white',
    padding: '2px',
    paddingLeft: '35px',
    paddingRight: '35px',
    marginTop: '2px',
    cursor: 'pointer',
    ':hover': {
      borderColor: '#c4c4c4',
      color: '#c4c4c4',
    },
    marginBottom: '1rem',
  },
  line: {
    overflow: 'hidden',
    width: '48%',
    color: 'white',
    backgroundColor: 'white',
  },
  login: {
    marginTop: '10px',
    fontSize: '15px',
    textAlign: 'center',
  },
  ouText: {
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    paddingLeft: '5px',
    paddingRight: '5px',
  },
  pageBackground: {
    backgroundColor: 'lightblue',
    backgroundImage: 'linear-gradient(to bottom right, #db3d78, #3C71D7)',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: 'white',
    minHeight: '100%',
    height: 'fit-content',
  },
  row: {
    display: 'flex',
    width: '100%',
    maxWidth: '275px',
  },
  subtitleText: {
    textAlign: 'center',
    maxWidth: '250px',
    marginTop: '10px',
    marginBottom: '10px',
  },
  termos: {
    marginTop: '5px',
    textAlign: 'center',
    marginLeft: '100px',
    marginRight: '100px',
    fontSize: '11px',
  },
});

export default SignUpWithSocialMedia;
