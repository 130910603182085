import React from 'react';

// CSS import
import '../../assets/css/atractionList.css';

export default function UsersList({ doc, handleEdit }) {
  function handleCitiesAdmin() {
    if (!doc.data().cityAdmin) return 'Nenhuma';

    const cities = doc.data().cityAdmin;

    let textCities = '';

    for (let i = 0; i < cities.length - 1; i++) {
      textCities = textCities + cities[i] + ', ';
    }

    return textCities + cities[cities.length - 1] + '.';
  }

  return (
    <tr className="row_table_inventory body_table">
      <td className="title_table_inventory">{doc.data().name}</td>
      <td className="title_table_inventory">{doc.data().email}</td>
      <td className="title_table_inventory">{doc.data().role || 'Sem cargo'}</td>
      <td className="title_table_inventory">{handleCitiesAdmin()}</td>
      <td className="title_table_inventory">{doc.data().phoneNumber || 'Sem telefone'}</td>
      <td className="column_edit_table_inventory">
        <div className='div_mod_inventory'>
          <button className='btn_mod_adm' onClick={() => handleEdit(doc)}>Editar</button>
        </div>
      </td>
    </tr>
  );
}