import React from "react";

// CSS import
import '../../assets/css/InputComponents/inputCheckboxCustom.css';

export default function InputCheckbox({ handleAction, doc, listPend, nameCheck }) {
  return (
    <label className='label_check_pendencies'>
      <input type='checkbox' id='check_all_pendencies' onClick={(e) => handleAction(doc || e)} disabled={listPend ? listPend.values.length === 0 : false} className='checkbox_pendency' name={nameCheck} />
      <span className='checkmark_pendy'></span>
    </label>
  );
}