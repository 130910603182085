import React, { useState, useEffect } from "react";

// Firebase import
import firebase from '../../config/Firebase';

// Components imports
import VerifyPopup from '../VerifyPopup';
import Loading from '../Loading';
import ButtonForm from '../FormComponents/ButtonForm';
import TitleEditor from '../FormComponents/TitleEditor';
import HeaderForm from '../FormComponents/HeaderForm';
import { inputsComponents } from '../../utils/formManager';
import { fieldsEditProgram } from '../FieldsForm/program.fields';

export default function EditProgram({ doc, action, userCity, setEditPopup }) {
  const [popup, setPopup] = useState(false);
  const [item, setItem] = useState(doc ? doc.data() : {
    titulo: '',
    cidade: userCity,
    idDiretrizes: '',
    active: true,
    original: {
      titulo: '',
    },
  });

  const handleEditProgram = async () => {
    await firebase.firestore().collection('PlanoDiretor_Programas').doc(doc.id).set(item);
    setEditPopup(false);
  };

  const handleAddProgram = async () => {
    await firebase.firestore().collection('PlanoDiretor_Programas').add(item);
    setEditPopup(false);
  };

  function handleCheckFields() {
    const keys = ['titulo', 'idDiretrizes'];

    for (let i = 0; i < keys.length; i++) {
      if (item[keys[i]] === '') {
        document.getElementById(keys[i] + 'Form').focus();
        return;
      }
    }

    setPopup(true);
  }

  useEffect(() => {
    if (userCity)
      setItem({ ...item, cidade: userCity });
  }, [userCity]);

  useEffect(() => {
    setItem({ ...item, cidade: userCity });
  }, [item.cidade]);

  return (
    <div className='EditInventory'>
      <div className='bodyEditInventory'>
        <TitleEditor action={action} type={'Programa'} />
        <div>
          <form className='form_edit_admin'>
            <HeaderForm />
            {
              fieldsEditProgram.map((field) => {
                return inputsComponents[field.component]({ term: field, item: item, setItem: setItem, doc: doc, userCity: userCity });
              })
            }
            { popup ? <VerifyPopup setVerifyPopup={setPopup} handleAction={doc ? handleEditProgram : handleAddProgram} title={`Deseja ${action} este Programa?`} message={'Ao realizar essas ações, elas serão gravadas na base de dados, e as novas informações poderão ser vistas posteriormente. Deseja continuar?'} /> : null }
            <ButtonForm setEditPopup={setEditPopup} handleCheckFields={handleCheckFields} />
          </form>
        </div>
      </div>
    </div>
  );
}