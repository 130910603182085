import React from 'react';
import { Pins } from '../assets/imgs/index';
import { compose, withProps, lifecycle, withHandlers } from 'recompose';
import {
  withGoogleMap, GoogleMap, withScriptjs, KmlLayer,
} from 'react-google-maps';
// import MarkerClusterer from 'react-google-maps/lib/components/addons/MarkerClusterer';
import MarkerClusterer from 'react-google-maps/lib/components/addons/MarkerClusterer';
import MapCustomControl from './MapCustomControl';
import MapCenterButton from './MapCenterButton';
import MarkerWithOverlayView from './MarkerWithOverlayView';
import CloseDrawerButton from './CloseDrawerButton';
import FilterButton from './FilterButton';
import { store } from '../redux/store';
import { TOGGLE_PREVIEW, OPEN_DRAWER } from '../redux/actionTypes';
import Fuel from '../assets/imgs/pins/pin_gasolina.png';
import GreenSpace from '../assets/imgs/pins/pin_atrativos.png';
import Default from '../assets/imgs/pins/pin_ambulancia.png';
import SearchBar from './SearchBar';
import { Hidden } from '@material-ui/core';
import MobileAttractivePreview from './MobileAttractivePreview';
import { connect } from 'react-redux';
import EventButton from './EventButton';
import { css, StyleSheet } from 'aphrodite';
import { setLoading, updateUserLocation, centerMap, setMapZoom, closePreview, closeEvents } from '../redux/actions';
import DesktopAttractivePreview from './DesktopAttractivePreview';
import { EventWithParameters } from '../config/TrackingGA';
import '../assets/css/mapStyle.css';
import { Spiderfy } from './Spiderfy';

const mapStyles = [
  {
    featureType: 'poi',
    stylers: [
      { visibility: 'off' },
    ],
  },
];
function selectIcon(type, subType) {
  const List = [
    'Infrastructure',
    'Drugstore',
    'Lodging',
    'Food',
    'Services',
    'Attraction',
    'Bank',
    'Commerce',
    'Craftwork',
    'FuelStation',
    'Supermarket',
    'User',
  ];

  if (type === 'Fuel Station')
    return Fuel;

  if (type === 'Green Space')
    return GreenSpace;

  if (List.includes(type))
    return Pins[type];

  return Default;
}

const defaultDesktopOptions = {
  fullscreenControl: false,
  styles: mapStyles,
  gestureHandling: 'greedy',
};
const defaultMobileOptions = {
  fullscreenControl: false,
  styles: mapStyles,
  gestureHandling: 'greedy',
  mapTypeControl: false,
  zoomControl: false,
  streetViewControl: false,
};

const handlePreviewClick = async (attractive) => {
  store.dispatch({
    type: OPEN_DRAWER,
    attractive,
  });
  EventWithParameters('User', 'OpenAttractiveDrawer', 'AtrativoAberto', attractive.document, attractive.Type, attractive.Subtype);
};

export const MapComponent = compose(
  withProps({
    googleMapURL: 'https://maps.googleapis.com/maps/api/js?key=AIzaSyA9M3d7pLlXAAu4JiWyKZQhSaIdsDvNAJY&v=3.exp&libraries=geometry,drawing,places,visualization',
    loadingElement: <div style={{ height: '100%' }} />,
    containerElement: <div style={{ height: '100%' }} />,
    mapElement: <div style={{ height: '100%' }} />,
  }),
  connect(mapStateToProps, mapDispatchToProps),
  withScriptjs,
  withGoogleMap,
  withHandlers(() => {
    const refs = {
      map: undefined,
    };

    return {
      onMapMounted: () => (ref) => {
        refs.map = ref;
      },
      handleCenterClickButton: (props) => () => {
        // Checks if HTML5 geolocation is supported
        if (navigator.geolocation) {
          navigator.geolocation.getCurrentPosition((position) => { // Success callback
            props.updateUserLocation(position.coords.latitude, position.coords.longitude);
          }, () => { // Error callback
            console.log('erro ao pegar localização do usuário');
          });
        } else { // HTML5 geolocation is not suported
          alert('Navegador não supoorta sistema de geolocalização');
        }
        props.centerMap(props.userLocation);
        props.setMapZoom(16);
      },
    };
  }),
  lifecycle({
    componentWillMount() {
      const refs = {};

      this.setState({
        onMapMounted: (ref) => {
          refs.map = ref;
        },
        waitMapLoad: () => {
          if (refs.map !== undefined)
            return;
          const timer = setInterval(() => {
            if (refs.map !== undefined) {
              clearInterval(timer);
            }
          }, 100);
        },
        waitBoundsLoad: () => {

        },
        changeMapCenter: async (location, singleAttractive = false) => {
          const { latitude, longitude, marker } = location;
          const { lat, long } = location;
          const { isEventDrawerOpen, isDrawerOpen } = this.props;
          const rightLat = lat !== undefined ? lat : latitude;
          const rightLong = long !== undefined ? long : longitude;

          await this.state.waitMapLoad(); // Nos casos de compartilhamento, será dado o comando para centralizar o marker, mas para isso o mapa precisa estar carregado

          if ((((isDrawerOpen || isEventDrawerOpen) && marker) || singleAttractive) && window.innerWidth > 500) {
            // edimar
            const newPosition = new Promise(async (resolve) => {
              await refs.map.panTo({ lat: rightLat, lng: rightLong });

              // const newPosition = new Promise((resolve) => {
              //  refs.map.panTo({ lat: rightLat, lng: rightLong });
              let bounds = refs.map.getBounds();
              if (bounds !== undefined) {
                const longI = bounds[Object.keys(bounds)[1]][Object.keys(bounds[Object.keys(bounds)[0]])[0]];
                const longJ = bounds[Object.keys(bounds)[1]][Object.keys(bounds[Object.keys(bounds)[0]])[1]];
                const latI = bounds[Object.keys(bounds)[0]][Object.keys(bounds[Object.keys(bounds)[0]])[0]];
                const latJ = bounds[Object.keys(bounds)[0]][Object.keys(bounds[Object.keys(bounds)[0]])[1]];

                const diffLong = Math.abs(longI - longJ);
                const diffLat = Math.abs(latI - latJ);

                const newLong = Math.min(longI, longJ) + diffLong * 0.65;
                const newLat = Math.min(latI, latJ) + diffLat * 0.35;
                resolve({ newLat: newLat, newLong: newLong });
              } else {
                const timer = setInterval(() => {
                  bounds = refs.map.getBounds();
                  if (bounds !== undefined) {
                    clearInterval(timer);
                    const longI = bounds[Object.keys(bounds)[1]][Object.keys(bounds[Object.keys(bounds)[0]])[0]];
                    const longJ = bounds[Object.keys(bounds)[1]][Object.keys(bounds[Object.keys(bounds)[0]])[1]];
                    const latI = bounds[Object.keys(bounds)[0]][Object.keys(bounds[Object.keys(bounds)[0]])[0]];
                    const latJ = bounds[Object.keys(bounds)[0]][Object.keys(bounds[Object.keys(bounds)[0]])[1]];

                    const diffLong = Math.abs(longI - longJ);
                    const diffLat = Math.abs(latI - latJ);

                    const newLong = Math.min(longI, longJ) + diffLong * 0.65;
                    const newLat = Math.min(latI, latJ) + diffLat * 0.35;
                    resolve({ newLat: newLat, newLong: newLong });
                  }
                }, 100);
              }
            });
            newPosition.then((positions) => {
              const { newLat, newLong } = positions;
              refs.map.panTo({ lat: newLat, lng: newLong });
            });
          } else {
            refs.map.panTo({ lat: rightLat, lng: rightLong });
          }
        },
      });
    },
    componentWillReceiveProps(newProps) {
      const { centerMapLocation } = newProps;
      const { centerMapLocation: old } = this.props;

      if (centerMapLocation && old && centerMapLocation.lat && centerMapLocation.long && (centerMapLocation.lat !== old.lat || centerMapLocation.long !== old.long)) {
        const { lat, long } = centerMapLocation;
        this.state.changeMapCenter({
          latitude: lat,
          longitude: long,
          marker: true,
        });
      }
    },
  }),
)((props) => (
  <GoogleMap
    zoom={props.mapZoom || props.defaultZoom}
    defaultZoom={props.defaultZoom}
    ref={props.onMapMounted}
    defaultCenter={{ lat: props.centerLocation.latitude, lng: props.centerLocation.longitude }}
    defaultOptions={props.isMobile ? defaultMobileOptions : defaultDesktopOptions}
    onClick={() => {
      if (props.isEventsPreviewOpen && props.isPreviewOpen) {
        if (props.lastPreviewOpened === 'event')
          props.closeEvents();
        else
          props.closePreview();
      } else if (props.isEventsPreviewOpen)
        props.closeEvents();
      else if (props.isPreviewOpen)
        props.closePreview();

      if (document && document.activeElement && window.innerWidth <= 500) {
        document.activeElement.blur();
      }
    }}
    onDragStart={() => {
      if (document && document.activeElement && window.innerWidth <= 500) {
        document.activeElement.blur();
      }
    }}
    onTilesLoaded={() => props.setLoading(false)}
  >
    <div style={{ position: 'absolute', bottom: '30%', right: '4px' }} onClick={ () => props.handleCenterClickButton(props.userLocation.lat, props.userLocation.long) }>
      <MapCenterButton />
    </div>

    <MapCustomControl position={window.google.maps.ControlPosition.RIGHT_CENTER}>
      <div id='drawerCloseButton' style={{ visibility: ((props.isDrawerOpen || props.isEventDrawerOpen) && !props.isMobile && !props.searching) ? 'visible' : 'hidden' }}>
        <CloseDrawerButton />
      </div>
    </MapCustomControl>

    <MapCustomControl position={props.isMobile ? window.google.maps.ControlPosition.BOTTOM_CENTER : window.google.maps.ControlPosition.LEFT_CENTER}>
      <div className={css(styles.buttons)}>
        <FilterButton mapCenter={(location) => props.changeMapCenter(location)} isMobile={props.isMobile}/>
        {// edimar - adicionado Botao tipo roteiro
          props.isMobile &&
            <div style={{ marginLeft: 10 }}>
              <Hidden mdUp><EventButton/></Hidden>
            </div>
        }
      </div>
    </MapCustomControl>

    <MapCustomControl position={window.google.maps.ControlPosition.TOP_CENTER}>
      <Hidden mdUp><SearchBar/></Hidden>
    </MapCustomControl>

    <MobileAttractivePreview
      handleClick={(attractive) => { handlePreviewClick(attractive); }}
      isMobile={props.isMobile}
    />
    <DesktopAttractivePreview
      handleClick={(attractive) => { handlePreviewClick(attractive); store.dispatch({ type: TOGGLE_PREVIEW }); }}
      isMobile={props.isMobile}
    />

    <KmlLayer
      url={props.KMLFile}
      options = {{ preserveViewport: true }}
    />
    {
      !props.loading &&
        <MarkerClusterer
          maxZoom={18}
          averageCenter
          enableRetinaIcons
          gridSize={80}
          zoomOnClick={true}
          onClick={() => {
            if (document && document.activeElement && window.innerWidth <= 500) {
              document.activeElement.blur();
            }
          }}
        >
          <Spiderfy isMobile={props.isMobile} >
            {props.filtered.map((filtered, i) =>
              <MarkerWithOverlayView
                handleCenterClick={() => props.changeMapCenter({ latitude: filtered.lat, longitude: filtered.long, marker: true }, !props.singleAttractive)}
                key = { i }
                position = { { lat: filtered.lat, lng: filtered.long } }
                icon={{ url: selectIcon(filtered.Type, filtered.Subtype), scaledSize: new window.google.maps.Size(48, 48) }}
                attractive={filtered}
              />)}
        </Spiderfy>

        {

                     // Atualiza Localização do Usuário
                     // Edimar
                     !isNaN(props.userLocation.lat) &&
                    (props.userLocation.lat) && (
                    <MarkerWithOverlayView
                        handleCenterClick={() => props.changeMapCenter({ latitude: props.userLocation.lat, longitude: props.userLocation.long, marker: true }, false)}
                        key={999}
                        position={{ lat: props.userLocation.lat, lng: props.userLocation.long }}
                        icon={{ url: selectIcon('User', null), scaledSize: new window.google.maps.Size(15, 15) }}
                    />)
        }

        </MarkerClusterer>
    }
  </GoogleMap>
));

function mapStateToProps(state) {
  return {
    searching: state.search.searching,
    centerMapLocation: state.centerMap,
    isEventsPreviewOpen: state.isEventsPreviewOpen,
    mapZoom: state.mapZoom,
    loading: state.loading,
    userLocation: state.user.location,
    isPreviewOpen: state.isPreviewOpen,
    isEventDrawerOpen: state.isEventDrawerOpen,
    isDrawerOpen: state.isDrawerOpen,
    lastPreviewOpened: state.lastPreviewOpened,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    setMapZoom: (zoom) => { dispatch(setMapZoom(zoom)); },
    setLoading: (loading) => { dispatch(setLoading(loading)); },
    updateUserLocation: (lat, long) => { dispatch(updateUserLocation(lat, long)); },
    centerMap: (location) => { dispatch(centerMap(location)); },
    closePreview: () => { dispatch(closePreview()); },
    closeEvents: () => { dispatch(closeEvents()); },
  };
}

const styles = StyleSheet.create({
  buttons: {
    width: '100%',
    display: 'flex',
    '@media (max-width: 500px)': {
      justifyContent: 'space-evenly',
    } },
});
