import React from 'react';

// CSS Import
import '../../assets/css/InputComponents/optionEditorInput.css';

// Assets imports
import TrashIcon from '../../assets/imgs/trash_icon.svg';
import AddIcon from '../../assets/imgs/add_icon.svg';

// Components imports
import Loading from '../Loading';

export default function OptionEditorInput({ title, indexQuestion, explanation, field, value, options, item, setItem, publish }) {
  const typesControl = ['escolhaUnicaOutro', 'escolhaMultiplaOutro'];

  function handleSetOption(text, index) {
    const newVet = value[field];

    newVet[index] = text;

    setItem(Object.values({ ...item, [indexQuestion]: { ...item[indexQuestion], [field]: newVet } }));
  }

  function handleAddOption() {
    const array = item[indexQuestion][field];
    let newField = array;

    if (array[array.length - 1] !== 'Outro')
      newField = [...array, ''];
    else
      newField.splice(array.length - 1, 0, '');
    
    setItem(Object.values({ ...item, [indexQuestion]: { ...item[indexQuestion], [field]: newField } }));
  }

  function handleDeleteOption(indexDelete) {
    const newVet = item[indexQuestion][field].filter((opt, index) => index !== indexDelete);
    setItem(Object.values({ ...item, [indexQuestion]: { ...item[indexQuestion], [field]: newVet } }));
  }

  if (!options) {
    return <Loading />;
  }

  return (
    <div className="OptionEditorInput">
      <div className='div_label_input_text'>
        <label className="label_text_input label_editor_input">
          <strong>{title}</strong>
        </label>
      </div>
      <div className='div_align_explanation_input'>
        <div className='div_align_options_editor'>
          {
            options.map((option, index) => {
              return (
                <div className="div_out_input_editor" key={index + 'div_out_input_editor'}>
                  <label htmlFor={title + field + 'Form' + index} className='label_option_question'>
                    Opção {index + 1}
                  </label>
                  <input type='text' id={title + field + 'Form' + index} onChange={(e) => handleSetOption(e.target.value, index)} placeholder={'Digite aqui...'} value={option} disabled={(typesControl.includes(value.tipoPergunta) && option === 'Outro') || publish} />
                  <button type='button' className='btn_delete_option_editor' onClick={() => handleDeleteOption(index)} disabled={((typesControl.includes(value.tipoPergunta) && option === 'Outro') || options.length === 1 || publish)}>
                    <img src={TrashIcon} alt='Icone de uma lixeira' className='img_delete_option_editor' />
                  </button>
                </div>
              )
            })
          }
          {
            !publish ? (
              <div className='div_add_new_option'>
                <div className='div_btn_add_question div_btn_add_option' onClick={() => handleAddOption()}>
                  <img src={AddIcon} alt='Ícone de adicionar' className='img_add_option' />
                </div>
              </div>
            ) : null
          }
        </div>
        <div className='div_explanation_input'>
          <p className='subtext_explanation_input'>{explanation}</p>
        </div>
      </div>
    </div>
  );
}