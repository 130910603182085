import React from 'react';
import EventCard from './EventCard';
import {
  Carousel,
  CarouselItem,
  CarouselControl,
} from 'reactstrap';
import { StyleSheet, css } from 'aphrodite';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { centerMap, setMapZoom, filterPins, setLoading, openEventDrawer } from '../redux/actions';

class EventsCaroussel extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      activeIndex: 0,

    };
  }

  _isMounted = false;

  onExiting = () => {
    this.animating = true;
  }

  onExited = () => {
    this.animating = false;
  }

  next = () => {
    if (this.animating)
      return;

    const nextIndex = this.state.activeIndex === this.props.events.length - 1 ? 0 : this.state.activeIndex + 1;
    this.setState({
      activeIndex: nextIndex,
    });
  }

  previous = () => {
    if (this.animating)
      return;

    const nextIndex = this.state.activeIndex === 0 ? this.props.events.length - 1 : this.state.activeIndex - 1;
    this.setState({
      activeIndex: nextIndex,
    });
  }

  async componentDidMount() {
    this._isMounted = true;

    await this.props.setLoading(true);
    // Isso faz com que, mesmo que haja dois ou mais 
    // eventos em um mesmo atrativo, o atrativo apareça apenas uma vez
    const pins = [...new Set(this.props.events.map((event) => event.attractive))];

    this.props.filterPins(pins);
    setLoading(false);

    if (this.props.events.length !== 0) {
      this.props.centerMap({
        lat: this.props.events[0].attractive.lat,
        long: this.props.events[0].attractive.long,
      });
      this.props.setMapZoom(17);
    }
  }

  componentDidUpdate() {
    const { activeIndex } = this.state;
    if (this._isMounted && this.props.events.length !== 0) {
      this.props.centerMap({
        lat: this.props.events[activeIndex].attractive.lat,
        long: this.props.events[activeIndex].attractive.long,
      });
    }
  }

  async componentWillUnmount() {
    this._isMounted = false;
    await this.props.setLoading(true);
    this.props.filterPins(this.props.points);
    this.props.setLoading(false);
  }

  handleCarouselClick = (event) => {
    this.props.openEventDrawer(event);
  }

  render() {
    const { activeIndex } = this.state;
    const { lastPreviewOpened } = this.props;

    if (lastPreviewOpened !== 'event') return null;
    return (
      <div className={this.props.events.length === 0 ? css(styles.emptyEvents) : css(styles.nonEmptyEvents)}>
        {
          this.props.events.length === 0 ?
            <div style={{ textAlign: 'center', height: '100%', width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              <p>Infelizmente não há eventos agendados para os próximos dias </p>
            </div>
            :
            <Carousel activeIndex={activeIndex}>
              {this.props.events.map((event, i) => {
                return (
                  <CarouselItem
                    onExiting={this.onExiting}
                    onExited={this.onExited}
                    key={i}
                  >
                    <EventCard
                      handleSwipeLeft={this.next}
                      handleSwipeRight={this.previous}
                      onClick={() => this.handleCarouselClick(event)}
                      {...event}
                    />
                  </CarouselItem>
                );
              })}
              <CarouselControl direction='prev' onClickHandler={this.previous} />
              <CarouselControl direction='next' onClickHandler={this.next} />
            </Carousel>
        }
      </div>
    );
  }
}

const styles = StyleSheet.create({
  nonEmptyEvents: {
    padding: '10px',
    margin: 'auto',
    borderTopLeftRadius: 30,
    borderTopRightRadius: 30,
    position: 'absolute',
    bottom: 0,
    zIndex: 4,
    height: 'fit-content',
    width: '100%',
    backgroundColor: '#b03cdb',
    color: 'white',
  },
  emptyEvents: {
    padding: '20px',
    margin: 'auto',
    borderTopLeftRadius: 30,
    borderTopRightRadius: 30,
    position: 'absolute',
    bottom: 0,
    zIndex: 4,
    height: '35%',
    width: '100%',
    backgroundColor: '#b03cdb',
    color: 'white',
  },
});

function mapDispatchToProps(dispatch) {
  return bindActionCreators({ centerMap, setMapZoom, filterPins, setLoading, openEventDrawer }, dispatch);
}
function mapStateToProps(state) {
  return {
    events: state.events,
    eventsDefaultImage: state.eventsDefaultImage,
    lastPreviewOpened: state.lastPreviewOpened,
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(EventsCaroussel);