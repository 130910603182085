import React, { useEffect, useState } from 'react';

// Component Imports
import ExportExcel from '../ExportExcel';

// Firebase Import
import firebase from '../../config/Firebase';

export default function DemandList({ doc, handleEdit, handleChange, handleOpenReportDemand, role }) {
  const [initial, setInitial] = useState();
  const [final, setFinal] = useState();
  const [publish, setPublish] = useState();
  const [data, setData] = useState();
  const [dataExport, setDataExport] = useState();
  const labels = [
    { label: 'Titulo Estudo de Demanda', value: 'tituloEstudoDemanda' },
    { label: 'Data', value: 'data' },
    { label: 'Cidade', value: 'cidade' },
    { label: 'Tipo da Pergunta', value: 'tipoPergunta' },
    { label: 'ID Resposta', value: 'idResposta' },
    { label: 'Numero da Pergunta', value: 'numeroPergunta' },
    { label: 'Pergunta', value: 'pergunta' },
    { label: 'Resposta', value: 'exportAnswer' },
    { label: 'Referência', value: 'reference' },
  ];

  async function handleSetCSVFile() {
    const docs = await firebase.firestore().collection('EstudoDemandaRespostas')
      .where('idEstudoDemanda', '==', doc.id)
      .orderBy('numeroPergunta')
      .get();

    let dataExport = [];
    let data = [];

    docs.docs.map((docRes) => {
      let dataRes = docRes.data();
      dataRes = { ...dataRes, data: handleFormatDate(dataRes.data) };
      handleManagerDataType(dataRes, dataExport);
      data.push(dataRes);
    });

    setData(data);
    setDataExport(dataExport)
  }

  function handleManagerDataType(dataRes, data) {

    const type = dataRes.tipoPergunta;

    switch (type) {
      case "cep":
        getCityAnswer(dataRes, data);
        return;
      case "cidadesVisitadas":
        getMultipleCitiesAnswer(dataRes, data);
        return;
      case "atrativos":
      case "escolha_multipla":
      case "escolha_multipla_outro":
        getMultipleAnswer(dataRes, data);
        return;
      case "nota_item":
        getObjectAnswer(dataRes, data);
        return;
      default:
        data.push({ ...dataRes, exportAnswer: dataRes.resposta });
        return;
    }
  }

  function getObjectAnswer(dataRes, data) {
    const responses = dataRes.resposta;
    const keys = Object.keys(responses).sort();

    keys.map((key) => {
      data.push({ ...dataRes, exportAnswer: responses[key], reference: key });
    });
  }

  function getCityAnswer(dataRes, data) {
    const responses = dataRes.resposta;
    const field = ["Cidade", "Estado", "País"];

    responses.map((res, index) => {
      data.push({ ...dataRes, exportAnswer: res, reference: field[index] });
    });

    return data;
  }

  function getMultipleCitiesAnswer(dataRes, data) {
    const responses = dataRes.resposta;
    const field = ["País", "Estado", "Cidade"];

    responses.map((res) => {
      res.split(",").map((local, index) => {
        data.push({ ...dataRes, exportAnswer: local, reference: field[index] });
      })
    });

    return data;
  }

  function getMultipleAnswer(dataRes, data) {
    const responses = dataRes.resposta;

    responses.map((res) => {
      data.push({ ...dataRes, exportAnswer: res });
    });

    return data;
  }

  function pad(number) {
    if (number < 10) {
      return '0' + number;
    }
    return number;
  }

  function handleFormatDate(date) {
    const dateType = new Date(date.seconds * 1000);
    let textDate = '';

    textDate = pad(dateType.getDate()) +
        '/' + pad(dateType.getUTCMonth() + 1) +
        '/' + dateType.getUTCFullYear();

    return textDate;
  }

  async function handleSetPublish() {
    const docInfo = await firebase.firestore().collection('EstudoDemanda').doc(doc.id).get();

    setPublish(!!docInfo.data().publicado);
  }

  async function handlePublish() {
    await firebase.firestore().collection('EstudoDemanda').doc(doc.id).update({
      publicado: !publish,
    });

    setPublish(!publish);
    handleChange();
  }

  useEffect(() => {
    const data = doc.data();

    const dateIn = new Date(data.dataIni.seconds * 1000);
    const dateFi = new Date(data.dataFim.seconds * 1000);

    setInitial(`${dateIn.getDate()}/${dateIn.getMonth() + 1}/${dateIn.getFullYear()}`);
    setFinal(`${dateFi.getDate()}/${dateFi.getMonth() + 1}/${dateFi.getFullYear()}`);

    handleSetPublish();
    handleSetCSVFile();
  }, []);

  if (!initial || !final || publish === undefined)
    return <></>;

  return (
    <tr className="row_table_inventory body_table">
      <td className="title_table_inventory">{doc.data().titulo}</td>
      <td className="title_table_inventory">{doc.data().cidade}</td>
      <td className="title_table_inventory">{initial}</td>
      <td className="title_table_inventory">{final}</td>
      <td className="title_table_inventory">{doc.data().respondentes.length || 0}</td>
      <td className="title_table_inventory">{doc.data().publicado ? 'Publicado' : 'Não Publicado'}</td>
      <td className="column_edit_table_inventory">
        <div className='div_mod_inventory'>
          <button className='btn_mod_adm' onClick={() => handleEdit(doc)}>Editar</button>
          <button className='btn_mod_adm' onClick={() => handlePublish()}>{ publish ? 'Despublicar' : 'Publicar' }</button>
          <ExportExcel name={'Estudo de demanda respostas'} labels={labels} data={dataExport} title={doc.data().titulo} />
          { ['contentManager', 'admin', 'cityAdmin', 'masterAdmin'].includes(role) ? <button className='btn_mod_adm' onClick={() => handleOpenReportDemand(data, doc.data())}>Emitir relatório</button> : null }
        </div>
      </td>
    </tr>
  );
}