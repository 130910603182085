import React from 'react';

// Assets imports
import InstagramIcon from '../../assets/imgs/instagram_icon.svg';
import LinkedinIcon from '../../assets/imgs/linkedin_icon.svg';

// CSS import
import '../../css/CaeLogin/FooterLoginCae.css';

export default function FooterLoginCae() {
  return (
		<footer className="FooterLoginCae">
			<span>Embratur em parceria com Destinos Inteligentes<br />Todos os direitos reservados.</span>
			<div className="div-align-social-media">
				<a href="https://www.instagram.com/destinosinteligentesoficial/" target="_blank">
					<img src={InstagramIcon} alt="Ícone do Instagram" />
				</a>
				<a href="https://www.linkedin.com/company/destinosinteligentes/" target="_blank">
					<img src={LinkedinIcon} alt="Ícone do Linkedin" />
				</a>
			</div>
		</footer>
  );
}