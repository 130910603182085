// Firebase import
import React, { useEffect, useState } from 'react';

// Firebase import
import firebase from '../config/Firebase';

// Css import
import '../assets/css/EditComponents/EditInventory.css';


// Components Imports
import Loading from './Loading';
import VerifyPopup from './VerifyPopup';
import TitleEditor from './FormComponents/TitleEditor';
import HeaderForm from './FormComponents/HeaderForm';
import ButtonForm from './FormComponents/ButtonForm';
import { fieldsEditRoutes } from './FieldsForm/routes.fields';
import { inputsComponents } from '../utils/formManager';

export default function EditRoutes({ setEditPopup, doc, action, userCity, pendency, objectPend, setObjectPend, setNumPendency, role, setRoutesAdmin }) {
  const [popup, setPopup] = useState(false);
  const [allImages, setAllImages] = useState([]);
  const [item, setItem] = useState(handleItem());
  const [edited, setEdited] = useState(false);

  function handleItem() {
    if (objectPend)
      return objectPend.currDoc;
  
    return doc ? doc.data() : {
      Title: '',
      'Short Descr': '',
      'Long Descr': '',
      City: userCity,
      Tipo: '',
      Subtipo: '',
      Active: false,
      Roteiros: [],
      Cities: [userCity],
      location: null,
      original: {
        'Short Descr': '',
        'Long Descr': '',
      },
    };
  }

  const handleEditRoute = async () => {
    try {
      let docInfo = { ...item };
      const imagefileURL = [];

      for (let index = 0; index < allImages.length; index++) {
        const img = allImages[index];
        const refImage = firebase.storage().ref(`roteiros/${doc.id}/${img.name}`);
        if (img.image !== '')
          await refImage.put(img.image);
        imagefileURL.push(await refImage.getDownloadURL());
      }

      if (imagefileURL.length > 0) {
        docInfo = { ...docInfo,
          imagefileURL: imagefileURL,
          fotoCapa: imagefileURL[0],
        };
      }

      await firebase.firestore().collection('Roteiros').doc(doc.id).set(docInfo);
      setEditPopup(false);
    } catch (error) {
      setEditPopup(false);
    }
  };

  const handleAddRoute = async () => {
    const docRef = await firebase.firestore().collection('Roteiros').add(item);

    const imagefileURL = [];

    for (let index = 0; index < allImages.length; index++) {
      const img = allImages[index];
      const refImage = firebase.storage().ref(`roteiros/${docRef.id}/${img.name}`);
      await refImage.put(img.image);
      imagefileURL.push(await refImage.getDownloadURL());
    }

    if (imagefileURL.length > 0) {
      await firebase.firestore().collection('Roteiros').doc(docRef.id).update({
        imagefileURL: imagefileURL,
        fotoCapa: imagefileURL[0],
      });
    }

    if (role === 'rotaAdmin') {
      const userUid = firebase.auth().currentUser.uid;
      const user = await getUserInfo();
      const updatedRoutes = user.rotaAdmin || [];
      await firebase.firestore().collection('users').doc(userUid).update({
        rotaAdmin: [...updatedRoutes, docRef.id], 
      });

      setRoutesAdmin([...updatedRoutes, docRef.id]);
    }

    setEditPopup(false);
  };

  async function getUserInfo() {
    const userUid = firebase.auth().currentUser.uid;
    const userData = await firebase.firestore().collection('users').doc(userUid).get();

    return userData.data();
  }

  async function handleSetRegionName(newItem) {
    const docCity = await firebase.firestore().collection('Cities').where('name', '==', newItem.City).get();
    const cityData = docCity.docs[0].data();

    setItem({...newItem, nomeRegiao: cityData.nomeRegiao || '', Estado: cityData.Estado || '' });
    setEdited(true);
  }

  useEffect(() => {
    let newItem = { ...item };
    if (doc && edited)
      newItem = { ...item, Cities: [item.City] }
    handleSetRegionName(newItem);
  }, [item.City]);

  function handleCheckFields() {
    const keys = ['Title', 'Short Descr', 'Long Descr', 'City', 'Tipo', 'location'];

    for (let i = 0; i < keys.length; i++) {
      if ((item[keys[i]] === '' || item[keys[i]] === 0) || item[keys[i]] === null || !item[keys[i]]) {
        document.getElementById(keys[i] + 'Form').focus();
        return;
      }
    }

    if (objectPend)
      handleEditPendency();
    else
      setPopup(true);
  }

  async function handleEditPendency() {
    const newPendency = { ...objectPend, currDoc: item };
    setObjectPend(newPendency);
    await firebase.firestore().collection('Pendencies').doc(pendency.id).set(newPendency)
      .then(() => setEditPopup(false));
  }

  if (!userCity) {
    return <Loading />;
  }

  return (
    <div className='EditInventory'>
      <div className='bodyEditInventory'>
        <TitleEditor action={action} type={'Roteiro'} />
        <div>
          <form className='form_edit_admin'>
            <HeaderForm />
            {
              fieldsEditRoutes.map((field) => {
                return inputsComponents[field.component]({ term: field, item: item, setItem: setItem, doc: doc, userCity: action === 'Criar' && !objectPend ? userCity : item.City, allImages: allImages, setAllImages: setAllImages, objectPend: objectPend, typeDocument: 'routes', pathStorage: `roteiros/${doc ? doc.id : ''}`, action: action === 'Criar', role: role, actionType: action, userRole: role, typeRemove: true });
              })
            }
            { popup ? <VerifyPopup setVerifyPopup={setPopup} handleAction={doc ? handleEditRoute : handleAddRoute} title={'Deseja gravar as alterações ?'} message={'As informações desse formulário serão gravadas e poderão ser visualizadas posteriormente. Deseja continuar?'} /> : null }
            <ButtonForm setEditPopup={setEditPopup} handleCheckFields={handleCheckFields} />
          </form>
        </div>
      </div>
    </div>
  );
}