import React from "react";
import ReactExport from "react-export-excel";

export default function ExportExcel({ name, data, labels, title }) {
  function handleValue(res, value) {
    const filtered = res[value] ? res[value] : '';

    if (value === 'tituloEstudoDemanda')
      return res['tituloEstudoDemanda'] || res['tituloEstudoDemana'];

    if (typeof filtered === 'object')
      return handleTextObject(filtered);

    return filtered;
  }

  function handleTextObject(obj) {
    let text = obj.toString();
   
    if (text !== '[object Object]')
      return text;

    const keys = Object.keys(obj).sort();

    text = ''
    keys.map((key) => {
      text = text + key + ':' + obj[key] + ',';
    });

    text = text.replace(/,$/, "");

    return text;
  }
  
  return (
    <ReactExport.ExcelFile element={<button className="btn_mod_adm">Exportar respostas</button>}>
      <ReactExport.ExcelFile.ExcelSheet data={data} name={name} >
        {
          labels.map((label, index) => {
            return <ReactExport.ExcelFile.ExcelColumn key={title + index} label={label.label} value={(res) => handleValue(res, label.value)} />;
          })
        }
      </ReactExport.ExcelFile.ExcelSheet>
    </ReactExport.ExcelFile>
  );
}