import React from 'react';

// Components imports
import { InfoSegmentsRow } from './InfoSegmentsRow';

// CSS import
import '../../assets/css/Dashboard/cardTableDashboard.css';

export function CardTableDashboard({ title, titleColumn, titleSemicolumn, segments }) {
  return (
    <div className='CardTableDashboard card_container_dash'>
      <div className={titleColumn !== 'Palavra' ? 'div_headear_carddashboard' : 'div_headear_cardtable'}>
        <h1 className='title_cardtable'>{title}</h1>
      </div>
      <div className='div_body_cardtable'>
        <div>
          <table className='table_cardtable'>
            <tbody>
              <tr>
                <th className='th_table_dashboard_column'>{titleColumn}</th>
                <th className='th_table_dashboard_semicolumn'>{titleSemicolumn}</th>
              </tr>
              {
                Object.keys(segments).length !== 0 ? Object.keys(segments).map((field, index) => {
                  return <InfoSegmentsRow name={field} num={segments[field]} key={index} />;
                }) : <tr><td>Sem ocorrencia</td></tr>
              }
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}