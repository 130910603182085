import React from 'react';

// CSS Import
import '../../assets/css/InputComponents/selectInput.css';

export default function SelectReview({ field, title, setAvaliation }) {
  return (
    <div className='SelectInput'>
      <div className='div_label_input_text'>
        <label htmlFor={field + 'Form'} className="label_text_input">
          <strong>{title}</strong>
        </label>
      </div>
      <div className='div_select_input_select'>
        <select onChange={(event) => setAvaliation(event.target.value === 'Aceitar')} id={field + 'Form'} >
          <option>Aceitar</option>
          <option>Recusar</option>
        </select>
      </div>
    </div>
  );
}