/* eslint-disable no-extra-boolean-cast */
/* eslint-disable no-nested-ternary */
import React from 'react';
import firebase from '../config/Firebase';
import fire from 'firebase'; // Para confirmar email e senha
import FileUploader from 'react-firebase-file-uploader';
import { StyleSheet, css } from 'aphrodite';
import { PageView, Event } from '../config/TrackingGA';
import Slide from '@material-ui/core/Slide';
import Header from '../component/Header';
import UserMenu from '../component/UserMenu';
import { IconContext } from 'react-icons';
import { FaUser } from 'react-icons/fa';
import { Button } from '@material-ui/core';
import IosSync from 'react-ionicons/lib/IosSync';
import AlertMessage from '../component/Alert';
import ProfileModal from '../component/ProfileModal';
import ProfileField from '../component/ProfileField';
import { connect } from 'react-redux';

class Profile extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      width: window.innerWidth,
      name: '',
      previousName: '',
      city: '',
      previousCity: '',
      email: '',
      previousEmail: '',
      phoneNumber: '',
      previousPhoneNumber: '',
      isAdmin: false,
      reauthenticate: false,
      changePassord: false,
      image: undefined,
      editing: false,
      userMenuStatus: false,
      uploadingImage: false,
      errorMsg: '',
      errorFlag: false,
      password: '',
      newPassowrd: '',
    };
  }

  _isMounted = false;

  componentDidMount() {
    this._isMounted = true;
    this.retrieveData();
    this.updateDimensions();
    PageView();
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  updateDimensions = () => {
    this.setState({
      width: window.innerWidth,
    });
  }

  handleStartEditing = () => {
    const { city, email, name, phoneNumber } = this.state;
    this.setState({
      editing: true,
      previousCity: city,
      previousEmail: email,
      previousName: name,
      previousPhoneNumber: phoneNumber,
    });
    Event('User', 'EditProfile', 'PerfilEditado');
  }

  handleCancelEditing = () => {
    const { previousCity, previousEmail, previousName, previousPhoneNumber } = this.state;
    this.setState({
      editing: false,
      city: previousCity,
      email: previousEmail,
      name: previousName,
      phoneNumber: previousPhoneNumber,
    });
  }

  handleFinishEditing = () => {
    // Atualiza os dados do usuário no firebase
    const currentUser = firebase.auth().currentUser !== null;
    if (currentUser) {
      const userDoc = firebase.auth().currentUser.uid;
      // Verifica se o componente esta propriamente montado
      if (this._isMounted) {
        firebase.auth().currentUser.updateEmail(this.state.email)
          .then(() => {
            if (firebase.auth().currentUser.email !== this.state.email) {
              firebase.auth().currentUser.sendEmailVerification();
            }
            const updateFields = {
              name: this.state.name,
              email: this.state.email,
              city: this.state.city,
            };

            if (this.state.phoneNumber)
              updateFields.phoneNumber = this.state.phoneNumber;

            // Atualiza os dados do usuário no firebase
            firebase.firestore().collection('users').doc(userDoc).update({
              ...updateFields,
            })
              .then(() => {
                // Indica que a edição foi concluída
                this.setState({
                  editing: false,
                  previousCity: '',
                  previousEmail: '',
                  previousName: '',
                  previousPhoneNumber: '',
                });
                // EDIMAR - Fechar a janela após concluir a alteração
                this.props.history.goBack();
              });
          })
          .catch((err) => {
            if (err.code === 'auth/invalid-email')
              this.setState({ errorFlag: true, errorMsg: 'Email inválido' });
            else if (err.code === 'auth/email-already-in-use')
              this.setState({ errorFlag: true, errorMsg: 'Email em uso' });
            else if (err.code === 'auth/requires-recent-login') {
              this.setState({ reauthenticate: true });
            } else {
              this.setState({ errorFlag: true, errorMsg: err.message });
            }
          });
      }
    }
  }

  handleChange = (name) => (event) => {
    // Atualiza o estado do componente
    if (name === 'city') {
      if (event === null)
        this.setState({ [name]: '' });
      else
        this.setState({ [name]: event.value });
    } else {
      this.setState({ [name]: event.target.value });
    }
  }

  // Função que altera o estado do menu do usuário
  toggleUserMenu = () => {
    this.setState({
      userMenuStatus: !this.state.userMenuStatus,
    });
  }

  retrieveData = () => {
    const userLoggedIn = firebase.auth().currentUser !== null;
    if (userLoggedIn) {
      const userDoc = firebase.auth().currentUser.uid;
      firebase.firestore().collection('users').doc(userDoc).onSnapshot((doc) => {
        // Verifica se o componente está propriamente montado
        if (this._isMounted) {
          this.setState({
            userLoggedIn: true,
            name: doc.data().name,
            city: doc.data().city,
            image: doc.data().image,
            email: doc.data().email,
            phoneNumber: doc.data().phoneNumber,
            isAdmin: doc.data().role === 'admin',
            loading: false,
          });
        }
      });
    } else {
      this.setState({ loading: false });
    }
  }

  openPasswordModal = () => {
    this.setState({
      changePassord: true,
    });
  }

  handleUploadStart = () => {
    this.setState({ uploadingImage: true });
  }

  handleUploadError = (err) => {
    this.setState({ uploadingImage: false });
    alert('Ocorreu um erro. Por favor, tente novamente mais tarde');
    console.error(err);
  }

  handleUploadSuccess = (file) => {
    firebase
      .storage()
      .ref('users')
      .child(file)
      .getDownloadURL()
      .then((imageUrl) => {
        const userId = firebase.auth().currentUser.uid;
        firebase.firestore().collection('users').doc(userId).update({
          image: imageUrl,
        })
          .then(() => (this.setState({ uploadingImage: false })));
      });
  }

  onModalConfirm = (data, callback) => {
    const { email, password, newPassword } = data;
    const { changePassord } = this.state;
    const cred = fire.auth.EmailAuthProvider.credential(email, password);

    if (changePassord) {
      // mudança de senha apenas
      firebase.auth().currentUser.reauthenticateWithCredential(cred)
        .then(() => {
          firebase.auth().currentUser.updatePassword(newPassword)
            .then(() => {
              this.setState({ changePassord: false }, () => { alert('Senha alterada com sucesso'); });
              callback(false);
            })
            .catch((e) => {
              if (e.code === 'auth/weak-password') {
                callback('A senha deve ter pelo menos 6 caracteres');
              } else {
                callback(e.message);
              }
            });
        })
        .catch((e) => {
          if (e.code === 'auth/invalid-email') {
            callback('Email inválido');
          } else if (e.code === 'auth/wrong-password') {
            callback('Senha incorreta');
          } else if (e.code === 'auth/user-mismatch') {
            callback('Email incorreto');
          } else {
            callback(e.message);
          }
        });
    } else {
      // Mudança de email (muda tudo junto)
      firebase.auth().currentUser.reauthenticateWithCredential(cred)
        .then(() => {
          const userDoc = firebase.auth().currentUser.uid;
          if (firebase.auth().currentUser.email !== this.state.email) {
            firebase.auth().currentUser.sendEmailVerification();
          }

          firebase.firestore().collection('users').doc(userDoc).update({
            name: this.state.name,
            email: this.state.email,
            city: this.state.city,
          })
            .then(() => {
              // Indica que a edição foi concluída
              this.setState({
                editing: false,
                reauthenticate: false,
              });
            });
          callback(false);
        })
        .catch((e) => {
          if (e.code === 'auth/wrong-password') {
            callback('Senha incorreta');
          } else if (e.code === 'auth/user-mismatch') {
            callback('Email incorreto');
          } else if (e.code === 'auth/invalid-email') {
            callback('Email inválido');
          } else {
            callback(e.message);
          }
        });
    }
  }

  render() {
    const { isAdmin } = this.state;

    return (
      <div className={css(styles.container)}>
        <div className={css(styles.header)}>
        <Header uploadingImage={this.state.uploadingImage} isMobile={this.state.width <= 500} toggleUserMenu={this.toggleUserMenu}/>
          {
            this.state.userMenuStatus &&
              <Slide element={document.getElementById('user-menu')} in={this.state.userMenuStatus} direction='down' timeout={200}>
                <UserMenu id='user-menu' />
              </Slide>
          }
        </div>
        <div className={css(styles.profileDiv)}>
            <div className={css(styles.profileImageDiv)}>
              {
                !this.state.uploadingImage ?
                  (this.state.image === undefined || this.state.image === '') ?
                    <IconContext.Provider value={{ color: 'white' }}>
                      <FaUser className={`icon m-3 ${css(styles.image)}`} />
                    </IconContext.Provider>
                    :
                    <img alt='' className={css(styles.image)} src={this.state.image}/>
                  :
                <IosSync color='white' rotate className={`icon m-3 ${css(styles.image)} `}/>
              }
              <label style={{ cursor: 'pointer', textDecoration: 'underline', marginBottom: '0px' }} className={css(styles.uploadPhoto)}>
                Alterar foto do perfil
                <FileUploader
                  hidden
                  accept="image/*"
                  name="avatar"
                  filename = {firebase.auth().currentUser.uid}
                  storageRef={firebase.storage().ref('users')}
                  onUploadStart={this.handleUploadStart}
                  onUploadError={this.handleUploadError}
                  onUploadSuccess={this.handleUploadSuccess}
                />
              </label>
              <span style={{ cursor: 'pointer', textDecoration: 'underline' }} onClick={this.openPasswordModal}>Alterar senha</span>
            </div>
          <div className={css(styles.profileDataDivContainer)}>
          { this.state.errorFlag && <AlertMessage color='danger' isOpen={this.state.errorFlag} onToggle={ () => { this.setState({ errorFlag: false, errorMsg: '' }); } } msg={this.state.errorMsg}/> }
            <div className={css(styles.profileDataDiv)}>
              <ProfileField
                isAdmin={isAdmin}
                fieldName={'Nome'}
                data={this.state.name || []}
                name={'name'}
                editing={this.state.editing}
                isMobile={this.state.width <= 500}
                placeholder={this.state.name}
                onClick={(e) => { e.target.focus(); }}
                onChange={this.handleChange('name')}
              />
              <ProfileField
                isAdmin={isAdmin}
                fieldName={'Email'}
                data={this.state.email || []}
                name={'email'}
                editing={this.state.editing}
                isMobile={this.state.width <= 500}
                placeholder={this.state.email}
                onClick={(e) => { e.target.focus(); }}
                onChange={this.handleChange('email')}
              />
              <ProfileField
                isAdmin={isAdmin}
                fieldName={'Cidade'}
                data={this.state.city || []}
                name={'city'}
                editing={this.state.editing}
                isMobile={this.state.width <= 500}
                placeholder={this.state.city}
                onClick={(e) => { e.target.focus(); }}
                onChange={this.handleChange('city')}
              />
              <ProfileField
                isAdmin={isAdmin}
                fieldName={'Telefone'}
                data={this.state.phoneNumber || []}
                name={'phoneNumber'}
                editing={this.state.editing}
                isMobile={this.state.width <= 500}
                placeholder={this.state.phoneNumber === undefined || this.state.phoneNumber === '' ? '(55) 95555-5555' : this.state.phoneNumber}
                onClick={(e) => { e.target.focus(); }}
                onChange={this.handleChange('phoneNumber')}
              />
            </div>
            { this.state.editing ?
              <div className={css(styles.buttonsDiv)}>
                <Button className={css(styles.finishButton)} onClick={this.handleFinishEditing}>Concluir</Button>
                <Button className={css(styles.backButton)} onClick={this.handleCancelEditing}>Cancelar</Button>
              </div>
              :
              <div className={css(styles.buttonsDiv)}>
                <Button className={css(styles.editButton)} onClick={this.handleStartEditing}>Editar</Button>
                <Button onClick={() => this.props.history.goBack()} className={css(styles.backButton)}>Voltar</Button>
              </div>
            }
          </div>
        </div>
        <ProfileModal
          password={this.state.changePassord}
          onConfirm={(response, callback) => this.onModalConfirm(response, callback)}
          onCancel={() => { this.setState({ reauthenticate: false, changePassord: false }); }}
          open={ this.state.reauthenticate || this.state.changePassord}
        />
      </div>
    );
  }
}

function mapStateToProps(state) {
  const filters = state.filterInfo.filters;
  const showEvents = state.isEventsPreviewOpen;

  return {
    params: {
      ...filters,
      showEvents,
    },
  };
}

export default connect(mapStateToProps, null)(Profile);

const styles = StyleSheet.create({
  backButton: {
    color: 'white',
    backgroundColor: 'rgb(146, 85, 142)',
    borderRadius: 50,
    fontSize: 18,
    height: 50,
    marginRight: '2vw',
    width: 150,
  },
  editButton: {
    color: '#c94283',
    borderRadius: 50,
    borderColor: '#c94283',
    borderStyle: 'solid',
    borderWidth: '1px',
    fontSize: 18,
    height: 50,
    marginRight: '2vw',
    width: 150,
  },
  buttonsDiv: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginLeft: '5vw',
    // width: '100%',
    // marginTop: '10px',
    // marginBottom: '10px',
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100vh',
    width: '100%',
    overflowX: 'hidden',
  },
  fieldContainer: {
    '@media (max-width: 500px)': {
      top: 0,
      height: '50%',
    },
  },
  finishButton: {
    color: 'white',
    backgroundColor: '#db3d78',
    borderRadius: 50,
    fontSize: 18,
    height: 50,
    marginRight: '2vw',
    width: 150,
  },
  header: {
    flex: 1,
    width: '100vw',
  },
  image: {
    width: '190px',
    height: '190px',
    borderRadius: '50%',
    marginBottom: '10px',
  },
  profileDiv: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    width: '100%',
  },
  profileDataDiv: {
    flex: 0.55,
    marginTop: '3%',
    display: 'flex',
    justifyContent: 'flex-start',
    backgroundColor: 'white',
    width: '100vw',
    height: '100vw',
    flexDirection: 'column',
    '@media (max-width: 500px)': {
      flex: 1,
      width: '90vw',
      height: '70vh',
      overflowY: 'scroll',
      position: 'relative',
      justifyContent: 'flex-start',
    },
  },
  profileDataDivContainer: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    flexDirection: 'column',
    flex: 0.6,
  },
  profileImageDiv: {
    paddingTop: '1rem',
    paddingBottom: '1rem',
    flex: 0.4,
    flexDirection: 'column',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundImage: 'linear-gradient(to right, #c94283, #664d7f)',
    color: 'white',
  },
  uploadPhoto: {
    cursor: 'pointer',
  },
});
