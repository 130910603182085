import React from 'react';
import { StyleSheet, css } from 'aphrodite';
import '../assets/css/carouselStyle.css';
import HexagonImage from '../assets/imgs/hexagon.png';
import Grid from '@material-ui/core/Grid';
import { store } from '../redux/store';

class DivHexagons extends React.Component {
  getActivityImageLink = (iconName) => {
    const links = store.getState().activitiesIcons;
    const citiesDefaultImages = store.getState().citiesDefaultImages;

    const baseUrl = citiesDefaultImages[Object.keys(citiesDefaultImages)[0]][0];
    const slash = '%2F';
    let url = baseUrl.substring(0, baseUrl.indexOf('/o/'));
    const sulfix = '?alt=media';

    if (Object.keys(links).indexOf(iconName) !== -1) {
      url += '/o/activities' + slash + links[iconName];
    } else {
      url += '/o/activities' + slash + links.default;
    }

    url += sulfix;
    
    return url;
  }

  render() {
    const { activities, isMobile } = this.props;
    return (
      <div>
        {
          activities && (
            isMobile || this.props.isPreviewOpen ?
              <div className={css(styles.containerMobile)}>
                {
                  activities.map((subType, i) =>
                    <div key={i} className={css(styles.imageContainerMobile)}>
                      <img
                        alt=''
                        src={HexagonImage}
                        className={css(styles.image)}
                      />
                      <img 
                        alt=''
                        src={this.getActivityImageLink(subType)}
                        className={css(styles.icon)}
                      />
                      <p className={css(styles.descriptionText)}>{subType}</p>
                    </div>)
                }
              </div>
              :
              <Grid container spacing={0} className={css(styles.container)}>
                {
                  activities.map((subType, i) =>
                    <Grid key={i} item xs={4} className={css(styles.imageContainer)}>
                      <img
                        alt=''
                        src={HexagonImage}
                        className={css(styles.image)}
                      />
                      <img 
                        alt=''
                        src={this.getActivityImageLink(subType)}
                        className={css(styles.iconAttractive)}
                      />
                      <p className={css(styles.descriptionText)}>{subType}</p>
                    </Grid>)
                }
              </Grid>
          )
        }
      </div>
    );
  }
}

const styles = StyleSheet.create({
  containerMobile: {
    color: 'white',
    display: 'flex',
    flexDirection: 'row',
    height: 'auto',
    overflow: 'auto',
    paddingBottom: '10px',
    WebkitMaskImage: 'linear-gradient(to right, rgba(0,0,0,1) 70%,rgba(0,3,4,.3) 100%)',
  },
  container: {
    color: 'white',
    textAlign: 'center',
    width: '90%',
    margin: 'auto',
    justifyContent: 'space-between',
  },
  descriptionText: {
    margin: 0,
    fontSize: '1rem',
  },
  icon: {
    position: 'absolute',
    height: '2.5rem',
    width: '2.5rem',
    top: '0.75rem',
  },
  iconAttractive: {
    position: 'absolute',
    height: '2.5rem',
    width: '2.5rem',
    top: '0.75rem',
    left: '2.2rem',
  },
  imageContainer: {
    marginBottom: '2.25rem',
    position: 'relative',
  },
  imageContainerMobile: {
    alignItems: 'center',
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    marginRight: '1rem',
    position: 'relative',
  },
  image: {
    height: '4rem',
    width: '4rem',
    borderRadius: '5rem',
    position: 'relative',
    top: '0',
    left: '0',
  },
});

export default DivHexagons;