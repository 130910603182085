import React from 'react';
import { OverlayView } from 'react-google-maps';
import { Row, Col } from 'reactstrap';
import { StyleSheet, css } from 'aphrodite';
import Rating from './Rating';
import Favorite from './Favorite';
import DistanceToAttractive from './DistanceToAttractive';
import { connect } from 'react-redux';
import DivHexagons from './DivHexagons';

class DesktopAttractivePreview extends React.Component {
  render() {
    const { attractive, isPreviewOpen, isMobile, showFavorites, showRating } = this.props;
    if (isMobile || !isPreviewOpen) return null;

    return (
      <OverlayView
        position={{ lat: attractive.lat, lng: attractive.long }}
        mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
        key={Math.random()}
        styles={{ justifyContent: 'center' }}
      >
        <div ref={(ref) => ref && window.google.maps.OverlayView.preventMapHitsFrom(ref)} onClick={() => this.props.handleClick(this.props.attractive)} className={css(styles.attractivesPreview)}>
          <Row className={css(styles.ratingAndFavoriteRow)}>
            {showRating && <Rating document={attractive.document} collection={attractive.collection} />}
            {showFavorites && <Favorite document={attractive.document} collection={attractive.collection} />}
          </Row>
          <Row>
            <p className={css(styles.previewTitle)}>
              {attractive.Title}
            </p>
          </Row>
          <Row>
            <div className={css(styles.pinkLine)} />
          </Row>
          <Row>
            <div className={css(styles.shortDescriptionDiv)}>
              <p className={css(styles.shortDescription)}>{attractive['Short Description']}</p>
            </div>
          </Row>
          <Row className={css(styles.hexagonsAndDistanceRow)}>
          <Col lg='8' xs='8' sm='8'>
              <DivHexagons
                activities={attractive['Activities List']}
                isPreviewOpen={isPreviewOpen}
              />
            </Col>
            <Col lg='4' xs='4' sm='4'>
              <DistanceToAttractive
                isPreviewOpen={isPreviewOpen}
                attractiveLat={attractive.lat}
                attractiveLng={attractive.long}
              />
            </Col>
          </Row>
        </div>
      </OverlayView>
    );
  }
}

const styles = StyleSheet.create({
  attractivesPreview: {
    '@media (max-width: 768px)': {
      bottom: 0,
    },
    backgroundColor: '#c844dd',
    borderRadius: '25px',
    justifyContent: 'center',
    alignItems: 'center',
    minHeight: '13rem',
    height: 'auto',
    width: '25rem',
    padding: '1rem',
    left: '2px',
    // paddingLeft: '1.3rem',
    // paddingRight: '1.3rem',
    cursor: 'pointer',
    zIndex: '3',
  },
  distanceText: {
    // margin: '0',
    marginBottom: '1rem',
    padding: '1.3rem',
    color: 'white',
    fontSize: '0.8rem',
  },
  hexagonsAndDistanceRow: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: '1rem',
    paddingLeft: '.35rem',
  },
  pinkLine: {
    borderBottomColor: '#f9407d',
    borderBottomStyle: 'solid',
    borderBottomWidth: '3px',
    width: '3rem',
    marginTop: '0.3rem',
    marginBottom: '0.3rem',
    marginRight: '0.5rem',
    marginLeft: '0.5rem',
  },
  previewTitle: {
    textAlign: 'left',
    color: 'white',
    fontWeight: 'bold',
    fontSize: '1.6rem !important',
    marginBottom: '0rem',
    paddingLeft: '1rem',
    paddingRight: '1rem',
    fontFamily: 'ubuntu',
  },
  ratingAndFavoriteRow: {
    display: 'flex',
    alignItems: 'center',
    paddingLeft: '.35rem',
    marginBottom: '.2rem',
  },
  shortDescription: {
    textOverflow: 'ellipsis',
    overFlow: 'auto',
    color: 'white',
    paddingLeft: '1rem',
    paddingRight: '1rem',
    fontSize: '1.1rem',
    marginBottom: '0.5rem',
    textAlign: 'justify',
    fontFamily: 'ubuntu',
    fontWeight: 'regular',

  },
  shortDescriptionDiv: {
    marginRight: '0.7rem',
  },
});

function mapStateToProps(state) {
  return {
    isPreviewOpen: state.isPreviewOpen,
    attractive: state.previewInfo,
    showRating: state.flags.showRating,
    showFavorites: state.flags.showFavorites,
  };
}

export default connect(mapStateToProps)(DesktopAttractivePreview);