export const OPEN_PREVIEW = 'OPEN_PREVIEW';
export const CLOSE_PREVIEW = 'CLOSE_PREVIEW';
export const TOGGLE_PREVIEW = 'TOGGLE_PREVIEW';
export const UPDATE_PREVIEW_DATA = 'UPDATE_PREVIEW_DATA';

export const OPEN_DRAWER = 'OPEN_DRAWER';
export const CLOSE_DRAWER = 'CLOSE_DRAWER';

export const UPDATE_USER_LOCATION = 'UPDATE_USER_LOCATION';

export const TOGGLE_FILTER = 'TOGGLE_FILTER';
export const FILTER_PINS = 'FILTER_PINS';
export const FILTER_CHECKBOX = 'FILTER_CHECKBOX';

export const OPEN_EVENTS = 'OPEN_EVENTS';
export const CLOSE_EVENTS = 'HIDE_EVENTS';

export const TOGGLE_LOG_OUT_FLAG = 'TOGGLE_LOG_OUT_FLAG';

export const TOGGLE_SEARCH = 'TOGGLE_SEARCH';
export const HIDE_SEARCH = 'HIDE_SEARCH';
export const SHOW_SEARCH = 'SHOW_SEARCH';

export const CHANGE_FLAGS = 'CHANGE_FLAGS';

export const CENTER_MAP = 'CENTER_MAP';
export const SET_MAP_ZOOM = 'SET_MAP_ZOOM';

export const SET_LOADING = 'SET_LOADING';

export const SAVE_EVENTS = 'SAVE_EVENTS';

export const SAVE_CITY_DEFAULT_IMAGE = 'SAVE_CITY_DEFAULT_IMAGE';
export const SAVE_EVENTS_DEFAULT_IMAGE = 'SAVE_EVENTS_DEFAULT_IMAGE';

export const OPEN_EVENT_DRAWER = 'OPEN_EVENT_DRAWER';
export const CLOSE_EVENT_DRAWER = 'CLOSE_EVENT_DRAWER';

export const SAVE_FILTER_MASK = 'SAVE_FILTER_MASK';

export const SAVE_ACTIVITIES = 'SAVE_ACTIVITIES';