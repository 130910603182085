// Firebase Import
import firebase from "../config/Firebase";

export async function firstSection(cityData) {
  const attractiveDocs = await firebase
    .firestore()
    .collection("Attractions")
    .where("City", "==", cityData.name)
    .where("Active", "==", true)
    .get();
  const attractives = attractiveDocs.docs;

  const score = [
    {
      title: "Fluxo Internacional",
      field: ["internationalFlux"],
      verifyAction: handleSumScoreType,
      type: "sumScore",
      score: 0,
      scoreType: [0, 1, 19999, 20000, 49999],
      pointType: [0, 3, 3, 4, 4, 5],
      currScore: 0,
      maxScore: 5,
      typeDoc: "City",
    },
    {
      title: "Fluxo Doméstico",
      field: ["domesticFlux"],
      verifyAction: handleSumScoreType,
      type: "sumScore",
      score: 0,
      scoreType: [0, 1, 9999, 10000, 19999, 20000, 49999, 50000, 99999],
      pointType: [0, 1, 1, 2, 2, 3, 3, 4, 4, 5],
      currScore: 0,
      maxScore: 5,
      typeDoc: "City",
    },
    {
      title: "Redes Sociais - Tem Engajamento (Tem Instagram oficial do turismo com seguidores) ?",
      field: "socialMediaEngagement",
      like: [true],
      verifyAction: handleScoreType,
      score: "Não",
      currScore: 0,
      maxScore: 1.5,
      typeDoc: "City",
      editType: true,
      formatEdit: "select",
      options: ["Sim", "Não"],
      pointType: [1.5, 0],
    },
    {
      title:
        "Sua cidade participou da pesquisa de perceção do turismo do CIET ? (2022)",
      field: "cietYearsSearch2022",
      // like: ['2022'],
      // verifyAction: handleSegmentType,
      like: [true],
      verifyAction: handleScoreType,
      score: "Não",
      currScore: 0,
      maxScore: 0.5,
      typeDoc: "City",
      editType: true,
      formatEdit: "select",
      options: ["Sim", "Não"],
      pointType: [0.5, 0],
    },
    {
      title:
        "Sua cidade participou da pesquisa de perceção do turismo do CIET ? (2023)",
      field: "cietYearsSearch2023",
      // like: ['2023'],
      // verifyAction: handleSegmentType,
      like: [true],
      verifyAction: handleScoreType,
      score: "Não",
      currScore: 0,
      maxScore: 0.5,
      typeDoc: "City",
      editType: true,
      formatEdit: "select",
      options: ["Sim", "Não"],
      pointType: [0.5, 0],
    },
  ];

  attractives.map((attr) => {
    const attrData = attr.data();
    score.map((scr) => {
      if (scr.typeDoc === "Attraction")
        scr.currScore = calculatePointsAttraction(scr, attrData);
    });
  });

  score.map((scr) => {
    if (scr.typeDoc === "City")
      scr.currScore = calculatePointsCity(scr, cityData);
  });

  return score;
}

export async function secondSection(cityData) {
  const attractiveDocs = await firebase
    .firestore()
    .collection("Attractions")
    .where("City", "==", cityData.name)
    .where("Active", "==", true)
    .get();
  const eventDocs = await firebase
    .firestore()
    .collection("Events")
    .where("City", "==", cityData.name)
    .where("Active", "==", true)
    .get();

  const attractives = attractiveDocs.docs;
  const events = eventDocs.docs;

  const score = [
    // DEIXAR NO AGUARDO
    {
      title:
        "Atrativo / Evento com Excepcional Valor Turístico (atrativos/eventos com grandes fluxos - mínimo de 5 edições - empregos e receitas gerados) - aguardando definições de critério da Setur/SP -",
      field: "teste",
      like: [true],
      verifyAction: handleScoreType,
      currScore: 0,
      score: "Não",
      maxScore: 50,
      typeDoc: "City",
    },
    {
      title: "Tem circuito Quilombola?",
      field: "temCircuitoQuilombola",
      like: [true],
      verifyAction: handleScoreType,
      currScore: 0,
      score: "Não",
      maxScore: 2.5,
      typeDoc: "City",
    },
    {
      title: "Aldeias que recebem visitação",
      field: "Especialidade",
      like: ["Aldeia indígena"],
      verifyAction: handleScoreType,
      score: "Não",
      currScore: 0,
      maxScore: 1,
      typeDoc: "Attractions",
    },
    {
      title: "Assentamentos que recebem turistas",
      field: "Especialidade",
      like: ["Assentamento"],
      verifyAction: handleScoreType,
      score: "Não",
      currScore: 0,
      maxScore: 1,
      typeDoc: "Attractions",
    },
    {
      title: "Ecoturismo - Parques naturais municipal",
      field: "Especialidade",
      like: ["Parque natural municipal"],
      verifyAction: handleScoreType,
      score: 0,
      type: "score",
      scoreType: [0, 1],
      pointType: [0, 1, 2],
      currScore: 0,
      maxScore: 2,
      typeDoc: "Attractions",
    },
    {
      title: "Ecoturismo - Hidrografia",
      field: "Especialidade",
      like: ["Represa", "Lago", "Corredeira", "Rio", "Cachoeira", "Queda"],
      verifyAction: handleScoreType,
      score: 0,
      type: "score",
      scoreType: [0, 1],
      pointType: [0, 0.5, 1],
      currScore: 0,
      maxScore: 1,
      typeDoc: "Attractions",
    },
    {
      title: "Ecoturismo - Relevo",
      field: "Especialidade",
      like: [
        "Montanha",
        "Vale",
        "Duna",
        "Depressão",
        "Chapada",
        "Ilha fluvial",
        "Ilha marítima",
        "Praia marítima",
        "Praia fluvial e lacustre",
        "Caverna",
        "Gruta",
      ],
      verifyAction: handleScoreType,
      score: "Não",
      currScore: 0,
      maxScore: 1,
      typeDoc: "Attractions",
    },
    {
      title: "Ecoturismo - Vegetação",
      field: "Especialidade",
      like: ["Bosque", "Viveiro e orquidário", "Jardim botânico", "Mangue"],
      verifyAction: handleScoreType,
      score: "Não",
      currScore: 0,
      maxScore: 1,
      typeDoc: "Attractions",
    },
    {
      title: "Ecoturismo -  Quantidade de Trilhas",
      field: "Especialidade",
      like: ["Trilhas"],
      verifyAction: handleScoreType,
      score: 0,
      type: "score",
      scoreType: [0, 1],
      pointType: [0, 0.5, 1],
      currScore: 0,
      maxScore: 1,
      typeDoc: "Attractions",
    },
    {
      title:
        "Ecoturismo - Planetário / Aquário / Zoológico / Santuário Vida Selvagem",
      field: "Especialidade",
      like: [
        "Santuário da vida selvagem",
        "Zoológico",
        "Aquário",
        "Planetário",
      ],
      verifyAction: handleScoreType,
      score: 0,
      type: "score",
      scoreType: [0, 1],
      pointType: [0, 1, 2],
      currScore: 0,
      maxScore: 2,
      typeDoc: "Attractions",
    },
    {
      title: "Ecoturismo - Possui Observação aves (fauna - flora)",
      field: "ecoturismBirdsWatch",
      like: [true],
      verifyAction: handleScoreType,
      currScore: 0,
      score: "Não",
      maxScore: 1,
      editType: true,
      formatEdit: "select",
      options: ["Sim", "Não"],
      pointType: [1, 0],
      typeDoc: "City",
    },
    {
      title: "Possui Fluxo por motivação do Clima / Inverno",
      field: "motivationFluxByClimate",
      like: [true],
      verifyAction: handleScoreType,
      currScore: 0,
      score: "Não",
      maxScore: 1,
      editType: true,
      formatEdit: "select",
      options: ["Sim", "Não"],
      pointType: [1, 0],
      typeDoc: "City",
    },
    {
      title: "Tem monumento geológico?",
      field: "possuiMonumentosGeologico",
      like: [true],
      verifyAction: handleScoreType,
      currScore: 0,
      score: "Não",
      maxScore: 2,
      typeDoc: "City",
    },
    {
      title: "Parques Unidades de Conservação",
      field: "Especialidade",
      like: ["Unidade de conservação"],
      verifyAction: handleScoreType,
      type: "score",
      score: 0,
      scoreType: [0, 1],
      pointType: [0, 1, 2],
      currScore: 0,
      maxScore: 2,
      typeDoc: "Attractions",
    },
    {
      title:
        "Turismo náutico - Quantidade de locais (marinas, píer, atracadouro)",
      field: "Especialidade",
      like: ["Cais", "Estaleiro", "Garagem náutica", "Marina", "Píer", "Rampa náutica", "Clube náutico"],
      verifyAction: handleScoreType,
      score: 0,
      scoreType: [0, 1],
      pointType: [0, 1, 2],
      currScore: 0,
      type: "score",
      maxScore: 2,
      typeDoc: "Attractions",
    },
    {
      title: "Turismo náutico - Capacidade de embarcações",
      field: ["boatTravelCapacity"],
      type: "sumScore",
      verifyAction: handleSumScoreType,
      score: 0,
      scoreType: [0, 1, 50],
      pointType: [0, 1, 1, 2],
      currScore: 0,
      maxScore: 2,
      editType: true,
      formatEdit: "amount",
      typeDoc: "City",
      typeSave: true,
    },
    {
      title:
        "Turismo náutico - Passeios de Barcos regulares/ Roteiros Náuticos",
      field: ["boatTravelRoute"],
      type: "sumScore",
      verifyAction: handleSumScoreType,
      score: 0,
      scoreType: [0, 1],
      pointType: [0, 1, 2],
      currScore: 0,
      maxScore: 2,
      editType: true,
      formatEdit: "amount",
      typeDoc: "City",
      typeSave: true,
    },
    {
      title: "Turismo náutico - Empresa p/ locação de barcos",
      field: "Especialidade",
      like: ["Locação de barcos"],
      verifyAction: handleScoreType,
      score: 0,
      type: "score",
      scoreType: [0, 1],
      pointType: [0, 1, 2],
      currScore: 0,
      maxScore: 2,
      typeDoc: "Attractions",
    },
    {
      title: "Turismo náutico - Empresa p/ locação de moto náutica",
      field: "Especialidade",
      like: ["Locação de moto náutica"],
      verifyAction: handleScoreType,
      score: 0,
      type: "score",
      scoreType: [0, 1],
      pointType: [0, 1, 2],
      currScore: 0,
      maxScore: 2,
      typeDoc: "Attractions",
    },
    {
      title: "Pesca - Pesque-pague / Pesque Solte / Recantos",
      field: "Especialidade",
      like: ["Pesqueiro"],
      verifyAction: handleScoreType,
      score: 0,
      type: "score",
      scoreType: [0, 1],
      pointType: [0, 0.5, 1],
      currScore: 0,
      maxScore: 1,
      typeDoc: "Attractions",
    },
    {
      title: "Pesca - Loja de equipamentos de pesca",
      field: "Especialidade",
      like: ["Artigos para pesca e acampamento"],
      verifyAction: handleScoreType,
      score: 0,
      type: "score",
      scoreType: [0, 1],
      pointType: [0, 0.5, 1],
      currScore: 0,
      maxScore: 1,
      typeDoc: "Attractions",
    },
    {
      title:
        "Turismo de Aventura - Quantidade de empresas de Turismo de Aventura que operam",
      field: ["Especialidade", "Segmento Turístico"],
      like: [
        "Operadora turística",
        "Agencia de turismo receptivo",
        "Guia de turismo",
        "Aventura",
      ],
      verifyAction: handleSegmentAdventureType,
      score: 0,
      scoreType: [0, 1, 2],
      pointType: [0, 1, 1, 2],
      currScore: 0,
      type: "seg",
      maxScore: 2,
      typeDoc: "Attractions",
    },
    {
      title:
        "Possui Locais de uso livre para praticantes Paraglider/ Asa Delta / Escalada / Escalaminhada etc",
      field: "placesMountainSports",
      like: [true],
      verifyAction: handleScoreType,
      score: "Não",
      currScore: 0,
      maxScore: 1,
      editType: true,
      formatEdit: "select",
      options: ["Sim", "Não"],
      pointType: [1, 0],
      typeDoc: "City",
    },
    {
      title: "Cicloturismo - Qtde de Roteiros",
      field: ["amountRoutes"],
      type: "sumScore",
      verifyAction: handleSumScoreType,
      score: 0,
      type: "sumScore",
      scoreType: [0, 1],
      pointType: [0, 1, 2],
      currScore: 0,
      maxScore: 2,
      editType: true,
      formatEdit: "amount",
      typeDoc: "City",
      typeSave: true,
    },
    {
      title: "Turismo de Sol e Praia - Possui Praias Marítimas?",
      field: "Especialidade",
      like: ["Praia marítima"],
      verifyAction: handleScoreType,
      score: "Não",
      currScore: 0,
      maxScore: 10,
      typeDoc: "Attractions",
    },
    {
      title: "Turismo de Sol e Praia - Possui Praias (lacustres/ fluviais)",
      field: "Especialidade",
      like: ["Praia fluvial e lacustre"],
      verifyAction: handleScoreType,
      score: "Não",
      currScore: 0,
      maxScore: 5,
      typeDoc: "Attractions",
    },
    {
      title:
        "Turismo de Saúde e Bem Estar - Possui Fontanário de águas minerais registradas",
      field: "Especialidade",
      like: ["Fonte de água mineral"],
      verifyAction: handleScoreType,
      score: "Não",
      currScore: 0,
      maxScore: 5,
      typeDoc: "Attractions",
    },
    {
      title:
        "Turismo de Saúde e Bem Estar - Possui Balneário para tratamentos medicinais /estéticos",
      field: ["Type", "Subtype", "Especialidade"],
      like: ["Services", "Saúde", "Balneário"],
      verifyAction: handleMultipleRelations,
      score: "Não",
      currScore: 0,
      maxScore: 5,
      typeDoc: "Attractions",
      type: "multiple",
    },
    {
      title:
        "Turismo de Saúde e Bem Estar - Possui Hospital / clínica / instituição de saúde / balneário / spas / casas de estética/ tratamentos terapêuticos / espaços terapêuticos, de notório conhecimento, que atraia pessoas para tratamentos de outras regiões / estados / países",
      field: "healthPlaces",
      like: [true],
      verifyAction: handleScoreType,
      score: "Não",
      currScore: 0,
      maxScore: 2.5,
      editType: true,
      formatEdit: "select",
      options: ["Sim", "Não"],
      pointType: [2.5, 0],
      typeDoc: "City",
    },
    // PERGUNTAR PARA O EDIAMR
    {
      title:
        "Turismo de Esporte - Possui Equipamentos (Pista de boliche / Campo de Golfe / Pista de Skate/ Skate park / Estádio/Conjunto esportivo / Piscina Olímpica / Trail (moto-cross)/ Kartódromo/Autódromo / Trilha para bicicleta / ciclovia / Rampa de voo livre",
      // field: 'Especialidade',
      // like: ['Pista de boliche', 'Campo de golfe', 'Pista de Skate', 'Skate park', 'Estádio', 'Complexo Esportivo', 'Piscina olímpica', 'Pista motocross', 'Kartódromo', 'Autódromo', 'Trilha para bicicleta', 'Ciclovia', 'Rampa de voo livre'],
      verifyAction: handleScoreType,
      like: [true],
      field: "haveRadicalEquipaments",
      score: "Não",
      currScore: 0,
      maxScore: 1,
      editType: true,
      formatEdit: "select",
      options: ["Sim", "Não"],
      pointType: [1, 0],
      typeDoc: "City",
    },
    {
      title: "Turismo de Esporte - Possui Evento esportivo tradicional",
      field: "classificacaoEvento",
      like: ["esportivo"],
      verifyAction: handleSegmentType,
      type: "seg",
      score: "Não",
      currScore: 0,
      maxScore: 1,
      typeDoc: "Events",
    },
    {
      title:
        "Turismo Religioso - Possui Lugares de Manifestação de Fé (Romaria e procissão / Culto / Encontro / Referencial para mitos e narrativas de fé / Visitação de cunho religioso)",
      field: "lugaresReligiosos",
      like: [true],
      verifyAction: handleScoreType,
      score: "Não",
      currScore: 0,
      maxScore: 1,
      editType: true,
      formatEdit: "select",
      options: ["Sim", "Não"],
      pointType: [1, 0],
      typeDoc: "City",
    },
    {
      title: "Turismo Religioso - Evento com mais de 5 edições",
      field: ["classificacaoEvento", "numEditions"],
      like: ["religioso", "sim"],
      verifyAction: handleNumEditions,
      score: "Não",
      currScore: 0,
      maxScore: 1,
      typeDoc: "Events",
    },
    {
      title: "Turismo Religioso - Faz parte de Roteiro Religioso",
      verifyAction: handleScoreType,
      like: [true],
      field: "numEditionsEvents",
      score: "Não",
      currScore: 0,
      maxScore: 1,
      editType: true,
      formatEdit: "select",
      options: ["Sim", "Não"],
      pointType: [1, 0],
      typeDoc: "City",
    },
    {
      title: "Turismo Cultural - Possui Arquitetura e Edificações históricas",
      field: "Especialidade",
      like: ["Patrimônio histórico"],
      verifyAction: handleScoreType,
      score: "Não",
      currScore: 0,
      maxScore: 1,
      typeDoc: "Attractions",
    },
    {
      title: "Turismo Cultural - Atrativo cultural tombado pelo CONDEPHAAT",
      field: "tombadoCondephaat",
      verifyAction: handleScoreType,
      like: [true],
      score: "Não",
      currScore: 0,
      maxScore: 3,
      typeDoc: "Attractions",
    },
    {
      title:
        "Turismo Cultural - Possui bem com Registro de Patrimônio Imaterial",
      field: "registroPatrimonioImaterial",
      like: [true],
      verifyAction: handleScoreType,
      score: "Não",
      currScore: 0,
      maxScore: 1,
      editType: true,
      formatEdit: "select",
      options: ["Sim", "Não"],
      pointType: [1, 0],
      typeDoc: "City",
    },
    {
      title: "Turismo Cultural - Tem Bens tombados pelo IPHAN",
      field: "tombadoIPHAN",
      verifyAction: handleScoreType,
      like: [true],
      score: "Não",
      currScore: 0,
      maxScore: 1,
      typeDoc: "Attractions",
    },
    {
      title:
        "Turismo Cultural - Museus - Qualificação (Fonte Secretaria da Cultura e Economia Criativa)",
      field: "museumsQualification",
      type: "radioType",
      score: "Não",
      currScore: 0,
      maxScore: 4,
      editType: true,
      formatEdit: "select",
      pointType: [0, 1, 2, 3, 4],
      options: ["Não", "Possui cadastro", "Nível 1", "Nível 2", "Nível 3"],
      typeDoc: "City",
      typeSave: true,
    },
    {
      title: "Turismo Cultural - Possui Museus",
      field: "Especialidade",
      like: ["Museu"],
      verifyAction: handleScoreType,
      score: "Não",
      maxScore: 1,
      typeDoc: "Attractions",
    },
    {
      title: "Turismo Cultural - Possui Sítios Arqueológicos",
      field: "Especialidade",
      like: ["Sítio arqueológico"],
      verifyAction: handleScoreType,
      score: "Não",
      maxScore: 1,
      typeDoc: "Attractions",
    },
    {
      title: "Turismo Cultural - Possui Comunidades Tradicionais",
      field: "Especialidade",
      like: ["Comunidade tradicional"],
      verifyAction: handleScoreType,
      score: "Não",
      maxScore: 1,
      typeDoc: "Attractions",
    },
    {
      title: "Turismo Cultural - Possui Ateliês de Artistas com visitação",
      field: "Especialidade",
      like: ["Ateliê"],
      verifyAction: handleScoreType,
      score: "Não",
      maxScore: 1,
      typeDoc: "Attractions",
    },
    // TESTAR
    {
      title: "Turismo Cultural - Possui Feiras/mercados de caráter cultural - turístico",
      field: ["Especialidade", "classificadoAtrativo"],
      like: ["Mercado municipal", "Feira livre", "Feira de artesanato", true],
      verifyAction: scoreMultipleRelations,
      score: "Não",
      type: "scoreMultiple",
      maxScore: 1,
      typeDoc: "Attractions",
    },
    {
      title: "Turismo Cultural - Gastronomia - Possui Prato Típico",
      field: "gastronomyTypicalFood",
      like: [true],
      verifyAction: handleScoreType,
      score: "Não",
      currScore: 0,
      maxScore: 1,
      editType: true,
      formatEdit: "select",
      options: ["Sim", "Não"],
      pointType: [1, 0],
      typeDoc: "City",
    },
    {
      title: "Turismo Cultural - Gastronomia - Possui Certificado de Origem",
      field: "originCertificate",
      like: [true],
      verifyAction: handleScoreType,
      score: "Não",
      currScore: 0,
      maxScore: 1.5,
      editType: true,
      formatEdit: "select",
      options: ["Sim", "Não"],
      pointType: [1.5, 0],
      typeDoc: "City",
    },
    {
      title: "Artesanato - Possui Loja de Artesanato / Souvenir",
      field: "Especialidade",
      like: ["Loja de artesanato"],
      verifyAction: handleScoreType,
      score: "Não",
      currScore: 0,
      maxScore: 1,
      typeDoc: "Attractions",
    },
    {
      title: "Artesanato - Artesão participante do Revelando SP",
      field: "artesaoRevelandoSP",
      like: [true],
      verifyAction: handleScoreType,
      score: "Não",
      currScore: 0,
      maxScore: 1,
      editType: true,
      formatEdit: "select",
      options: ["Sim", "Não"],
      pointType: [1, 0],
      typeDoc: "City",
    },
    {
      title:
        "Turismo Étnico - Possui Manifestação cultural e/ou artística, ligadas a algum grupo étnico",
      field: "manifestacaoCultural",
      like: [true],
      verifyAction: handleScoreType,
      score: "Não",
      currScore: 0,
      maxScore: 1,
      editType: true,
      formatEdit: "select",
      options: ["Sim", "Não"],
      pointType: [1, 0],
      typeDoc: "City",
    },
    {
      title:
        "Turismo Cultural - Possui Centro de tradição, associação, museu, monumentos e construções ligadas a algum grupo étnico",
      field: "centroTradicao",
      like: [true],
      verifyAction: handleScoreType,
      score: "Não",
      currScore: 0,
      maxScore: 1,
      editType: true,
      formatEdit: "select",
      options: ["Sim", "Não"],
      pointType: [1, 0],
      typeDoc: "City",
    },
    // TESTAR
    {
      title:
        "Turismo de Negócios e Eventos - Quantidade de Eventos (Feiras e Congressos)",
      field: "classificacaoEvento",
      like: ["feirasCongressos"],
      verifyAction: handleSegmentType,
      type: "seg",
      score: 0,
      scoreType: [0, 1, 10],
      pointType: [0, 1, 1, 2],
      currScore: 0,
      maxScore: 2,
      typeDoc: "Events",
    },
    {
      title: "Turismo Industrial - Empresas abertas à visitação",
      field: "Subtype",
      like: ["Indústria"],
      verifyAction: handleScoreType,
      score: 0,
      scoreType: [0, 1, 2, 5],
      pointType: [0, 1, 2, 2, 3],
      currScore: 0,
      type: "score",
      maxScore: 3,
      typeDoc: "Attractions",
    },
    {
      title: "Turismo Rural - Quantidade de propriedades com Turismo Rural",
      field: "Subtype",
      like: ["Rural"],
      verifyAction: handleScoreType,
      scoreType: [0, 1, 2, 5, 6, 10],
      pointType: [0, 2, 3, 3, 4, 4, 5],
      currScore: 0,
      score: 0,
      type: "score",
      maxScore: 5,
      typeDoc: "Attractions",
    },

    // CRIAR CAMPO MAS PODE SER DEPOIS
    {
      title: "Capacitação SENAR - Programa de Turismo Rural 2022",
      field: "capacitacaoSENAR2022",
      // like: ['2022'],
      // verifyAction: handleSegmentType,
      like: [true],
      verifyAction: handleScoreType,
      score: "Não",
      currScore: 0,
      maxScore: 2.5,
      editType: true,
      formatEdit: "select",
      options: ["Sim", "Não"],
      pointType: [2.5, 0],
      typeDoc: "City",
    },
    {
      title: "Capacitação SENAR - Programa de Turismo Rural 2023",
      field: "capacitacaoSENAR2023",
      // like: ['2023'],
      // verifyAction: handleSegmentType,
      like: [true],
      verifyAction: handleScoreType,
      score: "Não",
      currScore: 0,
      maxScore: 2.5,
      editType: true,
      formatEdit: "select",
      options: ["Sim", "Não"],
      pointType: [2.5, 0],
      typeDoc: "City",
    },
    {
      title: "Turismo de Compras - Possui Título por Lei Estadual / Federal",
      field: "tituloLeiEstadualFederal",
      like: [true],
      verifyAction: handleScoreType,
      score: "Não",
      currScore: 0,
      maxScore: 3,
      editType: true,
      formatEdit: "select",
      options: ["Sim", "Não"],
      pointType: [3, 0],
      typeDoc: "City",
    },
    {
      title:
        "Turismo de Compras - Possui Comércio Especializado / Produto referência do Município",
      field: "comercioEspecializado",
      like: [true],
      verifyAction: handleScoreType,
      score: "Não",
      currScore: 0,
      maxScore: 1,
      editType: true,
      formatEdit: "select",
      options: ["Sim", "Não"],
      pointType: [1, 0],
      typeDoc: "City",
    },
    {
      title:
        "Turismo de Compras - Possui Abertura do Comércio aos domingos e feriados",
      field: "commerceDisponibility",
      like: [true],
      verifyAction: handleScoreType,
      score: "Não",
      currScore: 0,
      maxScore: 2,
      editType: true,
      formatEdit: "select",
      options: ["Sim", "Não"],
      pointType: [2, 0],
      typeDoc: "City",
    },
    {
      title: "Parques Temáticos - Quantidade de Locais",
      field: "Especialidade",
      like: ["Parque temático e de lazer", "Parque aquático"],
      verifyAction: handleScoreType,
      type: "score",
      scoreType: [0, 1],
      pointType: [0, 1, 3],
      currScore: 0,
      score: 0,
      maxScore: 3,
      typeDoc: "Attractions",
    },
    // SOMAR DOS 2 EVENTOS E ATRATIVOS
    {
      title: "Turismo LGBT+ - Possui Evento LGBT + Locais Gay Friendly",
      field: ["TipoPublico", "classificacaoEvento"],
      like: ["LGBT+", "lgbt"],
      verifyAction: handleScoreLGBT,
      type: "sumDocs",
      score: "Não",
      currScore: 0,
      maxScore: 2.5,
      typeDoc: "Attractions/Events",
    },
    {
      title: "Turismo Ferroviário",
      field: "Especialidade",
      like: ["Trem turístico"],
      verifyAction: handleScoreType,
      score: "Não",
      currScore: 0,
      maxScore: 3,
      typeDoc: "Attractions",
    },
    {
      title: "Terceira Idade - Possui Grupo que realize viagem",
      field: "oldAgeGroupTravel",
      like: [true],
      verifyAction: handleScoreType,
      score: "Não",
      currScore: 0,
      maxScore: 1,
      editType: true,
      formatEdit: "select",
      options: ["Sim", "Não"],
      pointType: [1, 0],
      typeDoc: "City",
    },
    {
      title: "Oferta turística presente no TripAdvisor",
      field: "Tripadvisor_location_id",
      like: [],
      verifyAction: handleVerifyField,
      type: "score",
      scoreType: [0, 5],
      pointType: [0, 0.5, 1],
      currScore: 0,
      score: 0,
      maxScore: 1,
      typeDoc: "Attractions",
    },
  ];

  attractives.map((attr) => {
    const attrData = attr.data();
    score.map((scr) => {
      if (scr.typeDoc === "Attractions" || scr.typeDoc === "Attractions/Events")
        scr.currScore = calculatePointsAttraction(scr, attrData);
    });
  });

  score.map((scr) => {
    if (scr.typeDoc === "City")
      scr.currScore = calculatePointsCity(scr, cityData);
  });

  events.map((even) => {
    const evenData = even.data();
    score.map((scr) => {
      if (scr.typeDoc === "Events" || scr.typeDoc === "Attractions/Events")
        scr.currScore = calculatePointsAttraction(scr, evenData);
    });
  });

  return score;
}

export async function thirdSection(cityData) {
  const attractiveDocs = await firebase
    .firestore()
    .collection("Attractions")
    .where("City", "==", cityData.name)
    .where("Active", "==", true)
    .get();
  const attractives = attractiveDocs.docs;

  const score = [
    {
      title: "Meios de Hospedagens - Quantidade de Meios de Hospedagem",
      field: "Type",
      like: ["Lodging"],
      verifyAction: handleScoreType,
      type: "score",
      scoreType: [0, 1, 2, 3, 9],
      pointType: [0, 1, 1, 2, 2, 3],
      currScore: 0,
      score: 0,
      maxScore: 3,
      typeDoc: "Attractions",
    },
    {
      title: "Meios de Hospedagens com CADASTUR",
      field: ["Type", "Cadastur"],
      like: ["Lodging"],
      verifyAction: handleFieldType,
      type: "score",
      scoreType: [0, 1, 2, 5, 6, 10, 11, 19],
      pointType: [0, 1, 2, 2, 3, 3, 4, 4, 5],
      currScore: 0,
      score: 0,
      maxScore: 5,
      typeDoc: "Attractions",
    },
    {
      title: "Empregados em Meios de Hospedagem",
      field: [
        "Type",
        "Número de Funcionários Fixos",
        "Número de Funcionários Temporários",
      ],
      like: ["Lodging"],
      type: "sum",
      verifyAction: handleSumType,
      scoreType: [0, 1, 19, 20, 49, 50],
      pointType: [0, 1, 1, 1.5, 1.5, 2, 2],
      currScore: 0,
      score: 0,
      maxScore: 2,
      typeDoc: "Attractions",
    },
    {
      title: "Número de Unidade Habitacionais",
      field: "Type",
      like: ["Lodging"],
      verifyAction: handleVerifyUHSField,
      scoreType: [0, 1, 40, 41, 100],
      pointType: [0, 1, 1, 1.5, 1.5, 2],
      currScore: 0,
      type: "sumScore",
      score: 0,
      maxScore: 2,
      typeDoc: "Attractions",
    },
    {
      title: "Quantidade de Ranchos/ Casas de Campo",
      field: "Subtype",
      like: ["Temporada"],
      verifyAction: handleScoreType,
      type: "score",
      scoreType: [0, 10, 11, 49, 50],
      pointType: [0, 1, 1.5, 1.5, 2, 2],
      currScore: 0,
      score: 0,
      maxScore: 2,
      typeDoc: "Attractions",
    },
    {
      title: "Possui AIRBNB para locação eventual",
      field: "haveAirBnb",
      like: [true],
      verifyAction: handleScoreType,
      score: "Não",
      currScore: 0,
      maxScore: 0.5,
      editType: true,
      formatEdit: "select",
      options: ["Sim", "Não"],
      pointType: [0.5, 0],
      typeDoc: "City",
    },
    {
      title: "Quantidade de Meios de Alimentação",
      field: "Type",
      like: ["Food"],
      verifyAction: handleScoreType,
      type: "score",
      scoreType: [0, 1, 5, 6, 10, 11, 20, 21, 50],
      pointType: [0, 0.5, 0.5, 1, 1, 1.5, 1.5, 2, 2, 2.5],
      currScore: 0,
      score: 0,
      maxScore: 2.5,
      typeDoc: "Attractions",
    },
    {
      title: "Capacidade dos Meios de Alimentação",
      field: ["Type", "Capacidade Individual"],
      like: ["Food"],
      type: "sum",
      verifyAction: handleSumType,
      score: 0,
      scoreType: [0, 50, 51, 100, 101, 200, 201, 500],
      pointType: [0, 0.5, 1, 1, 1.5, 1.5, 2, 2, 2.5],
      currScore: 0,
      maxScore: 2.5,
      typeDoc: "Attractions",
    },
    {
      title: "Empregados em A&B",
      field: [
        "Type",
        "Número de Funcionários Fixos",
        "Número de Funcionários Temporários",
      ],
      like: ["Food"],
      type: "sum",
      verifyAction: handleSumType,
      scoreType: [0, 1, 19, 20, 49],
      pointType: [0, 1, 1, 1.5, 1.5, 2],
      currScore: 0,
      score: 0,
      maxScore: 2,
      typeDoc: "Attractions",
    },
    {
      title: "Possui Posto de Informações Turísticas",
      field: "possuiPit",
      like: [true],
      verifyAction: handleScoreType,
      currScore: 0,
      score: "Não",
      maxScore: 1,
      editType: true,
      formatEdit: "select",
      options: ["Sim", "Não"],
      pointType: [1, 0],
      typeDoc: "City",
    },
    {
      title: "PIT Atendimento 7 dias na semana",
      field: "pitAtendimento",
      like: ["sim"],
      verifyAction: handleScoreType,
      currScore: 0,
      score: "Não",
      maxScore: 1,
      typeDoc: "City",
    },
    {
      title:
        "Site do Turismo apresenta meios de hospedagem, restaurantes e atrativos",
      field: "Especialidade",
      like: [true],
      verifyAction: handleScoreType,
      currScore: 1,
      score: "Sim",
      maxScore: 1,
      typeDoc: "City",
    },
    {
      title: "Possui Agência de Receptivo Turístico ",
      field: "Especialidade",
      like: ["Agência de turismo receptivo"],
      verifyAction: handleScoreType,
      score: "Não",
      currScore: 0,
      maxScore: 1.5,
      typeDoc: "Attractions",
    },
    {
      title: "Possui Guia de Turismo ",
      field: "Especialidade",
      like: ["Guia de turismo", "Guia de bike"],
      verifyAction: handleScoreType,
      score: "Não",
      currScore: 0,
      maxScore: 1.5,
      typeDoc: "Attractions",
    },
    {
      title: "Possui Monitores de Turismo  para os Atrativos",
      field: "tourismMonitorsAttraction",
      like: [true],
      verifyAction: handleScoreType,
      score: "Não",
      currScore: 0,
      maxScore: 1,
      editType: true,
      formatEdit: "select",
      options: ["Sim", "Não"],
      pointType: [1, 0],
      typeDoc: "City",
    },
    // TESTAR
    {
      title: "Possui Roteiro Turístico Formatado por agência receptiva",
      field: "qtdRoteiroTuristico",
      like: 0,
      verifyAction: handleCompareValue,
      score: "Não",
      currScore: 0,
      maxScore: 1.5,
      editType: true,
      formatEdit: "select",
      options: ["Sim", "Não"],
      pointType: [1.5, 0],
      typeDoc: "City",
      typeSave: true,
    },
  ];

  attractives.map((attr) => {
    const attrData = attr.data();
    score.map((scr) => {
      if (scr.typeDoc === "Attractions")
        scr.currScore = calculatePointsAttraction(scr, attrData);
    });
  });

  score.map((scr) => {
    if (scr.typeDoc === "City")
      scr.currScore = calculatePointsCity(scr, cityData);
  });

  return score;
}

export async function fourthSection(cityData) {
  const attractiveDocs = await firebase
    .firestore()
    .collection('Attractions')
    .where("City", "==", cityData.name)
    .where("Active", "==", true)
    .get();
  const attractives = attractiveDocs.docs;

  const score = [
    {
      title: "Possui Sinalização Turística no município e acessos",
      field: "SinalizacaoTuristica",
      like: [true, "sim", "Sim"],
      verifyAction: handleScoreType,
      score: "Não",
      currScore: 0,
      maxScore: 1,
      typeDoc: "City",
    },
    {
      title: "Faz parte de Circuito / Região Turística reconhecido pela SETUR",
      field: "nomeRegiao",
      verifyAction: handleScoreType,
      score: "Não",
      currScore: 0,
      maxScore: 2,
      typeDoc: "City",
    },
    {
      title: "Turismólogo/ Técnico em Turismo na equipe municipal",
      field: "thereTurismologistTeam",
      type: "radioType",
      score: "Não",
      currScore: 0,
      maxScore: 1,
      editType: true,
      formatEdit: "select",
      pointType: [0, 1],
      typeDoc: "City",
      options: ["Não", "Sim"],
      typeSave: true,
    },
    {
      title: "Turismólogo/ Técnico concursado na equipe municipal",
      field: "turismologistTech",
      type: "radioType",
      score: "Não",
      currScore: 0,
      maxScore: 2,
      editType: true,
      formatEdit: "select",
      pointType: [0, 2],
      typeDoc: "City",
      options: ["Não", "Sim"],
      typeSave: true,
    },
    {
      title: "Turismólogo/ Técnico em Turismo no COMTUR",
      field: "LeisCOMTURTurismologo",
      // like: ['composiçãoDefinida'],
      // verifyAction: handleSegmentType,
      like: [true],
      verifyAction: handleScoreType,
      type: "seg",
      score: "Não",
      currScore: 0,
      maxScore: 1,
      editType: true,
      formatEdit: "select",
      options: ["Sim", "Não"],
      pointType: [1, 0],
      typeDoc: "City",
    },
    {
      title: "Lei COMTUR com composição definida na Lei 1261/15",
      field: "LeisCOMTUR",
      like: ["composiçãoDefinida"],
      verifyAction: handleSegmentType,
      type: "seg",
      score: "Não",
      currScore: 0,
      maxScore: 80,
      typeDoc: "City",
    },
    {
      title: "Atas com Registro Cartório",
      field: "LeisCOMTUR",
      like: ["registroCartorio"],
      verifyAction: handleSegmentType,
      type: "seg",
      score: "Não",
      currScore: 0,
      maxScore: 1,
      typeDoc: "City",
    },
    {
      title: "Lista de presença ?",
      field: "LeisCOMTUR",
      like: ["listaPresenca"],
      verifyAction: handleSegmentType,
      type: "seg",
      score: "Não",
      currScore: 0,
      maxScore: 1,
      typeDoc: "City",
    },
    {
      title: "Lei com um 2/3 da sociedade civil ?",
      field: "LeisCOMTUR",
      like: ["sociedadeCivil"],
      verifyAction: handleSegmentType,
      type: "seg",
      score: "Não",
      currScore: 0,
      maxScore: 20,
      typeDoc: "City",
    },
    {
      title: "Presidente Iniciativa Privada ou Sociedade Civil",
      field: "presidenteIniciativaPrivada",
      like: [true],
      verifyAction: handleScoreType,
      score: "Não",
      currScore: 0,
      maxScore: 20,
      editType: true,
      formatEdit: "select",
      options: ["Sim", "Não"],
      pointType: [20, 0],
      typeDoc: "City",
    },
    {
      title: "Pesquisa de Demanda - Turismólogo assinando",
      field: "turismologistDemandSearch",
      like: [true],
      verifyAction: handleScoreType,
      score: "Não",
      currScore: 0,
      maxScore: 3,
      editType: true,
      formatEdit: "select",
      options: ["Sim", "Não"],
      pointType: [3, 0],
      typeDoc: "City",
    },
    {
      title: "Mais de 100 formulários aplicados",
      field: "appliedHundredFormDemand",
      verifyAction: handleScoreType,
      like: [true],
      score: "Não",
      currScore: 0,
      maxScore: 1,
      forms: 0,
      typeDoc: "City",
    },
    {
      title: "Lei do Plano atualizada a cada 3 anos",
      field: "directorPlansLawUpdate",
      like: [true],
      verifyAction: handleScoreType,
      score: "Não",
      currScore: 0,
      maxScore: 20,
      editType: true,
      formatEdit: "select",
      options: ["Sim", "Não"],
      pointType: [20, 0],
      typeDoc: "City",
    },
    // TESTAR
    {
      title: "Plano Diretor de Turismo - Turismólogo assinando",
      field: "turismologistDirectorPlan",
      like: [true],
      verifyAction: handleScoreType,
      score: "Não",
      currScore: 0,
      maxScore: 5,
      editType: true,
      formatEdit: "select",
      options: ["Sim", "Não"],
      pointType: [5, 0],
      typeDoc: "City",
    },
    {
      title: "Realizou Audiência Pública",
      field: "realizedPublicHearing",
      like: [true],
      verifyAction: handleScoreType,
      score: "Não",
      currScore: 0,
      maxScore: 5,
      editType: true,
      formatEdit: "select",
      options: ["Sim", "Não"],
      pointType: [5, 0],
      typeDoc: "City",
    },
    {
      title: "Aprovação pelo COMTUR",
      field: "comturAcknowledge",
      like: [true],
      verifyAction: handleScoreType,
      score: "Não",
      currScore: 0,
      maxScore: 5,
      editType: true,
      formatEdit: "select",
      options: ["Sim", "Não"],
      pointType: [5, 0],
      typeDoc: "City",
    },
    {
      title: "Sustentabilidade - Possui selo Verde Azul 2022?",
      field: "possuiSeloVerdeAzul2022",
      like: [true],
      verifyAction: handleScoreType,
      editType: true,
      formatEdit: "select",
      options: ["Sim", "Não"],
      pointType: [2, 0],
      score: "Não",
      currScore: 0,
      maxScore: 2,
      typeDoc: "City",
    },
    {
      title: "Sustentabilidade - Possui selo Verde Azul 2023?",
      field: "possuiSeloVerdeAzul2023",
      like: [true],
      verifyAction: handleScoreType,
      score: "Não",
      editType: true,
      formatEdit: "select",
      options: ["Sim", "Não"],
      pointType: [2, 0],
      currScore: 0,
      maxScore: 2,
      typeDoc: "City",
    },
    {
      title: "População",
      field: ["Populacao"],
      verifyAction: handleSumScoreType,
      score: 0,
      scoreType: [0, 1, 10999, 11000, 29999, 30000, 60999, 61000, 199999],
      pointType: [0, 5, 5, 4, 4, 3, 3, 2, 2, 1],
      currScore: 0,
      type: "sumScore",
      maxScore: 5,
      typeDoc: "City",
    },
    {
      title: "Nível de Atendimento de Abastecimento de Água acima de 90%",
      field: "NivelAbastecimentoAgua",
      like: 90,
      verifyAction: handleCompareValue,
      score: "Não",
      currScore: 0,
      maxScore: 0.5,
      typeDoc: "City",
    },
    {
      title: "Nível de Atendimento de Coleta de Resíduos Sólidos acima de 90%",
      field: "NivelColetaLixo",
      like: 90,
      verifyAction: handleCompareValue,
      score: "Não",
      currScore: 0,
      maxScore: 0.5,
      typeDoc: "City",
    },
    {
      title: "Nível de Atendimento de Coleta de Esgoto acima de 70%",
      field: "NivelSaneamento",
      like: 70,
      verifyAction: handleCompareValue,
      score: "Não",
      currScore: 0,
      maxScore: 0.5,
      typeDoc: "City",
    },
    { // Não ta funfando
      title: "Nível de Atendimento de Tratamento de Esgoto acima 70%",
      field: "NivelTratamentoEsgoto",
      score: "Não",
      type: "radioType",
      currScore: 0,
      maxScore: 0.5,
      typeDoc: "City",
      editType: true,
      formatEdit: "select",
      options: ["Sim", "Não"],
      pointType: [0.5, 0],
      typeSave: true,
    },
    {
      title: "Possui atendimento médico 24 horas (emergência)",
      field: "Especialidade",
      like: ["Hospital e pronto socorro 24h"],
      verifyAction: handleScoreType,
      score: "Não",
      currScore: 0,
      maxScore: 0.5,
      typeDoc: "Attractions",
    },
    {
      title: "Sistema S",
      field: "sSystem",
      like: [true],
      verifyAction: handleScoreType,
      score: "Não",
      currScore: 0,
      maxScore: 1,
      editType: true,
      formatEdit: "select",
      options: ["Sim", "Não"],
      pointType: [1, 0],
      typeDoc: "City",
    },
    {
      title: "CIET / Amitur / Aprecesp / Amitesp",
      field: "cietAmiturAprecespAmitesp",
      like: [true],
      verifyAction: handleScoreType,
      score: "Não",
      currScore: 0,
      maxScore: 2,
      editType: true,
      formatEdit: "select",
      options: ["Sim", "Não"],
      pointType: [2, 0],
      typeDoc: "City",
    },
    {
      title: "Disciplina de Turismo inserida na grade curricular Municipal",
      field: "disciplinaGradeCurricularMunicipal",
      like: [true],
      verifyAction: handleScoreType,
      score: "Não",
      currScore: 0,
      maxScore: 1,
      editType: true,
      formatEdit: "select",
      options: ["Sim", "Não"],
      pointType: [1, 0],
      typeDoc: "City",
    },
    // TESTAR AQUI SE FUNCIONA
    {
      title: "Meios de hospedagens possui acessibilidade",
      field: ["Type", "Acessibilidade"],
      like: ["Lodging", "sim"],
      verifyAction: handleNumEditions,
      score: "Não",
      currScore: 0,
      maxScore: 1,
      typeDoc: "Attractions",
    },
    {
      title: "Restaurante possui acessibilidade",
      field: ["Type", "Acessibilidade"],
      like: ["Food", "sim"],
      verifyAction: handleNumEditions,
      score: "Não",
      currScore: 0,
      maxScore: 1,
      typeDoc: "Attractions",
    },
    {
      title: "Atrativos possui acessibilidade",
      field: ["Type", "Acessibilidade"],
      like: ["Attraction", "sim"],
      verifyAction: handleNumEditions,
      score: "Não",
      currScore: 0,
      maxScore: 1,
      typeDoc: "Attractions",
    },
  ];

  const currYear = new Date().getFullYear();
  const yearsStats = [currYear - 1, currYear - 2];
  const positions = [11, 12];

  yearsStats.forEach((year, index) => {
    score.splice(positions[index], 0, {
      title: `Aplicou Estudo de Demanda em ${year}?`,
      field: "yearsApplyDemand",
      like: [year.toString()],
      verifyAction: handleSegmentType,
      score: "Não",
      currScore: 0,
      maxScore: 1,
      typeDoc: "City",
    });
  });

  attractives.map((attr) => {
    const attrData = attr.data();
    score.map((scr) => {
      if (scr.typeDoc === "Attractions")
        scr.currScore = calculatePointsAttraction(scr, attrData);
    });
  });

  score.map((scr) => {
    if (scr.typeDoc === "City") {
      scr.currScore = calculatePointsCity(scr, cityData);
    }
  });

  return score;
}

function handleMultipleRelations(like, field, item) {
  for (let index = 0; index < field.length; index ++) {
    if (!item[field[index]] || item[field[index]] !== like[index])
      return false;
  }

  return true;
}

function handleScoreType(like, field, item) {
  if (!like) return !!item[field];

  return like.includes(item[field]);
}

function handleSegmentType(like, field, item) {
  if (!item[field]) return false;

  for (let index = 0; index < like.length; index++) {
    if (item[field].includes(like[index])) return true;
  }

  return false;
}

function handleSegmentAdventureType(like, field, item) {
  if (!item[field[0]]) return false;

  const types = [like[0], like[1], like[2]];

  if (
    types.includes(item[field[0]]) &&
    item[field[1]] &&
    item[field[1]].includes(like[3])
  )
    return true;

  return false;
}

function handleFieldType(like, field, item) {
  if (like.includes(item[field[0]])) return !!item[field[1]];

  return false;
}

function handleSumScoreType(field, item) {
  if (!item[field[0]]) return 0;

  let sum = 0;

  for (let i = 0; i < field.length; i++)
    sum += item[field[i]] ? Number(item[field[i]]) : 0;

  return sum;
}

function handleSumType(like, field, item) {
  let sum = 0;

  if (like) {
    if (like.includes(item[field[0]])) {
      for (let i = 1; i < field.length; i++)
        sum += item[field[i]] ? item[field[i]] : 0;
    }
  } else {
    for (let i = 1; i < field.length; i++)
      sum += item[field[i]] ? Number(item[field[i]]) : 0;
  }

  return sum;
}

function handleScore(score, scoreType, pointType, maxScore, options) {
  if (scoreType) {
    for (let index = 0; index < scoreType.length; index++) {
      if (score < scoreType[index]) return pointType[index - 1];

      if (score === scoreType[index]) return pointType[index];
    }
  } else if (score === "Não") {
    return 0;
  } else if (options !== undefined) {
    const val = options.findIndex((element) => {
      return element === score;
    });

    return pointType[val];
  }

  return maxScore;
}

function handleNumEditions(like, field, item) {
  if (field.includes("classificacaoEvento")) {
    if (!item[field[0]] || !item[field[0]].includes(like[0])) {
      return false;
    }
    if (item[field[1]] === "sim") {
      return true;
    }
    return false;
  }
  if (!like.includes(item[field[0]])) return false;

  return like.includes(item[field[1]]);
}

function handleVerifyField(like, field, item) {
  if (item.Type !== "Attraction")
    return false;

  return item[field] !== null && item[field] !== undefined;
}

function handleCompareValue(like, field, item) {
  if (!item[field]) return false;

  return Number(item[field]) > like;
}

function handleScoreLGBT(like, field, item, doc) {
  if (doc) {
    return item[field[0]] ? item[field[0]].includes(like[0]) : false;
  }

  return item[field[1]] ? item[field[1]].includes(like[1]) : false;
}

function handleVerifyUHSField(field, item) {
  if (!item.UHs) return false;

  if (item[field] !== "Lodging") return false;

  return Number(item.UHs);
}

function scoreMultipleRelations(like, field, item) {
  for (let index = 0; index < field.length; index++) {
    if (!like.includes(item[field[index]]))
      return false;
  }

  return true;
}

function calculatePointsAttraction(scr, attrData) {
  if (scr.type === "score")
    scr.score += scr["verifyAction"](scr.like, scr.field, attrData) ? 1 : 0;
  else if (scr.type === "sum")
    scr.score += scr["verifyAction"](scr.like, scr.field, attrData, scr.score);
  else if (scr.type === "sumScore")
    scr.score += scr["verifyAction"](scr.field, attrData);
  else if (scr.score === "Não")
    scr.score = scr["verifyAction"](scr.like, scr.field, attrData, 1)
      ? "Sim"
      : "Não";
  else if (scr.type === "seg" && scr.score !== "Sim")
    scr.score += scr["verifyAction"](scr.like, scr.field, attrData) ? 1 : 0;

  return handleScore(
    scr.score,
    scr.scoreType,
    scr.pointType,
    scr.maxScore,
    scr.options
  );
}

function calculatePointsCity(scr, cityData, eventDocs) {

  if (scr.type === "score")
    scr.score += scr["verifyAction"](scr.like, scr.field, cityData) ? 1 : 0;
  else if (scr.type === "sum")
    scr.score += scr["verifyAction"](scr.like, scr.field, cityData, scr.score);
  else if (scr.type === "sumScore")
    scr.score += scr["verifyAction"](scr.field, cityData);
  else if (scr.type === "other")
    scr.score += scr["verifyAction"](
      scr.like,
      scr.field,
      cityData,
      scr.collection
    )
      ? 1
      : 0;
  else if (scr.type === "flux")
    scr.score = scr["verifyAction"](scr.field, cityData, eventDocs);
  else if (scr.type === "radioType")
    scr.score = cityData[scr.field] !== undefined ? cityData[scr.field] : "Não";
  else if (scr.score === "Não")
    scr.score = scr["verifyAction"](scr.like, scr.field, cityData, 0)
      ? "Sim"
      : "Não";

  return handleScore(
    scr.score,
    scr.scoreType,
    scr.pointType,
    scr.maxScore,
    scr.options
  );
}

// REMOVED POINTS
/*
  {
    title: 'Quantidade de Eventos',
    verifyAction: handleSumEvents,
    score: 0,
    scoreType: [0, 1, 10],
    pointType: [0, 1, 1, 2],
    currScore: 0,
    type: 'sumEvent',
    maxScore: 2,
  },
  {
    title: 'Eventos Fluxo (Fluxo Evento/População)',
    field: ['estimativaPublico', 'Populacao'],
    verifyAction: handleFluxScore,
    scoreType: [0, 1, 1.01, 10],
    pointType: [0, 1, 2, 2, 3],
    currScore: 0,
    type: 'flux',
    score: 0,
    maxScore: 3,
  },
  {
    title: 'Site com informações dos atrativos ?',
    field: '',
    like: [true],
    verifyAction: handleScoreType,
    currScore: 0.5,
    score: 'Sim',
    maxScore: 0.5,
  },
  {
    title: 'Site com informações dos Meios Hospedagem ?',
    field: '',
    like: [true],
    verifyAction: handleScoreType,
    currScore: 0.5,
    score: 'Sim',
    maxScore: 0.5,
  },
  {
    title: 'Site com informações dos Restaurantes ?',
    field: '',
    like: [true],
    verifyAction: handleScoreType,
    currScore: 0.5,
    score: 'Sim',
    maxScore: 0.5,
  },
  {
    title: 'Site com informações do Receptivo/Passeios ?',
    field: '',
    like: [true],
    verifyAction: handleScoreType,
    currScore: 0.5,
    score: 'Sim',
    maxScore: 0.5,
  },
  
  {
    title: 'Tem circuito Quilombola?',
    field: 'temCircuitoQuilombola',
    like: [true],
    verifyAction: handleScoreType,
    currScore: 0,
    score: 'Não',
    maxScore: 1,
  },
  {
    title: 'Possui Plano Diretor de Turismo ?',
    field: 'PlanoDiretorTurismo',
    like: ['sim'],
    verifyAction: handleScoreType,
    score: 'Não',
    currScore: 0,
    maxScore: 1,
  },
  {
    title: 'Roteiro Turístico Formatado por agência receptiva - Qtde de produtos',
    field: ['qtdRoteiroTuristico'],
    verifyAction: handleSumScoreType,
    score: 0,
    type: 'sumScore',
    scoreType: [0, 1],
    pointType: [0, 1, 2],
    currScore: 0,
    maxScore: 2,
  },
   {
    title: 'Atrativos Naturais',
    field: 'Subtype',
    like: ['Natural'],
    verifyAction: handleScoreType,
    type: 'score',
    score: 0,
    scoreType: [0, 1, 3, 4, 6],
    pointType: [0, 1, 1, 2, 2, 3],
    currScore: 0,
    maxScore: 3,
  },
  {
      title: 'Atrativos Culturais',
      field: 'Subtype',
      like: ['Cultural'],
      type: 'score',
      verifyAction: handleScoreType,
      scoreType: [0, 1],
      pointType: [0, 1, 2], 
      currScore: 0,
      score: 0,
      maxScore: 2,
    },
   {
    title: 'Artesanato',
    field: 'Subtype',
    like: ['Artesanato'], MOVER PARA CIDADES
    score: 'Não',
    currScore: 0,
    maxScore: 1,
    verifyAction: handleScoreType,
  }       
    {
    title: 'Possui Serviço de Informação Turística',
    field: 'Especialidade',
    like: ['Informações turísticas'],
    verifyAction: handleScoreType,
    currScore: 0,
    score: 'Não',
    maxScore: 1,
  }    
  {
      title: 'Centro histórico tombado?',
      field: 'centroHistoricoTombado',
      verifyAction: handleScoreType,
      like: [true],
      score: 'Não',
      currScore: 0,
      maxScore: 1,
    },
    {
      title: 'Tem Bens tombados pelo IPHAN?',
      field: 'tombadoIPHAN',
      verifyAction: handleScoreType,
      like: [true],
      score: 'Não',
      currScore: 0,
      maxScore: 1,
    },
  {
    title: 'Turismo Religioso - Quantidade de locais de Cunho Religioso',
    field: 'Especialidade',
    like: ['Arquitetura religiosa'],
    verifyAction: handleScoreType,
    score: 0,
    scoreType: [0, 1, 2, 5],
    pointType: [0, 1, 2, 2, 3], 
    currScore: 0,
    type: 'seg',
    maxScore: 3,
  },
  {
    title: 'Turismo LGBT+ Locais Gay Friendly',
    field: 'lgbt',
    like: [true],
    verifyAction: handleScoreType,
    score: 'Não',
    currScore: 0,
    maxScore: 1,
  },
*/
