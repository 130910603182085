import React, { useState } from 'react';

// CSS import
import '../../assets/css/InputComponents/waypointInput.css';
import WaypointMap from '../FormComponents/WaypointMap';
import { GeolocationItineraryInput } from './GeolocationItineraryInput';

export function WaypointInput({ field, title, value, setValue, doc, objectPend, userCity, explanation }) {
  const [type, setType] = useState('file');

  return (
    <div className='waypointInput'>
      <div className='div_align_input_geolocation'>
        <div className='div_label_input_geolocation'>
          <label className='label_text_input'>
            <strong>{title}</strong>
          </label>
        </div>
        <div className='div_explanation_waypoint_input'>
          <div className="div_align_button_type_waypoint">
            <div id={type === 'file' ? 'typeSelectWaypoint' : 'typeUnselectWaypoint'} className="div_button_type_waypoint" onClick={() => setType('file')}>
              <span>Arquivo</span>
            </div>
            <div id={type === 'map' ? 'typeSelectWaypoint' : 'typeUnselectWaypoint'} className="div_button_type_waypoint" onClick={() => setType('map')}>
              <span>Mapa</span>
            </div>
          </div>
          {
            type === 'map' ? <WaypointMap field={field} value={value} setValue={setValue} doc={doc} objectPend={objectPend} userCity={userCity} explanation={explanation} /> : <GeolocationItineraryInput value={value} setValue={setValue} explanation={explanation} />
          }
        </div>
      </div>
    </div>
  );
}