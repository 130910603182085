import React, { useContext, useEffect, useState } from 'react'; 

// CSS import
import '../../assets/css/Dashboard/dashBoardAdmin.css';

// Components Imports
import Loading from '../../component/Loading';
import AgencyInfo from '../../component/DashboardComponents/AgencyInfo';
import EventsCityInfo from '../../component/DashboardComponents/EventsCityInfo';
import EmployeesInfo from '../../component/DashboardComponents/EmployeeInfo';
import { CardHeatmap } from '../../component/DashboardComponents/CardHeatmap';

// eslint-disable-next-line import/no-cycle
import { MyContext } from '../../component/SidebarAdmin';
import { CardDashboard } from '../../component/DashboardComponents/CardDashboard';
import { CardInventoryAdmin } from '../../component/DashboardComponents/CardInventoryAdmin';
import AtractiveOperator from '../../component/DashboardComponents/AtractiveOperator';
import { CardTableDashboard } from '../../component/DashboardComponents/CardTableDashboard';

import { handleStatisticsEvents, handleGetLocationCity, handleStatisticsAttractions, handleStatisticsInventory, handleStatisticsWords, handleStatisticsUsers, handleStatisticsLog } from '../../utils/dashBoardManager';
import CardChartDashboard from '../../component/DashboardComponents/CardChartDashboard';

export default function DashboardAdmin() {
  const { city, neighborhood } = useContext(MyContext);
  const [cityStats, setCityStats] = useState();
  const [date, setDate] = useState();
  const [attracStats, setAttracStats] = useState();
  const [invStats, setInvStats] = useState();
  const [eventsStats, setEventsStats] = useState();
  const [wordsStats, setWordsStats] = useState();
  const [usersStats, setUsersStats] = useState();
  const [logStats, setLogStats] = useState();
  const [dateMap, setDateMap] = useState({
    initialDate: '2020-01-01',
    finalDate: (new Date()).toString(),
  });

  function handleSetDate() {
    const firstDate = new Date();
    const lastDate = new Date();
  
    firstDate.setMonth(firstDate.getMonth() - 1);
  
    setDate({
      initialDate: firstDate.toISOString().slice(0, 10),
      finalDate: lastDate.toISOString().slice(0, 10),
    });
  }

  async function handleSetStats() {
    const cityStats = await handleGetLocationCity(city);
    const attracStats = await handleStatisticsAttractions(neighborhood, city);
    const eventsStats = await handleStatisticsEvents(neighborhood, city);
    const wordsStats = await handleStatisticsWords(neighborhood, city);
    const logStats = await handleStatisticsLog(neighborhood, city);

    setCityStats(cityStats);
    setAttracStats(attracStats);
    setEventsStats(eventsStats);
    setWordsStats(wordsStats);
    setLogStats(logStats);
  }

  async function handleSetStatsInv() {
    const invStats = await handleStatisticsInventory(city, date);

    setInvStats(invStats);
  }

  async function handleSetStatsUsers() {
    const usersStats = await handleStatisticsUsers(neighborhood, city, dateMap);

    setUsersStats(usersStats);
  }

  useEffect(() => {
    handleSetDate();
  }, []);

  useEffect(() => {
    if (neighborhood && city) {
      handleSetStats();
    }
  }, [neighborhood, city]);

  useEffect(() => {
    if (neighborhood && city && date)
      handleSetStatsInv();
  }, [neighborhood, city, date]);

  useEffect(() => {
    if (neighborhood && city)
      handleSetStatsUsers();
  }, [neighborhood, city, dateMap]);

  if (!city || !date || !cityStats || !attracStats || !eventsStats || !invStats || !wordsStats || !logStats) {
    return <Loading />;
  }

  return (
    <div className='InventarioAdmin'>
      <div className='div_small_container_dash'>
        <div className='div_cardInfo_dashboard'>
          <AgencyInfo infoNumAgency={attracStats.totalAgency} infoNumArt={attracStats.totalArt} />
          <EventsCityInfo eventsLastYear={eventsStats.eventsLastYear} eventsThisYear={eventsStats.eventsThisYear} nextEvents={eventsStats.nextEvents} totalEvents={eventsStats.totalEvents} />
          <EmployeesInfo totalFix={attracStats.qtdFix} totalTemp={attracStats.qtdTemp} />
        </div>
        <div className='div_cardsdashboard'>
          {
            Object.keys(attracStats.stats).map((item, index) => {
              return <CardDashboard key={index} city={city} setor={attracStats.stats[item].name} stats={attracStats.stats[item].value} />;
            })
          }
        </div>
      </div>  
      <div className='div_cards_places_info'>
        <CardInventoryAdmin dateObj={date} setDateObj={setDate} placesInfo={invStats.placesInfo} total={invStats.total} />
        <CardHeatmap location={cityStats.location} date={dateMap} setDate={setDateMap} attractionsCord={usersStats.attractionsCord} qtdUsers={usersStats.qtdUsers} qtdInit={usersStats.qtdInit} city={city} />
      </div>
      <div className='div_align_cards_sunburst'>
        { Object.keys(attracStats.taxonData).length > 0 ? <CardChartDashboard title={'Distribuição do Inventário'} data={attracStats.taxonData} /> : null }
        { Object.keys(logStats.taxonData).length > 0 ? <CardChartDashboard title={'Relação de Taxonomia de atrativos acessados pelos usuários'} data={logStats.taxonData} /> : null }
      </div>
      <div className='div_cards_operator_segments_info'>
        <CardTableDashboard title={'Segmentação da Cidade'} titleColumn={'Mapa do Segmento'} titleSemicolumn={'Qtd de Segmentos'} segments={attracStats.segments} />
        <CardTableDashboard title={'Palavras mais buscadas'} titleColumn={'Palavra'} titleSemicolumn={'N° de Ocorrências'} segments={wordsStats.segments} />
      </div>
    </div>
  );
}