import { valuesPeopleType, valuesTypeEvent } from '../InputComponents/ValuesCheckBox';

export const fieldsEditEvents = [
  {
    title: 'Titulo*',
    field: 'Title',
    type: 'text',
    boolType: false,
    component: 'text',
    placeholder: '',
    explanation: 'Coloque aqui o nome do evento, com letras maiúsculas e minúsculas',
  },
  {
    title: 'Cidade',
    field: 'City',
    boolType: false,
    component: 'select',
    disable: true,
  },
  {    
    title: 'Local',
    field: 'AttractionId',
    boolType: false,
    component: 'selectLocal',
    disable: false,
  },
  {
    title: 'Data de início*',
    field: 'StartDate',
    type: 'datetime-local',
    boolType: false,
    component: 'date',
  },
  {
    title: 'Data de término*',
    field: 'EndDate',
    type: 'datetime-local',
    boolType: false,
    component: 'date',
  },
  {    
    title: 'Telefone',
    field: 'Telefone',
    type: 'text',
    boolType: false,
    component: 'text',
    placeholder: '(xx) xxxx-xxxx',
  },
  {    
    title: 'WhatsApp',
    field: 'Whatsapp',
    type: 'text',
    boolType: false,
    component: 'text',
    placeholder: '(xx) xxxx-xxxx',
  },
  {    
    title: 'Email',
    field: 'Email',
    type: 'text',
    boolType: false,
    component: 'text',
    placeholder: '',
  },
  {    
    title: 'Site',
    field: 'Site',
    type: 'text',
    boolType: false,
    component: 'text',
    placeholder: '',
    link: true,
  },
  {    
    title: 'Vídeo (Link)',
    field: 'Video',
    type: 'text',
    boolType: false,
    component: 'text',
    placeholder: '',
    link: true,
  },
  {    
    title: 'Programação (Link)',
    field: 'linkProgramacao',
    type: 'text',
    boolType: false,
    component: 'text',
    placeholder: '',
    link: true,
  },
  {    
    title: 'Cardápio (Link)',
    field: 'linkCardapio',
    type: 'text',
    boolType: false,
    component: 'text',
    placeholder: '',
    link: true,
  },
  {    
    title: 'Facebook',
    field: 'Facebook',
    type: 'text',
    boolType: false,
    component: 'text',
    placeholder: '',
    link: true,
  },
  {    
    title: 'Instagram',
    field: 'Instagram',
    type: 'text',
    boolType: false,
    component: 'text',
    placeholder: '',
    link: true,
  },
  {
    title: 'Tipo de Evento',
    field: 'tipoEvento',
    type: 'radio',
    boolType: false,
    component: 'radio',
  },
  {    
    title: 'Evento ocorreu mais de 5 edições',
    field: 'numEditions',
    type: 'radio',
    boolType: false,
    component: 'radio',
    rankingPoints: true,
  },
  /* {    
    title: 'Segmento Turístico',
    field: 'Segmento Turístico',
    type: 'checkbox',
    boolType: false,
    component: 'checkbox',
    checkboxValues: valuesSegments,
    typeCheck: true,
  }, */
  {    
    title: 'Lista de Atividades',
    field: 'Activities List',
    type: 'text',
    boolType: false,
    component: 'adder',
    explanation: 'Descreva as atividades praticadas no local, informações da infraestrutura',
  },
  {    
    title: 'Lista de Imagens',
    field: 'imagefile',
    type: 'file',
    boolType: false,
    component: 'image',
    explanation: 'Tamanho máximo da imagem: 1MB (MegaBytes)',
  },
  {    
    title: 'Classificação do Evento',
    field: 'classificacaoEvento',
    type: 'checkbox',
    boolType: false,
    component: 'checkbox',
    checkboxValues: valuesTypeEvent,
    typeCheck: true,
    rankingPoints: true,
  },
  /* {    
    title: 'Características',
    field: 'typeLocal',
    type: 'checkbox',
    boolType: false,
    component: 'checkbox',
    checkboxValues: valuesTypeLocalEvent,
    typeCheck: false,
  }, */
  {    
    title: 'Faixa Etária',
    field: 'FaixaEtaria',
    type: 'text',
    boolType: false,
    component: 'text',
    placeholder: '',
  },
  {    
    title: 'Tipo de Público',
    field: 'TipoPublico',
    type: 'checkbox',
    boolType: false,
    component: 'checkbox',
    checkboxValues: valuesPeopleType,
    typeCheck: true,
    explanation: 'Marque apenas o tipo de público específico',
  },
  {    
    title: 'Estimativa de Público',
    field: 'estimativaPublico',
    type: 'number',
    boolType: false,
    component: 'text',
    explanation: 'Uma estimativa da quantidade de pessoas que compareceram no evento',
    rankingPoints: true,
  },
  {    
    title: 'Descrição Curta*',
    field: 'Description',
    type: 'textarea',
    boolType: false,
    component: 'textarea',
    placeholder: '',
    explanation: 'Uma breve descrição sobre o evento',
  },
  {    
    title: 'Descrição Longa*',
    field: 'Long Description',
    type: 'textarea',
    boolType: false,
    component: 'textarea',
    placeholder: '',
    explanation: 'Uma descrição mais detalhada sobre o evento',
  },
];