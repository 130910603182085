import React, { Component } from 'react';
import Select from 'react-select';
import firebase from '../config/Firebase';

export default class SingleSelect extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isClearable: true,
      isDisabled: false,
      isLoading: false,
      isRtl: false,
      isSearchable: true,
      cidades: [],
    };
  }

  retrieveData() {
    // Procura na coleção Cities as cidades disponíveis
    // Edimar - Ordenar retorno de cidade combo
    firebase.firestore().collection('Cities').orderBy('name', 'asc').get()
      .then(async (querySnapshot) => {
        this.setState({
          cidades: querySnapshot.docs.map((doc) => {
            return {
              value: doc.data().name,
              label: doc.data().name,
            };
          }),
        });
      });
  }

  componentDidMount() {
    this.retrieveData();
  }

  render() {
    const {
      isClearable,
      isSearchable,
      isDisabled,
      isLoading,
      isRtl,
    } = this.state;
    const customStyles = {
      input: () => ({
        height: 40,
      }),
    };
    return (
        <Select
          className={this.props.className}
          classNamePrefix="select"
          placeholder={this.props.placeholder}
          isDisabled={isDisabled}
          isLoading={isLoading}
          isClearable={isClearable}
          isRtl={isRtl}
          isSearchable={isSearchable}
          name='city'
          options={this.state.cidades}
          onChange={this.props.onChange}
          styles={customStyles}
        />
    );
  }
}
